import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GetSitesService {
  constructor(private http: HttpClient) {}

  getSites(): Observable<string[]> {
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetSafetyCultureSites`;
    return this.http
      .get<any>(url).pipe(
        map((response) =>
          response.data.map((site: string) => site.trim()).sort()
        )
      );  
  }
}

<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [id]="id" [deductionStatus]="deductionStatus"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
        <app-header></app-header>
        <div class="main-content-inner">
            <div class=" mt-3">
                <!-- market value area start -->
                <div *ngIf="!loadingFiles" class="table-responsive ">
                    <table *ngIf="!openDeduction" class="table table-hover align-middle bg-white">
                        <thead>
                            <tr>
                                <th colspan="3">Deductions</th>
                            </tr>
                        </thead>
                        <tbody class="m-3">
                            <ng-container *ngIf="queryData">
                                <ng-container *ngFor="let item of queryData">
                                    <tr class="m-3">
                                        <td colspan="2">
                                            {{item.companyName}}
                                        </td>
                                        <td style="align-self: center;">
                                            <button class=" btn btn-sm btn-primary px-3 mr-3" type="button"
                                                (click)="viewDeduction(item)" title="View File">
                                                View
                                            </button>
                                            <app-status-icons [statusValue]="item.deductionStatus=='Complete'?'true':'false'"></app-status-icons>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!queryData">
                                <tr>
                                    <td colspan="3" class="text-center">No Data
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <div *ngIf="openDeduction" class="table-responsive">
                        <div>
                            <button (click)="openDeduction=false" class=" btn badge badge-primary my-3"
                                type="button">Back</button>
                            <div class="card">
                                <div class="card-body" style="overflow-x: auto">
                                    <h5>Deductions Each Pay</h5>
                                    <table
                                        class="table table-bordered tablecenter table-hover align-middle mb-0 bg-white">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount Each Pay </th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Amount Owed</th>
                                                <th>Total Paid</th>
                                                <th>Balance</th>
                                                <th colspan="3"></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="unlimitedData.length">
                                            <tr *ngFor="let item of unlimitedData">
                                                <th>{{item.name?item.name:'-'}}</th>
                                                <td>{{item.amount?(item.amount |number:'1.2-2'):'-'}}</td>
                                                <td>{{(item.date_from!='-' && item.date_from!=null )?(item.date_from |
                                                    date: 'dd-MM-yyyy'):'-'}}</td>
                                                <td>
                                                    {{ (item.date_to != '-' && item.date_to != 'PAID'&&
                                                    item.date_to!=null ) ? (item.date_to | date: 'dd-MM-yyyy') :'-' }}
                                                </td>
                                                <td>{{item.amount_owed?(item.amount_owed |number:'1.2-2'):'-'}}</td>
                                                <td>{{item.amount_taken?(item.amount_taken|number:'1.2-2'):'-'}}</td>
                                                <td>{{(item.balance ||item.balance==0)?(item.balance|number:'1.2-2'):'-'}}</td>
                                                <td>
                                                    <button *ngIf="item.isPayOut===false" type="button" class="btn btn-sm btn-danger text-center tButton" 
                                                    data-toggle="modal" data-target="#staticBackdrop" (click)="stopDeduction(item)">Stop</button> 
                                                    <button disabled title="Pending for approval." type="button" class="btn btn-sm tButton btn-light" *ngIf="item.isPayOut===true">Pending</button>                                                     
                                                </td>
                                                <td *ngIf="item.isAstute===false">
                                                    <app-status-icons [statusValue]="item.status=='Complete'?'true':'false'"></app-status-icons>
                                                </td>
                                                <td *ngIf="item.isAstute===false">
                                                    <span *ngIf="item.fileId"
                                                        style="display: flex; align-items: center;">
                                                        <button class="btn btn-sm btn-primary mr-1" title="Download"
                                                            (click)="onClickDownload(item)">
                                                            <i class="fa fa-download" style="font-size: 12px"></i>
                                                        </button>
                                                        <button title="View" [id]="'item_' + item.id"
                                                            class="btn btn-sm btn-info" (click)="onClickView(item)">
                                                            <i class="fa fa-file-text-o" style="font-size: 12px"></i>
                                                        </button>
                                                    </span>
                                                    <span *ngIf="!item.fileId || item.isSigned===false">
                                                        <button disabled title="Check your email to add sign." type="button" class="btn btn-sm  px-3 btn-light" *ngIf="item.isSigned===false">Pending</button>                                                      
                                                        <button  *ngIf="item.isSigned!==false && item.isSigned!==true"   type="button"
                                                                class="btn btn-sm btn-secondary px-3 tButton" data-toggle="modal"
                                                                data-target="#AuthorityModal"
                                                            (click)="createDeductionSlip(item)" title="Sign File">
                                                            Sign
                                                        </button>
                                                    </span>
                                                </td>
                                                <td *ngIf="item.isAstute===true" colspan="2"></td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="!unlimitedData.length">
                                            <tr>
                                                <td colspan="8" class="text-center"> No data. </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br />
                            <div class="card">
                                <div class="card-body" style="overflow-x: auto">
                                    <h5>Fixed Deductions</h5>
                                    <table
                                        class="table table-bordered tablecenter table-hover align-middle mb-0 bg-white">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount Each Pay </th>
                                                <th>Start Date</th>
                                                <th>Estimated End Date</th>
                                                <th>Total Amount</th>
                                                <th>Total Paid</th>
                                                <th>Balance</th>
                                                <th colspan="3"></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="fixedData.length">
                                            <tr *ngFor="let item of fixedData">
                                                <th>{{item.name?item.name:'-'}}</th>
                                                <td>{{item.amount?(item.amount | number:'1.2-2'):'-'}}</td>
                                                <td>{{(item.date_from!='-' && item.date_from!=null )?(item.date_from |
                                                    date: 'dd-MM-yyyy'):'-'}}</td>
                                                <td>
                                                    {{ (item.date_to != '-' && item.date_to != 'PAID' && item.date_to !=
                                                    null)? (item.date_to | date: 'dd-MM-yyyy') :item.date_to ==
                                                    'PAID'?'PAID': '-' }}
                                                </td>
                                                <td>{{item.limit_fixed?(item.limit_fixed | number:'1.2-2'):'-'}}</td>
                                                <td>{{item.amount_taken?(item.amount_taken | number:'1.2-2'):'-'}}</td>
                                                <td>{{(item.balance||item.balance==0)?(item.balance | number:'1.2-2'):'-'}}</td>
                                                <td>
                                                    <button *ngIf="item.balance>0 && item.isPayOut===false &&  (item.isSigned===true || item.fileId || item.isAstute)" type="button" class="btn btn-sm btn-success text-center tButton"
                                                        data-toggle="modal" data-target="#staticBackdrop" (click)="payOutDeduction(item)">Pay Out</button> 
                                                    <button *ngIf="item.isPayOut===true" disabled title="Pending for approval." type="button" class="btn btn-sm tButton btn-light">Pending</button> 
                                                </td>
                                                <td *ngIf="item.isAstute===false">
                                                    <app-status-icons [statusValue]="item.status=='Complete'?'true':'false'"></app-status-icons>
                                                </td>
                                                <td *ngIf="item.isAstute===false">
                                                    <span *ngIf="item.fileId"
                                                        style="display: flex; align-items: center;">
                                                        <button class="btn btn-sm btn-primary mr-1" title="Download"
                                                            (click)="onClickDownload(item)">
                                                            <i class="fa fa-download" style="font-size: 12px"></i>
                                                        </button>
                                                        <button title="View" [id]="'item_' + item.id"
                                                            class="btn btn-sm btn-info" (click)="onClickView(item)">
                                                            <i class="fa fa-file-text-o" style="font-size: 12px"></i>
                                                        </button>
                                                    </span>
                                                    <span *ngIf="!item.fileId">
                                                        <button *ngIf="item.isSigned===false" disabled title="Check your email to add sign." type="button" class="btn btn-sm px-3 btn-light" >Pending</button>                                                      
                                                        <button  *ngIf="item.isSigned!==false && item.isSigned!==true"   type="button"
                                                                class="btn btn-sm btn-secondary px-3 tButton" data-toggle="modal"
                                                                data-target="#AuthorityModal"
                                                            (click)="createDeductionSlip(item)" title="Sign File">
                                                            Sign
                                                        </button>
                                                    </span>
                                                </td>
                                                <td *ngIf="item.isAstute===true" colspan="2"></td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="!fixedData.length">
                                            <tr>
                                                <td colspan="8" class="text-center"> No data. </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- market value area end -->
                    </div>
                </div>
                <div *ngIf="loadingFiles">
                    <li class="t_loader">
                        <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load"></div>
                    </li>
                </div>
            </div>
        </div>
        <!-- main content area end -->
    </div>
    <!-- Modal AuthorityModal -->
    <div class="modal fade" id="AuthorityModal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog"
        aria-labelledby="AuthorityModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="AuthorityModalTitle">Authority To Deduct</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"
                            (click)="closeAuthorityModal('AuthorityModal');payoutATD=false">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="!loading && !successMessage" class="card table-responsive">
                        <div class="card-body" id="ATD_pdf">
                            <div class="top p-2">
                                <div style="display: flex; justify-content:space-between;">
                                    <div class="px-3 py-3">
                                        <img [src]='brandLogo' alt="logo" width="55%" />
                                    </div>
                                    <div class="mr-3">
                                        <table class="table table-sm table-borderless ">
                                            <tr style="font-size: 12px; color: #818285;">
                                                {{brandPhone}} <br>
                                                G01N 527 Gregory Terrace,<br>
                                                Fortitude Valley QLD 4006<br>
                                                ABN 23 142 526 216<br>
                                                ACN 142 526 216<br>
                                                {{brandUrl}}<br>
                                                <hr style="background-color: #0C578E;">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14"
                                                    viewBox="0 0 448 512" class="mr-2" style="color: #0C578E;">
                                                    <path
                                                        d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                                                        fill="#0C578E" />
                                                </svg>{{brandName}}<br>
                                                <span *ngIf="brandName!=='Health Plus People'">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="10"
                                                    viewBox="0 0 320 512" class="mr-2" style="color: #0C578E;">
                                                    <path
                                                        d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                                                        fill="#0C578E" />
                                                </svg>&#64;agrilabouraus<br>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14"
                                                    viewBox="0 0 448 512" class="mr-2" style="color: #0C578E;">
                                                    <path
                                                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                                        fill="#0C578E" />
                                                </svg>&#64;agrilabourau<br>
                                                </span>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="mx-4">
                                    <h3> <strong>AUTHORITY FOR PAYROLL DEDUCTIONS</strong> </h3>
                                    <h6>I <strong> {{name}} </strong>authorize {{brandName}} Pty Ltd to make
                                        deductions to my pay, as specified in the table below.</h6>
                                    <div class="table-responsive">
                                        <table class="table table-sm table-bordered table-hover p-3">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">Description</th>
                                                    <ng-container *ngIf="!payoutATD">
                                                        <th scope="col">Amount Each Pay</th>
                                                        <th scope="col">Total Amount</th>
                                                        <th scope="col">Commencing</th>
                                                        <th scope="col">Notes</th>
                                                    </ng-container>
                                                    <ng-container *ngIf="payoutATD">
                                                        <th scope="col">Pay-Out Amount</th>
                                                        <th scope="col">Comments </th>
                                                    </ng-container>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of dataATD">
                                                    <td scope="row">{{item.name}}</td>
                                                    <ng-container *ngIf="!payoutATD">
                                                        <td>${{item.amount | number:'1.2-2'}}</td>
                                                        <td>${{item.total_paid?(item.total_paid | number:'1.2-2'):'-'}}
                                                        </td>
                                                        <td>{{item.date_from | date:'MMM d, y'}}</td>
                                                        <td>{{item.note?item.note:"-"}}</td>
                                                    </ng-container>
                                                    <ng-container *ngIf="payoutATD">
                                                        <td>${{payoutAmount1 | number:'1.2-2'}}</td>
                                                        <td>{{comment?comment:"-"}}</td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br><br>
                                    <br><br>
                                    <br><br>
                                </div>
                            </div>
                            <div class="bottom">
                                <div>
                                    <p><b class="mr-4">Signature:</b></p>
                                    <p><b class="mr-4">Date:</b> {{currentDate| date:'medium'}} </p>
                                </div>
                                <h2 style="color: #ECB41F;" class="mt-5">LABOUR HIRE</h2>
                                <h2 style="color: #624891;">PERMANENT RECRUITMENT</h2>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loading" class="text-center mt-3 my-3">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <p>Please wait, processing your request...</p>
                    </div>
                    <div *ngIf="successMessage" class="text-center mt-3 text-success">
                        <p>Submission successful! <br> {{ successMessage }}</p>
                        <button type="button" class="btn btn-primary" (click)="openDocuSign();" data-dismiss="modal"><a
                                style="color: white;">Open DocuSign </a></button>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeAuthorityModal('AuthorityModal');payoutATD=false">Close</button>
                    <button *ngIf="!loading && !successMessage" type="button" class="btn btn-primary text-center"
                        (click)="submitATD()">Add Sign</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal staticBackdrop-->
    <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" *ngIf="modalTitle">
            <div class="modal-content">
                <div class="modal-header d-flex position-relative">
                    <h6 class="modal-title w-100 text-center mr-1" id="staticBackdropLabel">{{modalTitle}}</h6>
                    <button type="button" class="close position-absolute pl-1" style="right: 20px;" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body justify-content-center table-responsive">
                    <p class="text-center text-danger" *ngIf="!showSuccessMsg">{{staticWarning}}</p>
                    <div *ngIf="validationMessage" class="alert alert-danger">
                        {{validationMessage}}
                    </div>
                    <div *ngIf="requestData && !showSuccessMsg && !loading">
                        <table class="table table-bordered tablecenter table-hover table-sm align-middle mb-0">
                            <tr>
                                <th>Deduction Name</th>
                                <td>{{requestData.name}}</td>
                            </tr>
                            <tr *ngIf="fixedDeduction===true">
                                <th>Amount Owing</th>
                                <td>{{requestData.limit_fixed | number:'1.2-2'}}</td>
                            </tr>
                            <tr *ngIf="fixedDeduction===true">
                                <th>Pay Out Amount</th>
                                <td>
                                    <div class="input-with-dollar">
                                        <input type="number" id="payOutAmount" (input)="validationMessage = ''"
                                            class="form-control" name="payOutAmount" [(ngModel)]="payOutAmount"
                                            required />
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngIf="recurringDeduction===true">
                                <tr>
                                    <th>New End Date</th>
                                    <td><input type="date" title="Select Date From Calendar" class="form-control"
                                            [(ngModel)]="end_date" name="end_date" id="end_date" [min]="minDate"
                                            (keydown)="onKeyDown($event)" (input)="validationMessage = ''"></td>
                                </tr>
                                <tr>
                                    <th>Reason</th>
                                    <td>
                                        <select id="reason" name="reason" [(ngModel)]="reason"
                                            class="custom-select custom-select-sm" (change)="validationMessage = ''">
                                            <option value="" disabled selected>Select Reason</option>
                                            <option *ngFor="let value of reasonsOptions" [value]='value'>{{value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <h6 class="modal-title w-100 text-center mr-1">If you are changing address.</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <th for="address">New Address</th>
                                    <td>
                                        <input type="text" id="address" name="address"
                                            [(ngModel)]="address" class="form-control"
                                            placeholder="Enter new address" />
                                    </td>
                                </tr>
                                <tr>
                                    <th for="numberOfPeople">Number of People</th>
                                    <td>
                                        <input type="number" id="numberOfPeople" name="numberOfPeople"
                                            [(ngModel)]="numberOfPeople" class="form-control"
                                            placeholder="Enter number of people" />
                                    </td>
                                </tr>
                                <tr>
                                    <th for="accommodation">Accommodation</th>
                                    <td>
                                        <select id="accommodation" name="accommodation" [(ngModel)]="accommodation"
                                        class="custom-select custom-select-sm">
                                            <option value="" disabled selected>How did you find new Accommodation?</option>
                                            <option *ngFor="let option of accommodationOptions" [value]="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th for="transport">Transport</th>
                                    <td>
                                        <select id="transport" name="transport" [(ngModel)]="transport"
                                        class="custom-select custom-select-sm">
                                            <option value="" disabled selected>What Vehicle you be using?</option>
                                            <option *ngFor="let option of transportOptions" [value]="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr>
                                <th>Comments</th>
                                <td><textarea id="comments" name="comments" rows="3" class="form-control"
                                        [(ngModel)]="comments" (input)="validationMessage = ''">
                            </textarea></td>
                            </tr>
                        </table>
                    </div>

                    <div *ngIf="loading" class="text-center mt-3 my-3">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div *ngIf="showSuccessMsg" class="text-center">
                        <p>{{messageATD}}</p>
                    </div>
                </div>
                <div class="modal-footer d-flex align-items-center justify-content-center">
                    <button *ngIf="!showSuccessMsg" type="button" class="btn btn-primary"
                        (click)="submitRequest(comments, payOutAmount, end_date, reason)">Submit</button>
                    <button #closeStaticModal *ngIf="showSuccessMsg" type="button" class="btn btn-primary"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { Component, OnInit, Inject } from '@angular/core';
import { InteractionType } from '@azure/msal-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { History } from '../../models/History';
import { HistoryService } from '../../services/history.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-payroll-history',
  templateUrl: './payroll-history.component.html',
  styleUrls: ['./payroll-history.component.css']
})
export class PayrollHistoryComponent implements OnInit {
  displayLoader: boolean = true;
  loginDisplay = false;
  name: any;
  email: any;
  historyList: any;
  mobileView: boolean = false;
  selected: string = 'payroll-history';
  id: string = '';
  public model: any;
  history: History[] = [];
  filteredHistory: any[] = [];
  pageSizeOptions = [10, 20, 50, 100];
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;
  loading: boolean = false;
  errorMessage : string = '';


  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private route: ActivatedRoute,
    private historyService: HistoryService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    this.setLoginDisplay();
  }

  public refreshHistory = (
    page: string = '1',
    query: string = '',
    pageSize: string = '10'
  ) => {
    this.loading = true;
    this.page = page;
    this.pageSize = pageSize;
    this.history = [];
    this.historyService
      .getEditHistory(this.page.toString(), this.pageSize, `${environment.apiConfig.uri}/Candidate/AstuteEditHistory`)
      .subscribe((response: any) => {
        this.history = response.data.editHistories;
        this.collectionSize = response.extraParam.totalCount;
        this.errorMessage='';
        this.loading = false;
      },
      (error) => {
        this.errorMessage = 'Oops, something went wrong while fetching payroll history. Please try again later.';
        this.loading = false;
      });
  };
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

}

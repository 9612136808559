import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';

type Quiz = {
  atttemptedQuestionCount?: number;
  candidateId?: number;
  contentSubType?: string;
  contentType?: string;
  fileName?: string;
  id?: number;
  joborderId?: string;
  obtainedMarks?: number;
  questionCount?: number;
  status?: string;
  trainingFileId?: number;
};

@Component({
  selector: 'app-candidate-training',
  templateUrl: './candidate-training.component.html',
  styleUrls: ['./candidate-training.component.css']
})
export class CandidateTrainingComponent implements OnInit {

  @Input() candidateID: string = '';
  loadingCandidateFiles = false;
  quizList: any;
  trainingcompleted: any;
  loader = false;
  totalQuiz:number=0;
  constructor(
    private http: HttpClient,
    // private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.getQuizData();
  }
  getQuizData() {
    var url = `${environment.apiConfig.uri}/admin/Candidates/GetCandidateTrainingData/${this.candidateID}`;
    this.http.get(url).subscribe((response: any) => {
      this.quizList = response.data;
      this.totalQuiz=this.quizList.length;
      for (let i = 0; i < this.quizList.length; i++) {
        let quiz: Quiz = this.quizList[i];
        if (quiz.status == 'Incomplete') {
          localStorage.setItem('trainingcompleted', 'false');
          this.trainingcompleted = 'false';
          break;
        }
        else {
          localStorage.setItem('trainingcompleted', 'true');
          this.trainingcompleted = 'true';
        }
      }

      this.loader = false;
    });
  };

}

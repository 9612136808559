import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Accommodation } from 'src/app/models/Accommodations';
import { GetSitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.css']
})
export class AccommodationComponent implements OnInit {
  selected: string = 'accommodation';
  loading: boolean = false;
  mobileView: boolean = false;
  validationMessage: string = '';
  errorMessage: string = '';
  unsavedModel: boolean = false;
  isFormSaved: boolean = false;
  loadingAccommodations: boolean = false;
  accomodationForm!: FormGroup;
  selectedAccomodationIndex: number | null = null;
  successMessage: string = '';
  isModalVisible: boolean = false;
  sites: string[] = [];
  statuses = ['Active'];
  states = ['ACT', 'NSW', 'QLD', 'SA', 'NT', 'TAS', 'VIC', 'WA'];
  allAccommodations: Accommodation[] = [];
  saveAccommodationUrl: string = '';
  selectedAccommodationId!: number;
  safetyCultureAssetId: string = '';
  currentSortColumn: string = '';
  sortDirection: boolean = true; // true for ascending, false for descending

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal,
    private getSitesService: GetSitesService
  ) {}
  ngOnInit(): void {
    this.initializeForm();
    this.loadSites();
    this.getAccommodations();
    this.accomodationForm.valueChanges.subscribe(() => {
      if (this.isFormSaved) {
        this.isFormSaved = false;
      }
    });
  }

  getAccommodations() {
    this.loadingAccommodations = true;
    const apiUrl = `${environment.apiConfig.uri}/admin/Candidates/GetAccommodation`;
    this.http.get<{ data: Accommodation[] }>(apiUrl).subscribe(
      (response) => {
        this.allAccommodations = response.data
        .filter(accommodation => accommodation.id !== -1 && accommodation.id !== -2)
        .map(accommodation=>{
          return{
            ...accommodation,
            startDate:accommodation.startDate?accommodation.startDate.split('T')[0]:'',
            endDate:accommodation.endDate?accommodation.endDate.split('T')[0]:''
          }
          
        });
        this.loadingAccommodations = false;
      },
      (error) => {
        this.errorMessage ='Failed to load Accommodations. Please try again later.';
        this.loadingAccommodations = false;
      }
    );
  }
  // Initialize the form with validators
  initializeForm(): void {
    this.accomodationForm = this.fb.group({
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      beds: [null, [Validators.required, Validators.min(1)]],
      rentalProvider: ['', Validators.required],
      site: ['', Validators.required],
      status: ['', Validators.required],
      costPerWeek: [null, [Validators.required, Validators.pattern('^[0-9]*$')]], // Only numeric values allowed
      startDate:[null],
      endDate:[null],
      utilities:[''],
      notes:[''],
      report:['']
    });
  }

  loadSites(): void {
    this.getSitesService.getSites().subscribe(
      (sites: string[]) => {
        this.sites = sites;
      },
      (error) => {
        console.error('Error fetching sites:', error);
        this.validationMessage = 'Failed to load sites';
      }
    );
  }

  addAccomodation() {
    this.statuses=['Active'];
    this.initializeForm();
    this.accomodationForm.markAsPristine();
    this.unsavedModel = false;
    this.successMessage = '';
    this.isFormSaved = false;
    this.selectedAccomodationIndex = null;
    this.saveAccommodationUrl = `${environment.apiConfig.uri}/admin/Candidates/AddAccommodation`;
  }

  updateAccomodation(index: any) {
    this.initializeForm();
    this.statuses=['Active', 'InActive'];
    this.errorMessage='';
    this.validationMessage='';
    this.successMessage = '';
    this.unsavedModel = false;
    this.isFormSaved = false;
    this.selectedAccomodationIndex = index;
    const selectedAccommodation = this.allAccommodations[index];
    this.saveAccommodationUrl = `${environment.apiConfig.uri}/admin/Candidates/UpdateAccommodation`;
    this.accomodationForm.patchValue({
      address: selectedAccommodation.address,
      city: selectedAccommodation.city,
      state: this.states.find((s) => s === selectedAccommodation.state), // Find the state in the states array and set it
      beds: selectedAccommodation.beds,
      rentalProvider: selectedAccommodation.rentalProvider,
      site: this.sites.find((s) => s === selectedAccommodation.site), // Find the site in the sites array and set it
      status: selectedAccommodation.status,
      costPerWeek: selectedAccommodation.costPerWeek,
      startDate: selectedAccommodation.startDate?selectedAccommodation.startDate:null,
      endDate: selectedAccommodation.endDate?selectedAccommodation.endDate:null,
      notes: selectedAccommodation.notes,
      utilities: selectedAccommodation.utilities,
      report: selectedAccommodation.report === true ? true : selectedAccommodation.report === false ? false : null
    });    
    this.selectedAccommodationId = selectedAccommodation.id;
    this.safetyCultureAssetId = selectedAccommodation.safetyCultureAssetId;
  }

  discardChanges(): void {
    this.accomodationForm.markAsPristine();
    this.modalService.dismissAll();
  }
  saveAccomodation() {
    this.successMessage = '';
    this.unsavedModel = false;
    this.loading = true;
    if (this.accomodationForm.invalid) {
      this.loading = false;
      this.validationMessage = 'Please fill out all required fields correctly.';
      return;
    }
    const accomodationData = this.accomodationForm.value;
    // Only assign a value to 'report' if it is explicitly selected
    if (accomodationData.report === null || accomodationData.report === '') {
      accomodationData.report = null; // Set to null if not selected
    } else {
      accomodationData.report = accomodationData.report === 'true' || accomodationData.report === true; // Ensure boolean value
    }
    accomodationData.safetyCultureAssetId = this.safetyCultureAssetId || '';
    if (accomodationData.startDate == "") {
      accomodationData.startDate = null;
    }
    if (accomodationData.endDate == "") {
      accomodationData.endDate = null;
    }
    // Check if this is an update operation by checking the URL
    const isUpdateOperation = this.saveAccommodationUrl.includes('Update');
    let duplicateAccommodation;
    if (isUpdateOperation) {
      accomodationData.id = this.selectedAccommodationId;
      // If updating, compare address with other accommodations except the current one
      duplicateAccommodation = this.allAccommodations.find(
        (accommodation) =>
          accommodation.address.toLowerCase().trim() ===
            accomodationData.address.toLowerCase().trim() &&
          accommodation.id !== this.selectedAccommodationId
      );
    } else {
      // If adding, compare address with all accommodations
      duplicateAccommodation = this.allAccommodations.find(
        (accommodation) =>
          accommodation.address.toLowerCase().trim() ===
          accomodationData.address.toLowerCase().trim()
      );
    }

    if (duplicateAccommodation) {
      this.loading = false;
      this.validationMessage = 'There is already accomommodation with this address. Record not saved.';
      return;
    }

    this.http.post(this.saveAccommodationUrl, accomodationData).subscribe(
      (response) => {
        this.successMessage = 'Accommodation saved successfully';
        this.getAccommodations();
        this.loading = false;
        this.isFormSaved = true;
      },
      (err) => {
        console.error('Error saving Accommodation', err.error);
        this.validationMessage = err.error.message;
        this.loading = false;
        this.isFormSaved = false;
      }
    );
  }

  onCloseClick(): void {
    if (this.accomodationForm.dirty && !this.isFormSaved) {
      this.unsavedModel = true;
    } else {
      this.modalService.dismissAll();
    }
  }

  deleteAccommodation(data: any) {
    if (data.status === 'Active') {
      this.successMessage = 'You cannot delete Active Accommodation. Please make it Inactive first if you want to delete.';
      this.isModalVisible = true;
    } else {
      this.safetyCultureAssetId = data.safetyCultureAssetId;
      this.successMessage = '';
      this.isModalVisible = true;
    }
  }

  confirmDelete() {
    if (this.safetyCultureAssetId) {
      this.loading = true;
      const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteAccommodation?safetyCultureAssetId=${this.safetyCultureAssetId}`;
      this.http.delete(url).subscribe(
        (res: any) => {
          this.getAccommodations();
          this.successMessage = 'Accommodation Deleted Successfully.';
          this.loading = false;
        },
        (error) => {
          console.error('Error deleting accommodation:', error);
          this.loading = false;
        }
      );
    }
  }

  onCancel() {
    this.isModalVisible = false;
  }

  onClose() {
    this.isModalVisible = false;
  }

  sort(column: string) {
    if (this.currentSortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.currentSortColumn = column;
      this.sortDirection = true;
    }
    this.allAccommodations.sort((a: any, b: any) => {
      let comparison = 0;

      if (
        [
          'address',
          'city',
          'state',
          'rentalProvider',
          'site',
          'status',
          'notes',
          'utilities','report'
        ].includes(column)
      ) {
        const valueA = a[column]?.toString().trim().toLowerCase() || '';
        const valueB = b[column]?.toString().trim().toLowerCase() || '';
        comparison = valueA.localeCompare(valueB);
      } else if (['beds', 'costPerWeek'].includes(column)) {
        comparison = a[column] - b[column];
      }
      else if (['startDate','endDate'].includes(column)) {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }
      }
      return this.sortDirection ? comparison : -comparison;
    });
  }
}

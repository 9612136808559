import { HttpClient } from '@angular/common/http';
import {  MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration} from '@azure/msal-angular';
import { Component, OnInit, Inject } from '@angular/core';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { environment } from '../../../environments/environment';
import { InteractionType, PopupRequest, RedirectRequest, AuthenticationResult} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

type Jobs = {
  title?: string;
  address_line1?: string;
  address_city?: string;
  address_state?: string;
  address_zip?: string;
  address_country?: string;
  clientCorporation?: number;
  startDate?: Timestamp<Date>;
  status?: string;
  id?: number;
  clientName?: string;
};
type Claims = {
  name?: string;
  given_name?: string;
  family_name?: string;
  emails?: any;
  aud?: string;
  auth_time?: string;
  exp?: number;
  extension_CandidateId?: number;
  iat?: number;
  iss?: string;
  nbf?: number;
  nonce?: string;
  oid?: string;
  sub?: string;
  tfp?: string;
  ver?: string;
};

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css'],
})
export class JobsComponent implements OnInit {
  profile: any;
  claims!: Claims;
  name: any;
  email: any;
  addItem(newItem: string) {
    this.profile = newItem;
  }
  private readonly _destroying$ = new Subject<void>();
  title = 'FrontendService';
  jobFilesList: any;
  mobileViewJobsList: any = [];
  jobs!: Jobs;
  jobsList: any;
  file: any;
  selected: string = 'jobs';
  id: string = '';
  jobsOnboard = new Array<any>();
  jobsPlaced = new Array<any>();
  jobsCompleted = new Array<any>();
  jobsTest = new Array<any>();
  isIframe = false;
  loginDisplay = false;
  loadingJobFiles: boolean = true;
  displayLoader: boolean = true;
  sendingFile: boolean = false;
  mobileView: boolean = false;

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private route: ActivatedRoute,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    this.setLoginDisplay();
    this.getJobs(environment.apiConfig.uri + '/JobOrder');
  }

  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

  getJobs(url: string) {
    this.http.get(url).subscribe((jobs: any) => {
      this.jobsList = jobs.data;
      this.displayLoader = false;
    });
  }

  loadFiles(id: any) {
    this.loadingJobFiles = true;
    var url = environment.apiConfig.uri + '/JobFiles/' + id;
    this.http.get(url).subscribe((response: any) => {
      this.jobFilesList = response.data;  
      this.loadingJobFiles = false;
    });
  }
  mobileJobloadFiles(id: any, index: number) {
    this.loadingJobFiles = true;
    var url = environment.apiConfig.uri + '/JobFiles/' + id;
      this.http.get(url).subscribe((response: any) => {
      this.jobFilesList = response.data;
      this.mobileViewJobsList = this.jobsList[index];
      this.loadingJobFiles = false;
    });
  }

  onClickDownload(event: any, item: any) {
    if (!item.fileStream) {
      this.getFileStream(event, item, false);
    } else {
      const link = document.createElement('a');
      link.download = `${item.fileName}`;
      const source = `data:${item.contentType}/${item.contentSubType};base64,${item.fileStream}`;
      link.href = source;
      link.click();
    }
  }

  onClickView(event: any, item: any) {
    if (!item.fileStream) {
      this.getFileStream(event, item, true);
    } else {
      const link = document.createElement('a');
      link.target = '_blank';
      const Blob = this.dataURItoBlob(item);
      const file = new File([Blob], item.fileName, {
        type: `${item.contentType}/${item.contentSubType}`,
      });
      let url = window.URL.createObjectURL(file);
      link.href = url;
      link.click();
    }
  }

  getFileStream(event: any, item: any, view: boolean) {
    var url = environment.apiConfig.uri + `/JobFiles/GetFileStream/${item.id}`;
    this.http.get(url).subscribe((response: any) => {
      this.file = response.data ? response.data : '$';
      item.fileStream = this.file;
      if (view) {
        this.onClickView(event, item);
      } else {
        this.onClickDownload(event, item);
      }
    });
  }

  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: `${item.contentType}/${item.contentSubType}`,
    });
    return blob;
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
}

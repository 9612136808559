import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from "moment";
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnInit {
  @Input() loading: boolean = true;
  @Input() data: any = [];
  @Input() pageSizeOptions: any = [];
  @Input() page: any = 1;
  @Input() pageSize: any = 10;
  @Input() collectionSize = 10;
  @Input() model: any = '';
  @Input() typeAhead: string[] = [''];
  @Input() tableHeader: string[] = [''];
  @Input() tableRows: string[] = [''];
  @Input() isView: boolean = false;
  @Input() isSearch: boolean = false;
  @Input() title: string = 'Table';
  @Input() public refreshData!: Function;
  @Input() public openViewModel!: Function;
  @Input() adminView:boolean=false;
  @Output() statusChange = new EventEmitter<any>();
  @Input() jobMatch:boolean=false;
  @Output() refreshJobData = new EventEmitter<{ page: number; pageSize: number }>();

  moment: any = moment;
  query: string = '';
  adminstatus:string[]=['Yes','No'];

  constructor(
    private formBuilder: FormBuilder
  ) { }

  openContact(id: any) {
      this.openViewModel(id);
  }

  async ngOnInit(): Promise<void> {
    if(!this.jobMatch){
    await this.refreshData(1, '', this.pageSize || 10);
    }
  }

  searchForm = this.formBuilder.group({
    searchQuery: '',
  });

  async onSubmit(): Promise<void> {
    this.query = this.searchForm.value.searchQuery;
    await this.refreshData(1, this.query, this.pageSize);
    localStorage.setItem('query', this.query);
  }

  onStatusChange(item: any){
    this.statusChange.emit(item);
  }

  onPageChange(newPage: number): void {
    this.refreshJobData.emit({ page: newPage, pageSize: this.pageSize });
  }

  onPageSizeChange(newPageSize: number): void {
    this.page = 1; // Reset to first page when page size changes
    this.refreshJobData.emit({ page: this.page, pageSize: newPageSize });
  }

  typeAheadSearch: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.typeAhead
            .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      )
    );
}

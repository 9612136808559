<div class="row mt-5">
  <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
    <div class="card">
      <div class="card-body">
        <!-- Candidate Files -->
        <app-table [tableHeader]="[
            'Display',
            'New Value',
            'Old Value',
            'Modifying Person',
            'Modified (UTC)'
          ]" [tableRows]="[
            'display',
            'newValue',
            'oldValue',
            'modifyingPerson',
            'dateAdded'
          ]" [data]="history" [pageSizeOptions]="pageSizeOptions" [collectionSize]="collectionSize" [model]="model"
          [refreshData]="refreshHistory" [loading]="loading" [title]="'History'"></app-table>
      </div>
    </div>
  </div>
</div>
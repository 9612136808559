<div class="blur-content">
    <div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
        <app-sidebar-menu [selected]="selected" [isAdmin]="true" style="width:80%;"></app-sidebar-menu>
        <!-- main content area start -->
        <div class="main-content">
            <app-header></app-header>
            <div class="main-content-inner">
                <!-- market value area start -->
                <div class="row mt-3">
                    <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
                        <div *ngIf="!viewTaxFileTable" class="card">
                            <div class="card-body">
                                <table *ngIf="!loadingtaxfiles" class="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Candidate Name</th>
                                            <th>Employer</th>
                                            <th>Date Submitted </th>
                                            <th colspan="3">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr *ngIf="taxdatalength==0; else dataBlock " style=" text-align: center;">
                                            <td colspan="6">No data</td>
                                        </tr>

                                        <ng-template #dataBlock>
                                            <tr *ngFor="let item of taxDecData;index as i;">
                                                <td>{{item.candidateName}}
                                                </td>
                                                <td>
                                                    <span *ngIf="item.employee_remote_id.includes('PAYG-1')">
                                                        Agri Labour Australia Pty Ltd
                                                    </span>
                                                    <span *ngIf="item.employee_remote_id.includes('PAYG-2')">
                                                        ALA Staff Sourcing Pty Ltd Ltd</span>
                                                    <span *ngIf="item.employee_remote_id.includes('PAYG-4')">
                                                        Health Plus People Pty Ltd
                                                     </span>
                                                </td>
                                                <td> {{item.submissionTime | date: 'dd/MM/yyyy'}}</td>
                                                <td>
                                                    <span *ngIf="item.submissionStatus==true">
                                                        Ready
                                                    </span>
                                                    <span *ngIf="item.submissionStatus==false && item.requestStatus==1"
                                                        class="mx-1" style="color: rgb(236, 236, 27);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                            fill="currentColor" class="bi bi-exclamation-triangle-fill"
                                                            viewBox="0 0 16 16" style="margin-top: 1px;">
                                                            <path
                                                                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                        </svg>
                                                    </span>
                                                    <span *ngIf="item.requestStatus==0" style="color: red; " class="mx-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                            fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                            <path
                                                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                        </svg>
                                                    </span>
                                                </td>
                                                <td>
                                                    <a type="button" style="width: 70px; "
                                                        class="btn btn-sm btn-outline-primary "
                                                        (click)="viewCandidateFile(item,i)"> View </a>
                                                    <a *ngIf="item.requestStatus!=0"  type="button" style="width: 70px; "
                                                        class="btn btn-sm btn-outline-info ml-1"
                                                        (click)="submitOnAstutue(item)">
                                                        Submit </a>
                                                </td>
                                                <td>
                                                    <span *ngIf="item.submissionStatus==false && item.requestStatus!=0">
                                                        <button style="width: 70px; "
                                                            class="btn btn-sm btn-outline-success "
                                                            (click)="changeStaus(item,2)" data-toggle="modal"
                                                            [disabled]="item.requestStatus==0"
                                                            data-target="#exampleModal">Success</button>
                                                        <button style="width: 70px; "
                                                            class="btn btn-sm btn-outline-danger ml-1"
                                                            [disabled]="item.requestStatus==0" (click)="changeStaus(item,0);"
                                                            data-toggle="modal" data-target="#exampleModal">Fail</button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <!--  -->
                                        
                                    </tbody>
                                </table>
                                <ng-container *ngIf="loadingtaxfiles">
                                    <div>
                                        <li class="t_loader">
                                            <div class="card__desc load" id="long"></div>
                                        </li>
                                        <li class="t_loader">
                                            <div class="card__desc load"></div>
                                        </li>
                                        <li class="t_loader">
                                            <div class="card__desc load" id="long"></div>
                                        </li>
                                        <li class="t_loader">
                                            <div class="card__desc load"></div>
                                        </li>
                                        <li class="t_loader">
                                            <div class="card__desc load" id="long"></div>
                                        </li>
                                        <li class="t_loader">
                                            <div class="card__desc load"></div>
                                        </li>
                                    </div>
                                </ng-container>
                                <!--   -->

                            </div>
                        </div>
                        <div *ngIf="viewTaxFileTable">
                            <button (click)="viewTaxFileTable=false" class=" btn badge badge-primary  my-1 button-right"
                                type="button">
                                Back
                            </button>
                            <div class="card">
                                <div class="card-body">
                                    <div>
                                        <div class="  bg-white mt-1">
                                            <div class="mx-2 ">
                                                <table class="table table-hover table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td scope="col" colspan="4"><strong>Tax File Number
                                                                    Declaration
                                                                </strong></td>
                                                        </tr>
                                                        <!-- 0 -->
                                                        <tr>
                                                            <td scope="col" colspan="3">
                                                                <div class=""> <strong>Submission Date</strong>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div>
                                                                    <span>{{viewFormData.submissionTime| date:
                                                                        'dd/MM/yyyy'}}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- 1 -->
                                                        <tr>
                                                            <td scope="col" colspan="4"
                                                                style="background-color: #e9ecef;   ">
                                                                <strong> Tax File number Declaration</strong>
                                                            </td>
                                                        </tr>
                                                        <!-- 2 -->
                                                        <tr>
                                                            <td scope="col" colspan="3">
                                                                <strong *ngIf="viewFormData.no_tfn==true"
                                                                    for="no_tfn-2">I
                                                                    don't Have my
                                                                    TFN</strong> <br>
                                                                <strong *ngIf="viewFormData.tfnProvided"
                                                                    for="tfnProvided">Tax
                                                                    File
                                                                    Number: </strong>
                                                            </td>
                                                            <td scope="col">
                                                                <input *ngIf="viewFormData.no_tfn==true" type="checkbox"
                                                                    name="no_tfn" id="no_tfn-2"
                                                                    [(ngModel)]="viewFormData.no_tfn" disabled
                                                                    ngModel><br>
                                                                <input *ngIf="viewFormData.tfnProvided" disabled
                                                                    type="text" placeholder="Tax File Number"
                                                                    [(ngModel)]="viewFormData.tfnProvided"
                                                                    name="tfnProvided" id="tfnProvided"
                                                                    aria-label="Default" ngModel>
                                                            </td>
                                                        </tr>
                                                        <!-- 3 -->
                                                        <tr *ngIf="viewFormData.exemptionReason">
                                                            <td scope="col" colspan="3">
                                                                <strong for="exemptionReason">Exemption Reason
                                                                </strong>
                                                            </td>
                                                            <td scope="col" style="font: size 1rem;">
                                                                <input disabled type="radio" name="exemptionReason"
                                                                    [checked]="viewFormData.exemptionReason==4"
                                                                    id="exemptionReason-4">
                                                                <label for="exemptionReason-4" class="mx-2">
                                                                    I am claiming an exemption because I am in receipt
                                                                    of a pension,
                                                                    benefit, or
                                                                    allowance
                                                                </label><br>
                                                                <input disabled type="radio"
                                                                    [checked]="viewFormData.exemptionReason==3"
                                                                    name="exemptionReason" id="exemptionReason-3">
                                                                <label for="exemptionReason-3" class="mx-2">
                                                                    I am claiming an exemption because I am under 18
                                                                    years old and do not
                                                                    earn
                                                                    enough to pay tax
                                                                </label><br>
                                                                <input type="radio" disabled id="exemptionReason-1"
                                                                    name="exemptionReason"
                                                                    [checked]="viewFormData.exemptionReason==1">
                                                                <label for="exemptionReason-1" class="mx-2">
                                                                    I have made a separate application to the ATO for a
                                                                    new or existing tax
                                                                    file
                                                                    number
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <!-- 4 -->
                                                        <tr>
                                                            <td scope="col" colspan="3"> <strong>Have you change your
                                                                    surename since you last
                                                                    dealt with the Australian Tax
                                                                    Office
                                                                </strong>
                                                            </td>
                                                            <td scope="col">
                                                                <input disabled type="radio"
                                                                    name="isPreviousSurnameChanged"
                                                                    [checked]="viewFormData.isPreviousSurnameChanged==1"
                                                                    id="isPreviousSurnameChanged-1">
                                                                <label for="isPreviousSurnameChanged-1" class="mx-2">
                                                                    Yes
                                                                </label><br>
                                                                <input disabled type="radio"
                                                                    name="isPreviousSurnameChanged"
                                                                    [checked]="viewFormData.isPreviousSurnameChanged==0"
                                                                    id="isPreviousSurnameChanged-0">
                                                                <label for="isPreviousSurnameChanged-0" class="mx-2">
                                                                    No
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="viewFormData.previousSurname">
                                                            <td scope="col" colspan="3">
                                                                <strong for="previousSurname" class="mx-2">Previous
                                                                    surname: </strong>
                                                            </td>
                                                            <td scope="col">
                                                                <input type="text" placeholder="Previous surename"
                                                                    name="previousSurname"
                                                                    [(ngModel)]="viewFormData.previousSurname "
                                                                    id="previousSurname" value disabled
                                                                    aria-label="Default" ngModel>
                                                            </td>
                                                        </tr>
                                                        <!-- 5 -->
                                                        <tr *ngIf="viewFormData.residencyStatus">
                                                            <td scope="col" colspan="3"> <strong>Are you an Australian
                                                                    resident for tax purposes
                                                                    or a working holidays maker?
                                                                </strong></td>
                                                            <td scope="col">
                                                                <div>
                                                                    <input type="radio" disabled name="residencyStatus"
                                                                        [(ngModel)]="viewFormData.residencyStatus "
                                                                        id="residencyStatus-A" value="A" ngModel>
                                                                    <label for="residencyStatus-A" class="mx-2">
                                                                        Australian resident for tax purpose
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input type="radio"
                                                                        [(ngModel)]="viewFormData.residencyStatus "
                                                                        disabled name="residencyStatus"
                                                                        id="residencyStatus-F" value="F" ngModel>
                                                                    <label for="residencyStatus-F" class="mx-2">
                                                                        Foreign resident
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input type="radio" disabled name="residencyStatus"
                                                                        [(ngModel)]="viewFormData.residencyStatus "
                                                                        value="H" id="residencyStatus-H" ngModel>
                                                                    <label for="residencyStatus-H" class="mx-2">
                                                                        Working Holidays maker
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- 6 -->
                                                        <tr *ngIf="viewFormData.residencyStatus=='A' ">
                                                            <td scope="col" colspan="3"> <strong>Do You want to claim
                                                                    the
                                                                    tax free threshold
                                                                    from this player?
                                                                </strong></td>
                                                            <td scope="col">

                                                                <input disabled name="taxFreeThresholdClaimed"
                                                                    [checked]="viewFormData.taxFreeThresholdClaimed==1"
                                                                    id="taxFreeThresholdClaimed-1" type="radio">
                                                                <label for="taxFreeThresholdClaimed-1" class="mx-2">
                                                                    Yes
                                                                </label><br>
                                                                <input disabled type="radio"
                                                                    name="taxFreeThresholdClaimed"
                                                                    [checked]="viewFormData.taxFreeThresholdClaimed==0"
                                                                    id="taxFreeThresholdClaimed-0">
                                                                <label for="taxFreeThresholdClaimed-0" class="mx-2">
                                                                    No
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <!-- 7 -->
                                                        <tr
                                                            *ngIf="viewFormData.residencyStatus=='A' ||viewFormData.residencyStatus=='F'">
                                                            <td scope="col" colspan="3"> <strong>Do you have a Higher
                                                                    Education Loan Program
                                                                    (HELP), VET Student Loan (VSL),
                                                                    Financial Supplement (FS), Student Start-up Loan
                                                                    (SSL) or Trade Support Loan
                                                                    (TSL)
                                                                    debt?
                                                                </strong></td>
                                                            <td scope="col">
                                                                <input type="radio" disabled name="hasHelpDebtSupport"
                                                                    [checked]="viewFormData.hasHelpDebtSupport==1"
                                                                    id="hasHelpDebtSupport-1">
                                                                <label for="hasHelpDebtSupport-1" class="mx-2">
                                                                    Yes
                                                                </label> <br>
                                                                <input type="radio"
                                                                    [checked]="viewFormData.hasHelpDebtSupport==0"
                                                                    disabled name="hasHelpDebtSupport"
                                                                    id="hasHelpDebtSupport-0">
                                                                <label for="hasHelpDebtSupport-0" class="mx-2">
                                                                    No
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <!-- 8 -->
                                                        <tr>
                                                            <td scope="col" colspan="3"> <strong>On what basis are you
                                                                    paid?
                                                                </strong></td>
                                                            <td scope="col">
                                                                <div>
                                                                    <input type="radio" disabled name="employmentBasis"
                                                                        [(ngModel)]="viewFormData.employmentBasis "
                                                                        id="employmentBasis-full-time" value="full-time"
                                                                        ngModel>
                                                                    <label for="employmentBasis-full-time" class="mx-2">
                                                                        Full-time
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input type="radio" disabled name="employmentBasis"
                                                                        id="employmentBasis-part-time" value="part-time"
                                                                        [(ngModel)]="viewFormData.employmentBasis "
                                                                        ngModel>
                                                                    <label for="employmentBasis-part-time" class="mx-2">
                                                                        Part-time
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input type="radio" disabled name="employmentBasis"
                                                                        id="employmentBasis-casual"
                                                                        [(ngModel)]="viewFormData.employmentBasis "
                                                                        value="casual" ngModel>
                                                                    <label for="employmentBasis-casual" class="mx-2">
                                                                        Casual
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input disabled type="radio"
                                                                        [(ngModel)]="viewFormData.employmentBasis "
                                                                        id="employmentBasis-labour-hire"
                                                                        name="employmentBasis" value="labour-hire"
                                                                        ngModel>
                                                                    <label for="employmentBasis-labour-hire"
                                                                        class="mx-2">
                                                                        Labour Hire
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- 9 -->
                                                        <tr>
                                                            <td scope="col" colspan="4"
                                                                style="background-color: #e9ecef; ">
                                                                <strong>I declare that the information transmitted in
                                                                    this TFN Declaration is true
                                                                    and
                                                                    correct and that I am authorised to make this
                                                                    declaration
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                        <!-- 10 -->
                                                        <tr>
                                                            <td scope="col" colspan="3">
                                                                <strong for="declarationName">Full name of the person
                                                                    making
                                                                    the
                                                                    declaration</strong>
                                                                <br>
                                                                <strong for=" Check1" for="declarationSigned">Tick this
                                                                    box
                                                                    to sign with the
                                                                    identification details you used to log on and your
                                                                    name
                                                                    supplied
                                                                    above</strong>
                                                            </td>
                                                            <td scope="col">
                                                                <input type="text" disabled name="declarationName"
                                                                    id="declarationName" value placeholder="Full name "
                                                                    aria-label="Username"
                                                                    [(ngModel)]="viewFormData.declarationName "
                                                                    aria-describedby="addon-wrapping" ngModel>
                                                                <br><br>
                                                                <input disabled type="checkbox"
                                                                    [(ngModel)]="viewFormData.declarationSigned "
                                                                    name="declarationSigned" id="declarationSigned"
                                                                    ngModel>
                                                            </td>
                                                        </tr>
                                                        <!-- 11 -->
                                                        <tr *ngIf="viewFormData.residencyStatus=='H'">
                                                            <td scope="col" colspan="3"><strong>Working holiday makers
                                                                    must
                                                                    provide a country of
                                                                    passport
                                                                </strong><br>
                                                                <strong for="passportIssuanceCountry">What is the place
                                                                    of
                                                                    issue
                                                                    in your passport?</strong>
                                                            </td>
                                                            <td scope="col">
                                                                <div
                                                                    *ngFor="let country of passportIssuanceCountryObj | keyvalue">
                                                                    <div
                                                                        *ngIf="country.value == viewFormData.passportIssuanceCountry">
                                                                        <span> {{country.key}} </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- market value area end -->
            </div>
            <!-- main content area end -->
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog  modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <p style="display: flex; align-items: center; justify-content: center; ">{{changeStatusMessage}}</p>
                <div style="display: flex; align-items: center; justify-content: center; ">
                    <button type="button" class="btn btn-sm btn-info px-3 mr-3" data-dismiss="modal">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
import {  Component, EventEmitter, Input, OnInit, Output,Inject, ElementRef, ViewChild, OnChanges,
  SimpleChanges} from '@angular/core';
import {  MsalService,  MSAL_GUARD_CONFIG,  MsalGuardConfiguration,} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { CookieService } from 'ng2-cookies/cookie';
import { DOCUMENT } from '@angular/common';
import { PortalAdminService } from 'src/app/services/portal-admin.service';

type Quiz = {
  atttemptedQuestionCount?: number;
  candidateId?: number;
  contentSubType?: string;
  contentType?: string;
  fileName?: string;
  id?: number;
  joborderId?: string;
  obtainedMarks?: number;
  questionCount?: number;
  status?: string;
  trainingFileId?: number;
};

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css'],
})
export class SidebarMenuComponent implements OnInit, OnChanges {
  @Input() name: any;
  @Input() lastname: any;
  @Input() email: any;
  @Input() isAdmin: boolean = false;
  @Input() dataID: boolean = false;
  @Input() selected = '';
  @Input() id = '';
  @Input() profilecompleted: any;
  @Input() trainingcompleted: any;
  @Input() superannuationStatus: any;
  @Input() taxStatus: any;
  @Input() bankdetailsStatus: any;
  @Input() formSession: boolean = false;
  @Output() pageName: EventEmitter<string> = new EventEmitter();
  @Output() updateProfileForm: EventEmitter<boolean> = new EventEmitter();
  @Input() homeData: any;
  @Input() deductionStatus:any;
  @ViewChild('btnSwitchTab2') btnSwitchTab2!: ElementRef;
  @ViewChild('btnWarningModal') btnWarningModal!: ElementRef;
  
  showSubMenu: boolean = false;
  quizList: any;
  candidateId!: number;
  router: any;
  brandLogo:string = 'assets/img/agrilabour-logo-01.png';
  brandPhone:string='1300 247 823';
  brandEmail:string='info@agrilabour.com.au';
  menuStates: { [key: string]: boolean } = {};
  isPortalAdmin:boolean=true;

  constructor(
    private cookieService: CookieService,
    private authService: MsalService,private portalAdminService:PortalAdminService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.portalAdminService.isPortalAdmin().subscribe((res:any)=>{
    this.isPortalAdmin = res;
    },
    (error) => {
      console.error('Failed to determine admin status', error);
      this.isPortalAdmin = false;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.statusLoad();
  }
  statusLoad() {
    this.name = localStorage.getItem('name');
    this.lastname = localStorage.getItem('lastname');
    this.email = localStorage.getItem('email');
    this.profilecompleted = localStorage.getItem('profilecompleted');
    this.trainingcompleted = localStorage.getItem('trainingcompleted');
    this.homeData = localStorage.getItem('homeData');
    this.taxStatus = localStorage.getItem('taxStatus');
    this.bankdetailsStatus = localStorage.getItem('bankdetailsStatus');
    this.superannuationStatus = localStorage.getItem('superannuationStatus');
    this.deductionStatus = localStorage.getItem('deductionStatus');
  }

  // Function to toggle the state of a specific menu
  toggleMenu(menuName: string): void {
    this.menuStates[menuName] = !this.menuStates[menuName];
  }
  // Function to check if a menu is open
  isMenuOpen(menuName: string): boolean {
    return this.menuStates[menuName] || false;
  }
   // Function to set the selected menu
   selectMenu(menuName: string): void {
    this.selected = menuName;
  }

  ngOnInit(): void {
    const brandHostname = this.document.location.hostname;
    if (brandHostname.includes('candidates.healthpluspeople.com.au')){
      this.brandLogo = 'assets/img/health_People.png';
      this.brandEmail='info@healthpluspeople.com.au';
      this.brandPhone='1300 367 719';
    } 
    this.statusLoad();
    if (['tax-declaration', 'pay-slips', 'superannuation', 'bank-account', 'deductions', 'payroll-history'].includes(this.selected)) {
      this.showSubMenu = true;
    } else {
      this.showSubMenu = false;
    }  
    if (['training-files', 'training-assessment'].includes(this.selected)) {
      this.menuStates['training'] = true;
    }
    if(['candidate-search','job-match'].includes(this.selected)){
      this.menuStates['search'] = true;
    }
    if (['add-deductions', 'pending-deductions', 'submit-deductions', 'change-deductions'].includes(this.selected)) {
      this.menuStates['deductions'] = true;
    }
    if (['create-workflow', 'workflow-actions'].includes(this.selected)) {
      this.menuStates['workflows'] = true;
    }
    if (['vehicles', 'accommodation', 'logistics'].includes(this.selected)) {
      this.menuStates['logistics'] = true;
    }
  }

  nextUrl(pageName: string) {
    this.pageName.emit(pageName);
  }

  checkProfile() {
    if (this.formSession) {
      this.btnSwitchTab2.nativeElement.click();
    } else {
      this.btnWarningModal.nativeElement.click();
    }
  }

  updateProfileform() {
    this.updateProfileForm.emit(true);
  }

  openPayroll() {
    window.open('https://agrilabour.astutepayroll.com/agrilabour/auth/login');
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });

  }
}

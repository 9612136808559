import { HttpClient, HttpParams } from '@angular/common/http';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

type Jobs = {
  title?: string;
  address_line1?: string;
  address_city?: string;
  address_state?: string;
  address_zip?: string;
  address_country?: string;
  clientCorporation?: number;
  startDate?: Timestamp<Date>;
  status?: string;
  id?: number;
  clientName?: string;
};

@Component({
  selector: 'app-candidate-jobs',
  templateUrl: './candidate-jobs.component.html',
  styleUrls: ['./candidate-jobs.component.css'],
})
export class CandidateJobsComponent implements OnInit {
  @Input() candidateID: string = '';
  profile: any;
  name: any;
  email: any;
  addItem(newItem: string) {
    this.profile = newItem;
  }
  private readonly _destroying$ = new Subject<void>();
  title = 'FrontendService';
  isIframe = false;
  loginDisplay = false;
  loadingJobFiles: boolean = true;
  jobFilesList: any;
  jobs!: Jobs;
  jobsList: any;
  jobsOnboard = new Array<any>();
  jobsPlaced = new Array<any>();
  jobsCompleted = new Array<any>();
  jobsTest = new Array<any>();
  displayLoader: boolean = true;
  file: any;
  sendingFile: boolean = false;
  mobileView: boolean = false;
  selected: string = 'jobs';
  id: string = '';
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private route: ActivatedRoute,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  ngOnInit(): void {
    let api = '/admin/Candidates/joborder/' + this.candidateID;

    this.getJobs(environment.apiConfig.uri + api);
  }

  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
    }
  }

  getJobs(url: string) {
    this.http.get(url).subscribe((jobs: any) => {
      this.jobsList = jobs.data;
      this.displayLoader = false;
    });
  }

  loadFiles(id: number) {
    var url = environment.apiConfig.uri + '/JobFiles/' + id;
    this.http.get(url).subscribe((response: any) => {
      this.jobFilesList = response.data;
      this.loadingJobFiles = false;
    });
  }

  onClickDownload(event: any, item: any) {
    if (!item.fileStream) {
      this.getFileStream(event, item, false);
    } else {
      const link = document.createElement('a');
      link.download = `${item.fileName}`;
      const source = `data:${item.contentType}/${item.contentSubType};base64,${item.fileStream}`;
      link.href = source;
      link.click();
    }
  }

  onClickView(event: any, item: any) {
    if (!item.fileStream) {
      this.getFileStream(event, item, true);
    } else {
      const link = document.createElement('a');
      link.target = '_blank';
      const Blob = this.dataURItoBlob(item);
      const file = new File([Blob], item.fileName, {
        type: `${item.contentType}/${item.contentSubType}`,
      });
      let url = window.URL.createObjectURL(file);
      link.href = url;
      link.click();
    }
  }

  getFileStream(event: any, item: any, view: boolean) {
    var url = environment.apiConfig.uri + `/admin/File/GetFileStream/JobOrder/${item.id}`;
    this.http.get(url).subscribe((response: any) => {
      this.file = response.data ? response.data : '$';
      item.fileStream = this.file;
      if (view) {
        this.onClickView(event, item);
      } else {
        this.onClickDownload(event, item);
      }
    });
  }

  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: `${item.contentType}/${item.contentSubType}`,
    });
    return blob;
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BankDetails } from 'src/app/classes/bank-details';
import { environment } from 'src/environments/environment';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css'],
})
export class BankAccountComponent implements OnInit {
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  bankDetail = new BankDetails();
  selected: string = 'bank-account';
  id: string = '';
  messageforUser: string = '';
  mobileView: boolean = false;
  loadingTaxFiles: boolean = true;
  newBankDataForm: boolean = false;
  viewBankDataForm: boolean = false;
  disablefield: boolean = false;
  modalbtn: boolean = false;
  userQueryData: any = {};
  bankLength?: number;
  bankdetailsStatus: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }
  ngOnInit(): void {
    this.getUserQuery();
    this.menuStatus();
  }
  
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

  menuStatus() {
    var url = `${environment.apiConfig.uri}/Candidate/AstuteBankStatus`;
    this.http.post(url, {}).subscribe(async (response: any) => {
      this.bankdetailsStatus = response.data.astuteBankStatus;
      localStorage.setItem('bankdetailsStatus', this.bankdetailsStatus);
    });
  }
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }
 
  getUserQuery() {
    var url = `${environment.apiConfig.uri}/candidate/AstuteBankAccount/GetUserQuery`;
    this.http.get(url).subscribe(async (response: any) => {
      this.userQueryData = response.data;
      this.bankLength = response.data.length;
      this.loadingTaxFiles = false;
    });
  }
  newBankAccount(data: any) {
    this.modalbtn = false;
    this.disablefield = false;
    this.newBankDataForm = true;
    this.bankDetail.account_name = data.account_name;
    this.bankDetail.account_bsb = data.account_bsb;
    this.bankDetail.account_number = data.account_number;
    this.bankDetail.secondary_account_name = data.secondary_account_name;
    this.bankDetail.secondary_account_bsb = data.secondary_account_bsb;
    this.bankDetail.secondary_account_number = data.secondary_account_number;
    this.bankDetail.deposit_amount = data.deposit_amount;
    this.bankDetail.deposit_type = data.deposit_type;
    this.bankDetail.uid = data.mid.toString();
    this.bankDetail.remoteid = data.remoteid;
  }
  viewBankAccount(data: any) {
    this.disablefield = true;
    this.newBankDataForm = false;
    this.viewBankDataForm = true;
    this.bankDetail.account_name = data.account_name;
    this.bankDetail.account_bsb = data.account_bsb;
    this.bankDetail.account_number = data.account_number;
    this.bankDetail.secondary_account_name = data.secondary_account_name;
    this.bankDetail.secondary_account_bsb = data.secondary_account_bsb;
    this.bankDetail.secondary_account_number = data.secondary_account_number;
    this.bankDetail.deposit_amount = data.deposit_amount;
    this.bankDetail.deposit_type = data.deposit_type;
  }

  bankAccountSubmit(data: any) {
    this.messageforUser = '';
    if (
      !data.account_name ||
      !/^[\w\s\d]+$/.test(data.account_name) ||
      !data.account_bsb ||
      !/^(?!0+$)[0-9]{4,}$/.test(data.account_bsb)||
      !data.account_number ||
      !/^[0-9]+$/.test(data.account_number)
    ) {
      return (this.messageforUser = 'Please complete Primary account details.');
    } else if (
      (data.secondary_account_name &&
        !/^[\w\s\d]+$/.test(data.secondary_account_name)) ||
      (data.secondary_account_bsb &&
        !/^(?!0+$)[0-9]{4,}$/.test(data.secondary_account_bsb)) ||
      (data.secondary_account_number &&
        !/^[0-9]+$/.test(data.secondary_account_number))
    ) {
      return (this.messageforUser =
        'Please complete Secondary account details.');
    } else {
      this.bankDetail.account_name = data.account_name ? data.account_name : '';
      this.bankDetail.account_bsb = data.account_bsb
        ? data.account_bsb.toString()
        : '';
      this.bankDetail.account_number = data.account_number
        ? data.account_number.toString()
        : '';
      this.bankDetail.secondary_account_name = data.secondary_account_name
        ? data.secondary_account_name
        : '';
      this.bankDetail.secondary_account_bsb = data.secondary_account_bsb
        ? data.secondary_account_bsb.toString()
        : '';
      this.bankDetail.secondary_account_number = data.secondary_account_number
        ? data.secondary_account_number.toString()
        : '';
      this.bankDetail.deposit_type = data.deposit_type;
      this.bankDetail.deposit_amount = data.deposit_amount
        ? data.deposit_amount.toString()
        : '';

      var url = `${environment.apiConfig.uri}/candidate/AstuteBankAccount`;
      this.modalbtn = false;
      return this.http
        .post(url, this.bankDetail)
        .subscribe(async (response: any) => {
          if (response.isSuccess) {
            this.modalbtn = true;
            this.messageforUser = 'Bank Details added successfully';
            this.getUserQuery();
            this.menuStatus();
          } else {
            console.log('error');
          }
        });
    }
  }
}

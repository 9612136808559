import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: MsalService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAdminFlow = window.location.href.includes('staff');

    if (this.authService?.instance?.getActiveAccount() !== null) {
      if (isAdminFlow) {
        this.router.navigate(['candidate-search']);
      } else {
        this.router.navigate(['profile']);
      }
      return false;
    }
    return true;
  }
}

export const driversLicenceOptions: any =[
    { value: "n/a", label: "n/a" },
    { value: "Australian Capital Territory", label: "Australian Capital Territory" },
    { value: "New South Wales", label: "New South Wales" },
    { value: "Northern Territory", label: "Northern Territory" },
    { value: "Queensland", label: "Queensland" },
    { value: "South Australia", label: "South Australia" },
    { value: "Tasmania", label: "Tasmania" },
    { value: "Victoria", label: "Victoria" },
    { value: "Western Australia", label: "Western Australia" },
    { value: "International", label: "International" }
  ];
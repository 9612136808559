<!-- Report Button  -->
<button *ngIf="name.length" type="button" title="Report Your Problem" style="position:fixed;height: 42px;width: 42px; z-index:999;display: flex;align-items: 
center;justify-content: center;border-radius: 50%;box-shadow: 2px 2px 3px rgba(0,0,0.25);
 right: 25px;bottom:25px" class="btn  btnRepo" data-toggle="modal" data-target="#exampleModalCenterReport">
  <span class="repoStyle"><i class="fa fa-solid fa-bug"></i></span>
</button>
<!-- Modal Body Start  -->
<div class="modal fade dd" name="reportModal" id="exampleModalCenterReport" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Describe Your Problem</h5>
        <button #closeModelAdd type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #reportForm="ngForm" method="post" (ngSubmit)="reportProblem(reportForm.value ,reportForm )">
          <div class="form-group">
            <label for="name">Title Of Report</label>
            <input type="text" class="form-control" id="title" name="title" required ngModel>
          </div>
          <div class="form-group">
            <label for="name">Page Name</label>
            <input type="text" class="form-control" id="pagename" name="pageName" required ngModel>
          </div>
          <div class="form-group">
            <label for="name"> Please Briefly Describe Your Problem. </label>
            <textarea type="text" class="form-control" id="textArea" name="description" required ngModel></textarea>
          </div>
          <div class="">
            <button [disabled]=reportForm.form.invalid type="submit" class="btn btn-primary" value="submitReport">
              Submit</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal"
              style="margin-left: 10px;">Close</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { QuizService } from '../../services/quiz.service';

type Quiz = {
  atttemptedQuestionCount?: number;
  candidateId?: number;
  contentSubType?: string;
  contentType?: string;
  fileName?: string;
  id?: number;
  joborderId?: string;
  obtainedMarks?: number;
  questionCount?: number;
  status?: string;
  trainingFileId?: number;
};
@Component({
  selector: 'app-candidate-training-assessment',
  templateUrl: './candidate-training-assessment.component.html',
  styleUrls: ['./candidate-training-assessment.component.css']
})
export class CandidateTrainingAssessmentComponent implements OnInit {
  mobileView: boolean = false;
  loader: boolean = true;
  showQuiz: boolean = false;
  check: boolean = false;
  exitButton: boolean = false;
  questionList: any;
  mobileViewQuizList: any = [];
  quizList: any;
  currentQuiz: any;
  selected: string = 'candidate-training-assessment';
  id: string = '';
  file: any;
  total: any;
  attempted: any;
  blobURL: any;
  currentQuestion: any;
  questionIndex: number = 0;
  quizIndex: number = 0;
  lastIndex: number = 0;
  errorMessage: any;
  trainingcompleted: any;
  mobileFileView: any = false;
  fileLoaded: boolean = false;
  res: any;
  questionCount: any;
  rightQuestions: any;
  mobileLoader: boolean = true;
  disableSubmit: boolean = false;

  responseData: any = {
    extraParam: {},
    isSuccess: false,
    message: "",
    data: {}
  }
  pdfContent: any;
  @ViewChild('pdfview') pdfview!: ElementRef;
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer, private router: Router,
    private cdr: ChangeDetectorRef, private quizService: QuizService,
  ) { }
  ngOnInit(): void {
    this.getQuizzes();
  }
  async getQuizzes() {
    this.loader = true;
    this.quizService.getQuizzes().subscribe(({quizList, trainingStatus }) => {
      this.quizList=quizList;
      this.trainingcompleted = trainingStatus ? true : false;
      this.loader = false;
    });
  };

  getQuiz(url: string) {
    this.http.get(url).subscribe((response: any) => {
      if (response.isSuccess) {
        this.questionList = response.data;
        this.lastIndex = this.questionList.length - 1;
        this.currentQuestion = this.questionList[0];
        this.showQuiz = true;
        this.loader = false;
      } else {
        console.log("Error Loading Quiz File");
      }

    });
  };

  async retakeQuiz(quiz: any) {
    this.errorMessage = "";
    var url = `${environment.apiConfig.uri}/candidate/Quiz/Reattempt?quizId=${quiz.id}&candidateId=${quiz.candidateId}`;
    try {
      const response = await this.http.post(url, {}).toPromise();
      this.responseData = response;
      if (this.responseData.isSuccess) {
        this.questionList = null;
        this.questionIndex = 0;
        this.mobileLoader = true;
        await this.getQuizzes();
        setTimeout(() => {
          if (this.mobileView) {
            this.filesForMobile(this.quizIndex);
          }
        }, 1000);
        this.mobileLoader = false;

      }
    } catch (error) {
      console.error(error);
    }
  }
  filesForMobile(index: number) {
    this.mobileFileView = false;
    this.quizIndex = index;
    this.mobileViewQuizList = this.quizList[index];
    this.attempted = this.mobileViewQuizList.atttemptedQuestionCount;
    this.total = this.mobileViewQuizList.questionCount;
    this.rightQuestions = this.mobileViewQuizList.rightQuestions;
    this.questionCount = this.mobileViewQuizList.questionCount;
  }

  viewQuizFile(quiz: any, isMobile: boolean) {
    if (quiz.extension != "pdf" || null || undefined) {
      var url = `${environment.storageUrl}/training-files/${quiz.trainingFileId}.${quiz.extension ? quiz.extension : "mp4"}${environment.SASToken}`;
    }
    else {
      var url = `${environment.storageUrl}/training-files/${quiz.trainingFileId}.${quiz.extension ? quiz.extension : "pdf"}${environment.SASToken}`;
    }
    this.blobURL = url;
    this.check = url.includes("mp4");
    if (isMobile) {
      if (quiz.extension == "pdf" || null || undefined) {
        window.open(this.blobURL);
        this.mobileFileView = false;
      } else {
        this.mobileFileView = true;
      }
    }
  }
  async reset() {
    await this.getQuizzes();
    setTimeout(() => {
      this.filesForMobile(this.quizIndex);
    }, 500);
  }

  attemptQuiz(quiz: any, isMobile: boolean) {
    this.mobileLoader = true;
    setTimeout(() => {
      this.mobileLoader = false;
    }, 1000);
    this.questionIndex = 0;
    this.currentQuestion = {};
    this.errorMessage = "";

    if (quiz.extension != "pdf" || null || undefined) {
      url = `${environment.storageUrl}/training-files/${quiz.trainingFileId}.${quiz.extension ? quiz.extension : "mp4"}${environment.SASToken}`;
    }
    else {
      url = `${environment.storageUrl}/training-files/${quiz.trainingFileId}.${quiz.extension ? quiz.extension : "pdf"}${environment.SASToken}`;
    }
    this.blobURL = url;
    this.check = url.includes("mp4");

    if (quiz.extension == 'pdf' || "mp4") {
      this.blobURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    this.attempted = quiz.atttemptedQuestionCount;
    this.total = quiz.questionCount;
    if (this.attempted == this.total) {
      this.exitButton = true;
    }
    else {
      this.exitButton = false;
    }
    this.currentQuiz = quiz;
    this.loader = true;
    this.rightQuestions = quiz.rightQuestions;
    this.questionCount = quiz.questionCount;
    var url = environment.apiConfig.uri + `/candidate/Quiz/${quiz.id}`;
    this.getQuiz(url);
    if (isMobile) {
      this.showQuiz = true;
    }
  }

  submitAnswer(saveOpt: any) {
    this.disableSubmit = true;
    var SelectedOptionId = saveOpt.value.radio;
    if (!SelectedOptionId) {
      this.errorMessage = "Please select an answer then Submit!";
      this.disableSubmit = false;
    } else {
      this.errorMessage = "Submitting the answer...";
      var questionId = this.currentQuestion.options[0].questionId;
      var trainingFileId = this.currentQuestion.questions.trainingFileID;
      var quizId = this.currentQuestion.questions.quizId;
      var url = environment.apiConfig.uri + `/candidate/Quiz/SaveAnswer?questionId=${questionId}&SelectedOptionId=${SelectedOptionId}&trainingFileId=${trainingFileId}&quizId=${quizId}`;
      this.http.post(url, {}).subscribe((response: any) => {
        this.responseData = response;
        if (this.responseData.isSuccess) {
          this.disableSubmit = false;
          var selectedOptionId = this.responseData.data.selectedOptionId;
          var correctOptionId = this.responseData.data.correctOptionId;
          if (selectedOptionId == correctOptionId) {
            this.errorMessage = "Correct! Well done ";
            this.quizList[this.quizIndex].rightQuestions += 1;
            this.rightQuestions += 1;
          } else  if (selectedOptionId != correctOptionId) {
            this.errorMessage = "Incorrect! You need 80% to pass.";
          }
          this.questionList[this.questionIndex].questions.isAttempted = true;
          this.questionList[this.questionIndex].questions.selectedOptionId = this.responseData.data.selectedOptionId;
          this.questionList[this.questionIndex].questions.rightOptionId = this.responseData.data.correctOptionId;
          this.attempted += 1;
          this.quizList[this.quizIndex].atttemptedQuestionCount += 1;
          if (this.attempted == this.total) {
            this.exitButton = true;
            this.questionIndex = 0;
          }
          else {
            this.exitButton = false;
          }
          this.res = ((this.rightQuestions / this.questionCount) * 100);
          if (this.res >= 80) {
            var postURL = `${environment.apiConfig.uri}/Candidate/CandidateStatus`;
            this.http.post(postURL, {}).subscribe((response: any) => {
            });
            var postURL1 = `${environment.apiConfig.uri}/Candidate/CandidatePlacementStatus?jobOrder=${this.currentQuiz.joborderId * 1}`;
            this.http.post(postURL1, {}).subscribe((response: any) => {
            });
          }
          if (this.mobileView) {
            this.filesForMobile(this.quizIndex);
          }
          this.getQuizzes();
          setTimeout(() => {
            this.errorMessage = null;
          }, 1000);
        } else {
          this.errorMessage = "Error saving the option";
        }
      });
    }
  }

  goBack() {
    this.questionIndex -= 1;
    this.currentQuestion = this.questionList[this.questionIndex];
    this.errorMessage = null;
  }

  goNext() {
    this.questionIndex += 1;
    this.currentQuestion = this.questionList[this.questionIndex];
    this.errorMessage = null;
  }

  switchQuestion(index: number) {
    this.errorMessage = null;
    this.questionIndex = index;
    this.currentQuestion = this.questionList[index];
  }

  onClickDownload(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item);
    } else {
      const link = document.createElement('a');
      link.download = `${item.fileName}`;
      const source = `data:${item.contentType}/${item.contentSubType};base64,${item.fileStream}`;
      link.href = source;
      link.click();
    }
  }
  onClickView(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item, true);
    } else {
      const link = document.createElement('a');
      link.target = '_blank';
      const Blob = this.dataURItoBlob(item);
      //('blob', Blob);
      const file = new File([Blob], item.fileName, {
        type: `${item.contentType}/${item.contentSubType}`,
      });
      //('file', file);
      let url = window.URL.createObjectURL(file);
      link.href = url;
      link.click();
    }
  }
  getFileStream(item: any, view: boolean = false) {
    var url = environment.apiConfig.uri + `/admin/File/GetFileStream/training-files/${item.trainingFileId}`;
    this.http.get(url).subscribe((response: any) => {
      this.file = response.data ? response.data : '$';
      item.fileStream = this.file;
      if (view == true) {
        this.onClickView(item);
      } else {
        this.onClickDownload(item);
      }
    });
  }

  b64toBlob(b64Data: any, contentType: any) {
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: `${item.contentType}/${item.contentSubType}`,
    });
    return blob;
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

}

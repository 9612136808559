import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.css']
})
export class FileViewerComponent implements OnInit {
  blobURL:any;
  docType:any;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.blobURL = this.activeRoute.snapshot.queryParams.fileUrl;
    this.docType = this.activeRoute.snapshot.queryParams.docType;
    if(this.docType == 'document'){
      this.blobURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobURL);
    }
  }

}

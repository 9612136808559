import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Step,Workflow, Department} from '../../models/CreateWorkflow';
import { GetAdminsService } from 'src/app/services/getAdmins.service';

@Component({
  selector: 'app-create-workflow',
  templateUrl: './create-workflow.component.html',
  styleUrls: ['./create-workflow.component.css'],
})

export class CreateWorkflowComponent implements OnInit {
  loadingWorkflow: boolean = true;
  selected: string = 'create-workflow';
  mobileView: boolean = false;
  errorMsg: any;
  workflowForm!: FormGroup;
  loading: boolean = false;
  triggers: string[] = ['New Client (Labour Hire)', 'New Client (PALM)','New Client (RPO)', 'New Job (Labour Hire)', 'New Client (Fee-only)','New Job (Fee-only)',
     'New Job (PALM)', 'New Job (RPO)', 'New Candidate (Labour Hire)', 'New Candidate (PALM)', 'New Candidate (RPO)', 'Weekly', 'Monthly'];
  files: File[] = [];
  allWorkFlows: Workflow[] = [];
  departments: Department[] = [];
  currentFileIndex: number | null = null;
  fileExtension: any;
  fileSize!: number;
  file: any;
  contentType: any;
  contentSubType: any;
  fileName: any;
  FileID: any;
  uploadFailed: boolean = false;
  currentFile: File = new File([], '');
  uploaded: boolean = false;
  progressValue: number = 0;
  uploading: boolean = false;
  updating: boolean = false;
  fileInputText = '';
  progressText: string = 'uploading...';
  selectedData: any;
  selectedWorkflowId: string = '';
  stepIndex: any;
  submitFlowUrl: string = '';
  workflowIdToDelete: any;
  successMessage: string = '';
  validationMessage: string = '';
  unsavedModel: boolean = false;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChild('staticBackdrop') staticBackdrop!: ElementRef;
  isFormSaved: boolean = false;
  isUploading: boolean = false;
  uploadingStepIndex: number | null = null;
  currentSortColumn: string = '';
  sortDirection: boolean = true; // true for ascending, false for descending
  admins:string[]=[];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal, private admin:GetAdminsService
  ) {
    this.workflowForm = this.fb.group({
      workflowTitle: ['', Validators.required],
      triggerTitle: ['', Validators.required],
      department: ['', Validators.required],
      steps: this.fb.array(this.initSteps()),
    });
  }

  ngOnInit(): void {
    this.fetchAdmins()
    this.getAllData();
    this.addStep(); // Add one step by default
    this.workflowForm.valueChanges.subscribe(() => {
      if (this.isFormSaved) {
        this.isFormSaved = false;
      }
    });
  }
  fetchAdmins(){
    this.admin.getAdmins().subscribe(
      (res: any) => {
        this.admins = res;
      },
      (error) => {
        console.error('Failed to determine admin status', error);
      }
    );
  }

  sort(column: string) {
    if (this.currentSortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.currentSortColumn = column;
      this.sortDirection = true;
    }

    this.allWorkFlows.sort((a: any, b: any) => {
      let comparison = 0;

      // Ensure the comparison handles different data types
      let valueA = a[column];
      let valueB = b[column];

      // Handle null or undefined values
      if (valueA === null || valueA === undefined) valueA = '';
      if (valueB === null || valueB === undefined) valueB = '';

      // If the values are strings, compare them case-insensitively
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }

      return this.sortDirection ? comparison : -comparison;
    });
  }

  initSteps() {
    return [
      this.fb.group({
        id: [0],
        title: [''],
        fileId: [''],
        fileName: [''],
        loading: [false],
        assignTo:['']
      })
    ];
  }

  getAllData() {
    this.loadingWorkflow = true;
    const departmentsUrl = `${environment.apiConfig.uri}/admin/Candidates/GetWorkflowDepartments`;
    const workflowsUrl = `${environment.apiConfig.uri}/admin/Candidates/GetAllWorkflows`;
    forkJoin({
      departments: this.http.get<{ data: Department[] }>(departmentsUrl),
      workflows: this.http.get<{ data: Workflow[] }>(workflowsUrl),
    }).subscribe(
      (response) => {
        this.departments = response.departments.data;
        this.allWorkFlows = response.workflows.data;
        this.loadingWorkflow = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.loadingWorkflow = false;
      }
    );
  }

  editworkflow(workflowId: any) {
    this.validationMessage = '';
    this.unsavedModel = false;
    this.isFormSaved = false;
    this.workflowForm.markAsPristine();
    this.successMessage = '';
    this.submitFlowUrl = `${environment.apiConfig.uri}/admin/Candidates/UpdateWorkFlow`;
    this.loading = true;
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetSpecificWorkflow?workflowId=${workflowId}`;
    this.http.get(url).subscribe((res: any) => {
      this.selectedData = res.data;
      this.selectedWorkflowId = workflowId; // Store the workflowId
      this.workflowForm.patchValue({
        workflowTitle: this.selectedData.workflowTitle,
        triggerTitle: this.selectedData.triggerName,
        department: this.selectedData.departmentName
      });

      this.steps.clear();
      this.selectedData.steps.forEach((step: any) => {
        this.addStep(step);
      });
      this.loading = false;
    },
    (error) => {
      console.error('Error fetching workflow:', error);
      this.loading = false;
    });
  }

  createWorkflow() {
    this.validationMessage = '';
    this.loading = false;
    this.isFormSaved = false;
    this.unsavedModel = false;
    this.workflowForm.markAsPristine();
    this.successMessage = '';
    this.selectedWorkflowId = '';
    this.submitFlowUrl = `${environment.apiConfig.uri}/admin/Candidates/CreateWorkFlow`;
    this.selectedData = null;
    // Reset the form to default values
    this.workflowForm.reset({
      workflowTitle: '',
      triggerTitle: '',
      department: '',
    });
    // Clear existing steps
    this.steps.clear();
    // Add one default step
    this.addStep();
  }

  deleteworkflow(workflowId: any) {
    this.successMessage = '';
    this.workflowIdToDelete = workflowId;
  }
  confirmDelete() {
    this.loading = true;
    const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteWorkFlow?workflowId=${this.workflowIdToDelete}`;
    this.http.delete(url).subscribe((res: any) => {
      this.getAllData();
      this.successMessage = 'Workflow Deleted Successfully.';
      this.loading = false;
    });
  }

  get steps(): FormArray {
    return this.workflowForm.get('steps') as FormArray;
  }

  discardChanges(): void {
    this.workflowForm.markAsPristine();
    this.modalService.dismissAll();
  }

  addStep(stepData?: Partial<Step>): void {
    this.steps.push(
      this.fb.group({
        id: [stepData?.id || 0],
        title: [stepData?.title || ''],
        fileId: [stepData?.fileId || ''],
        fileName: [stepData?.fileName || ''],
        assignTo:[stepData?.assignTo || ''],
      })
    );
  }

  triggerFileInput(index: number) {
    this.stepIndex = index;
    this.currentFileIndex = index;
    this.fileInput.nativeElement.click();
  }

  onCloseClick(): void {
    if (this.workflowForm.dirty && !this.isFormSaved) {
      this.unsavedModel = true;
    }
    this.modalService.dismissAll();
  }

  onFileChange(event: any) {
    if (!this.steps || this.steps.length <= this.stepIndex) {
      console.error(`Invalid stepIndex: ${this.stepIndex}`);
      return;
    }
    const reader = new FileReader();
    if (event.target.files) {
      const file = event.target.files[0];
      if (!file) return;
      this.fileInputText = file.name;
      const fileNameList = this.fileInputText.split('.');
      this.fileExtension = fileNameList.pop(); // Get the last element as the extension
      this.fileSize = Math.round(file.size / 1000);
      this.currentFile = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileString = reader.result?.toString();
        const base64Content = fileString?.split(';base64,')[1]; // Extract Base64 content

        this.contentType = fileString
          ?.split(';base64,')[0]
          .split('/')[0]
          .split(':')[1];
        this.contentSubType = fileString?.split(';base64,')[0].split('/')[1];

        // Adjust content type and subtype for specific extensions
        if (
          this.fileExtension.toLowerCase() === 'mp4' ||
          this.fileExtension.toLowerCase() === 'mov'
        ) {
          this.contentType = 'video';
          this.contentSubType = 'mp4';
          this.fileExtension = 'mp4'; // Ensuring consistency for video files
        }

        // Join remaining parts to form the file name without extension
        const fileNameWithoutExtension = fileNameList.join('.');
        const fileNameWithExtension = `${fileNameWithoutExtension}.${this.fileExtension}`;
        const FileObj = {
          fileName: fileNameWithoutExtension,
          fileSize: this.fileSize.toString(),
          contentType: 'application',
          contentSubType: 'pdf',
          extension: this.fileExtension,
          fileContent: base64Content,
        };

        const url = `${environment.apiConfig.uri}/admin/File/AddWorkFlowFile`;

        this.isUploading = true;
        this.uploadingStepIndex = this.stepIndex;
        this.http.post<any>(url, FileObj).subscribe(
          (response: any) => {
            const fileId = response.data.id;
            let fileName = response.data.fileName;
            // Remove the file extension from the file name if it exists
            if (fileName.includes('.')) {
              fileName = fileName.substring(0, fileName.lastIndexOf('.'));
            }
            // Ensure stepIndex is valid before updating step
            if (this.steps && this.steps.length > this.stepIndex) {
              const step = this.steps.at(this.stepIndex);
              step.get('fileId')?.setValue(fileId);
              step.get('fileName')?.setValue(fileNameWithExtension); // Include extension here
            } else {
              console.error(`Invalid stepIndex: ${this.stepIndex}`);
            }
            // Mark the form as dirty
            this.workflowForm.markAsDirty();
            // Set isUploading to false and reset uploadingStepIndex after the upload is complete
            this.isUploading = false;
            this.uploadingStepIndex = null;
          },
          (error: any) => {
            console.error('Error uploading file', error);
            // Set isUploading to false and reset uploadingStepIndex if there is an error
            this.isUploading = false;
            this.uploadingStepIndex = null;
          }
        );
      };
    }
  }

  saveWorkflow() {
    this.unsavedModel = false;
    this.validationMessage = ''; // Reset validation message
    this.successMessage = '';
    this.loading = true;
    const workflowTitle = this.workflowForm.get('workflowTitle')?.value;
    const triggerName = this.workflowForm.get('triggerTitle')?.value;
    const departmentName = this.workflowForm.get('department')?.value;
    // Validation
    if (!workflowTitle) {
      this.validationMessage = 'Workflow Title is required.';
      this.loading = false;
      return;
    } else if (!triggerName) {
      this.validationMessage = 'Trigger is required.';
      this.loading = false;
      return;
    } else if (!departmentName) {
      this.validationMessage = 'Department is required.';
      this.loading = false;
      return;
    }

    const trigger = this.triggers.includes(triggerName) ? triggerName : '';
    const department = this.departments.find(
      (d: any) => d.departmentName == this.workflowForm.get('department')?.value
    );
    const workflowData: any = {
      workflowTitle: this.workflowForm.get('workflowTitle')?.value,
      triggerName: trigger,
      departmentId: department ? department.id : 0,
      departmentName: department ? department.departmentName : '',
      steps: this.steps.controls.map((step, index) => {
        const stepData: any = {
          id: step.get('id')?.value || 0,
          stepNumber: index + 1,
          title: step.get('title')?.value,
          fileId: step.get('fileId')?.value || null,
          fileName: step.get('fileName')?.value || null,
          status: 'New',
          assignTo:step.get('assignTo')?.value || null,
        };
        const fileId = step.get('fileId')?.value;
        const fileName = step.get('fileName')?.value;
        if (fileId) {
          stepData.fileId = fileId;
        }
        if (fileName) {
          stepData.fileName = fileName;
        }
        return stepData;
      }),
    };
    console.log(workflowData)

    if (this.selectedWorkflowId) {
      workflowData.workflowId = this.selectedWorkflowId;
    }

    this.http.post(this.submitFlowUrl, workflowData).subscribe(
      (response) => {
        this.isFormSaved = true;
        this.workflowForm.markAsPristine();
        this.workflowForm.markAsDirty();
        this.getAllData();
        this.loading = false;
        this.successMessage = 'Workflow Created Successfully';
      },
      (e) => {
        console.error('Error saving workflow', e.error.message);
        this.loading = false;
        this.successMessage = e.error.message;
        this.isFormSaved = false;
      }
    );
  }

  getFileStream(item: any, view: boolean = false) {
    const url = `${environment.apiConfig.uri}/admin/File/GetWorkFlowFileStream/${item.value.fileId}`;
    this.http.get(url).subscribe(
      (response: any) => {
        this.file = response.data || '';
        item.fileStream = this.file;
        if (view) {
          this.onClickView(item);
        } else {
          this.onClickDownload(item);
        }
      },
      (error) => {
        console.error('Error fetching file stream:', error);
      }
    );
  }

  onClickDownload(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const link = document.createElement('a');
      link.download = `${item.value.fileName}`;
      const source = `data:${this.getMimeType(item.value.fileName)};base64,${
        item.fileStream
      }`;
      link.href = source;
      link.click();
    }
  }

  onClickView(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item, true);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const mimeType = this.getMimeType(item.value.fileName);
      if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.onClickDownload(item);
      } else {
        const blob = this.dataURItoBlob(item);
        const file = new File([blob], item.value.fileName, {
          type: mimeType,
        });
        const url = window.URL.createObjectURL(file);
        window.open(url);
      }
    }
  }

  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: this.getMimeType(item.value.fileName),
    });
    return blob;
  }

  getMimeType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'pdf': return 'application/pdf';
      case 'jpg':
        case 'jpeg': return 'image/jpeg';
        case 'png': return 'image/png';
        case 'gif': return 'image/gif';
        case 'mp4': return 'video/mp4';
        case 'mov': return 'video/quicktime';
        case 'avi': return 'video/x-msvideo';
        case 'mp3': return 'audio/mpeg';
        case 'webp':return 'image/webp';
        case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        default: return 'application/octet-stream';
    }
  }

  deleteStep(index: number): void {
    const step = this.steps.at(index);
    const fileId = step.get('fileId')?.value;
    if (fileId) {
      this.deleteFile(fileId).subscribe(
        (response) => {
          this.steps.removeAt(index);
        },
        (error) => {
          console.error('Error deleting file', error);
        }
      );
    } else {
      this.steps.removeAt(index);
    }
  }

  deleteFile(fileId: string): Observable<any> {
    const url = `${environment.apiConfig.uri}/admin/File/DeleteWorkFlowFile${fileId}`;
    this.loading = true;
    return this.http.delete(url).pipe(
      tap(() => {
        this.loading = false;
        this.errorMsg = 'Deleted the file!';
        setTimeout(() => {
          this.errorMsg = null;
        }, 2000);
      }),
      catchError((error) => {
        this.loading = false;
        this.errorMsg = 'Unable to delete the file. Please delete the associated quiz first.';
        setTimeout(() => {
          this.errorMsg = null;
        }, 2000);
        return throwError(error);
      })
    );
  }

}

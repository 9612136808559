import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Vehicle } from 'src/app/models/Vehicles';
import { GetSitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {
  selected:string='vehicles'
  validationMessage:string='';
  successMessage:string=''; 
  loading: boolean = false;
  unsavedModel:boolean=false;
  isFormSaved: boolean = false;
  vehicleForm!: FormGroup;
  loadingVehicles:boolean=false;
  mobileView: boolean = false;
  sites: string[] = []; 
  statuses = ['Active'];
  allVehicles:Vehicle[] = [];
  errorMessage:string='';
  saveVehicleUrl:string='';
  selectedSafetyCultureAssetId:string='';
  selectedVehicleId!:number;
  safetyCultureAssetId:string='';
  isModalVisible: boolean = false;
  currentSortColumn: string = '';
  sortDirection: boolean = true; // true for ascending, false for descending
  constructor(private fb: FormBuilder, private http: HttpClient, private modalService: NgbModal, private getSitesService: GetSitesService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.loadSites();
    this.getVehicles()
    this.vehicleForm.valueChanges.subscribe(() => {
      if (this.isFormSaved) {
          this.isFormSaved = false;
      }
  });
  }

  loadSites(): void {
    this.getSitesService.getSites().subscribe(
      (sites: string[]) => {
        this.sites = sites;
      },
      (error) => {
        console.error('Error fetching sites:', error);
        this.validationMessage = 'Failed to load sites';
      }
    );
  }

  getVehicles(){   
    this.validationMessage ='';
    this.loadingVehicles = true; 
    const apiUrl = `${environment.apiConfig.uri}/admin/Candidates/GetVechiles`;
    this.http.get<{data:Vehicle[]}>(apiUrl).subscribe((response)=>{
      this.allVehicles = response.data
      .filter(vehicle => vehicle.id !== -1 && vehicle.id !== -2)
      .map(vehicle=>{
        return{
          ...vehicle,
          startDate:vehicle.startDate?vehicle.startDate.split('T')[0]:'',
          endDate:vehicle.endDate?vehicle.endDate.split('T')[0]:''
        }
      });
      this.loadingVehicles = false;
    },(error)=>{
      this.errorMessage = 'Failed to load vehicles. Please try again later.';
      this.loadingVehicles = false;
    })  
  }

  initializeForm() {
    this.vehicleForm = this.fb.group({
      rego: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      seats: [null, [Validators.required, Validators.min(1)]],  // Assuming seats is a number
      rentalProvider: ['', Validators.required],
      site: ['', Validators.required],
      status: ['', Validators.required],
      costPerWeek: [null, [Validators.required, Validators.min(0)]],  // Assuming costPerWeek is a number
      startDate:[null],
      endDate:[null],
      notes:[''],
      report:['']
    });
  }

  addVehicle(){
    this.statuses=['Active'];
    this.vehicleForm.markAsPristine();
    this.initializeForm();
    this.unsavedModel = false;
    this.successMessage = '';
    this.validationMessage ='';
    this.isFormSaved = false;
    this.saveVehicleUrl=`${environment.apiConfig.uri}/admin/Candidates/AddVehicle`
  }

  saveVehicle(): void {
    this.validationMessage = '';
    this.successMessage = '';
    this.unsavedModel = false;
    this.loading = true;
  
    if (this.vehicleForm.invalid) {
      this.loading = false;
      this.validationMessage = 'Please fill out all required fields correctly.';
      return;
    }
  
    const vehicleData = this.vehicleForm.value;
 // Only assign a value to 'report' if it is explicitly selected
    if (vehicleData.report === null || vehicleData.report === '') {
      vehicleData.report = null; // Set to null if not selected
    } else {
      vehicleData.report = vehicleData.report === 'true' || vehicleData.report === true; // Ensure boolean value
    }    
    vehicleData.safetyCultureAssetId = this.selectedSafetyCultureAssetId || '';
    if (vehicleData.startDate == "") {
      vehicleData.startDate = null;
    }
    if (vehicleData.endDate == "") {
      vehicleData.endDate = null;
    }
    // Check if this is an update operation by checking the URL
    const isUpdateOperation = this.saveVehicleUrl.includes('Update');
    let duplicateVehicle;
    if (isUpdateOperation) {
      vehicleData.id = this.selectedVehicleId;
      // If updating, compare rego with other vehicles except the current one
      duplicateVehicle = this.allVehicles.find(vehicle =>
        vehicle.rego.toLowerCase().trim() === vehicleData.rego.toLowerCase().trim() && vehicle.id !== this.selectedVehicleId
      );
    } else {
      // If adding, compare rego with all vehicles
      duplicateVehicle = this.allVehicles.find(vehicle =>
        vehicle.rego.toLowerCase().trim() === vehicleData.rego.toLowerCase().trim()
      );
    }
  
    if (duplicateVehicle) {
      this.loading = false;
      this.validationMessage = 'There is already a vehicle with this rego. Record not saved.';
      return;
    }
  
    this.http.post(this.saveVehicleUrl, vehicleData).subscribe(
      response => {
        this.successMessage = 'Vehicle saved successfully';
        this.getVehicles();
        this.loading = false;
        this.isFormSaved = true;
      },
      err => {
        console.error('Error saving vehicle', err.error);
        this.validationMessage = err.error.message;
        this.loading = false;
        this.isFormSaved = false;
      }
    );
  }
  
  discardChanges(): void {
    this.vehicleForm.markAsPristine();
    this.modalService.dismissAll();
  }

  updateVehicle(index:any){
    this.initializeForm();
    this.validationMessage ='';
    this.statuses=['Active', 'InActive'];
    this.errorMessage='';
    this.successMessage=''
    this.unsavedModel=false;
    this.isFormSaved = false;
    this.saveVehicleUrl=`${environment.apiConfig.uri}/admin/Candidates/UpdateVehicle`;
    const selectedVehicle = this.allVehicles[index];
    this.vehicleForm.patchValue({
      rego: selectedVehicle.rego,
      make: selectedVehicle.make,
      model: selectedVehicle.model,
      seats: selectedVehicle.seats,
      rentalProvider: selectedVehicle.rentalProvider,
      site: this.sites.find((s) => s === selectedVehicle.site),
      status: selectedVehicle.status,
      costPerWeek: selectedVehicle.costPerWeek,
      startDate: selectedVehicle.startDate?selectedVehicle.startDate:null,
      endDate: selectedVehicle.endDate?selectedVehicle.endDate:null,
      notes: selectedVehicle.notes,
      report: selectedVehicle.report === true ? true : selectedVehicle.report === false ? false : null
    });
    this.selectedVehicleId = selectedVehicle.id;
    this.selectedSafetyCultureAssetId = selectedVehicle.safetyCultureAssetId;
  }
 
  deleteVehicle(data: Vehicle) {
    if (data.status === "Active") {
      this.successMessage = "You cannot delete an Active Vehicle. Please make it Inactive first if you want to delete.";
      this.isModalVisible = true;
    } else {
      this.safetyCultureAssetId = data.safetyCultureAssetId;
      this.successMessage = '';
      this.isModalVisible = true;
    }
  }
  
  confirmDelete() {
    if (this.safetyCultureAssetId) {
      this.loading = true;
      const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteVehicle?safetyCultureAssetId=${this.safetyCultureAssetId}`;
      this.http.delete(url).subscribe(
        (res: any) => {
          this.getVehicles();
          this.successMessage = 'Vehicle Deleted Successfully.';
          this.loading = false;
        },
        (error) => {
          console.error('Error deleting vehicle:', error);
          this.loading = false;
        }
      );
    }
  }
  
  onCancel() {
    this.isModalVisible = false;
  }
  
  onClose() {
    this.isModalVisible = false;
  }
  onCloseClick(): void {
    if (this.vehicleForm.dirty && !this.isFormSaved) {
      this.unsavedModel = true;
    } else {
      this.modalService.dismissAll();
    }
  }

  sort(column: string) {
    if (this.currentSortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.currentSortColumn = column;
      this.sortDirection = true;
    }
    this.allVehicles.sort((a: any, b: any) => {
      let comparison = 0;
      if (['rego', 'make', 'model', 'rentalProvider','site','status','notes','report'].includes(column)) {
        const valueA = a[column]?.toString().trim().toLowerCase() || '';
        const valueB = b[column]?.toString().trim().toLowerCase() || '';
        comparison = valueA.localeCompare(valueB);
      } 
      else if (['seats', 'costPerWeek'].includes(column)) {
        comparison = a[column] - b[column];
      }
      else if (['statDate','endDate'].includes(column)) {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }
      }
      return this.sortDirection ? comparison : -comparison;
    });
  }

}

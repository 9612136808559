import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FIEIDS } from 'src/commons/constants';
import {MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG,MsalGuardConfiguration,} from '@azure/msal-angular';
import {Component,OnInit,Inject,ViewChild,ElementRef} from '@angular/core';
import {EventMessage, EventType, InteractionType,InteractionStatus,PopupRequest,RedirectRequest,AuthenticationResult} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GetUserQueryService } from 'src/app/services/get-user-query.service';
import { ChangeDetectorRef } from '@angular/core';
import { UpdateCandidate } from 'src/app/models/UpdateCandidate';
import { Address } from 'src/app/models/UpdateCandidate';
import { countryIds } from 'src/app/models/countryIds';
import { driversLicenceOptions } from 'src/app/models/driversLicenceOptions';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent
  implements OnInit {
  formSession: boolean = false;
  UnSavedPopUp: boolean = false;
  updatecandidate!: UpdateCandidate;  
  profile: any;
  name: any;
  email: any;
  lastname: any;
  displayLoader: boolean = true;
  update: boolean = false;
  preferred_lang: string = 'Spanish';
  dateOfBirth: any;
  saving: boolean = false;
  message: string = 'Saving the Details..';
  navigate: boolean = false;
  BullhornMeta: any;
  namePrefixes: any;
  customText11: any;
  mobileView: boolean = false;
  selected: string = 'profile';
  quizList: any;
  trainingcompleted: any;
  id: string = '';
  profile_data: string | undefined;
  profilecompleted: any;
  first_Name: any;
  savedetailsModal: boolean = false;
  @ViewChild('btnSwitchTab') btnSwitchTab!: ElementRef;
  @ViewChild('closeNav') closeNav!: ElementRef;
  @ViewChild('closeModelProfile') closeModelProfile!: ElementRef;
  deductionStatus:any;
  countryIds = countryIds;
  homeData:any;
  driversLicenceOptions =driversLicenceOptions;
  pageName: any;
  payrollIconCount: any;
  title = 'FrontendService';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private route: ActivatedRoute,
    private getUserQuery:GetUserQueryService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private cdr: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit(): void {
    var url = `${environment.apiConfig.uri}/candidate/PortalStatus`;
    this.http.get(url).subscribe(async (response: any) => {
    })
    setTimeout(() => {
      this.profilecompleted = localStorage.getItem('profilecompleted');
    }, 1000);  
    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (window.screen.width <= 768) {
          this.mobileView = true;
        }
      }
    });
    var email_var = localStorage.getItem('email');
    if (email_var && email_var != 'null') {
      this.email = email_var;
      this.name = localStorage.getItem('name');
    }
    this.getQuery();
    this.menuStatus();
    this.tfnStatus();
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        return result;
      });
  }

  validatorInput(event: any) {
    this.first_Name = event.trim()
  }
  canDeactivate(): boolean {
    if (this.navigate) {
      this.UnSavedPopUp = false;
      return true;
    } else if (this.update) {
      this.UnSavedPopUp = true;
      this.btnSwitchTab.nativeElement.click();
      return false;
    }
    return true;
  }
  addItem(newItem: string) {
    this.profile = newItem;
  }
  
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  nextUrl(pageName: string) {
    this.pageName = pageName;
  }

  navigateUrl() {
    this.closeModelProfile.nativeElement.click();
    this.navigate = true;
    var url = this.pageName;
    this.router.navigate([url]);
  }

  submitForm(data: any) {
    this.savedetailsModal = true;
    this.UnSavedPopUp = false;
    this.togglePopUp();
    const profileOBJ = <UpdateCandidate>{};
    const addressOBJ = <Address>{};
    let newDate = new Date(this.profile.dateOfBirth).getTime() + 43200000;
    profileOBJ.dateOfBirth = newDate;
    profileOBJ.firstName = this.profile.firstName;
    profileOBJ.lastName = this.profile.lastName;
    if (this.profile.customDate6) {
      let customDate6Value = new Date(this.profile.customDate6).getTime();
      if (!isNaN(customDate6Value)) {
        profileOBJ.customDate6 = customDate6Value; // milliseconds
      }
    }
    for (var key in data) {
      var value = data[key];
      if (key == 'address1') {
        addressOBJ.address1 = value;
      } else if (key == 'customText26') {
        profileOBJ.customText26 = value;
      } else if (key == 'customText8') {
        profileOBJ.customText8 = value; 
      } else if (key == 'address2') {
        addressOBJ.address2 = value;
      } else if (key == 'city') {
        addressOBJ.city = value;
      } else if (key == 'state') {
        addressOBJ.state = value;
      } else if (key == 'zip') {
        addressOBJ.zip = value;
      } else if (key == 'candidateAddressCountryId') {
        addressOBJ.countryID = value;
      } else if (key == 'email') {
        profileOBJ.email = value;
      } else if (key == 'gender') {
        profileOBJ.gender = value;
      } else if (key == 'nickName') {
        profileOBJ.nickName = value;
      } else if (key == 'namePrefix') {
        profileOBJ.namePrefix = value;
      } else if (key == 'customText13') {
        profileOBJ.customText13 = value;
      } else if (key == 'customText11') {
        profileOBJ.customText11 = value;
      } else if (key == 'mobile') {
        profileOBJ.mobile = value;
      } else if (key == 'customText36') {
        profileOBJ.customText36 = value;
      } else if (key == 'customText37') {
        profileOBJ.customText37 = value;
      } else if (key == 'email3') {
        profileOBJ.email3 = value;
      } else if (key == 'phone3') {
        profileOBJ.phone3 = value;
      } else if (key == 'id') {
        profileOBJ.id = value;
      }
      if (this.customText11 && this.customText11.length > 0) {
        profileOBJ.customText11 = this.customText11;
      }
    }
    this.name = this.profile.firstName.trim();
    this.lastname = this.profile.lastName.trim();
    this.email = data.email;
    if (Object.keys(addressOBJ) && Object.keys(addressOBJ).length > 0) {
      profileOBJ.address = addressOBJ;
    }
    var url = environment.apiConfig.uri + '/Candidate';
    this.http.put<any>(url, profileOBJ).subscribe((response) => {
      var newData = JSON.parse(response.data).data;
      var add = newData.address;
      this.togglePopUp();
      this.update = false;
      this.formSession = false;
      if (response.isSuccess == true) {
        this.getProfile();
        const { nickName, ...formData } = newData;
        const { address2, ...addressData } = newData.address;
        let formValue = Object.values(formData);
        let formAdressValue = Object.values(addressData);
        let checkFormValues = formValue.every(e => e != "" && e != 0 && e != null && e != undefined);
        let checkFormAdressValue = formAdressValue.every(e => e != "" && e != null && e != undefined);
        if (checkFormValues && checkFormAdressValue) {
          localStorage.setItem('profilecompleted', 'true');
          this.profilecompleted = 'true';
        } else {
          localStorage.setItem('profilecompleted', 'false');
          this.profilecompleted = 'false';
        }
        setTimeout(() => {
          this.closeModelProfile.nativeElement.click();
          this.savedetailsModal = false;
        }, 1000);
        this.closeModelProfile.nativeElement.click();
      }
      if (this.pageName) {
        this.navigate = true;
        this.router.navigate([this.pageName]);
      }
    });
    setTimeout(() => {
      var postURL = `${environment.apiConfig.uri}/Candidate/CandidateStatus`;
      this.http.post(postURL, {}).subscribe((response: any) => {
      });
    }, 10000);

  }

  menuStatus() {
    var url = `${environment.apiConfig.uri}/Candidate/AstuteBankStatus`;
    this.http.post(url, {}).subscribe(async (response: any) => {
      if(response.data){
        localStorage.setItem('bankdetailsStatus',response.data.astuteBankStatus);
        localStorage.setItem('superannuationStatus',response.data.superAnnuationStatus);
      }
    })
  }

  getQuery(){
    this.getUserQuery.getUserQuery().subscribe((res:any)=>{
      this.payrollIconCount=res.data.length; 
        // Check if all deductions are complete
        let queryData=res.data;
        const allComplete = queryData?.every((item: { deductionStatus: string; }) => item.deductionStatus === "Complete");
        // Set localStorage value based on the result
      localStorage.setItem('deductionStatus', allComplete ? 'Complete' : 'Incomplete');
      this.deductionStatus=allComplete ? 'Complete' : 'Incomplete';
      localStorage.setItem('homeData',this.payrollIconCount);
      this.homeData=this.payrollIconCount;
      this.getProfile();      
    })
  }

  tfnStatus() {
    var url = `${environment.apiConfig.uri}/Candidate/TFNStatus`;
    this.http.post(url, {}).subscribe(async (response: any) => {
      if(response.data){
        localStorage.setItem('taxStatus',response.data.tfnStatus);
      }
    });
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
  
  getProfile() {
    var url = environment.apiConfig.uri + '/Candidate';
    this.http.get(url).subscribe((profile: any) => {
      this.profile = profile.data;
      var cData = this.profile;
      localStorage.setItem('mobile', this.profile.mobile);
      if (!cData.candidateAddress1 || !cData.candidateAddressCity || !cData.candidateAddressCountryId || !cData.candidateAddressState || !cData.customText11 || !cData.customText13 || !cData.namePrefix || !cData.firstName || !cData.lastName || !cData.email || !cData.mobile || !cData.dateOfBirth || !cData.gender || !cData.zip || !cData.customText36 || !cData.customText37 || !cData.email3 || !cData.phone3) {
        localStorage.setItem('profilecompleted', 'false');
        this.profilecompleted = 'false';
      }
      else {
        localStorage.setItem('profilecompleted', 'true');
        this.profilecompleted = 'true';
      }
      this.BullhornMeta = JSON.parse(this.profile.bullhornMetaFields);
      this.BullhornMeta = FIEIDS
      this.name = `${this.profile.firstName.trim()} ${this.profile.lastName.trim()}`;
      this.email = this.profile.email;
      this.dateOfBirth = this.profile?.dateOfBirth;
      localStorage.setItem('name', this.name);
      localStorage.setItem('email', this.email);
      if (!this.BullhornMeta) {
        this.BullhornMeta = FIEIDS
      }
      localStorage.setItem('dateOfBirth', this.dateOfBirth);
      const datepipe: DatePipe = new DatePipe('en-US');
      const formattedDate = datepipe.transform(
        this.profile?.dateOfBirth?.toString(),
        'yyyy-MM-dd'
      );
      this.profile.dateOfBirth = formattedDate;
      const formattedCustomDate6 = datepipe.transform(
        this.profile?.customDate6?.toString(),
        'yyyy-MM-dd'
      );
      this.profile.customDate6 = formattedCustomDate6;
      if (!this.profile.namePrefix) {
        this.profile.namePrefix = '';
      }
      if (!this.profile.gender) {
        this.profile.gender = '';
      }
      if (!this.profile.customText11) {
        this.profile.customText11 = '';
      }
      if (!this.profile.customText13) {
        this.profile.customText13 = '';
      }
      if (!this.profile.candidateAddressCountryId) {
        this.profile.candidateAddressCountryId = '';
      }
      this.displayLoader = false;
      // this.checkProfilestatus();
    });
  }

  getDropDownFields(url: string) {
    this.http.get(url, { responseType: 'text' }).subscribe((response) => {
      this.getMeta(response);
    });
  }
  getMeta(BhRestToken: string) {
    var url = `https://rest60.bullhornstaffing.com/rest-services/2g9m9s/meta/Candidate?fields=customText13,customText11,gender,namePrefix&BhRestToken=${BhRestToken}`;
    this.http.get(url).subscribe((response) => {
      this.BullhornMeta = response;
      this.BullhornMeta = this.BullhornMeta.fields;
      this.displayLoader = false;
    });
  }
  updateInput(event: any) {
    this.formSession = true;
    if (this.update === false) {
      this.update = true;
    }
    var attr = event.target.name;
    if (this.profile.attr === null || this.profile.attr === undefined) {
      this.profile.attr = '';
    }
    if (attr == 'customText11') {
      this.customText11 = event.target.value;
    }
  }
  selectPageName(pageName: any) {
    this.pageName = pageName;
  }
  togglePopUp() {
    if (this.saving == true) {
      this.saving = false;
    } else {
      this.saving = true;
    }
  }
  ClosePopUp() {
    this.saving = false;
  }
  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      //('checkAndSetActiveAccount: ', accounts);
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
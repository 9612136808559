<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [id]="id"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
      <app-header></app-header>
      <div class="main-content-inner">
        <!-- market value area start -->
        <div class="row mt-5">
          <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
            <div class="card">
              <div class="card-body">
                <!-- Candidate Files -->
                <div>
                  <div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center justify-content-between">
                    <p class="flex-grow-1">{{ errorMessage }}</p>
                    <button class="btn btn-primary" (click)="refreshHistory()">Retry</button>
                  </div>
                  <app-table *ngIf="!errorMessage" [tableHeader]="[
                      'Display',
                      'New Value',
                      'Old Value',
                      'Modifying Person',
                      'Modified (UTC)'
                    ]" [tableRows]="[
                      'columnName',
                      'newValue',
                      'oldValue',
                      'modifyingPerson',
                      'dateAdded'
                    ]" [data]="history" [pageSizeOptions]="pageSizeOptions" [collectionSize]="collectionSize"
                    [model]="model" [refreshData]="refreshHistory" [loading]="loading" [title]="'Payroll History'"></app-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- market value area end -->
      </div>
    </div>
    <!-- main content area end -->
  </div>

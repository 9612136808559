import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Admins } from 'src/app/models/Admins';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PortalAdminService } from 'src/app/services/portal-admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  admins: Admins[] = [];
  pageSizeOptions = [10, 20, 50, 100];
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;
  loading: boolean = true;
  selected: string = 'admin';
  adminId!: string;
  errorMessage: string = '';
  isPortalAdmin: boolean = false;

  constructor(
    private adminService: AdminService,
    private http: HttpClient,
    private portalAdminService: PortalAdminService
  ) {
    this.portalAdminService.isPortalAdmin().subscribe(
      (res: any) => {
        this.isPortalAdmin = res;
      },
      (error) => {
        console.error('Failed to determine admin status', error);
        this.isPortalAdmin = false;
      }
    );
  }

  ngOnInit(): void {}

  public refreshAdmin = (
    page: string = '1',
    query: string = '',
    pageSize: string = '10'
  ) => {
    this.errorMessage = '';
    this.loading = true;
    this.page = page;
    this.pageSize = pageSize;
    this.admins = [];
    if (this.isPortalAdmin) {
      this.adminService
        .getAdmins(this.page.toString(), query, this.pageSize)
        .subscribe(
          (response: any) => {
            this.admins = response.data;
            this.collectionSize = response.extraParam.totalCount;
            this.errorMessage = '';
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.errorMessage =
              'Oops, something went wrong while fetching candidates. Please try again later.';
          }
        );
    } else {
      this.loading = false;
      this.errorMessage = 'Access denied: You do not have admin permissions.';
    }
  };

  updateAdminStatus(item: any) {
    const userId = item.userId;
    const newStatus = item.isAdmin;
    const apiUrl = `${environment.apiConfig.uri}/admin/Candidates/UpdateIsAdmin?userId=${userId}&isAdmin=${newStatus}`;
    this.http.get(apiUrl).subscribe({
      next: (response) => {},
      error: (error) => {
        console.error('Error updating status', error);
      },
    });
  }
}

<div [ngClass]="{'page-container': true, 'sbar_collapsed': mobileView}" style="background-color: #f2f8fa !important;">
  <app-sidebar-menu [selected]="selected" [id]="id"></app-sidebar-menu>
  <!-- main content area start -->
  <div class="main-content">
    <app-header></app-header>
    <div class="main-content-inner">
      <!-- market value area start -->
      <div class="row mt-5">
        <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important;">
          <div class="card">
            <div class="card-body">
              <div *ngIf="displayLoader">
                <li class="t_loader">
                  <div class="card__desc load" id="long"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load" id="long"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load" id="long"></div>
                </li>
              </div>
              <div class="table-responsive">
                <table *ngIf="jobsList; else noJobs" class="table mb-2 table-hover">
                  <ng-container>
                    <thead>
                      <tr>
                        <th scope="col" *ngIf="!mobileView">No</th>
                        <th scope="col">Job Title</th>
                        <th scope="col" *ngIf="!mobileView">Host Employer</th>
                        <th scope="col" *ngIf="!mobileView">Street Address</th>
                        <th scope="col" *ngIf="!mobileView">Town / Suburb</th>
                        <th scope="col" *ngIf="!mobileView">State</th>
                        <th scope="col" *ngIf="!mobileView">Start Date</th>
                        <th scope="col">Status</th>
                        <th scope="col" >Files</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let job of jobsList;index as i">
                        <td *ngIf="!mobileView">{{i+1}}</td>
                        <td>{{job.title}}</td>
                        <td *ngIf="!mobileView">{{job.clientName || "N/A"}}</td>
                        <td *ngIf="!mobileView">{{job.address_line1 || "N/A"}}</td>
                        <td *ngIf="!mobileView">{{job.address_city || "N/A"}}</td>
                        <td *ngIf="!mobileView">{{job.address_state || "N/A"}}</td>
                        <td *ngIf="!mobileView">{{job.startDate | date:"dd-MM-yyy" || "N/A"}}</td>
                        <td>{{job.status}}</td>
                        <td>
                          <button *ngIf="!mobileView" (click)="loadFiles(job.id)" style="font-size: 12px !important;" type="button"
                            data-toggle="modal" data-target="#desktopModalCenter"
                            class="btn btn-sm btn-info mb-2 px-3">View</button>
                            <button *ngIf="mobileView" class="btn btn-sm btn-info" (click)="mobileJobloadFiles(job.id ,i)"
                            style="font-size: 2vw; margin: 0;" type="button" data-toggle="modal"
                            data-target="#mobileViewModal">View</button>
                        </td>
                      </tr>
                    </tbody>
                  </ng-container>
                </table>
                <ng-template #noJobs>
                  <div *ngIf="!displayLoader">
                    <h5>No Jobs</h5>
                  </div>
                </ng-template>
              </div>              
            </div>
          </div>
        </div>
      </div>
      <!-- market value area end -->
    </div>
  </div>
  <!-- main content area end -->
</div>

<!-- Modal -->
<div class="modal fade" id="desktopModalCenter" tabindex="-1" role="dialog" aria-labelledby="desktopModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Job Files</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="loadingJobFiles">
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
        </div>
        <div *ngIf="!loadingJobFiles" class="table-responsive">
          <table class="table mb-2 table-hover">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">File Name</th>
                <th scope="col">File Type</th>
                <th scope="col">File Size (Kb)</th>
                <th scope="col">Date Added</th>
                <th scope="col">View</th>
              </tr>
            </thead>
            <tbody *ngIf="jobFilesList.length">
              <tr *ngFor="let item of jobFilesList;index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.fileName }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.fileSize }}</td>
                <td>{{ item.dateAdded | date:"dd-MM-yyy" || "N/A" }}</td>
                <td>
                  <button
                    *ngIf="item.contentSubType == 'pdf' || item.contentSubType == 'jpg' || item.contentSubType == 'png' || item.contentSubType == 'jpeg'; else showJobDownload"
                    class="btn btn-sm btn-info px-4" (click)="onClickView($event, item)">
                    View
                  </button>
                  <ng-template #showJobDownload>
                    <button class="btn btn-sm btn-info" (click)="onClickDownload($event, item)">
                      Download
                    </button>
                  </ng-template>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!jobFilesList.length">
              <tr>
                <td colspan="6" class="text-center">No Files</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile View Modal Start  -->
<div class="modal fade" id="mobileViewModal" tabindex="-1" role="dialog" aria-labelledby="desktopModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Job Files</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table *ngIf="!loadingJobFiles" class="table table-hover">
            <tbody>
              <tr>
                <td>Job Title</td>
                <td>{{mobileViewJobsList.title}}</td>
              </tr>
              <tr>
                <td>Host Employer</td>
                <td>{{mobileViewJobsList.clientName || "N/A"}}</td>
              </tr>
              <tr>
                <td>Town / Suburb</td>
                <td>{{mobileViewJobsList.address_city || "N/A"}}</td>
              </tr>
              <tr>
                <td>Start Date</td>
                <td>{{mobileViewJobsList.startDate | date:"dd-MM-yyy" || "N/A"}}</td>
              </tr>
              <tr>
                <td>Status </td>
                <td>{{mobileViewJobsList.status || "N/A"}}</td>
              </tr>
              <tr>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th scope="col">Files Name</th>
                <th scope="col">View</th>
              </tr>
              <ng-container *ngIf="jobFilesList.length">
                <tr *ngFor="let item of jobFilesList">
                  <td>{{item.fileName}}</td>
                  <td>
                    <button
                      *ngIf="item.contentSubType == 'pdf' || item.contentSubType == 'jpg' || item.contentSubType == 'png' || item.contentSubType == 'jpeg'; else showJobDownload"
                      class="btn btn-sm btn-info px-4" (click)="onClickView($event, item)">
                      View
                    </button>
                    <ng-template #showJobDownload>
                      <button class="btn btn-sm btn-info" (click)="onClickDownload($event, item)">
                        Download
                      </button>
                    </ng-template>
                </tr>
              </ng-container>
                <tr *ngIf="!jobFilesList.length">
                <td colspan="2" class="text-center">No Files</td>
                </tr>
            </tbody>
          </table>
          <div *ngIf="loadingJobFiles">
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- mobileViewModal modal end  -->
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import { ChangeDetectorRef } from '@angular/core';

type taxDeclarationForm = {
  id?: number;
  taxfileId?: number;
  employee_remote_id?: any;
  how_to_complete?: string;
  no_tfn?: number;
  tfnProvided?: number;
  exemptionReason?: number;
  previousSurname?: string;
  isPreviousSurnameChanged?: number;
  residencyStatus?: string;
  taxFreeThresholdClaimed?: number;
  hasHelpDebtSupport?: number;
  employmentBasis?: string;
  declarationName?: string;
  declarationSigned?: number;
  passportIssuanceCountry?: string;
  submissionMethod?: string;
  mid?: number;
  dueDate?: string;
  declaration_id?: string;
  candidateId?: number;
  requestPending?: boolean;
  submissionTime: Date;
  requestStatus: number;
  approvalTime: Date;
  approvedby: string;
};
type CandidateFile = {
  externalID?: string;
  name?: string;
  fileContent?: string;
  type?: string;
};
@Component({
  selector: 'app-tax-declaration',
  templateUrl: './tax-declaration.component.html',
  styleUrls: ['./tax-declaration.component.css'],
})

export class TaxDeclarationComponent implements OnInit, OnDestroy {
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  selected: string = 'tax-declaration';
  id: string = '';
  mobileView: boolean = false;
  newTaxFileTable: boolean = false;
  viewTaxFileTable: boolean = false;
  editTaxFileTable: boolean = false;
  exemption: boolean = false;
  previousName: boolean = false;
  australianResident: boolean = false;
  commonQuestions: boolean = false;
  foreignResident: boolean = false;
  workingHoliday: boolean = false;
  canResetForm: boolean = false;
  passportIssuanceCountry: string = '';
  notfn: number = 0;
  candidateID!: number;
  viewFormData: any;
  error: boolean = true;
  messaageForUser: string = '';
  historyData: any;
  profile1new: boolean = false;
  profile2new!: boolean;
  profile4new: boolean = false;
  employee_remote_id: any;
  profile1Date: any;
  profile2Date: any;
  profile4Date: any;
  profile1Status: number = 0;
  profile2Status: number = 0;
  profile4Status: number = 0;
  pending1Status: number = 0;
  pending2Status: number = 0;
  pending4Status: number = 0;
  editTaxData: any = {};
  taxFileView: boolean = false;
  myform!: FormGroup;
  taxdeclarationObject: any = {};
  submissionTime!: Date;
  requestStatus: number = 0;
  approvalTime: string = '';
  approvedby: string = '';
  taxfileId!: number;
  mid!: number;
  taxProfiles: any;
  currentData: any;
  pendingData: any;
  loadingTaxFiles: boolean = true;
  taxProfileLength!: number;
  noData: boolean = false;
  showIcon: boolean = false;
  residencyStatus: any;
  taxStatus: any;
  firstmid: any;
  secondmid: any;
  fourthmid: any;
  dataWithStatus2: any;
  dataWithoutStatus2: any;
  name: any;
  email: any;
  submissionDate: any;
  base64: any;
  typeSelected: any;
  fileName: any;
  mobile:any;

  @ViewChild('taxForm') taxForm!: ElementRef;

  passportIssuanceCountryObj: any = {
    Afghanistan: 'af',
    'Åland Islands': 'ax',
    Albania: 'al',
    Algeria: 'dz',
    'American Samoa': 'as',
    Andorra: 'ad',
    Angola: 'ao',
    Anguilla: 'ai',
    Antarctica: 'aq',
    'Antigua and Barbuda ': 'ag',
    Argentina: 'ar',
    Armenia: 'am',
    Aruba: 'aw',
    // Australia: 'au',
    Austria: 'at',
    Azerbaijan: 'az',
    'Bahamas (the)': 'bs',
    Bahrain: 'bh',
    Bangladesh: 'bd',
    Barbados: 'bb',
    Belarus: 'by',
    Belgium: 'be',
    Belize: 'bz',
    Benin: 'bj',
    Bermuda: 'bm',
    Bhutan: 'bt',
    'Bolivia (Plurinational State of)': 'bo',
    'Bonaire, Sint Eustatius and Saba': 'bq',
    'Bosnia and Herzegovina': 'ba',
    Botswana: 'bw',
    'Bouvet Island': 'bv',
    Brazil: 'br',
    'British Indian Ocean Territory (the) ': 'io',
    'Brunei Darussalam ': 'bn',
    Bulgaria: 'bg',
    'Burkina Faso': 'bf',
    Burundi: 'bi',
    'Cabo Verde': 'cv',
    Cambodia: 'kh',
    Cameroon: 'cm',
    Canada: 'ca',
    'Cayman Islands (the) ': 'ky',
    'Central African Republic (the) ': 'cf',
    Chad: 'td',
    Chile: 'cl',
    China: 'cn',
    'Christmas Island': 'cx',
    'Cocos (Keeling) Islands (the)': 'cc',
    Colombia: 'co',
    'Comoros (the)': 'km',
    'Congo (the Democratic Republic of the)': 'cd',
    'Congo (the) ': 'cg',
    'Cook Islands (the)': 'ck',
    'Costa Rica': 'cr',
    'Côte d Ivoire': 'ci',
    Croatia: 'hr',
    Cuba: 'cu',
    Curaçao: 'cw',
    Cyprus: 'cy',
    Czechia: 'cz',
    Denmark: 'dk',
    Djibouti: 'dj',
    Dominica: 'dm',
    'Dominican Republic (the)': 'do',
    Ecuador: 'ec',
    Egypt: 'eg',
    'El Salvador ': 'sv',
    'Equatorial Guinea ': 'gq',
    Eritrea: 'er',
    Estonia: 'ee',
    Eswatini: 'sz',
    Ethiopia: 'et',
    'Falkland Islands (the) [Malvinas] ': 'fk',
    'Faroe Islands (the)  ': 'fo',
    Fiji: 'fj',
    Finland: 'fi',
    France: 'fr',
    'French Guiana': 'gf',
    'French Polynesia ': 'pf',
    'French Southern Territories (the)': 'tf',
    Gabon: 'ga',
    'Gambia (the)': 'gm',
    Georgia: 'ge',
    Germany: 'de',
    Ghana: 'gh',
    Gibraltar: 'gi',
    Greece: 'gr',
    Greenland: 'gl',
    Grenada: 'gd',
    Guadeloupe: 'gp',
    Guam: 'gu',
    Guatemala: 'gt',
    Guernsey: 'gg',
    Guinea: 'gn',
    'Guinea-Bissau': 'gw',
    Guyana: 'gy',
    Haiti: 'ht',
    'Heard Island and McDonald Islands ': 'hm',
    'Holy See (the) ': 'va',
    Honduras: 'hn',
    'Hong Kong': 'hk',
    Hungary: 'hu',
    Iceland: 'is',
    India: 'in',
    Indonesia: 'id',
    'Iran (Islamic Republic of)': 'ir',
    Iraq: 'iq',
    Ireland: 'ie',
    'Isle of Man': 'im',
    Israel: 'il',
    Italy: 'it',
    Jamaica: 'jm',
    Japan: 'jp',
    Jersey: 'je',
    Jordan: 'jo',
    Kazakhstan: 'kz',
    Kenya: 'ke',
    Kiribati: 'ki',
    'Korea (the Democratic Peoples Republic of)': 'kp',
    'Korea (the Republic of) ': 'kr',
    Kuwait: 'kw',
    Kyrgyzstan: 'kg',
    'Lao Peoples Democratic Republic (the) ': 'la',
    Latvia: 'lv',
    Lebanon: 'lb',
    Lesotho: 'ls',
    Liberia: 'lr',
    Libya: 'ly',
    Liechtenstein: 'li',
    Lithuania: 'lt',
    Luxembourg: 'lu',
    Macao: 'mo',
    Madagascar: 'mg',
    Malawi: 'mw',
    Malaysia: 'my',
    Maldives: 'mv',
    Mali: 'ml',
    Malta: 'mt',
    'Marshall Islands (the)': 'mh',
    Martinique: 'mq',
    Mauritania: 'mr',
    Mauritius: 'mu',
    Mayotte: 'yt',
    Mexico: 'mx',
    'Micronesia (Federated States of) ': 'fm',
    'Moldova (the Republic of) ': 'md',
    Monaco: 'mc',
    Mongolia: 'mn',
    Montenegro: 'me',
    Montserrat: 'ms',
    Morocco: 'ma',
    Mozambique: 'mz',
    Myanmar: 'mm',
    Namibia: 'na',
    Nauru: 'nr',
    Nepal: 'np',
    'Netherlands (the)': 'nl',
    'New Caledonia': 'nc',
    'New Zealand': 'nz',
    Nicaragua: 'ni',
    'Niger (the)': 'ne',
    Nigeria: 'ng',
    Niue: 'nu',
    'Norfolk Island': 'nf',
    'North Macedonia ': 'mk',
    'Northern Mariana Islands (the)': 'mp',
    Norway: 'no',
    Oman: 'om',
    Pakistan: 'pk',
    Palau: 'pw',
    'Palestine, State of ': 'ps',
    Panama: 'pa',
    'Papua New Guinea ': 'pg',
    Paraguay: 'py',
    Peru: 'pe',
    'Philippines (the)': 'ph',
    Pitcairn: 'pn',
    Poland: 'pl',
    Portugal: 'pt',
    'Puerto Rico': 'pr',
    Qatar: 'qa',
    Réunion: 're',
    Romania: 'ro',
    'Russian Federation (the)': 'ru',
    Rwanda: 'rw',
    'Saint Barthélemy': 'bl',
    'Saint Helena, Ascension and Tristan da Cunha ': 'sh',
    'Saint Kitts and Nevis': 'kn',
    'Saint Lucia': 'lc',
    'Saint Martin (French part)': 'mf',
    'Saint Pierre and Miquelon': 'pm',
    'Saint Vincent and the Grenadines': 'vc',
    Samoa: 'ws',
    'San Marino': 'sm',
    'Sao Tome and Principe': 'st',
    'Saudi Arabia': 'sa',
    Senegal: 'sn',
    Serbia: 'rs',
    Seychelles: 'sc',
    'Sierra Leone': 'sl',
    Singapore: 'sg',
    'Sint Maarten (Dutch part) ': 'sx',
    Slovakia: 'sk',
    Slovenia: 'si',
    'Solomon Islands': 'sb',
    Somalia: 'so',
    'South Africa': 'za',
    'South Georgia and the South Sandwich Islands ': 'gs',
    'South Sudan': 'ss',
    Spain: 'es',
    'Sri Lanka ': 'lk',
    'Sudan (the)': 'sd',
    Suriname: 'sr',
    'Svalbard and Jan Mayen': 'sj',
    Sweden: 'se',
    Switzerland: 'ch',
    'Syrian Arab Republic (the)': 'sy',
    'Taiwan (Province of China)': 'tw',
    Tajikistan: 'tj',
    'Tanzania, the United Republic of ': 'tz',
    Thailand: 'th',
    'Timor-Leste': 'tl',
    Togo: 'tg',
    Tokelau: 'tk',
    Tonga: 'to',
    'Trinidad and Tobago ': 'tt',
    Tunisia: 'tn',
    Turkey: 'tr',
    Turkmenistan: 'tm',
    'Turks and Caicos Islands (the)': 'tc',
    Tuvalu: 'tv',
    Uganda: 'ug',
    Ukraine: 'ua',
    'United Arab Emirates (the)': 'ae',
    'United Kingdom of Great Britain and Northern Ireland (the)': 'gb',
    'United States Minor Outlying Islands (the) ': 'um',
    'United States of America (the)': 'us',
    Uruguay: 'uy',
    Uzbekistan: 'uz',
    Vanuatu: 'vu',
    'Venezuela (Bolivarian Republic of) ': 've',
    'Viet Nam ': 'vn',
    'Virgin Islands (British) ': 'vg',
    'Virgin Islands (U.S.)  ': 'vi',
    'Wallis and Futuna ': 'wf',
    'Western Sahara*': 'eh',
    Yemen: 'ye',
    Zambia: 'zm',
    Zimbabwe: 'zw',
  };
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,  private cdr: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }
  ngOnDestroy(): void {
    this.loadingTaxFiles = true;
  }
  ngOnInit(): void {
    html2pdf();
    this.getUserQuery().then(() => {
      this.viewTaxFile();
    });
    this.tfnStatus();
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    this.submissionDate = new Date().toLocaleString();
    this.mobile = localStorage.getItem('mobile');
  }
  tfnStatus() {
    var url = `${environment.apiConfig.uri}/Candidate/TFNStatus`;
    this.http.post(url, {}).subscribe(async (response: any) => {
      this.candidateID = response.data.candidateId;
      this.taxStatus = response.data.tfnStatus;
      localStorage.setItem('taxStatus', this.taxStatus);
    });
  }
  getUserQuery() {
    return new Promise((resolve, reject) => {
      var url =
        environment.apiConfig.uri + `/candidate/TaxDecleration/GetUserQuery`;
      resolve(
        this.http.get(url).subscribe(async (response: any) => {
          this.taxProfiles = response.data;
          this.taxProfileLength = this.taxProfiles.length;
          this.noData = this.taxProfileLength < 1 ? true : false;
        })
      );
    });
  }
 
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

  viewTaxFile() {
    this.loadingTaxFiles = true;
    var url = environment.apiConfig.uri + `/candidate/TaxDecleration/GetAll`;
    this.http.get(url).subscribe(async (response: any) => {
      this.historyData = response.data;
      this.dataWithStatus2 = this.historyData.filter(
        (obj: { requestStatus: any }) => obj.requestStatus == 2
      );
      this.dataWithoutStatus2 = this.historyData.filter(
        (obj: { requestStatus: any }) => obj.requestStatus != 2
      );

      for (let item of this.historyData) {
        if (item.employee_remote_id.includes('PAYG-1')) {
          this.firstmid = item.mid;
        } else if (item.employee_remote_id.includes('PAYG-2')) {
          this.secondmid = item.mid;
        } else if (item.employee_remote_id.includes('PAYG-4')) {
          this.fourthmid = item.mid;
        }
      }
      for (let item of this.dataWithStatus2) {
        if (item.mid == this.firstmid) {
          if (item.requestStatus == 2) {
            this.profile1Status = 2;
            this.viewFormData = item;
            const submissionTimeUTC = new Date(item.submissionTime);
            // Get the time zone of the machine on which the project is running
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // Get the local date and time for the machine's time zone
            const options = { timeZone };
            const submissionTimeLocal = submissionTimeUTC.toLocaleString(
              'en-US',
              options
            );
            // Assign the local date and time to the profile1Date property
            this.profile1Date = submissionTimeLocal;
            this.viewFormData.submissionTime = this.profile1Date;
          }
        } else if (item.mid == this.secondmid) {
          if (item.requestStatus == 2) {
            this.profile2Status = 2;
            this.viewFormData = item;
            // Get the date in UTC
            const submissionTimeUTC = new Date(item.submissionTime);
            // Get the time zone of the machine on which the project is running
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // Get the local date and time for the machine's time zone
            const options = { timeZone };
            const submissionTimeLocal = submissionTimeUTC.toLocaleString(
              'en-US',
              options
            ); // Assign the local date and time to the profile2Date and viewFormData.submissionTime properties
            this.profile2Date = submissionTimeLocal;
            this.viewFormData.submissionTime = this.profile2Date;
          }
        } else if (item.mid == this.fourthmid) {
          if (item.requestStatus == 2) {
            this.profile4Status = 2;
            this.viewFormData = item;
            // Get the date in UTC
            const submissionTimeUTC = new Date(item.submissionTime);
            // Get the time zone of the machine on which the project is running
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // Get the local date and time for the machine's time zone
            const options = { timeZone };
            const submissionTimeLocal = submissionTimeUTC.toLocaleString(
              'en-US',
              options
            ); 
            this.profile4Date = submissionTimeLocal;
            this.viewFormData.submissionTime = this.profile4Date;
          }
        }
      }

      for (let item of this.dataWithoutStatus2) {
        if (
          item.mid === this.firstmid &&
          item.employee_remote_id.includes('PAYG-1') &&
          (item.requestStatus === 1 || item.requestStatus === 0)
        ) {
          this.profile1new = true;
          if (item.requestStatus == 1) {
            this.pending1Status = 1;
          }
        } else if (
          item.mid === this.secondmid &&
          item.employee_remote_id.includes('PAYG-2') &&
          (item.requestStatus === 1 || item.requestStatus === 0)
        ) {
          this.profile2new = true;
          if (item.requestStatus == 1) {
            this.pending2Status = 1;
          }
        } else if (
          item.mid === this.fourthmid &&
          item.employee_remote_id.includes('PAYG-4') &&
          (item.requestStatus === 1 || item.requestStatus === 0)
        ) {
          this.profile4new = true;
          if (item.requestStatus == 1) {
            this.pending4Status = 1;
          }
        }
      }

      this.loadingTaxFiles = false;
      this.showIcon = true;
    });
  }

  newTaxFile(rid: any, mid: any) {
    this.tfnStatus();
    this.taxfileId = 0;
    this.editTaxData = {};
    this.newTaxFileTable = true;
    this.mid = mid;
    this.employee_remote_id = rid;
    this.exemption = false;
    this.viewTaxFileTable = false;
  }
  showExpectionReason(event: any) {
    this.notfn = event.target.value;
    this.exemption = event.target.checked ? true : false;
  }

  checkForeignOrWork(a: any) {
    this.residencyStatus = a.target.value;
  }
  passportCoutrySelect(value: string): void {
    this.passportIssuanceCountry = value;
    const taxDecObj = <taxDeclarationForm>{};
    var countrycode = value;
    taxDecObj.passportIssuanceCountry = value;
  }

  submitElectronicDeclaration(data: any): void {
    this.submissionDate = new Date().toLocaleString();
    this.messaageForUser = ''; // Clear previous messages
    const taxDecObj: taxDeclarationForm = {
      // Initialize TaxDeclarationForm object
      how_to_complete: '',
      no_tfn: data.no_tfn,
      declarationSigned: data.declarationSigned,
      passportIssuanceCountry: data.passportIssuanceCountry,
      candidateId: this.candidateID,
      tfnProvided: data.tfnProvided,
      exemptionReason: data.exemptionReason,
      previousSurname: data.previousSurname,
      isPreviousSurnameChanged: data.isPreviousSurnameChanged,
      residencyStatus: data.residencyStatus,
      taxFreeThresholdClaimed: data.taxFreeThresholdClaimed,
      hasHelpDebtSupport: data.hasHelpDebtSupport,
      employmentBasis: data.employmentBasis,
      declarationName: data.declarationName,
      submissionMethod: 'electronic',
      mid: this.mid,
      dueDate: '28-10-2022',
      declaration_id: '1234',
      submissionTime: new Date(),
      requestStatus: this.requestStatus,
      approvalTime: new Date(),
      approvedby: this.approvedby,
      employee_remote_id: this.employee_remote_id,
      taxfileId: this.taxfileId,
      id: this.taxfileId,
    };

    // Validation checks
    if (!taxDecObj.no_tfn && !taxDecObj.tfnProvided) {
      this.handleValidationFailure('Tax File Number is required.');
      return;
    } else if (!data.exemptionReason && taxDecObj.no_tfn) {
      this.handleValidationFailure('Exemption reason is required.');
      return;
    } else if (
      data.isPreviousSurnameChanged !== 0 &&
      data.isPreviousSurnameChanged !== 1
    ) {
      this.handleValidationFailure(
        'Have you changed your surname is required.'
      );
      return;
    } else if (data.isPreviousSurnameChanged === 1 && !data.previousSurname) {
      this.handleValidationFailure('Previous surname is required.');
      return;
    } else if (!data.residencyStatus) {
      this.handleValidationFailure('Residency is required.');
      return;
    } else if (
      (data.residencyStatus === 'A') &&
      data.taxFreeThresholdClaimed !== 0 &&
      data.taxFreeThresholdClaimed !== 1
    ) {
      this.handleValidationFailure('Tax Free Threshold is required.');
      return;
    } else if (
      (data.residencyStatus === 'A' || data.residencyStatus === 'F') &&
      data.hasHelpDebtSupport !== 0 &&
      data.hasHelpDebtSupport !== 1
    ) {
      this.handleValidationFailure(
        'Select Higher Education Loan Program (HELP).'
      );
      return;
    } else if (!data.employmentBasis) {
      this.handleValidationFailure('On what basis are you paid is required.');
      return;
    } else if (!data.declarationName) {
      this.handleValidationFailure(
        'Full Name of the person making the declaration is required.'
      );
      return;
    } else if (data.residencyStatus === 'H' && !data.passportIssuanceCountry) {
      this.handleValidationFailure(
        'Working holiday makers must provide a country of passport.'
      );
      return;
    } else if (!data.declarationSigned) {
      this.handleValidationFailure(
        'The box to sign with the identification details is required.'
      );
      return;
    }

    // API Endpoint URL
    const url = this.taxfileId
      ? `${environment.apiConfig.uri}/candidate/TaxDecleration/EditTax`
      : `${environment.apiConfig.uri}/candidate/TaxDecleration/AddTax`;

    // API Request
    this.http.post(url, taxDecObj).subscribe(
      (response) => {
        // Create PDF and submit file
        this.createAndSubmitPDF();
        this.messaageForUser = 'Tax Form added Successfully';
        this.canResetForm = true;
        this.viewTaxFile();
        this.tfnStatus();
      },
      (error) => {
        this.handleSubmissionError();
        // console.log(error)
      }
    );
  }

  // Function to handle validation failure
  private handleValidationFailure(message: string): void {
    this.messaageForUser = message;
    this.error = false;
  }

  // Function to handle submission error
  private handleSubmissionError(): void {
    this.messaageForUser = 'Oops! Something went wrong.';
    this.canResetForm = false;
  }

  async createAndSubmitPDF() {
    const formElement = document.getElementById('taxForm');
    const extraContent = document.getElementById('extraData');
  
    if (formElement) {
      if(extraContent){
        extraContent.classList.remove('hidden-content');
      }  
      // Create configuration for html2pdf
      const pdfOptions = {
        margin: 5,
        filename: `TFN Declaration - ${this.name} - ${this.submissionDate}.pdf`,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 1, logging: true, useCORS: true },
        jsPDF: { unit: 'mm', format: 'legal', orientation: 'portrait' },
      };
  
      try {
        // Generate PDF using html2pdf
        const pdf = await html2pdf().from(formElement).set(pdfOptions).outputPdf();
        const pdfBase64 = this.encodeToBase64(pdf);
        const fileObj: CandidateFile = {
          externalID: 'portfolio',
          fileContent: pdfBase64,
          type: 'Others',
          name: pdfOptions.filename,
        };  
        const url = `${environment.apiConfig.uri}/CandidateFiles`;
  
        // Submit PDF to the server
        const response = await this.http.put<any>(url, fileObj).toPromise();
  
        // Handle the response as needed
        console.log('File submitted successfully:');
      } catch (error) {
        console.error('Error generating or submitting PDF:');
      } finally {
        // extraContent.classList.add('hidden-content');
      }
    } else {
      console.error('Content is empty or element not found.');
    }
  }

  private encodeToBase64(data: string): string {
    return btoa(data);
  }

  taxdeclarationHistory(index: any) {
    this.viewTaxFileTable = true;
    this.newTaxFileTable = false;
    this.viewFormData = this.dataWithoutStatus2[index];
    const submissionTimeUTC = new Date(this.viewFormData.submissionTime);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const submissionTimeLocal3 = submissionTimeUTC.toLocaleString('en-US', {
      timeZone,
    });
    this.viewFormData.submissionTime = submissionTimeLocal3;
  }
  acceptedTaxHis(data: any) {
    this.viewTaxFileTable = true;
    this.newTaxFileTable = false;
    this.viewFormData = data;
    const submissionTimeUTC = new Date(this.viewFormData.submissionTime);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const submissionTimeLocal4 = submissionTimeUTC.toLocaleString('en-US', {
      timeZone,
    });
    this.viewFormData.submissionTime = submissionTimeLocal4;
    this.viewTaxFileTable = true;
    this.newTaxFileTable = false;
  }
  editTaxDeclaration(index: any) {
    this.viewTaxFile();
    this.viewTaxFileTable = false;
    this.newTaxFileTable = true;
    this.editTaxData = this.dataWithoutStatus2[index];
    this.residencyStatus = this.editTaxData.residencyStatus;
    this.mid = this.editTaxData.mid;
    this.employee_remote_id = this.editTaxData.employee_remote_id;
    this.taxfileId = this.editTaxData.id;
  }
}

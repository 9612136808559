<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
  <app-sidebar-menu [selected]="selected" [id]="id"></app-sidebar-menu>
  <!-- main content area start -->
  <div class="main-content">
    <app-header></app-header>
    <div class="main-content-inner">
      <!-- market value area start -->
      <div class="row mt-5">
        <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
          <div class="card">
            <div class="card-body">
              <!-- Candidate Files -->
              <div class="row">
                <div class="col-7">
                  <h4  [ngClass]="{ 'mobile-font-size': mobileView }">Candidate files</h4>
                  <br />
                </div>
                <div class="col-5 text-right">
                  <button type="button" data-toggle="modal" data-target="#addModalCenter" class="btn btn-sm btn-info px-3 mr-3"  (click)="addFile()">
                    <i class="fa fa-solid fa-plus"></i> &nbsp; Add File
                  </button>
                </div>
              </div>

              <div *ngIf="loadingCandidateFiles">
                <li class="t_loader">
                  <div class="card__desc load" id="long"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load" id="long"></div>
                </li>
              </div>
              <div *ngIf="!loadingCandidateFiles" class="table-responsive">
                <table class="table mb-2 table-hover">
                  <thead>
                    <tr>
                      <th scope="col" *ngIf="!mobileView">No</th>
                      <th scope="col">File Name</th>
                      <th scope="col">Type</th>
                      <th scope="col" *ngIf="!mobileView">Size <sub>(Kb)</sub></th>
                      <th scope="col" *ngIf="!mobileView">Date Added</th>
                      <th scope="col">Download/View</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="candidateFilesList.length > 0">
                    <tr *ngFor="let item of candidateFilesList; index as i" id="item-{{ item.id }}">
                      <td *ngIf="!mobileView">{{ i + 1 }}</td>
                      <td>{{ item.fileName }}</td>
                      <td>{{ item.type }}</td>
                      <td *ngIf="!mobileView">{{ item.fileSize }}</td>
                      <td *ngIf="!mobileView">
                        {{ item.clientDate | date: "dd-MM-yyy" || "N/A" }}
                      </td>
                      <td>
                        <button class="btn btn-sm btn-primary" (click)="onClickDownload(item)" title="Download">
                          <i class="fa fa-download" style="font-size: 18px"></i>
                        </button>
                        <button title="View" [id]="'item_' + item.id" class="btn ml-1 btn-sm btn-info"
                          (click)="onClickView(item)">
                          <i class="fa fa-file-text-o" style="font-size: 18px"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="candidateFilesList.length == 0">
                    <tr>
                      <td colspan="6" class="text-center">No Files</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- market value area end -->
    </div>
  </div>
  <!-- main content area end -->
</div>

<!-- Modal Create File -->
<div class="modal fade" id="addModalCenter" tabindex="-1" role="dialog" aria-labelledby="addModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addModalLongTitle">Add File</h5>
        <button #closeModelAdd type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="closeModel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-10 mx-auto">
          <form (ngSubmit)="addFileSubmit(AddFileForm.value , AddFileForm)" #AddFileForm="ngForm" method="post">
            <div *ngIf="!loading">
              <div class="file-upload">
                <p *ngIf="fileError" class="text-danger text-left">
                  Error file size cannot be larger than 10 mb
                </p>
                <div class="file-select" for="chooseFileNew" aria-disabled="false">
                  <div class="file-select-button" id="fileNameAdd">
                    Choose File
                  </div>
                  <div class="file-select-name active" #fileChosenAdd>
                    {{fileInputText}}
                  </div>
                  <input type="file" name="chooseFileNew" id="chooseFileNew" (change)="onFileChange($event, 'add')"
                    accept="image/*,video/*,.xls,.xlsx,.xlsm,.csv,.ppt,.pptx,.txt,.odt,.pdf,.doc,.docx" />
                </div>
              </div>
              <select (change)="selectType($event)" class="form-control my-2" style="
                  border: 1px solid gray;
                  padding-top: 4px;
                  padding-bottom: 1.5px;
                ">
                <option #TypeAdd value="">Select Type</option>
                <option *ngFor="let item of fileTypes" [value]="item.value">
                  {{ item.label }}
                </option>
              </select>
              <div class="text-center pt-2">
                <button [disabled]="!enableUpload" class="btn btn-sm btn-info" type="submit">
                  <span class="px-3">Upload</span>
                </button>
                <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal" aria-label="Close"
                  (click)="closeModel()">
                  Cancel
                </button>
              </div>
            </div>
            <div *ngIf="loading" class="d-flex justify-content-center">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Update File -->
<div class="modal fade" id="updateModalCenter" tabindex="-1" role="dialog" aria-labelledby="updateModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLongTitle">Update File</h5>
        <button #closeModelUpdate type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="closeModel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-10 mx-auto">
          <form [formGroup]="formGroup" (ngSubmit)="updateFileSubmit($event, editFile.id, index)">
            <div *ngIf="!loading">
              <div class="file-upload">
                <!-- <p *ngIf="fileError" class="text-danger text-left">
                Error file size cannot be larger than 10 mb
              </p> -->
                <div class="file-select" for="chooseFileUpdate" aria-disabled="false">
                  <div class="file-select-button" id="fileNameUpdate">
                    Choose File
                  </div>
                  <div class="file-select-name active" #fileChosenUpdate>
                    No file chosen...
                  </div>
                  <input type="file" name="chooseFileUpdate" id="chooseFileUpdate"
                    (change)="onFileChange($event, 'edit')"
                    accept="image/*,video/*,.xls,.xlsx,.xlsm,.csv,.ppt,.pptx,.txt,.odt,.pdf,.doc,.docx" />
                </div>
              </div>

              <select [disabled]="!enableType" (change)="selectType($event)" class="form-control my-2" style="
                  border: 1px solid gray;
                  padding-top: 4px;
                  padding-bottom: 1.5px;
                ">
                <option #TypeUpdate value="">Select Type</option>
                <option *ngFor="let item of fileTypes" [value]="item.value">
                  {{ item.label }}
                </option>
              </select>
              <div class="text-center pt-2">
                <button [disabled]="!enableUpload" class="btn btn-sm btn-info" type="submit">
                  <span class="px-3">Update</span>
                </button>

                <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal" aria-label="Close"
                  (click)="closeModel()">
                  Cancel
                </button>
              </div>
            </div>
            <div *ngIf="loading" class="d-flex justify-content-center">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <!--  -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Delete File -->
<div class="modal fade" id="deleteModel" tabindex="-1" role="dialog" aria-labelledby="deleteModelCenterTitle"
  aria-hidden="true" (click)="closeModel()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModelTitle">Delete File</h5>
        <button #closeModelDelete type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!loading">
        <div class="px-1 text-center">
          <h5>Are you sure you want to delete this file?</h5>
          <p class="text-danger">(Note: This action cannot be undone)</p>

          <div class="pt-3">
            <button class="btn btn-sm btn-danger px-4" (click)="deleteFile( FileID, index)">
              Delete
            </button>

            <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal" aria-label="Close"
              (click)="closeModel()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      
      <!--  -->
      <div *ngIf="loading" class="d-flex justify-content-center">
        <div class="spinner-border m-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>
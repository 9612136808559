<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
  <app-sidebar-menu [selected]="selected" [isAdmin]="true" (pageName)="selectPageName($event)" style="width:80%;">
  </app-sidebar-menu>
  <!-- main content area start -->
  <div class="main-content">
    <app-header (pageName)="selectPageName($event)"></app-header>
    <div class="main-content-inner">
      <!-- market value area start -->
      <div class="row mt-5">
        <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
          <div class="card">
            <div class="card-body">
              <!-- Candidate Files -->
              <div class="row">
                <div class=" desktopView col-7" style="align-content: center;">
                  <h4>Training files</h4>
                  <br />
                </div>
                <div class=" mobileView col-7" style="align-content: center;">
                  <h4 style="font-size:5vw ;">Training files</h4>
                  <br />
                </div>

                <div class="col-5 text-right">
                  <!-- <span *ngIf="copied" style="font-size: 13px;color:gray;">
                  </span> -->
                  <button style="width: 120px; margin: 6px;" class="btn btn-sm btn-outline-info px-1 "
                    (click)="copyTrainingFiles()">
                    <span *ngIf="copied">Copied!</span>
                    <span *ngIf="!copied">Copy File Names</span>
                  </button>
                  <button style="width: 120px; margin: 6px;" class="btn btn-sm btn-primary px-3" [disabled]="uploading"
                    type="button" data-toggle="modal" data-target="#addModalCenter" (click)="addFile()">
                    <i class="fa fa-solid fa-plus"></i> &nbsp; Add File
                  </button>
                </div>
              </div>

              <div *ngIf="loadingTrainingFiles">
                <li class="t_loader">
                  <div class="card__desc load" id="long"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load"></div>
                </li>
                <li class="t_loader">
                  <div class="card__desc load" id="long"></div>
                </li>
              </div>

              <div *ngIf="!loadingTrainingFiles" class="table-responsive">
                <div *ngIf="loading" class="d-flex justify-content-center">
                  <div class="spinner-border m-5" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="table-responsive" style="overflow-x: auto">
                  <table *ngIf="!loading"
                    class="table table-hover table-bordered table-striped align-middle mb-0 bg-white">
                    <thead>
                      <tr>
                        <th> No</th>
                        <th class="files" *ngFor="let value of tableHeader" scope="col">{{ value.name }} <span
                            (click)="sort()"><i class={{value.button}}></i></span></th>
                        <th>Date Added</th>
                        <th>View</th>
                        <th>Update/Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor=" let item of trainingFilesList;index as i">
                        <td>{{i+1}}</td>
                        <td *ngFor="let value of tableRows" scope="col">{{ item[value] }}</td>
                        <td>
                          {{ item.dateAdded | date: "dd-MM-yyy" || "N/A" }}
                        </td>
                        <td>
                          <div class="btn btn-sm btn-info" target="_blank" (click)="viewFile(item)">
                            <i class="fa fa-file-text-o" style="font-size: 18px"></i>
                          </div>
                        </td>
                        <td>
                          <button [disabled]="uploading" title="Update" type="button" data-toggle="modal"
                            data-target="#updateModalCenter" class="btn btn-sm btn-primary ml-1"
                            (click)="updateFile(item, i)">
                            <i class="fa fa-edit" style="font-size: 18px"></i>
                          </button>
                          <button [disabled]="updating && index == i" title="Delete" class="btn btn-sm btn-info ml-1"
                            data-toggle="modal" data-target="#deleteModel" (click)="deleteFileRequest(item)">
                            <i class="fa fa-trash-o" style="font-size: 18px"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Pagination  -->
                <div>
                  <div *ngIf="!loading" class=" desktopView d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                      [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData()" class="flex-wrap">
                    </ngb-pagination>
                    <select class="form-select" style="width: auto" [(ngModel)]="pageSize"
                      (ngModelChange)="refreshData()">
                      <option *ngFor="let pageSizeOption of pageSizeOptions" [ngValue]="pageSizeOption">
                        {{ pageSizeOption }} items per page
                      </option>
                    </select>

                  </div>
                  <div class="mobileView">

                    <div *ngIf="!loading" class=" row d-flex justify-content-between p-2">
                      <div class="col-1"></div>
                      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                        [maxSize]="4" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData()"
                        class="flex-wrap col-10">
                      </ngb-pagination>
                      <div class="col-1"></div>

                      <div class="row">
                        <div class="col-2"></div>
                        <select class=" form-select col-8" style="width: auto" [(ngModel)]="pageSize"
                          (ngModelChange)="refreshData()">
                          <option *ngFor="let pageSizeOption of pageSizeOptions" [ngValue]="pageSizeOption">
                            {{ pageSizeOption }} items page
                          </option>
                        </select>
                        <div class="col-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="uploading" id="progress-model">
                  <p class="text-center" style="font-size: 12.5px;margin-bottom: -8px;">
                    <span *ngIf="uploaded">
                      <i class="fa fa-solid fa-check"></i>
                      Uploaded
                    </span>
                    <span *ngIf="uploadFailed" class="text-danger">
                      <i class="fa fa-times text-danger pr-1"></i>
                      Failed to upload..
                    </span>
                    <span *ngIf="!uploaded && !uploadFailed">{{ progressText }}</span>
                  </p>
                  <progress value="{{ progressValue }}" max="100"> </progress>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- market value area end -->
      </div>
    </div>
    <!-- main content area end -->
  </div>

  <!-- Modal Create File -->
  <form (ngSubmit)="addFileSubmit(AddFileForm.value , AddFileForm)" #AddFileForm="ngForm" method="post" #refAddForm>
    <div class="modal fade" id="addModalCenter" tabindex="-1" role="dialog" aria-labelledby="addModalCenterTitle"
      aria-hidden="true" (click)="cross(refAddForm)">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLongTitle">
              Add File
              <span class="text-danger pl-3" style="font-size: 13px;">{{ errorMsg }}</span>
            </h5>
            <button #closeModelAdd type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-10 mx-auto">
              <div *ngIf="!loading">
                <div class="col-l2 mb-2 file-upload">
                  <p *ngIf="fileError" class="text-danger text-left">
                  </p>
                  <div class="file-select" for="chooseFileNew" aria-disabled="false"
                    style="overflow-x:hidden ; text-overflow:clip; white-space: nowrap;">
                    <div class="file-select-button" id="fileNameAdd">
                      Choose File
                    </div>
                    <div class="file-select-name active" #fileChosenAdd>
                      {{fileInputText}}
                    </div>
                    <input type="file" name="chooseFileNew" id="chooseFileNew" #ref
                      (change)="onFileChange($event, 'add')" accept=".pdf,.mp4" ngModel>
                  </div>
                </div>

                <div class="col-l2 mb-2">
                  <select required class="form-control" (change)="selectFileName($event)" name="category" ngModel>
                    <option value="">Select Title</option>
                    <option *ngFor="let item of traininfFileMetaFields" [value]="item.value">{{item.label}}</option>
                  </select>
                </div>
                <small style="color:red">{{warning ? warning : null}}</small>
                <div class="text-center pt-2">
                  <button class="btn btn-sm btn-info" type="submit">
                    <span class="px-3">Upload</span>
                  </button>
                  <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal"
                    (click)=handelCancle(AddFileForm,AddFileForm.value,ref) aria-label="Close">
                    Cancel
                  </button>
                </div>
              </div>
              <div *ngIf="loading" class="d-flex justify-content-center">
                <div class="spinner-border m-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Modal Update File -->
  <form #formName="ngForm" #refForm (ngSubmit)="updateFileSubmit(editFile.id,formName.value,formName)">
    <div class="modal fade" id="updateModalCenter" tabindex="-1" role="dialog" aria-labelledby="updateModalCenterTitle"
      aria-hidden="true" (click)="cross(refForm)">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updateModalLongTitle">Update File</h5>
            <span class="text-danger pl-3" style="font-size: 13px;">{{ errorMsg }}</span>

            <button #closeModelUpdate type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" (click)="cross(refForm)">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-10 mx-auto">
              <div *ngIf="!loading">
                <div class="col-l2 mb-2 file-upload">
                  <p *ngIf="fileError" class="text-danger text-left">
                  </p>
                  <div class="file-select" for="chooseFileNew" aria-disabled="false">
                    <div class="file-select-button" id="fileNameAdd">
                      Choose File
                    </div>
                    <div class="file-select-name active" #fileChosenAdd>
                      {{fileInputText}}
                    </div>
                    <input type="file" name="chooseFileNewUpdate" id="chooseFileNew" #ref
                      (change)="onFileChange($event, 'update')" accept=".pdf,.mp4" ngModel />
                  </div>
                </div>
                <div class="col-l2 mb-2">
                  <select required name="newFile" class="form-control" ngModel disabled>
                    <option [selected]="editFile.fileName == item.value" *ngFor="let item of traininfFileMetaFields"
                      [value]="item.value">{{item.label}}</option>
                  </select>
                </div>
                <small style="color:red">{{warning ? warning : null}}</small>

                <div class="text-center pt-2">
                  <button class="btn btn-sm btn-info" type="submit">
                    <span class="px-3">Update</span>
                  </button>
                  <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal" aria-label="Close"
                    (click)=handelCancle(AddFileForm,AddFileForm.value,ref)>
                    Cancel
                  </button>
                </div>
              </div>
              <!--  -->
              <div *ngIf="loading" class="d-flex justify-content-center">
                <div class="spinner-border m-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Modal Delete File -->
  <div class="modal fade" id="deleteModel" tabindex="-1" role="dialog" aria-labelledby="deleteModelCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteModelTitle">Delete File</h5>
          <button #closeModelDelete type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="!loading">
          <div>
            <div class="px-1 text-center">
              <h5>Are you sure you want to delete this file?</h5>
              <p class="text-danger">(Note: This action cannot be undone)</p>

              <div class="pt-3">
                <button class="btn btn-sm btn-danger px-4" (click)="deleteFile()">
                  Delete
                </button>
                <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal" aria-label="Close">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loading" class="d-flex justify-content-center">
          <p *ngIf="errorMsg" class="m-5 text-danger h6">{{errorMsg}}</p>
          <div *ngIf="!errorMsg" class="spinner-border m-5" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Button Switch Tab -->
  <button class="invisible" #switchTabBtn data-toggle="modal" data-target="#switchModel"></button>

  <!-- Modal Switch Tab -->
  <div class="modal fade" id="switchModel" tabindex="-1" role="dialog" aria-labelledby="switchModelCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="switchModelTitle"></h5>
          <button #closeModelSwitch type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="px-1 text-center">
              <h5>File Uploading..</h5>
              <p class="text-danger">Are you sure you want to proceed?</p>
              <div class="pt-3">
                <button class="btn btn-sm btn-danger px-4" (click)="switchTab()">
                  Proceed
                </button>
                <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal" aria-label="Close">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
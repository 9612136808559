import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-pay-slips',
  templateUrl: './pay-slips.component.html',
  styleUrls: ['./pay-slips.component.css']
})
export class PaySlipsComponent implements OnInit {
  selected: string = 'pay-slips';
  id: string = '';
  mobileView: boolean = false;
  displayLoader: boolean = true;
  pageSizeOptions = [10, 20, 50, 100];
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;
  payslipsData: any;
  slipData: any;
  noPayslipData: boolean = false;
  uniqueCustomerName: any;
  myobj: any = {
    employee_mid: 0,
    employee_name_first: "",
    employee_name_last: "",
    employee_remoteid: 0,
    gross_wages: 0,
    id: 0,
    net_wages: 0,
    pay_id: 0,
    pay_period_end: 0,
    pay_period_start: 0,
    pay_run_number: 0,
    paydate: 0,
    payslip_link: "",
    payslip_status: "",
    superannuation: "",
    tax: 0,
    payAdviceHistory: "",
    othersDeduction: 0,
    totalDeduction: 0,
    netPay:0
   }

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  constructor(private http: HttpClient, private fb: FormBuilder, private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }
  ngOnInit(): void {
    this.http.get(`${environment.apiConfig.uri}/AstutePayAdvice?pageNumber=${this.page}&pagesize=${this.pageSize}`).subscribe((response: any) => {
      try {
        this.payslipsData = response.data;
        this.displayLoader = false;
        if (this.payslipsData.length < 1) {
          this.noPayslipData = true;
        } else {
          this.noPayslipData = false;
        }
    
        for (var index in this.payslipsData) {
          if (this.payslipsData[index].payslip_status == 'Sent' || this.payslipsData[index].payslip_status == 'sent') {
            this.payslipsData[index].payslip_status = 'Paid';
          }
          // Convert string values to numbers
          const grossWages = parseFloat(this.payslipsData[index].gross_wages);
          const tax = parseFloat(this.payslipsData[index].tax);
          const netWages = parseFloat(this.payslipsData[index].net_wages);
          // Calculate the deductions and assign to the 'deductions' property
          this.payslipsData[index].deductions = Math.abs(grossWages - (tax + netWages));
        }
    
        this.collectionSize = response.extraParam.totalCount;
      } catch (err) {
        console.log("PaySlips are not loading ");
      }
    })
    
  }
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }
  refreshData() {
    this.displayLoader = true;
    this.http.get(`${environment.apiConfig.uri}/AstutePayAdvice?pageNumber=${this.page}&pagesize=${this.pageSize}`).subscribe((response: any) => {
      try {
        this.payslipsData = response.data;
        for (var index in this.payslipsData) {
          if (this.payslipsData[index].payslip_status == 'Sent' || this.payslipsData[index].payslip_status == 'sent') {
            this.payslipsData[index].payslip_status = 'Paid';
          }
          const grossWages = parseFloat(this.payslipsData[index].gross_wages);
          const tax = parseFloat(this.payslipsData[index].tax);
          const netWages = parseFloat(this.payslipsData[index].net_wages);
          // Calculate the deductions and assign to the 'deductions' property
          this.payslipsData[index].deductions = Math.abs(grossWages - (tax + netWages));
        }
        this.displayLoader = false;
        this.collectionSize = response.extraParam.totalCount;
      }
      catch (err) {
        console.log("PaySlips are not loading ");
      }

    });
  }
  viewPaySlip(index: any) {
    this.slipData = this.payslipsData[index];
    this.myobj.employee_mid = this.slipData.employee_mid;
    this.myobj.employee_name_first = this.slipData.employee_name_first;
    this.myobj.employee_name_last = this.slipData.employee_name_last;
    this.myobj.employee_remoteid = this.slipData.employee_remoteid;
    this.myobj.gross_wages = this.slipData.gross_wages;
    this.myobj.id = this.slipData.id;
    this.myobj.net_wages = this.slipData.net_wages;
    this.myobj.pay_id = this.slipData.pay_id;
    this.myobj.pay_period_end = this.slipData.pay_period_end;
    this.myobj.pay_period_start = this.slipData.pay_period_start;
    this.myobj.pay_run_number = this.slipData.pay_run_number;
    this.myobj.paydate = this.slipData.paydate;
    this.myobj.payslip_link = this.slipData.payslip_link;
    this.myobj.payslip_status = this.slipData.payslip_status;
    this.myobj.superannuation = this.slipData.superannuation;
    this.myobj.tax = this.slipData.tax;
    this.myobj.payAdviceHistory = this.slipData.payAdviceHistory;
    this.myobj.othersDeduction = (parseFloat(this.myobj.gross_wages) - (parseFloat(this.myobj.net_wages) + parseFloat(this.myobj.tax)));
    this.myobj.totalDeduction = -((this.myobj.othersDeduction) + (parseInt(this.myobj.tax)));
    this.myobj.tax = -this.slipData.tax;
    this.myobj.othersDeduction = -this.myobj.othersDeduction;
    this.myobj.netPay=parseFloat(this.myobj.gross_wages)+parseFloat(this.myobj.totalDeduction);
    var a = this.myobj.payAdviceHistory;
    this.uniqueCustomerName = a.map((item: any) => item.customer_name)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

  }

}

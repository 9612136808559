import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { FormControl, FormGroup } from '@angular/forms';

type optionObj = {
  options?: any;
  correct?: string;
  questionId?: number;
};

type StatementObj = {
  id?: number;
  statement?: string;
  trainingFileID?: number;
};

@Component({
  selector: 'app-training-assessment',
  templateUrl: './training-assessment.component.html',
  styleUrls: ['./training-assessment.component.css'],
})
export class TrainingAssessmentComponent implements OnInit {
  updateForm!: FormGroup;
  selected: string = 'training-assessment';
  mobileView: boolean = false;
  mchoise: boolean = false;
  mchoise2: boolean = false;
  mchoise3: boolean = false;
  mchoise4: boolean = false;
  optionsubmited: boolean = false;
  loadingTrainingFiles: boolean = true;
  showoption: boolean = false;
  questionadded: boolean = false;
  questionstatement: boolean = false;
  @Input() candidateID: any;
  @ViewChild('addOpt')
  addOpt!: NgForm;
  @ViewChild('userlogin')
  userlogin!: NgForm;
  @ViewChild('completeQuiz')
  completeQuiz!: NgForm;
  @ViewChild('overflowTest') overflowTest: any;
  answers: any = [];
  questions: any = [];
  quizQuestions: any;
  statement: string = '';
  trainingFilesList: any;
  fileId: any;
  fileName: any;
  correctOption: any;
  tempRadio: boolean = false;
  questionId: any;
  option_obj!: optionObj;
  updateQuestion: boolean = false;
  updateOption: boolean = false;
  responseData: any = {
    extraParam: {},
    isSuccess: false,
    message: "",
    data: {}
  }
  questionData: any;
  McqsOption: any;
  toggle = true;
  status = "Enable";
  currentQuestion: any;
  errorMessage: any;
  errorDelete: any;
  correctIndex: any;
  FileID: any;
  deleteIndex: any;
  quizID: any;
  errorMsg: any;
  loading: boolean = false;
  option: any = []
  lastOption: any
  correctAnswerId: any
  term: boolean = false;
  optvalue: any;
  pageSizeOptions = [10, 20, 50, 100];
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;

  @ViewChild('closeModelDelete') closeModelDelete!: ElementRef;
  currentIndex: number = 0;
  constructor(private http: HttpClient) { }
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
    }
  }
  updateOptions(form: any) {

    let options = this.McqsOption.map((e: any) => ({ option: e, "isAccepted": false }))
    const payload: any = {};
    payload["statement"] = form.form.value.statement;
    payload["id"] = this.currentQuestion * 1;
    payload["options"] = options;  
    if (this.correctIndex == null) {
      this.errorMessage = "Please choose a correct option!";
      return
    }
    else if (this.correctIndex >= 0) {
      let result = payload["options"][this.correctIndex]
      result.isAccepted = true;
    }
    if (this.correctIndex == -1) {
      this.errorMessage = "Please choose a correct option!";
    } else {
      var update = true;
      if (update) {
        this.http
          .put(`${environment.apiConfig.uri}/admin/trainingFile/${this.fileId}/question`, payload)
          .subscribe(
            (result) => {
              this.errorMessage = null;
              this.updateOption = false;
              this.quizQuestions[this.currentQuestion].options = this.McqsOption;
              this.quizQuestions[this.currentQuestion].correct = this.McqsOption[this.correctIndex];
            },
            (error) => {
              this.errorMessage = error.error.message;
            }
          );
      }
    }
  }
  saverange(form: any) {
    this.statement = form.form.value.statement
  }
  addoptions(addOpt: any) {
    let formOption = Object.values(addOpt.form.value);
    console.log(formOption.length);
    for (let i = 0; i < formOption.length; i++) {
      this.optvalue = formOption[i];
      console.log(this.optvalue);
      if (this.optvalue.trim().length == 0) {
        this.errorMessage = "Empty option not allowed"
        return;
      }
    }
    let flag = formOption.some(e => e == "");
    if (flag) {
      this.errorMessage = "Empty option not allowed"
      return;
    }
    let options = formOption.map((e: any) => ({ option: e, "isAccepted": false }))
    var opt1 = addOpt.form.value.option1;
    var opt2 = addOpt.form.value.option2;
    var opt3 = addOpt.form.value.option3;
    var opt4 = addOpt.form.value.option4;
    if (!opt1 || !opt2) {
      this.errorMessage = "Enter Minimum Two Possible Options & Select Correct one!";
      return
    }
    if (this.correctAnswerId == undefined) {
      this.errorMessage = "Please select one Correct Answer"
      return
    }
    const payload: any = {};
    payload["statement"] = this.statement;
    payload["options"] = options
    if (this.correctAnswerId != undefined) {
      let result = payload["options"][this.correctAnswerId]
      result.isAccepted = true
    }
    this.optionsubmited = true;
    this.questionadded = false;
    this.questionstatement = false;
    this.http
      .post(
        `${environment.apiConfig.uri}/admin/trainingFile/${this.fileId}/question`,
        payload
      )
      .subscribe(
        (result) => {

          this.trainingFilesList[this.currentIndex].questionCount += 1;
          this.correctAnswerId = undefined;
          this.questionadded = true;
          this.mchoise4 = false;
          this.mchoise3 = false;
          this.mchoise2 = false;
          this.correctOption = null;
          this.questionstatement = true;
          this.correctAnswerId = "";
          this.correctIndex = "";
          this.correctOption = "";
          this.showoption = false;
          this.currentQuestion = null;
          this.addViewQuiz(this.fileId, this.fileName, this.currentIndex);
          addOpt.resetForm();
          this.mchoise = true;
          this.answers.push({ value: '' });
          this.correctAnswerId = undefined;
        },
        (error) => {
          this.errorMessage = error.error.message
        }
      );
    this.userlogin.reset();
    this.addNxtQuestion();


  }
  refreshData() {
    this.loadingTrainingFiles = true;
    this.http.get(
      `${environment.apiConfig.uri}/admin/TrainingFile/GetTrainingFiles?pageNumber=${this.page}&pagesize=${this.pageSize}`).subscribe((response: any) => {
        this.trainingFilesList = response.data;
        this.collectionSize = response.extraParam.FileCount;
        this.loadingTrainingFiles = false;
      });
  }


  UpdateQuestion(event: any) {
    this.statement = event.value.statement;
    const Question = <StatementObj>{};
    Question.id = this.currentQuestion;
    Question.statement = this.statement;
    Question.trainingFileID = this.fileId;
    var url = `${environment.apiConfig.uri}/admin/trainingFile/${this.fileId}/question`;
    this.http.put(url, Question).subscribe(
      (result) => {
        this.responseData = result;
        if (this.responseData.isSuccess) {
          this.updateQuestion = false;
        }
      },
      (error) => {
        //(error);
      }
    );
  }

  ngOnInit(): void {
    this.getTrainingFiles();
  }

  getTrainingFiles = () => {
    const url = environment.apiConfig.uri + '/admin/TrainingFile/GetTrainingFiles';
    this.http.get(url).subscribe(async (response: any) => {
      this.trainingFilesList = response.data;
      this.collectionSize = response.extraParam.FileCount;
      this.loadingTrainingFiles = false;
    });
  };

  addViewQuiz(id: Number, fileName: string, index: number) {
    this.statement = "";
    this.currentQuestion = "";
    this.questionstatement = false;
    this.questionadded = false;
    this.currentIndex = index;
    this.errorMessage = null;
    this.statement = "";
    this.fileId = id;
    this.fileName = fileName;
    this.mchoise = false;
    this.optionsubmited = false;
    this.showoption = false;
    this.currentQuestion = "";
    this.mchoise = true;
    var url = `${environment.apiConfig.uri}/admin/trainingFile/${id}/question`;
    this.http
      .get(url)
      .subscribe(
        (result) => {
          this.responseData = result;
          this.quizQuestions = this.responseData.data;
        },
        (error) => {
        }
      );
  }
  deleteQuizRequest(quiz: any): void {
    this.fileId = quiz.id;
    this.loading = false;
  }
  deleteQuiz() {
    this.errorMsg = null;
    var url = environment.apiConfig.uri + '/admin/TrainingFile/DeleteWithQuiz/' + this.fileId;
    this.loading = true;

    this.http.delete(url).subscribe(
      (response: any) => {
        this.errorMsg = "Deleted the Quiz!";
        setTimeout(() => {
          this.closeModelDelete.nativeElement.click();
          this.getTrainingFiles();
          this.fileId = null;
          setTimeout(() => {
            this.loading = false;
            this.errorMsg = null;
          }, 200);
        }, 500)
      },
      (error: any) => {
        //(error);
        this.errorMsg = "Unable to delete the quiz!";
        setTimeout(() => {
          this.closeModelDelete.nativeElement.click();
          this.getTrainingFiles();
          this.quizID = null;
          setTimeout(() => {
            this.loading = false;
            this.errorMsg = null;
          }, 200);
        }, 500)
      }
    );
  }

  addstatement(data: any) {
    this.questionadded = true;
    this.mchoise4 = false;
    this.mchoise3 = false;
    this.mchoise2 = false;
    this.correctOption = null;
    this.questionstatement = true;
    this.statement = data?.statement
    this.mchoise = true;
    this.answers.push({ value: '' });
  }
  foropt2(form: any) {
    this.mchoise2 = true;
  }
  foropt3(form: any) {
    this.mchoise3 = true;
  }
  foropt4(form: any) {
    this.mchoise4 = true;
  }

  selectAnswer(event: any): void {
    this.correctAnswerId = event.target.id;
  }

  updateOptRadio(index: number) {
    this.correctIndex = index;
  }

  updateOptTxt(event: any, index: number) {
    var value = event.target.value;
    this.McqsOption[index] = value;
  }
  // Request for next Question
  addNxtQuestion() {
    this.currentQuestion = "";
    this.correctOption = null;
    this.answers = [];
    this.questions = [];
    this.mchoise = false;
    this.showoption = false;
    this.questions.push({ q: '' });
    this.addViewQuiz(this.fileId, this.fileName, this.currentIndex);
    document.getElementById(this.currentQuestion.qid)?.style.color == "White";
  }

  removeOpt(index: number) {
    if (this.McqsOption.length > 2) {
      this.McqsOption.splice(index, 1)
      if (this.correctIndex == index) {
        this.correctIndex = null;
      }
    } else {
      this.errorMessage = 'Minimum 2 options are required!';
    }
  }

  appendOption() {
    this.McqsOption.push('');
  }

  displayquestion(qid: any) {
    this.errorMessage = null;
    this.updateQuestion = false;
    this.updateOption = false;
    this.showoption = true;
    this.currentQuestion = qid;
    this.questionData = this.quizQuestions[qid]
    this.correctOption = this.questionData.correct;
    this.McqsOption = this.questionData.options.filter((e: any) => { return e });
    this.correctIndex = this.McqsOption.indexOf(this.correctOption)
  }

  deleteQuestion() {
    var url = `${environment.apiConfig.uri}/admin/trainingFile/${this.fileId}/question/${this.currentQuestion}`;
    this.http.delete(url).subscribe(
      (result) => {
        delete this.quizQuestions[this.currentQuestion];
        this.showoption = false;
        this.currentQuestion = null;
        this.trainingFilesList[this.currentIndex].questionCount -= 1;
      },
      (error) => {
        this.errorDelete = "Unable to delete the question!"
        setTimeout(() => {
          this.errorDelete = null;
        }, 4000);
      }
    );
  }

  onCloseMember() {
    this.currentQuestion = "";
    this.questionstatement = false;
    this.questionadded = false;
    this.completeQuiz.reset();
    this.quizQuestions = "";
    this.addNxtQuestion();
    this.correctIndex = "";
    this.correctOption = "";
  }
}
function reset() {
  throw new Error('Function not implemented.');
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '@azure/msal-common';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PortalAdminService {

  constructor(private authService: MsalService, private http: HttpClient) {}

  isPortalAdmin(): Observable<any> {
    const idTokenClaims = this.authService?.instance?.getActiveAccount()?.idTokenClaims as IdTokenClaims;
    const email = (idTokenClaims as any)?.email;

    if (!email) {
      return of({ isAdmin: false });
    }

    const url = `${environment.apiConfig.uri}/admin/Candidates/GetAdmin?email=${email}`;
    return this.http.get(url).pipe(
      map((response:any)=>{
        if (response && response.data.isAdmin) {
          return !!response.data.isAdmin; // Convert to boolean
        }
        return false;
      }),
      catchError((error) => {
        console.error('Error fetching admin status:', error);
        return of({ isAdmin: false });
      })
    );
  }
}

 <!-- New Superannuation Form  -->
 <div class="row mt-3">
    <div class="col-12">
        <form #suerForm="ngForm">
            <div class="card form-group">
                <div class="card-body m-4">
                    <!-- Screen: 1 question:1  -->
                    <div *ngIf="!selectedValue">
                                <p class="text-center"><strong>* Only English letters and numbers can be
                                        used.</strong></p>
                                <div class="row">
                                    <div class="column">
                                        <b>Find your fund</b>
                                        <p>Superannuation funds are identified by the USI
                                            (Unique
                                            Superannuation Identifiers), but have previously
                                            been
                                            identified by a SPIN (Superannuation Provider
                                            Identification
                                            Number) or ABN (Australian Business Number). To
                                            select the
                                            correct fund, you need to begin with one of these
                                            numbers.
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label>
                                            <input type="radio" [(ngModel)]="super_findfund"
                                                name="super_findfund" id="radio_search_val"
                                                value="search" (change)="questionStatus($event)"
                                                ngModel>
                                            USI / ABN / SPIN
                                        </label>
                                    </div>
                                    <div class="ml-4  col-md-4">
                                        <input type="text" id="superStream_popup_identifierquery"
                                            #supeR_ABN1="ngModel" maxlength="14"
                                            class="customText form-control" name="super_usi"
                                            pattern="^[A-Za-z0-9]{1,14}$"
                                            [class.is-invalid]="supeR_ABN1.touched &&supeR_ABN1.invalid"
                                            placeholder="Enter USI / ABN / SPIN" [(ngModel)]="super_usi"
                                            ngModel required>
                                    </div>
                                    <div class=" ml-1 col-md-5"
                                        *ngIf="supeR_ABN1.invalid && supeR_ABN1.touched">
                                        <div *ngIf="supeR_ABN1.errors ">
                                            Only English letters and numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" name="super_findfund"
                                                [(ngModel)]="super_findfund" value="default"
                                                (change)="questionStatus($event);" ngModel>
                                            Use super fund nominated by employer</label>
                                    </div>
                                </div>
                                <div *ngIf="actualsupeR_ABN" class="row">
                                    <div class="column">
                                        <label><input type="radio" name="super_findfund"
                                                [(ngModel)]="super_findfund" value="use_existing"
                                                ngModel (change)="questionStatus($event)">
                                            Keep Existing Fund</label>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="text-secondary text-secondary">
                                        These changes will apply to all future superannuation
                                        contributions as well as any currently outstanding
                                        payments not
                                        yet submitted to your super fund. </div>
                                    <div class=" float-right">
                                        <button class=" btn btn-sm btn-danger px-3" type="button"
                                            (click)="resetSuperForm();suerForm.reset()">
                                            <img class="mr-2 mb-1" width="9px"
                                                src="https://cdn-icons-png.flaticon.com/512/2874/2874787.png" />
                                            Cancel</button>
                                        <button class="btn ml-1 btn-sm btn-info px-3"
                                            [disabled]="!super_findfund  || (super_findfund=='search'&&(!super_usi || supeR_ABN1.invalid)) "
                                            (click)="openNextQuestion()" type="button"> Next
                                            <img class="ml-3" width="9px"
                                                src="https://cdn-icons-png.flaticon.com/512/626/626053.png" />
                                        </button>
                                    </div>
                                </div>
                    </div>
                    <!-- Screen:2 question: extra   (shown when a search produces no results) -->
                    <div *ngIf="selectedValue=='nodata'">
                                <div class="row">
                                    <div class="column">
                                        <b>No Matching Funds Found</b>
                                        <p>
                                            We could not find any matching funds. To try again,
                                            use the
                                            'Back' button. If you still cannot find your
                                            regulated
                                            superannuation fund, but know the details, please
                                            provide
                                            them by selecting an option below. If you do not
                                            know the
                                            details, please close this wizard and speak to your
                                            payroll
                                            admin.
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" name="super_fund_type"
                                                (change)="questionStatus($event)"
                                                value="no_new_apra_rsa" [(ngModel)]="super_fund_type"
                                                ngModel> My fund is an
                                            APRA fund or retirement
                                            savings account (RSA)</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" name="super_fund_type"
                                                (change)="questionStatus($event)" value="no_new_smsf"
                                                [(ngModel)]="super_fund_type" ngModel>
                                            My fund is a self-managed super fund (SMSF)</label>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="text-secondary superstream_notice ">
                                        These changes will apply to all future superannuation
                                        contributions as well as any currently outstanding
                                        payments not
                                        yet submitted to your super fund. </div>
                                    <div class=" float-right">
                                        <button (click)="openPreviousQuestion()"
                                            class="btn btn-sm btn-danger px-3" type="button">
                                            <img class="mr-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/4223/4223848.png" />
                                            Back
                                        </button>
                                        <button class="btn ml-1 btn-sm btn-info px-3"
                                            (click)="openNextQuestion()" [disabled]="!super_fund_type"
                                            type="button">Next
                                            <img class="ml-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/626/626053.png" />
                                        </button>
                                    </div>
                                </div>
                    </div>
                    <!-- Screen:3 question:4 Select Fund (shown when a user selects "my fund is not listed here") -->
                    <div *ngIf="selectedValue=='new'">
                                <div class="row">
                                    <div class="column">
                                        <b>Fund Information</b>
                                        <p>
                                            If you know the details of your regulated
                                            superannuation fund, please provide them. If you do not,
                                            please close this wizard and speak to your payroll admin.
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" name="fundtype"
                                                (change)="questionStatus($event)" [(ngModel)]="fundtype"
                                                value="new_apra_rsa" ngModel>
                                            My fund is an APRA fund or retirement savings account
                                            (RSA)</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" name="fundtype" value="new_smsf"
                                                (change)="questionStatus($event)" [(ngModel)]="fundtype"
                                                ngModel>
                                            My
                                            fund is a self-managed super fund (SMSF)</label>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class=" text-secondary superstream_notice">
                                        These changes will apply to all future superannuation
                                        contributions as well as any currently outstanding
                                        payments not
                                        yet submitted to your super fund. </div>
                                    <div class="superstream_buttons float-right">
                                        <button class="btn btn-sm btn-danger px-3" type="button"
                                            (click)="openPreviousQuestion()">
                                            <img class="mr-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/4223/4223848.png" />
                                            Back
                                        </button>
                                        <button (click)="openNextQuestion()"
                                            class="btn ml-1 btn-sm btn-info px-3"
                                            [disabled]="!fundtype" type="button">Next
                                            <img class="ml-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/626/626053.png" />
                                        </button>
                                    </div>
                                </div>
                    </div>
                    <!-- Screen:4 || selectedValue=='default' question:2 Matching Funds (Shows table of results if > 1 search result is found) -->
                    <div *ngIf="selectedValue=='search' && dataFound==true">
                                <div class="row">
                                    <div class="column">
                                        <b>Matching Funds</b>
                                        <p>We have found the following matching funds. Please
                                            select the
                                            correct fund.</p>
                                        <p><b>Note that not all funds can be found using this
                                                tool. Please check the USI carefully before choosing
                                                your fund.</b></p>
                                    </div>
                                </div>
                                <div class="row table-responsive">
                                    <table class="table table-borderless ">
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th>Fund</th>
                                                <th>Product</th>
                                                <th>Type</th>
                                                <th>USI</th>
                                            </tr>
                                            <tr *ngFor="let item of fundDetailsData; let i = index;">
                                                <td><input type="radio" name="super_fund_{{i}}"
                                                        id="faund_{{i}}" [(ngModel)]="super_fund"
                                                        value="{{i}}"
                                                        (change)="questionStatus($event);apraValues(item)">
                                                </td>
                                                <td>{{item.fundName}} </td>
                                                <td><label
                                                        for="faund_{{i}}">{{item.productName}}</label>
                                                </td>
                                                <td>APRA</td>
                                                <td>{{item.usi}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="5">
                                                    <input type="radio" name="super_fund" id="mfund"
                                                        (change)="questionStatus($event)"
                                                        [(ngModel)]="super_fund" value="new"
                                                        ngModel>&nbsp;
                                                    <label for="mfund" class="ml-3">My fund is not
                                                        listed here</label>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="footer">
                                    <div class=" text-secondary superstream_notice">
                                        These changes will apply to all future superannuation
                                        contributions as well as any currently outstanding
                                        payments not yet submitted to your super fund. </div>
                                    <div class=" float-right">
                                        <button (click)="openPreviousQuestion();dataFound=false;"
                                            class="btn btn-sm btn-danger  px-3" type="button">
                                            <img class="mr-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/4223/4223848.png" />Back
                                        </button>
                                        <button (click)="openNextQuestion()"
                                            class="btn ml-1 btn-sm btn-info  px-3"
                                            [disabled]="!super_fund" type="button">Next
                                            <img class="ml-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/626/626053.png" />
                                        </button>
                                    </div>
                                </div>
                    </div>
                    <!-- Screen:5 selectedValue=='supeR_ABN' question:7,8,3 Existing Apra Fund (Shown if search result produces exactly one match) -->
                    <div
                        *ngIf=" (selectedValue=='default' && dataFound) ||selectedValue=='supeR_ABN' || selectedValue=='use_existing'  ">
                                <p class="text-center"><strong>* Only English letters and numbers can be
                                        used.</strong></p>
                                <div class="row">
                                    <div class="column">
                                        <b>APRA fund or Retirement Savings Account (RSA)</b>
                                        <p>Please double-check that you have selected the right
                                            fund
                                            and, if applicable, provide your account name and
                                            member
                                            number.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4">Fund ABN:</div>
                                    <div class="column col-md-8" data-field="abn">{{supeR_ABN}}</div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4">Fund Name:</div>
                                    <div class="column col-md-8" data-field="name">{{super_name}}</div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4">Product:</div>
                                    <div class="column col-md-8" data-field="product">
                                        {{super_product_name}}</div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4">Fund USI:</div>
                                    <div class="column col-md-8" data-field="usi">{{super_usi}}</div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4"><b>If applicable:</b></div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4">Your Account Name:</div>
                                    <div class="column column-half-width col-md-6">
                                        <input type="text" name="super_account_name" maxlength="60"
                                            #super_account_name4="ngModel"
                                            class="customText form-control"
                                            [class.is-invalid]="super_account_name4.invalid && (super_account_name4.touched || suerForm.submitted )"
                                            [(ngModel)]="super_account_name" pattern="[a-zA-Z0-9\s]+"
                                            ngModel
                                            [required]="(selectedValue === 'use_existing' ||selectedValue=='supeR_ABN')">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_account_name4.invalid && (super_account_name4.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_account_name4.errors &&( super_account_name4.errors['pattern'] )">
                                            Only English letters can be used.
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="column font-weight-bold col-md-4">Your Member Number:
                                    </div>
                                    <div class="column column-half-width col-md-6">
                                        <!-- super_member_number -->
                                        <input type="text" name="super_member_reference" maxlength="60"
                                            #super_member_number4="ngModel"
                                            [(ngModel)]="super_member_reference"
                                            [class.is-invalid]="super_member_number4.invalid &&(super_member_number4.touched || suerForm.submitted)"
                                            class="customText form-control" ngModel
                                            pattern="[a-zA-Z0-9\s]+"
                                            [required]="(selectedValue === 'use_existing' ||selectedValue=='supeR_ABN')">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_member_number4.invalid && (super_member_number4.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_member_number4.errors &&( super_member_number4.errors['pattern']  )">
                                            Only English letters & numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="footer">
                                    <div class="text-secondary">
                                        These changes will apply to all future superannuation
                                        contributions as well as any currently outstanding
                                        payments not yet submitted to your super fund. </div>
                                    <div class=" float-right">
                                        <button (click)="openPreviousQuestion();dataFound=true"
                                            data-previous="search"
                                            class="btn btn-sm btn-danger px-3" type="button">
                                            <img class="mr-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/4223/4223848.png" />Back
                                        </button>



                                        <button
                                            [disabled]="(super_account_name4.invalid  || super_member_number4.invalid) && (selectedValue == 'use_existing' || selectedValue =='supeR_ABN')"
                                            class="btn ml-1 btn-sm btn-info px-3"
                                            data-target="#submitTFN" data-toggle="modal"
                                            (click)="submitSuperannuationForm(suerForm.value)"
                                            type="submit">
                                            Save <img class="ml-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/907/907229.png" />
                                        </button>
                                    </div>
                                </div>
                    </div>
                    <!-- Screen:6 question:5 New APRA Fund -->
                    <div *ngIf="selectedValue=='new_apra_rsa' ||selectedValue=='no_new_apra_rsa'">
                                <p class="text-center"><strong>* Only English letters and numbers can be
                                        used.</strong></p>
                                <div class="row">
                                    <div class="column">
                                        <b>APRA fund or Retirement Savings Account (RSA)</b>
                                        <p>You will need current details from your APRA
                                            regulated fund
                                            or RSA to complete this form.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund ABN:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input maxlength="14" type="number" name="supeR_ABN"
                                            #supeR_ABN2="ngModel" pattern="^[0-9]{11,14}$"
                                            [(ngModel)]="supeR_ABN" class="customText form-control "
                                            [class.is-invalid]="(supeR_ABN2.touched || suerForm.submitted )&&supeR_ABN2.invalid"
                                            ngModel required>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="supeR_ABN2.touched && supeR_ABN2.invalid ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="supeR_ABN2.errors &&( supeR_ABN2.errors['pattern'] || suerForm.submitted )">
                                            Only English letters and numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund Name:</div>
                                    <div class="column column-fund-name col-md-6 mt-1">
                                        <input type="text" name="super_name" maxlength="100"
                                            #super_name2="ngModel"
                                            class="customText fund-name-field form-control "
                                            [class.is-invalid]="super_name2.invalid && (super_name2.touched || suerForm.submitted )  "
                                            pattern="[a-zA-Z0-9\s]+" [(ngModel)]="super_name" ngModel
                                            required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_name2.touched && super_name2.invalid ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_name2.errors &&( super_name2.errors['pattern'] || suerForm.submitted )">
                                            Only English letters and numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund Address:</div>
                                    <div class="column column-address col-md-6 mt-1">
                                        <input type="text" name="super_address1" maxlength="120"
                                            #super_address11="ngModel" pattern="[a-zA-Z0-9\s]+"
                                            [class.is-invalid]="super_address11.invalid && (super_address11.touched || suerForm.submitted) "
                                            class="customText address-field form-control"
                                            [(ngModel)]="super_address1" ngModel required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_address11.touched && super_address11.invalid ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_address11.errors &&( super_address11.errors['pattern'] || suerForm.submitted )">
                                            Only English letters and numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column column-heading col-md-4 mt-1">&nbsp;</div>
                                    <div class="column column-address col-md-6 mt-1">
                                        <input name="address2" type="text" maxlength="120" #super_address2="ngModel"
                                            pattern="[a-zA-Z0-9\s]+" [class.is-invalid]="super_address2.invalid && (super_address2.touched || suerForm.submitted)"
                                            class="customText address-field form-control " ngModel>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column column-heading col-md-4 mt-1">&nbsp;</div>
                                    <div class="column column-address col-md-6 mt-1">
                                        <input name="address3" maxlength="120"
                                        #super_address3="ngModel"
                                        pattern="[a-zA-Z0-9\s]+" [class.is-invalid]="super_address3.invalid && (super_address3.touched || suerForm.submitted)"
                                            class="customText address-field form-control " ngModel>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">State:</div>
                                    <div class="column column-state col-md-6 mt-1">
                                        <select class="customSelect form-control "
                                            #super_state1="ngModel" name="super_state"
                                            [(ngModel)]="super_state" required ngModel>
                                            <!-- selected="" -->
                                            <option value="" selected> Select state</option>
                                            <option value="ACT, AU">ACT, AU</option>
                                            <option value="NSW, AU">NSW, AU</option>
                                            <option value="NT, AU">NT, AU</option>
                                            <option value="QLD, AU">QLD, AU</option>
                                            <option value="SA, AU">SA, AU</option>
                                            <option value="TAS, AU">TAS, AU</option>
                                            <option value="VIC, AU">VIC, AU</option>
                                            <option value="WA, AU">WA, AU</option>
                                        </select>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf=" super_state1.invalid && (super_state1.touched || suerForm.submitted )">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_state1.errors">
                                            Select State.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Postcode:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input name="super_postcode" type="text"
                                            #super_postcode1="ngModel" pattern="[a-zA-Z0-9\s]{4,}"
                                            [class.is-invalid]="super_postcode1.invalid && super_postcode1.touched"
                                            class="customText postcode-field form-control"
                                            [(ngModel)]="super_postcode" title="Postcode" ngModel
                                            required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_postcode1.invalid && (super_postcode1.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_postcode1.errors &&( super_postcode1.errors['pattern'] || suerForm.submitted )">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund Phone:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input type="text" pattern="[a-zA-Z0-9\s]+" maxlength="20"
                                            class="customText form-control " [(ngModel)]="super_phone"
                                            name="super_phone" ngModel #super_phone1="ngModel"
                                            [class.is-invalid]="super_phone1.invalid && super_phone1.touched"
                                            required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_phone1.invalid && (super_phone1.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_phone1.errors &&( super_phone1.errors['pattern'] || suerForm.submitted )">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund USI:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input maxlength="14" type="text" pattern="^[A-Za-z0-9]{10,14}$"
                                            [class.is-invalid]="super_usi1.invalid && super_usi1.touched"
                                            name="super_usi" [(ngModel)]="super_usi"
                                            #super_usi1="ngModel" class="customText form-control "
                                            ngModel required>
                                    </div>

                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_usi1.invalid && (super_usi1.touched  ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_usi1.errors &&( super_usi1.errors['pattern']  )">
                                            Only numbers can be used(min length 10).
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column col-12"><b>If applicable:</b></div>
                                </div>
                                <div class="row">
                                    <div class="column column-heading font-weight-bold col-md-4 mt-1">Your Account Name:
                                    </div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input name="super_account_name" type="text"
                                            pattern="[a-zA-Z0-9\s]+" maxlength="60"
                                            class="customText form-control mt-1" ngModel>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Your Member Number:
                                    </div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input name="super_member_reference" type="text" maxlength="60"
                                            pattern="[a-zA-Z0-9\s]+"
                                            class="customText form-control mt-1 " ngModel>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="column">
                                        <b>Required Documentation</b>
                                        <p>You need to attach a letter from your fund stating
                                            that they
                                            are a complying fund and that they will accept
                                            contributions
                                            from your employer. Correct information about your
                                            super
                                            fund is needed for your employer to pay super
                                            contributions.
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" name="upload_nonsmsf" value="2"
                                                ngModel> I will
                                            provide a
                                            physical letter directly to my payroll
                                            administrator</label>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="text-secondary superstream_notice">
                                        These changes will apply to all future superannuation
                                        contributions as well as any currently outstanding
                                        payments not
                                        yet submitted to your super fund. </div>
                                    <div class="superstream_buttons float-right">
                                        <button (click)="openPreviousQuestion()"
                                            class="btn btn-sm btn-danger  px-3" type="button">
                                            <img class="mr-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/4223/4223848.png" />
                                            Back
                                        </button>
                                        <button
                                            *ngIf="super_usi1.valid && super_phone1.valid &&super_postcode1.valid && super_state1.valid && super_address11.valid &&super_name2.valid && supeR_ABN2.valid; else elseBlock"
                                            class="btn ml-1 btn-sm btn-info px-3"
                                            (click)="submitSuperannuationForm(suerForm.value)"
                                            data-target="#submitTFN" data-toggle="modal" type="submit">
                                            Save <img class="ml-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/907/907229.png" /></button>
                                        <ng-template #elseBlock>
                                            <button class="btn ml-1 btn-sm btn-info px-3"
                                                type="submit">
                                                Save <img class="ml-3" width="10px"
                                                    src="https://cdn-icons-png.flaticon.com/512/907/907229.png" /></button>
                                        </ng-template>
                                    </div>
                                </div>
                    </div>
                    <!-- Screen:7 question:6 New SMSF Fund -->
                    <div *ngIf="selectedValue=='new_smsf' || selectedValue=='no_new_smsf'">
                        <div>
                            <div>
                                <p class="text-center"><strong>* Only English letters and numbers can be
                                        used.</strong></p>
                                <div class="row">
                                    <div class="column">
                                        <b>Self Managed Super Fund (SMSF)</b>
                                        <p>You will need current details from your SMSF trustee
                                            to
                                            complete this form.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund ABN: </div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input name="supeR_ABN" [(ngModel)]="supeR_ABN" maxlength="14"
                                            #supeR_ABN3="ngModel" type="number" pattern="^[0-9]{11,14}$"
                                            [class.is-invalid]="supeR_ABN3.touched && supeR_ABN3.invalid"
                                            class="customText form-control" ngModel required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6"
                                        *ngIf="supeR_ABN3.invalid && (supeR_ABN3.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="supeR_ABN3.errors &&( supeR_ABN3.errors['pattern'] || suerForm.submitted )">
                                            Only English letters and numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund Name:</div>
                                    <div class="column column-fund-name col-md-6 mt-1">
                                        <input name="super_name" maxlength="60" [(ngModel)]="super_name"
                                            #super_name3="ngModel" type="text" pattern="[a-zA-Z0-9\s]+"
                                            [class.is-invalid]="super_name3.touched && super_name3.invalid"
                                            class="customText fund-name-field form-control " ngModel
                                            required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6"
                                        *ngIf="super_name3.invalid && (super_name3.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_name3.errors &&( super_name3.errors['pattern'] || suerForm.submitted )">
                                            Only English letters and numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund Address:</div>
                                    <div class="column column-address col-md-6 mt-1">
                                        <input name="super_address1" maxlength="120" type="text"
                                            pattern="[a-zA-Z0-9\s]+" #super_address13="ngModel"
                                            class="customText address-field form-control "
                                            [class.is-invalid]="super_address13.touched && super_address13.invalid"
                                            [(ngModel)]="super_address1" ngModel required>
                                    </div>

                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6"
                                        *ngIf="super_address13.invalid && (super_address13.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_address13.errors &&( super_address13.errors['pattern'] || suerForm.submitted )">
                                            Only English letters and numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">&nbsp;</div>
                                    <div class="column column-address col-md-6 mt-1">
                                        <input name="address2" maxlength="120" type="text"
                                            pattern="[a-zA-Z0-9\s]+" #super_address2="ngModel" [class.is-invalid]="super_address2.invalid && (super_address2.touched || suerForm.submitted)"
                                            class="customText address-field  form-control" ngModel>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column column-heading col-md-4 mt-1">&nbsp;</div>
                                    <div class="column column-address col-md-6 mt-1">
                                        <input name="address3" maxlength="30" type="text"
                                            pattern="[a-zA-Z0-9\s]+" #super_address3="ngModel"
                                            pattern="[a-zA-Z0-9\s]+" [class.is-invalid]="super_address3.invalid && (super_address3.touched || suerForm.submitted)"
                                            class="customText address-field form-control" ngModel>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">State:</div>
                                    <div class="column column-state col-md-6 mt-1">
                                        <select class="customSelect form-control "
                                            #super_state3="ngModel" [(ngModel)]="super_state"
                                            name="super_state" ngModel
                                            [class.is-invalid]="super_state3.touched && super_state3.invalid"
                                            required>
                                            <option value="" selected> Select state</option>
                                            <option value="ACT, AU">ACT, AU</option>
                                            <option value="NSW, AU">NSW, AU</option>
                                            <option value="NT, AU">NT, AU</option>
                                            <option value="QLD, AU">QLD, AU</option>
                                            <option value="SA, AU">SA, AU</option>
                                            <option value="TAS, AU">TAS, AU</option>
                                            <option value="VIC, AU">VIC, AU</option>
                                            <option value="WA, AU">WA, AU</option>
                                        </select>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_state3.invalid && (super_state3.touched || suerForm.submitted)">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_state3.errors  ">
                                            Select State.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Postcode:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input name="super_postcode" type="text"
                                            #super_postcode3="ngModel" pattern="[a-zA-Z0-9\s]{4,}"
                                            [(ngModel)]="super_postcode"
                                            [class.is-invalid]="super_postcode3.touched && super_postcode3.invalid"
                                            class="customText postcode-field form-control"
                                            title="Postcode" ngModel required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6"
                                        *ngIf="super_postcode3.invalid && (super_postcode3.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_postcode3.errors &&( super_postcode3.errors['pattern'] || suerForm.submitted )">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund Phone:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input name="super_phone" maxlength="20"
                                            [(ngModel)]="super_phone" type="text"
                                            #super_phone3="ngModel" pattern="[a-zA-Z0-9\s]+"
                                            class="customText form-control "
                                            [class.is-invalid]="super_phone3.touched && super_phone3.invalid"
                                            ngModel required>
                                    </div>

                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6"
                                        *ngIf="super_phone3.invalid && (super_phone3.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_phone3.errors &&( super_phone3.errors['pattern'] || suerForm.submitted )">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Fund ESA:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input maxlength="14" name="super_esa" [(ngModel)]="super_esa"
                                            #super_esa3="ngModel" type="text" pattern="[a-zA-Z0-9\s]+"
                                            [class.is-invalid]="super_esa3.touched && super_esa3.invalid"
                                            class="customText form-control" ngModel required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6"
                                        *ngIf="super_esa3.invalid && (super_esa3.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_esa3.errors &&( super_esa3.errors['pattern'] || suerForm.submitted )">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">BSB Code:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input maxlength="6" name="super_payment_bsb" type="text"
                                            #super_payment_bsb3="ngModel" pattern="[a-zA-Z0-9\s]+"
                                            [(ngModel)]="super_payment_bsb"
                                            [class.is-invalid]="super_payment_bsb3.touched && super_payment_bsb3.invalid"
                                            class="customText form-control " ngModel required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6"
                                        *ngIf="super_payment_bsb3.invalid && (super_payment_bsb3.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_payment_bsb3.errors &&( super_payment_bsb3.errors['pattern'] || suerForm.submitted )">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column font-weight-bold col-md-4 mt-1">Account Number:</div>
                                    <div class="column column-half-width col-md-6 mt-1">
                                        <input name="super_payment_account" maxlength="10" type="text"
                                            #super_payment_account3="ngModel" pattern="[0-9]{1,}"
                                            [(ngModel)]="super_payment_account"
                                            [class.is-invalid]="super_payment_account3.touched && super_payment_account3.invalid"
                                            class="customText form-control" ngModel required>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-6"
                                        *ngIf="super_payment_account3.invalid && (super_payment_account3.touched || suerForm.submitted ) ">
                                        <div class="alert alert-danger form-control"
                                            *ngIf="super_payment_account3.errors &&( super_payment_account3.errors['pattern'] || suerForm.submitted )">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <div class="row">
                                    <div class="column">
                                        <b>Required Documentation</b>
                                        <p>You need to attach a document confirming the SMSF is an ATO
                                            regulated super fund. You can locate and print a copy of the
                                            compliance status of your SMSF by searching using the ABN or
                                            fund name in the Super Fund Lookup service at
                                            http://superfundlookup.gov.au/
                                        </p>
                                        <p>If you are the trustee, or a director of the
                                            corporate
                                            trustee, you can confirm that your SMSF will accept
                                            contributions from your employer by making the
                                            following
                                            declaration:</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" name="upload_smsf" value="3" ngModel>
                                            I am the trustee or a director of the corporate trustee of
                                            the SMSF and I declare that the SMSF will accept
                                            contributions from my employer </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <label><input type="radio" class="my-1" name="upload_smsf"
                                                value="2" ngModel>
                                            I will
                                            provide a
                                            physical letter directly to my payroll
                                            administrator</label>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class=" text-secondary superstream_notice ">
                                        These changes will apply to all future superannuation
                                        contributions as well as any currently outstanding
                                        payments not
                                        yet submitted to your super fund. </div>
                                    <div class="superstream_buttons float-right">
                                        <button id="superStream_popup_new_smsf_back"
                                            (click)="openPreviousQuestion()"
                                            class="btn btn-sm btn-danger  px-3" type="button"><span
                                                class="ui-button-text">
                                                <img class="mr-3" width="10px"
                                                    src="https://cdn-icons-png.flaticon.com/512/4223/4223848.png" />Back
                                            </span></button>
                                        <button
                                            *ngIf="super_payment_account3.valid&& super_payment_bsb3.valid &&super_postcode3.valid && super_esa3.valid && super_phone3.valid&&super_state3.valid &&super_address13.valid &&super_name3.valid &&supeR_ABN3.valid; else elseBlock2"
                                            class="btn ml-1 btn-sm btn-info  px-3" type="submit"
                                            data-target="#submitTFN" data-toggle="modal"
                                            (click)="submitSuperannuationForm(suerForm.value)">
                                            Save<img class="ml-3" width="10px"
                                                src="https://cdn-icons-png.flaticon.com/512/907/907229.png" /></button>
                                        <ng-template #elseBlock2>
                                            <button class="btn ml-1 btn-sm btn-info  px-3"
                                                type="submit">
                                                Save <img class="ml-3" width="10px"
                                                    src="https://cdn-icons-png.flaticon.com/512/907/907229.png" /></button>
                                        </ng-template>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade mt-5" id="submitTFN" tabindex="-1" role="dialog" aria-labelledby="submitTFNLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-body ">
            <div id="extraInfo" class=" card hidden-content" >
                <div *ngIf="viewDataPdf">

                    <div class="card-body">
                        <p><b>New Superannuation Nomination</b></p>
                        <table class="table table-sm">
                            <tr>
                                <th>Fund Name</th>
                                <td>{{viewDataPdf.super_name }}</td>
                            </tr>
                            <tr>
                                <th>Fund ABN</th>
                                <td>{{viewDataPdf.supeR_ABN}}</td>
                            </tr>
                            <tr>
                                <th>Fund Product Name</th>
                                <td>{{viewDataPdf.super_product_name }}</td>
                            </tr>
                            <tr>
                                <th>Fund USI</th>
                                <td>{{viewDataPdf.super_usi}}</td>
                            </tr>
                            <tr>
                                <th>Account Name</th>
                                <td>{{viewDataPdf.super_account_name }}</td>
                            </tr>
                            <tr>
                                <th>Member Reference</th>
                                <td>{{viewDataPdf.super_member_reference}}</td>
                            </tr>                                          
                        </table>
                    </div>
                </div>
                <div class="mt-5 mx-4">
                    <p><b>Submitted By:&nbsp;&nbsp; </b>{{name}}</p>
                    <p><b>Login Credentials:&nbsp;&nbsp; </b>{{mobile?mobile:email}}</p>
                    <p><b>Submission Date:&nbsp;&nbsp; </b>{{submissionDate}}</p>
                </div>
            </div>

            <div *ngIf="messaageForUser">
                <p style="text-align: center;">{{messaageForUser}} </p>
                <div class="row">
                    <div class="col-5"></div>
                    <button type="button" class="btn btn-sm btn-info px-3 mr-3" data-dismiss="modal"
                    (click)="!canResetForm ? modalLoader=false : closeModal(); modalLoader=false">
                    Ok
                </button>
                    <div class=" col-2">
                    </div>
                    <div class="col-5"></div>
                </div>
            </div>
            <div *ngIf="!messaageForUser">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

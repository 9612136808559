<div class="header-section">
  <h4>{{ title }}</h4>
  <div *ngIf="isSearch" class="text-right">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <div class="input-group rounded">
        <input formControlName="searchQuery" type="search" placeholder="Search" class="form-control" />
        <button type="submit" class="btn btn-primary">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </form>
  </div>
</div>

<div *ngIf="loading" class="d-flex justify-content-center">
  <div class="spinner-border m-5" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="table-responsive" *ngIf="!loading && data.length" style="overflow-x: auto;">
  <table class="table table-bordered table-hover table-striped align-middle mb-0 bg-white">
    <thead>
      <tr>
        <th *ngFor="let header of tableHeader">{{ header }}</th>
        <th *ngIf="isView">Actions</th>
        <th *ngIf="adminView">Is Admin</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="data.length === 0">
        <td [attr.colspan]="tableHeader.length + 1" class="text-center">No data</td>
      </tr>
      <tr *ngFor="let item of data">
        <td *ngFor="let row of tableRows">
          {{ row === 'dateAdded' ? (item[row] | date: 'short') : item[row] }}
        </td>
        <td *ngIf="isView">
          <button type="button" class="btn btn-link btn-sm" (click)="openContact(item.id)">View</button>
        </td>
        <td *ngIf="adminView">
        <select id="isAdmin" name="isAdmin" class="custom-select custom-select-sm"
          [(ngModel)]="item.isAdmin" (change)="onStatusChange(item)">
          <option [value]="true">Yes</option>
          <option [value]="false">No</option>
        </select>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!loading && data.length === 0" class="text-center">No data</div>

<div *ngIf="!loading && data.length !== 0" class="pagination-container">
  <ngb-pagination 
    [collectionSize]="collectionSize"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="4"
    [boundaryLinks]="true"
    [rotate]="true"
    (pageChange)="refreshData(page, query, pageSize)"
    class="paginationSet">
  </ngb-pagination>

  <div class="select-wrapper">
    <select 
      class="form-select"
      [(ngModel)]="pageSize"
      (ngModelChange)="refreshData('1', query, pageSize)">
      <option *ngFor="let pageSizeOption of pageSizeOptions" [ngValue]="pageSizeOption">
        {{ pageSizeOption }} items per page
      </option>
    </select>
  </div>
</div>
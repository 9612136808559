import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
  })
  export class GetAdminsService {
    constructor(private http: HttpClient) {}
  
    getAdmins(): Observable<string[]> {
      const url = `${environment.apiConfig.uri}/admin/Candidates/GetAllAdmin`;
      return this.http
        .get<any>(url).pipe(
          map((response) =>
            response.data.map((admin:any)=>admin.displayName)
          )
        );  
    }
  }
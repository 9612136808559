<div *ngIf="isPortalAdmin; else accessDenied" class="row mt-5">
    <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
        <div class="mt-3">
            <!-- market value area start && countdownValue==0-->
            <div *ngIf="!loadingFiles " class="table-responsive" style="overflow-x:hidden">
                <table *ngIf="!openDeduction" class="table table-hover align-middle bg-white">
                    <thead>
                        <tr>
                            <th colspan="3">Deductions</th>
                        </tr>
                    </thead>
                    <tbody class="m-3">
                        <ng-container *ngIf="queryData.length>0">
                            <ng-container >
                                <tr class="m-3"*ngFor="let item of queryData">
                                    <td colspan="2">{{item.companyName}}
                                    </td>
                                    <td style="align-self: center;">
                                        <button class=" btn btn-sm btn-primary px-3 mr-3" type="button"
                                            (click)="viewDeduction(item)" title="View File">
                                            View
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="queryData.length<1">
                            <tr>
                                <td colspan="3" class="text-center">No Data
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div *ngIf="openDeduction" class="table-responsive">
                    <div>
                        <button (click)="openDeduction=false" class=" btn badge badge-primary my-3"
                            type="button">Back</button>
                        <div class="card">
                            <div class="card-body" style="overflow-x: auto">
                                <h5>Deductions Each Pay</h5>
                                <table class="table table-bordered tablecenter table-hover align-middle mb-0 bg-white">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Amount Each Pay </th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Amount Owed</th>
                                            <th>Total Paid</th>
                                            <th>Balance</th>
                                            <th colspan="2"></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="unlimitTable">
                                        <tr *ngFor="let item of unlimitedData">
                                            <th>{{item.name?item.name:'-'}}</th>
                                            <td>{{item.amount?(item.amount | number:'1.2-2'):'-'}}</td>
                                            <td>{{(item.date_from!='-' && item.date_from!=null )?(item.date_from |
                                                date: 'yyyy-MM-dd'):'-'}}</td>
                                            <td>
                                                {{ (item.date_to != '-' && item.date_to != 'PAID'&&
                                                item.date_to!=null ) ? (item.date_to | date: 'yyyy-MM-dd') :'-' }}
                                            </td>
                                            <td>{{item.amount_owed?(item.amount_owed |number:'1.2-2'):'-'}}</td>
                                            <td>{{item.amount_taken?(item.amount_taken|number:'1.2-2'):'-'}}</td>
                                            <td>{{(item.balance||item.balance==0)?(item.balance |number:'1.2-2'):'-'}}</td>
                                            <td *ngIf="item.isAstute===false">
                                                <app-status-icons [statusValue]="item.status=='Complete'?'true':'false'"></app-status-icons>
                                            </td>
                                            <td *ngIf="item.isAstute===false">
                                                <span *ngIf="item.fileId "
                                                    style="display: flex; align-items: center;">
                                                    <button class="btn btn-sm btn-primary mr-1" title="Download"
                                                        (click)="onClickDownload(item)">
                                                        <i class="fa fa-download" style="font-size: 12px"></i>
                                                    </button>
                                                    <button title="View" [id]="'item_' + item.id"
                                                        class="btn btn-sm btn-info" (click)="onClickView(item)">
                                                        <i class="fa fa-file-text-o" style="font-size: 12px"></i>
                                                    </button>
                                                </span>
                                                <span *ngIf="!item.fileId || item.isSigned===false">
                                                    <button disabled title="Check your email to add sign." class="btn btn-sm px-3 btn-light" *ngIf="item.isSigned===false">Pending</button>                                                      
                                                    <button  *ngIf="item.isSigned!==false && item.isSigned!==true"   type="button"
                                                            class="btn btn-sm btn-secondary px-3 " data-toggle="modal"
                                                            data-target="#AuthorityModal"
                                                            (click)="createDeductionSlip(item)" title="Sign File">
                                                            Sign
                                                    </button>
                                                </span>
                                            </td>
                                            <td *ngIf="item.isAstute===true" colspan="2"></td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!unlimitTable">
                                        <tr>
                                            <td colspan="8" class="text-center"> No data. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div class="card">
                            <div class="card-body" style="overflow-x: auto">
                                <h5>Fixed Deductions</h5>
                                <table class="table table-bordered tablecenter table-hover align-middle mb-0 bg-white">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Amount Each Pay </th>
                                            <th>Start Date</th>
                                            <th>Estimated End Date</th>
                                            <th>Total Amount</th>
                                            <th>Total Paid</th>
                                            <th>Balance</th>
                                            <th colspan="2"></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="fixTable">
                                        <tr *ngFor="let item of fixedData">
                                            <th>{{item.name?item.name:'-'}}</th>
                                            <td>{{item.amount?(item.amount | number:'1.2-2'):'-'}}</td>
                                            <td>{{(item.date_from!='-' && item.date_from!=null )?(item.date_from |
                                                date: 'yyyy-MM-dd'):'-'}}</td>
                                            <td>
                                                {{ (item.date_to != '-' && item.date_to != 'PAID' && item.date_to !=
                                                null)? (item.date_to | date: 'yyyy-MM-dd') :item.date_to ==
                                                'PAID'?'PAID': '-' }}
                                            </td>
                                            <td>{{item.limit_fixed?(item.limit_fixed | number:'1.2-2'):'-'}}</td>
                                            <td>{{item.amount_taken?(item.amount_taken |number:'1.2-2'):'-'}}</td>
                                            <td>{{(item.balance||item.balance==0)?(item.balance |number:'1.2-2'):'-'}}</td>
                                            <td *ngIf="item.isAstute===false">
                                                <app-status-icons [statusValue]="item.status=='Complete'?'true':'false'"></app-status-icons>
                                            </td>
                                            <td *ngIf="item.isAstute===false">
                                                <span *ngIf="item.fileId "
                                                    style="display: flex; align-items: center;">
                                                    <button class="btn btn-sm btn-primary mr-1" title="Download"
                                                        (click)="onClickDownload(item)">
                                                        <i class="fa fa-download" style="font-size: 12px"></i>
                                                    </button>
                                                    <button title="View" [id]="'item_' + item.id"
                                                        class="btn btn-sm btn-info" (click)="onClickView(item)">
                                                        <i class="fa fa-file-text-o" style="font-size: 12px"></i>
                                                    </button>
                                                </span>
                                                <span *ngIf="!item.fileId || item.isSigned===false ">
                                                    <button disabled title="Check your email to add sign." class="btn btn-sm px-3 btn-light" *ngIf="item.isSigned===false">Pending</button>                                                      
                                                    <button  *ngIf="item.isSigned!==false && item.isSigned!==true"   type="button"
                                                            class="btn btn-sm btn-secondary " data-toggle="modal"
                                                            data-target="#AuthorityModal"
                                                            (click)="createDeductionSlip(item)" title="Sign File">
                                                            Sign
                                                    </button>
                                                </span>
                                            </td>
                                            <td *ngIf="item.isAstute===true" colspan="2"></td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!fixTable">
                                        <tr>
                                            <td colspan="8" class="text-center"> No data. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- market value area end -->
                </div>
            </div>
            <div *ngIf="loadingFiles">
                <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                </li>
                <li class="t_loader">
                    <div class="card__desc load"></div>
                </li>
                <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                </li>
                <li class="t_loader">
                    <div class="card__desc load"></div>
                </li>
                <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                </li>
                <li class="t_loader">
                    <div class="card__desc load"></div>
                </li>
            </div>
        </div>
    </div>
</div>

<ng-template #accessDenied>
    <div class="access-denied-container">
        <h2 class="access-denied-message">Access Denied</h2>
    </div>
</ng-template>
<!-- Modal -->
<div class="modal fade" id="AuthorityModal" tabindex="-1" role="dialog" aria-labelledby="AuthorityModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="AuthorityModalTitle">Authority To Deduct</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="!loading && !successMessage" class="card table-responsive">
                    <div class="card-body" id="ATD_pdf">
                        <div class="top p-2">
                            <div style="display: flex; justify-content:space-between;">
                                <div class="px-3 py-3">
                                    <img [src]="brandLogo" alt="logo" width="60%" />
                                </div>
                                <div class="mr-3">
                                    <table class="table table-sm table-borderless ">
                                        <tr style="font-size: 12px; color: #818285;">
                                            {{brandPhone}} <br>
                                            G01N 527 Gregory Terrace,<br>
                                            Fortitude Valley QLD 4006<br>
                                            ABN 23 142 526 216<br>
                                            ACN 142 526 216<br>
                                            {{brandUrl}}<br>
                                            <hr style="background-color: #0C578E;">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14"
                                                viewBox="0 0 448 512" class="mr-2" style="color: #0C578E;">
                                                <path
                                                    d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                                                    fill="#0C578E" />
                                            </svg>{{brandName}}<br>
                                            <span *ngIf="brandName!=='Health Plus People'">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="10"
                                                viewBox="0 0 320 512" class="mr-2" style="color: #0C578E;">
                                                <path
                                                    d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                                                    fill="#0C578E" />
                                            </svg>&#64;agrilabouraus<br>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14"
                                                viewBox="0 0 448 512" class="mr-2" style="color: #0C578E;">
                                                <path
                                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                                    fill="#0C578E" />
                                            </svg>&#64;agrilabourau<br>
                                        </span>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="mx-4">
                                <h3> <strong>AUTHORITY FOR PAYROLL DEDUCTIONS</strong> </h3>
                                <h6>I <strong> {{name}} </strong>authorize {{brandName}} Pty Ltd to make
                                    deductions to
                                    my pay, as specified in the table below.</h6>
                                <div class="table-responsive ">
                                    <table class="table table-bordered table-hover ">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Amount Each Pay</th>
                                                <th scope="col">Total Amount</th>
                                                <th scope="col">Commencing</th>
                                                <th scope="col">Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of dataATD">
                                                <td scope="row">{{item.name}}</td>
                                                <td>${{item.amount | number:'1.2-2'}}</td>
                                                <td>${{item.total_paid?(item.total_paid | number:'1.2-2'):'-'}}</td>
                                                <td>{{item.date_from | date:'MMM d, y'}}</td>
                                                <td>{{item.note?item.note:"-"}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>                               
                            </div>
                        </div>

                        <div class="bottom">
                            <div>
                                <p><b class="mr-4">Signature:</b></p>
                                <p><b class="mr-4">Date:</b> {{currentDate| date:'medium'}} </p>
                            </div>
                            <h2 style="color: #ECB41F;" class="mt-5">LABOUR HIRE</h2>
                            <h2 style="color: #624891;">PERMANENT RECRUITMENT</h2>
                        </div>
                    </div>
                    
                </div>
                <div *ngIf="loading" class="text-center mt-3 my-3">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Please wait, processing your request...</p>
                </div>
                <div *ngIf="successMessage" class="text-center mt-3 text-success">
                    <p>Submission successful! <br> {{ successMessage }}</p>
                    <button class="btn btn-primary"><a (click)="openDocuSign();" target="_blank"
                            style="color: white;">Open DocuSign </a> </button>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="loading=false; successMessage?openDeduction=false:''">Close</button>
                <button *ngIf="!loading && !successMessage" type="button" class="btn btn-primary"
                    (click)="submitATD()">Add Sign</button>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  history: any;
  constructor(private http: HttpClient) {}

  getEditHistory(
    pageNumber: string = '1',
    pageSize: string = '10',
    url: string = `${environment.apiConfig.uri}/CandidateEditHistory`
  ): Observable<any> {
    return this.http.get(
      url + '?pageNumber=' + pageNumber + '&pagesize=' + pageSize
    );
  }
}

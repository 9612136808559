import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tax-declarations-details',
  templateUrl: './tax-declarations-details.component.html',
  styleUrls: ['./tax-declarations-details.component.css'],
})
export class TaxDeclarationsDetailsComponent implements OnInit {
  selected: string = 'tax-declarations-details';
  taxDecData: any;
  viewTaxFileTable: boolean = false;
  mobileView: boolean = false;
  viewFormData: any;
  filestatus?: number;
  fileid?: number;
  messageValue?: number;
  candidateId!: number;
  changeStatusMessage!: string;
  statusSign: any;
  taxdatalength!: number;
  loadingtaxfiles: boolean = true;
  passportIssuanceCountryObj: any = {
    Andorra: 'ad',
    'United Arab Emirates (the)': 'ae',
    Afghanistan: 'af',
    ' Antigua and Barbuda ': 'ag',
    Anguilla: 'ai',
    Albania: 'al',
    Armenia: 'am',
    Angola: 'ao',
    Antarctica: 'aq',
    Argentina: 'ar',
    'American Samoa': 'as',
    Austria: 'at',
    Australia: 'au',
    Aruba: 'aw',
    ' Åland Islands': 'ax',
    Azerbaijan: 'az',
    ' Bosnia and Herzegovina': 'ba',
    Barbados: 'bb',
    Bangladesh: 'bd',
    Belgium: 'be',
    ' Burkina Faso': 'bf',
    Bulgaria: 'bg',
    Bahrain: 'bh',
    Burundi: 'bi',
    Benin: 'bj',
    'Saint Barthélemy': 'bl',
    Bermuda: 'bm',
    'Brunei Darussalam ': 'bn',
    'Bolivia (Plurinational State of)': 'bo',
    'Bonaire, Sint Eustatius and Saba': 'bq',
    Brazil: 'br',
    ' Bahamas (the)': 'bs',
    Bhutan: 'bt',
    '  Bouvet Island': 'bv',
    Botswana: 'bw',
    Belarus: 'by',
    Belize: 'bz',
    Canada: 'ca',
    'Cocos (Keeling) Islands (the)': 'cc',
    ' Congo (the Democratic Republic of the)': 'cd',
    ' Central African Republic (the) ': 'cf',
    ' Congo (the) ': 'cg',
    Switzerland: 'ch',
    'Côte d Ivoire': 'ci',
    'Cook Islands (the)': 'ck',
    Chile: 'cl',
    Cameroon: 'cm',
    China: 'cn',
    Colombia: 'co',
    ' Costa Rica': 'cr',
    Cuba: 'cu',
    ' Cabo Verde': 'cv',
    Curaçao: 'cw',
    ' Christmas Island': 'cx',
    Cyprus: 'cy',
    Czechia: 'cz',
    Germany: 'de',
    Djibouti: 'dj',
    Denmark: 'dk',
    Dominica: 'dm',
    ' Dominican Republic (the)': 'do',
    Algeria: 'dz',
    Ecuador: 'ec',
    Estonia: 'ee',
    Egypt: 'eg',
    ' Western Sahara*': 'eh',
    Eritrea: 'er',
    Spain: 'es',
    Ethiopia: 'et',
    Finland: 'fi',
    Fiji: 'fj',
    ' Falkland Islands (the) [Malvinas] ': 'fk',
    'Micronesia (Federated States of) ': 'fm',
    ' Faroe Islands (the)  ': 'fo',
    France: 'fr',
    Gabon: 'ga',
    'United Kingdom of Great Britain and Northern Ireland (the)': 'gb',

    Grenada: 'gd',
    Georgia: 'ge',
    ' French Guiana': 'gf',
    Guernsey: 'gg',
    Ghana: 'gh',
    Gibraltar: 'gi',
    Greenland: 'gl',
    ' Gambia (the)': 'gm',
    Guinea: 'gn',
    Guadeloupe: 'gp',
    ' Equatorial Guinea ': 'gq',
    Greece: 'gr',
    'South Georgia and the South Sandwich Islands ': 'gs',
    Guatemala: 'gt',
    Guam: 'gu',
    ' Guinea-Bissau': 'gw',
    Guyana: 'gy',
    '  Hong Kong': 'hk',
    'Heard Island and McDonald Islands ': 'hm',
    Honduras: 'hn',
    Croatia: 'hr',
    Haiti: 'ht',
    Hungary: 'hu',
    Indonesia: 'id',
    Ireland: 'ie',
    Israel: 'il',
    ' Isle of Man': 'im',
    India: 'in',
    ' British Indian Ocean Territory (the) ': 'io',
    Iraq: 'iq',
    ' Iran (Islamic Republic of)': 'ir',
    Iceland: 'is',
    Italy: 'it',
    Jersey: 'je',
    Jamaica: 'jm',
    Jordan: 'jo',
    Japan: 'jp',
    Kenya: 'ke',
    Kyrgyzstan: 'kg',
    Cambodia: 'kh',
    Kiribati: 'ki',
    ' Comoros (the)': 'km',
    'Saint Kitts and Nevis': 'kn',
    ' Korea (the Democratic Peoples Republic of)': 'kp',
    ' Korea (the Republic of) ': 'kr',
    Kuwait: 'kw',
    ' Cayman Islands (the) ': 'ky',
    Kazakhstan: 'kz',
    ' Lao Peoples Democratic Republic (the) ': 'la',
    Lebanon: 'lb',
    ' Saint Lucia': 'lc',
    Liechtenstein: 'li',
    ' Sri Lanka ': 'lk',
    Liberia: 'lr',
    Lesotho: 'ls',
    Lithuania: 'lt',
    Luxembourg: 'lu',
    Latvia: 'lv',
    Libya: 'ly',
    Morocco: 'ma',
    Monaco: 'mc',
    ' Moldova (the Republic of) ': 'md',
    Montenegro: 'me',
    ' Saint Martin (French part)': 'mf',
    Madagascar: 'mg',
    ' Marshall Islands (the)': 'mh',
    ' North Macedonia ': 'mk',
    Mali: 'ml',
    Myanmar: 'mm',
    Mongolia: 'mn',
    Macao: 'mo',
    ' Northern Mariana Islands (the)': 'mp',
    Martinique: 'mq',
    Mauritania: 'mr',
    Montserrat: 'ms',
    Malta: 'mt',
    Mauritius: 'mu',
    Maldives: 'mv',
    Malawi: 'mw',
    Mexico: 'mx',
    Malaysia: 'my',
    Mozambique: 'mz',
    Namibia: 'na',
    ' New Caledonia': 'nc',
    ' Niger (the)': 'ne',
    '  Norfolk Island': 'nf',
    Nigeria: 'ng',
    Nicaragua: 'ni',
    ' Netherlands (the)': 'nl',
    Norway: 'no',
    Nepal: 'np',
    Nauru: 'nr',
    Niue: 'nu',
    '  New Zealand': 'nz',
    Oman: 'om',
    Panama: 'pa',
    Peru: 'pe',
    '    French Polynesia ': 'pf',
    'Papua New Guinea ': 'pg',
    '  Philippines (the)': 'ph',
    Pakistan: 'pk',
    Poland: 'pl',
    ' Saint Pierre and Miquelon': 'pm',
    Pitcairn: 'pn',
    'Puerto Rico': 'pr',
    ' Palestine, State of ': 'ps',
    Portugal: 'pt',
    Palau: 'pw',
    Paraguay: 'py',
    Qatar: 'qa',
    Réunion: 're',
    Romania: 'ro',
    Serbia: 'rs',
    ' Russian Federation (the)': 'ru',
    Rwanda: 'rw',
    ' Saudi Arabia': 'sa',
    ' Solomon Islands': 'sb',
    Seychelles: 'sc',
    ' Sudan (the)': 'sd',
    Sweden: 'se',
    Singapore: 'sg',
    ' Saint Helena, Ascension and Tristan da Cunha ': 'sh',
    Slovenia: 'si',
    '  Svalbard and Jan Mayen': 'sj',
    Slovakia: 'sk',
    '  Sierra Leone': 'sl',
    'San Marino': 'sm',
    Senegal: 'sn',
    Somalia: 'so',
    Suriname: 'sr',
    ' South Sudan': 'ss',
    ' Sao Tome and Principe': 'st',
    ' El Salvador ': 'sv',
    ' Sint Maarten (Dutch part) ': 'sx',
    ' Syrian Arab Republic (the)': 'sy',
    Eswatini: 'sz',
    ' Turks and Caicos Islands (the)': 'tc',
    Chad: 'td',
    ' French Southern Territories (the)': 'tf',
    Togo: 'tg',
    Thailand: 'th',
    Tajikistan: 'tj',
    Tokelau: 'tk',
    ' Timor-Leste': 'tl',
    Turkmenistan: 'tm',
    Tunisia: 'tn',
    Tonga: 'to',
    Turkey: 'tr',
    ' Trinidad and Tobago ': 'tt',
    Tuvalu: 'tv',
    ' Taiwan (Province of China)': 'tw',
    ' Tanzania, the United Republic of ': 'tz',
    Ukraine: 'ua',
    Uganda: 'ug',
    'United States Minor Outlying Islands (the) ': 'um',
    ' United States of America (the)': 'us',
    Uruguay: 'uy',
    Uzbekistan: 'uz',
    '  Holy See (the) ': 'va',
    ' Saint Vincent and the Grenadines': 'vc',
    'Venezuela (Bolivarian Republic of) ': 've',
    ' Virgin Islands (British) ': 'vg',
    ' Virgin Islands (U.S.)  ': 'vi',
    '  Viet Nam ': 'vn',
    Vanuatu: 'vu',
    '  Wallis and Futuna ': 'wf',
    Samoa: 'ws',
    Yemen: 'ye',
    Mayotte: 'yt',
    '   South Africa': 'za',
    Zambia: 'zm',
    Zimbabwe: 'zw',
  };
  constructor(private http: HttpClient,private route: ActivatedRoute,private router: Router,) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }
  ngOnInit(): void {
    this.getTaxdeclaration();
  }
  
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
    }
  }

  getTaxdeclaration() {
    var url = `${environment.apiConfig.uri}/admin/taxDecleration/PendingsRequest`;
    this.http.get(url).subscribe(async (response: any) => {

      try {
        this.taxDecData = response.data;
        this.taxdatalength = this.taxDecData.length;
        this.loadingtaxfiles = false;
      } catch (err) {
        console.log('error exist in tfn');
      }
    });
  }
  viewCandidateFile(data: any, index: any) {
    this.candidateId = data.candidateId;
    var url = `${environment.apiConfig.uri}/admin/taxDecleration/${this.candidateId}`;
    this.http.get(url).subscribe(async (response: any) => {
      try {
        this.viewFormData = this.taxDecData[index];
        this.viewTaxFileTable = true;
        const submissionTimeUTC = new Date(this.viewFormData.submissionTime);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const submissionTimeLocal4 = submissionTimeUTC.toLocaleString('en-US', {
          timeZone,
        });
        this.viewFormData.submissionTime = submissionTimeLocal4;
      } catch (err) {}
    });
  }
  submitOnAstutue(data: any) {
    this.candidateId = data.candidateId;
    window.open(
      `https://agrilabour.astutepayroll.com/user/edit?UID=${data.mid}`
    );
    var url = `${environment.apiConfig.uri}/admin/taxDecleration/ChangeSubmissioinStatus/${data.id}`;
    return this.http.post(url, {}).subscribe((response: any) => {
      this.getTaxdeclaration();
    });
  }
  changeStaus(item: any, a: number) {
    this.candidateId=item.candidateId;
    this.fileid = item.id;
    this.changeStatusMessage = a == 0 ? 'Rejected!' : 'Approved!';
    a == 0 ? item.submissionStatus == true : false;
    this.filestatus = a;
    var url = `${environment.apiConfig.uri}/admin/taxDecleration/ChangeStatus/${this.fileid}?status=${this.filestatus}`;
    this.http.post(url, {}).subscribe((res) => {
      this.TFNStatus(); 
      if (a == 2) {
        this.taxDecData.splice(item, 1);
      } else {
      }
    });    
  }

  TFNStatus(){
    var url2 = `${environment.apiConfig.uri}/admin/Candidates/TFNStatus/${this.candidateId}`;
    this.http.post(url2, {}).subscribe(async (response: any) => {
      this.CandidateStatusUpdate();
      this.getTaxdeclaration();
    });

  }

  CandidateStatusUpdate() {
    var postURL = `${environment.apiConfig.uri}/admin/Candidates/CandidateStatusUpdate/${this.candidateId}`;
    this.http.post(postURL, {}).subscribe(async (response: any) => {
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './candidate/profile/profile.component';
import { FailedComponent } from './failed/failed.component';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobsComponent } from './candidate/jobs/jobs.component';
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HistoryComponent } from './candidate/history/history.component';
import { PersonalDocumentsComponent } from './candidate/personal-documents/personal-documents.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { CandidateSearchComponent } from './admin/candidate-search/candidate-search.component';
import { TableComponent } from './components/table/table.component';
import { CandidateDetailsComponent } from './admin/candidate/candidate-details/candidate-details.component';
import { CandidateJobsComponent } from './admin/candidate/candidate-jobs/candidate-jobs.component';
import { CandidateHistoryComponent } from './admin/candidate/candidate-history/candidate-history.component';
import { CandidateDocumentsComponent } from './admin/candidate/candidate-documents/candidate-documents.component';
import { TrainingFilesComponent } from './admin/training-files/training-files.component';
import { TrainingAssessmentComponent } from './admin/training-assessment/training-assessment.component';
import { CandidateModalComponent } from './admin/candidate/candidate-modal/candidate-modal.component';
import { CandidateTrainingAssessmentComponent } from './candidate/candidate-training-assessment/candidate-training-assessment.component';
import { StatusIconsComponent } from './components/status-icons/status-icons.component';
import { FileViewerComponent } from './admin/file-viewer/file-viewer.component';
import { CandidateTrainingComponent } from './admin/candidate/candidate-training/candidate-training.component';
import { ReportProblemComponent } from './components/report-problem/report-problem.component';
import { CookieService } from 'ng2-cookies';
import { TaxDeclarationComponent } from './candidate/tax-declaration/tax-declaration.component';
import { PaySlipsComponent } from './candidate/pay-slips/pay-slips.component';
import { TaxDeclarationsDetailsComponent } from './admin/tax-declarations-details/tax-declarations-details.component';
import { TaxSuperComponent } from './admin/candidate/tax-super/tax-super.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SuperannuationComponent } from './candidate/superannuation/superannuation.component';
import { BankAccountComponent } from './candidate/bank-account/bank-account.component';
import { CandidateStatusComponent } from './admin/candidate/candidate-status/candidate-status.component';
import { DeductionsComponent } from './candidate/deductions/deductions.component';
import { CandidateDeductionsComponent } from './admin/candidate/candidate-deductions/candidate-deductions.component';
import { CandidateTaxDeclarationComponent } from './admin/candidate/candidate-tax-declaration/candidate-tax-declaration.component';
import { CandidateSuperannuationComponent } from './admin/candidate/candidate-superannuation/candidate-superannuation.component';
import { BankDetailsComponent } from './admin/candidate/bank-details/bank-details.component';
import { PayrollHistoryComponent } from './candidate/payroll-history/payroll-history.component';
import { CandidatePayrollHistoryComponent } from './admin/candidate/candidate-payroll-history/candidate-payroll-history.component';
import { AddDeductionsComponent } from './admin/add-deductions/add-deductions.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PendingDeductionsComponent } from './admin/pending-deductions/pending-deductions.component';
import { SubmitDeductionsComponent } from './admin/submit-deductions/submit-deductions.component';
import { ChangeDeductionsComponent } from './admin/change-deductions/change-deductions.component';
import { CreateWorkflowComponent } from './admin/create-workflow/create-workflow.component';
import { WorkflowActionsComponent } from './admin/workflow-actions/workflow-actions.component';
import { SearchIconComponent } from './components/search-icon/search-icon.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { AccommodationComponent } from './admin/accommodation/accommodation.component';
import { VehiclesComponent } from './admin/vehicles/vehicles.component';
import { LogisticsComponent } from './admin/logistics/logistics.component';
import { isAdminFlow } from './services/url-utils';
import { AdminComponent } from './admin/admin/admin.component';
import { JobMatchComponent } from './admin/job-match/job-match.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {

}
export function MSALInstanceFactory(): IPublicClientApplication {
  const adminFlow = isAdminFlow();
  if (adminFlow) {
    return new PublicClientApplication({
      auth: {
        clientId: environment.entraId.clientId,
        authority: environment.entraId.authority,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        knownAuthorities: [environment.entraId.authorityDomain],
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        }
      },
    });
  } else {
    return new PublicClientApplication({
      auth: {
        clientId: environment.b2cPolicies.clientId,
        authority: environment.b2cPolicies.authorities.SignIn.authority,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        knownAuthorities: [environment.b2cPolicies.authorityDomain],
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        }
      },
    });
  }
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const adminFlow = isAdminFlow();
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set(environment.apiConfig.uri, adminFlow?environment.entraId.scopes:environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const adminFlow = isAdminFlow();
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: adminFlow?environment.entraId.scopes:environment.apiConfig.scopes,
    },
    loginFailedRoute: 'login-failed',
  };
}

@NgModule({
  declarations: [
    ProfileComponent,
    JobsComponent,
    AppComponent,
    HomeComponent,
    FailedComponent,
    LoginRedirectComponent,
    HistoryComponent,
    CandidateTrainingAssessmentComponent,
    PersonalDocumentsComponent,
    SidebarMenuComponent,
    HeaderComponent,
    CandidateSearchComponent,
    TableComponent,
    CandidateDetailsComponent,
    CandidateJobsComponent,
    CandidateHistoryComponent,
    CandidateDocumentsComponent,
    TrainingFilesComponent,
    TrainingAssessmentComponent,
    CandidateModalComponent,
    CandidateTrainingAssessmentComponent,
    StatusIconsComponent,
    FileViewerComponent,
    CandidateTrainingComponent,
    ReportProblemComponent,
    TaxDeclarationComponent,
    PaySlipsComponent,
    TaxDeclarationsDetailsComponent,
    TaxSuperComponent,
    SuperannuationComponent,
    BankAccountComponent,
    CandidateStatusComponent,
    DeductionsComponent,
    CandidateDeductionsComponent,
    CandidateTaxDeclarationComponent,
    CandidateSuperannuationComponent,
    BankDetailsComponent,
    PayrollHistoryComponent,
    CandidatePayrollHistoryComponent,
    AddDeductionsComponent,
    PendingDeductionsComponent,
    SubmitDeductionsComponent,
    ChangeDeductionsComponent,
    CreateWorkflowComponent,
    WorkflowActionsComponent,
    SearchIconComponent,
    ConfirmationModalComponent,
    AccommodationComponent,
    VehiclesComponent,
    LogisticsComponent,
    AdminComponent,
    JobMatchComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgbPaginationModule,
    NgSelectModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthService,
    AuthGuardService,
    CookieService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }

<div class="card mt-4">
    <div class=" card-body">
        <h4>Candidate status </h4>
      <div *ngIf="displayLoader" style="width: 55% !important;">
        <li class="t_loader">
          <div class="card__desc load" id="long"></div>
        </li>
        <li class="t_loader">
          <div class="card__desc load"></div>
        </li>
        <li class="t_loader">
          <div class="card__desc load" id="long"></div>
        </li>
        <li class="t_loader">
          <div class="card__desc load"></div>
        </li>
        <li class="t_loader">
          <div class="card__desc load" id="long"></div>
        </li>
      </div>        
        <div *ngIf="!displayLoader" class="row">
            <table class=" col-6  table table-hover table-borderless table-hover">
                <thead>
                    <ng-container *ngIf="statusData.length==0; else dataBlock">
                        <tr>
                            <td colspan="3">No data</td>
                        </tr>
                    </ng-container>
                    <ng-template #dataBlock>                        
                        <tr *ngFor="let item of statusData">
                            <th >{{item.name}}</th>
                            <th >
                                <!-- Visual Prompt  -->                                
                                <app-status-icons [statusValue]="item.status"></app-status-icons>
                            </th>
                        </tr>
                    </ng-template>
                </thead>
            </table>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-deductions',
  templateUrl: './change-deductions.component.html',
  styleUrls: ['./change-deductions.component.css']
})

export class ChangeDeductionsComponent implements OnInit {
  loadingDeductions:boolean=true;
  selected:string='change-deductions'
  mobileView: boolean = false;
  message:string='';
  unlimited:any
  fixed_amount:any;
  loading:boolean=false;
  toCompleteData:any;
  note:string='';
  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    this.getChangedDeduction();
  }

  getChangedDeduction(){
  const url=`${environment.apiConfig.uri}/admin/Candidates/payouts`;
  this.loadingDeductions = true;
  this.http.get(url).subscribe(
    (response: any) => {
      this.unlimited = response.data.unlimited.filter((item: { status: number; }) => item.status === 1);
      this.fixed_amount = response.data.fixed_amount.filter((item: { status: number; }) => item.status === 1);
      this.loadingDeductions = false;
    },
    (error) => {
      console.error("Error fetching deductions:", error);
      this.loadingDeductions = false;
    })
  }

  completeDeduction(item:any){
    this.loading=false;
    this.message='';
    this.toCompleteData=item;
  }
  confirmSubmit(note:any){
    this.message='';
    this.loading=true;
    const pdid=this.toCompleteData.pdid;
    const uid=this.toCompleteData.uid;
    const id=this.toCompleteData.id;
    const requestUrl=`${environment.apiConfig.uri}/admin/Candidates/CompleteDeductionsPayout?pdid=${pdid}&uid=${uid}&id=${id}&note=${note}`
    this.http.post(requestUrl,'').subscribe((res:any)=>{
      try {
        this.getChangedDeduction();
        this.message=res.message;
        this.note=''
        this.loading=false;       
      } catch (error:any) {
        this.message="An unexpected error occurred. Please try again later.";
        this.loading=false;
      }
    })

  }

}

<div *ngIf="isPortalAdmin; else accessDenied" class="row mt-5">
    <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
      <div class="card">
        <div class="card-body">
          <!-- Candidate Files -->
          <app-table [tableHeader]="[
              'Display',
              'New Value',
              'Old Value',
              'Modifying Person',
              'Modified (UTC)'
            ]" [tableRows]="[
              'columnName',
              'newValue',
              'oldValue',
              'modifyingPerson',
              'dateAdded'
            ]" [data]="history" [pageSizeOptions]="pageSizeOptions" [collectionSize]="collectionSize" [model]="model"
            [refreshData]="refreshHistory" [loading]="loading" [title]="'Payroll History'"></app-table>
        </div>
      </div>
    </div>
  </div>
  <ng-template #accessDenied>
    <div class="access-denied-container">
        <h2 class="access-denied-message">Access Denied</h2>
    </div>
  </ng-template>
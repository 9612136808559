import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { WorkflowAction } from 'src/app/models/WorkflowActions';
import { GetAdminsService } from 'src/app/services/getAdmins.service';

interface Workflow {
  id: number;
  workflowId: string;
  createdAT: string;
  workflowTitle: string;
  triggerId: number;
  triggerName: string;
  triggerEntityId: number;
  entityName: string;
  departmentName: string;
  stepId: number;
  stepNumber: number;
  title: string;
  fileId?: number | null;
  fileName?: string | null;
  status: string;
  dateCreated: string;
  dueDate?: string | null;
  assignTo?: string | null;
}

interface GroupedWorkflow {
  isOpen: boolean;
  data: Workflow[];
  entityName?: string;
  workflowTitle:string;
  triggerEntityId?:number
}

@Component({
  selector: 'app-workflow-actions',
  templateUrl: './workflow-actions.component.html',
  styleUrls: ['./workflow-actions.component.css']
})
export class WorkflowActionsComponent implements OnInit {
  loadingWorkflow:boolean=true;
  selected:string='workflow-actions'
  mobileView: boolean = false;
  loading: boolean = false;
  status:string[]=['New', 'In Progress', 'Complete', 'Cancelled'];
  sorting: boolean = false;
  // workFlowsData: WorkflowAction[] = [];
  // transformedData: WorkflowAction[] = [];
  // Search filter properties
  searchWorkflowTitle: string = '';
  searchTriggerName: string = '';
  searchTriggerEntityId: string = '';
  searchStepTitle: string = '';
  searchDepartmentName: string = '';
  searchStepStatus: string = '';
  searchEntityName: string = '';
  currentSortColumn: string = '';
  sortDirection: boolean = true; // true for ascending, false for descending
  file:any;
  successMessage:string='';
  workflowToDelete: WorkflowAction | null = null;
  isModalVisible: boolean = false;
  admins:string[]=[];
  groupedWorkflows: { [key: string]: GroupedWorkflow } = {};
  isAscending: boolean = true;  
  sortProperty: string = '';
  areAdditionalHeadingsVisible: boolean = false;
  ascending: boolean = true;
  sortOrder: 'asc' | 'desc' = 'asc';

  constructor(private http: HttpClient,private cdr: ChangeDetectorRef, private admin:GetAdminsService) {
   }

  ngOnInit(): void {
    this.fetchAdmins()
    this.getAllWorkflows()
  }

  fetchAdmins(){
    this.admin.getAdmins().subscribe(
      (res: any) => {
        this.admins = res;
      },
      (error) => {
        console.error('Failed to determine admin status', error);
      }
    );
  }

  getAllWorkflows() {
    this.loadingWorkflow = true;
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetAllWorkflowActions`;
    this.http.get(url).subscribe(
      (response: any) => {
        if (response.isSuccess) {
          const transformedData = response.data.map((workflow: any) => ({
            ...workflow,
            createdAT: this.convertToLocalTime(workflow.createdAT),
            dueDate: workflow.dueDate ? this.formatDate(workflow.dueDate) : null,
          }));
          this.groupedWorkflows = this.groupDataByTriggerEntityId(transformedData);
        } else {
          console.error('Failed to fetch workflows:', response.message);
          this.groupedWorkflows = {}; // Reset to empty object on failure
        }
        this.loadingWorkflow = false;
      },
      (error) => {
        console.error('Error fetching workflows:', error);
        this.groupedWorkflows = {}; // Reset to empty object on error
        this.loadingWorkflow = false;
      }
    );
  }

  groupDataByTriggerEntityId(workflows: Workflow[]): { [key: string]: GroupedWorkflow } {
    return workflows.reduce((groups: { [key: string]: GroupedWorkflow }, workflow: Workflow) => {
      const groupKey = workflow.triggerEntityId;
      // If group doesn't exist, create it
      if (!groups[groupKey]) {
        groups[groupKey] = {
          isOpen: false,
          data: [],
          entityName: workflow.entityName, // Store the entityName at the parent level
          workflowTitle: workflow.workflowTitle, // Store the workflowTitle at the parent level
          triggerEntityId:workflow.triggerEntityId
        };
      }
      
      // Add workflow to the group's data array
      groups[groupKey].data.push(workflow);
      return groups;
    }, {});
  }

  // Method to get the keys of groupedWorkflows
  getGroupKeys(): string[] {
    return Object.keys(this.groupedWorkflows);  // Return the keys as an array
  }

toggleGroup(groupKey: string): void {
  const group = this.groupedWorkflows[groupKey];
  group.isOpen = !group.isOpen;

  // Show additional headings if any group is open
  this.areAdditionalHeadingsVisible = Object.values(this.groupedWorkflows).some(group => group.isOpen);
}

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  private convertToLocalTime(utcDate: string): string | Date {
    const localDate = new Date(`${utcDate}Z`);
    return localDate;
  }

  deleteworkflowAction(workflow:any){
    this.workflowToDelete = workflow;
    this.isModalVisible = true;
    this.successMessage='';
    this.cdr.detectChanges();
  }

  confirmDelete() {
    if (this.workflowToDelete) {
      this.loading = true;
      const { id, stepId } = this.workflowToDelete;
      const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteWorkFlowAction?Id=${id}&stepId=${stepId}`;
      this.http.delete(url).subscribe(
        (res: any) => {
          // Iterate over groupedWorkflows and remove the deleted workflow
          Object.keys(this.groupedWorkflows).forEach((key) => {
            const group = this.groupedWorkflows[key];
            group.data = group.data.filter(workflow => !(workflow.id === id && workflow.stepId === stepId));
            
            // Optionally remove empty groups if needed
            if (group.data.length === 0) {
              delete this.groupedWorkflows[key];
            }
          });
  
          this.successMessage = 'Workflow Action Deleted Successfully.';
          this.loading = false;
          this.isModalVisible = true;
        },
        (error) => {
          console.error('Error deleting workflow action:', error);
          this.loading = false;
          this.isModalVisible = true;
        }
      );
    }
  }

  onCancel() {
    this.isModalVisible = false;
  }

  onClose() {
    this.isModalVisible = false;
  }

  getFileStream(item: any, view: boolean = false) {
    const url = `${environment.apiConfig.uri}/admin/File/GetWorkFlowFileStream/${item.fileId}`;
    this.http.get(url).subscribe(
      (response: any) => {
        this.file = response.data || '';
        item.fileStream = this.file;
        if (view) {
          this.onClickView(item);
        } else {
          this.onClickDownload(item);
        }
      },
      (error) => {
        console.error('Error fetching file stream:', error);
      }
    );
}

onClickDownload(item: any) {
    if (!item.fileStream) {
        this.getFileStream(item);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
        const link = document.createElement('a');
        link.download = `${item.fileName}`;
        const source = `data:${this.getMimeType(item.fileName)};base64,${item.fileStream}`;
        link.href = source;
        link.click();
    }
}

onClickView(item: any) {
  if (!item.fileStream) {
      this.getFileStream(item, true);
  } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const mimeType = this.getMimeType(item.fileName);
      if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          this.onClickDownload(item);
      } else {
          const blob = this.dataURItoBlob(item);
          const file = new File([blob], item.fileName, {
              type: mimeType,
          });
          const url = window.URL.createObjectURL(file);
          window.open(url);
      }
  }
}

dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
        type: this.getMimeType(item.fileName),
    });
    return blob;
}

getMimeType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'pdf': return 'application/pdf';
        case 'jpg': 
        case 'jpeg': return 'image/jpeg';
        case 'png': return 'image/png';
        case 'gif': return 'image/gif';
        case 'mp4': return 'video/mp4';
        case 'mov': return 'video/quicktime';
        case 'avi': return 'video/x-msvideo';
        case 'mp3': return 'audio/mpeg';
        case 'webp': return 'image/webp';
        case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        default: return 'application/octet-stream';
    }
}

  // applyFilter() {
  //   this.workFlowsData = this.transformedData.filter((item: { workflowTitle: string; entityName: string; triggerEntityId: { toString: () => string | string[]; }; title: string; departmentName: string; status: string; }) => {
  //     return (!this.searchWorkflowTitle || (item.workflowTitle && item.workflowTitle.toLowerCase().includes(this.searchWorkflowTitle.toLowerCase()))) &&
  //          (!this.searchEntityName || (item.entityName && item.entityName.toLowerCase().includes(this.searchEntityName.toLowerCase()))) &&
  //          (!this.searchTriggerEntityId || item.triggerEntityId.toString().includes(this.searchTriggerEntityId)) &&
  //          (!this.searchStepTitle || (item.title && item.title.toLowerCase().includes(this.searchStepTitle.toLowerCase()))) &&
  //          (!this.searchDepartmentName || (item.departmentName && item.departmentName.toLowerCase().includes(this.searchDepartmentName.toLowerCase()))) &&
  //          (!this.searchStepStatus || (item.status && item.status.toLowerCase().includes(this.searchStepStatus.toLowerCase())));;
  //   });
  // }

  updateStep(id: number, status: string, dueDate: string, assignTo:string) {
    let url = `${environment.apiConfig.uri}/admin/Candidates/UpdateStep?id=${id}&status=${encodeURIComponent(status)}&dueDate=${dueDate}&assignTo=${encodeURIComponent(assignTo)}`;
    this.http.post(url, {}).subscribe(response => {
    }, error => {
      console.error('Error updating step', error);
    });
  }

  onAssignTo(item:any){
    this.updateStep(item.id, item.status?item.status:'', item.dueDate?item.dueDate:'', item.assignTo?item.assignTo:'');
  }

  onDueDateChange(item: any) {
    this.updateStep(item.id, item.status?item.status:'', item.dueDate?item.dueDate:'', item.assignTo?item.assignTo:'');
  }

  onStatusChange(item: any) {
    this.updateStep(item.id, item.status?item.status:'', item.dueDate?item.dueDate:'', item.assignTo?item.assignTo:'');
  }

  onKeyDown(event: KeyboardEvent) {
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].indexOf(event.key) !== -1) {
      event.preventDefault();
    }
  }
 
  sort(property: 'entityName' | 'workflowTitle' ): void {
    const groupedWorkflowsArray = Object.values(this.groupedWorkflows); // Convert object to array
    groupedWorkflowsArray.sort((a, b) => {
      const valueA = a[property]?.trim().toLowerCase() || '';
      const valueB = b[property]?.trim().toLowerCase() || '';
      if (valueA < valueB) return this.sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return this.sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    // Toggle sort order
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    // Recreate the object if needed
    this.groupedWorkflows = groupedWorkflowsArray.reduce((acc, item) => {
      const uniqueKey = `${item.triggerEntityId}-${item.workflowTitle}`;
      acc[uniqueKey] = item;
      return acc;
    }, {} as { [key: string]: GroupedWorkflow });
  }
  
}

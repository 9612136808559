  export const environment = {
    production: false,
    storageUrl: 'https://agrilabourdev.blob.core.windows.net',
    SASToken: '?sp=racwdli&st=2022-07-13T08:19:47Z&se=2099-07-13T16:19:47Z&sv=2021-06-08&sr=c&sig=8ylwM%2BB0MTLul1pjkzzqq1VaNNXmYPnCn6QSYQUJdoI%3D',
    // SASToken: '?sv=2021-06-08&ss=b&srt=co&sp=rli&se=2099-06-30T15:16:25Z&st=2022-06-30T07:16:25Z&spr=https,http&sig=yMteinlLkgPOmsC%2FyYH7cy55lxRN0eBvU0YrzLwv8VY%3D',
    b2cPolicies: {
    clientId: 'c5456b67-ca5a-4a6a-aec9-068d75d82eb7',
      names: {
        SignIn: "B2C_1_agrilabour-signin",
      }, 
      authorities: {
          SignIn: {
              authority: "https://alab2c.b2clogin.com/alab2c.onmicrosoft.com/B2C_1_agrilabour-signin",
          },
          ResetPassword: {
              authority: "https://alab2c.b2clogin.com/alab2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ForgotPassword&client_id=c5456b67-ca5a-4a6a-aec9-068d75d82eb7&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fclient-portal-dev.azurewebsites.net%2F&scope=openid%20https%3A%2F%2Falab2c.onmicrosoft.com%2Fc5456b67-ca5a-4a6a-aec9-068d75d82eb7%2FFiles.Write%20https%3A%2F%2Falab2c.onmicrosoft.com%2Fc5456b67-ca5a-4a6a-aec9-068d75d82eb7%2FFiles.Read&response_type=id_token%20token&prompt=login"
          }
      },
      authorityDomain: "alab2c.b2clogin.com"
    },

    apiConfig :{
      scopes: ['https://alab2c.onmicrosoft.com/c5456b67-ca5a-4a6a-aec9-068d75d82eb7/Files.Read'],
      uri: 'https://client-portal-backend-dev.azurewebsites.net',
    },
    
    entraId: {
      redirectUri: 'https://staff.ala-dev.com/',
      scopes: [ 'api://0c48c350-d946-483f-beca-b3729aa7cfb1/Files.write',
                'api://0c48c350-d946-483f-beca-b3729aa7cfb1/Files.read'],
      tenantId: '8cb6eb4c-d35a-4da1-8ef5-3230109990f7',
      clientId: '0c48c350-d946-483f-beca-b3729aa7cfb1',
      authority: 'https://login.microsoftonline.com/8cb6eb4c-d35a-4da1-8ef5-3230109990f7',
      authorityDomain: 'agrilabour.com.au'
    }

  }

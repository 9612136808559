<!-- header area start -->
<div class="header-area" style="padding-bottom: 5px !important;">
    <div class="row align-items-center">
        <!-- nav and search button -->
        <div class="col-8">
            <div class="nav-btn pull-left" (click)="toggleSidebar()" style="margin-top: -8px !important;">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="col-4 logout-div" style="margin-top: 0;">
            <ul class="desktopView notification-area pull-right">
                <li>
                    <a *ngIf="loginDisplay" class=" dropdown-item" (click)="logout()"> <i class="fa fa-sign-out"></i>
                        Log Out </a>
                </li>
            </ul>
        </div>
    </div>
</div>
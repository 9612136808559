<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }">
  <app-sidebar-menu [selected]="selected" [id]="id" [trainingcompleted]="trainingcompleted"></app-sidebar-menu>
  <!-- main content area start -->
  <div class="main-content">
    <app-header></app-header>
    <div class="main-content-inner">
      <!-- market value area start -->
      <div class="row mt-4">
        <div class="card-body" style="background: white;border:1px solid #d9d9d9; border-radius:5px; height: auto">
          <!------------ Loader ------------>
          <div *ngIf="loader">
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
          </div>
          <!------------ End Loader ------------>
          <div *ngIf="!loader" style="max-height: 100%;overflow-y:auto !important;">
            <!-- Display Quizes list  Desktop View-->
            <div *ngIf="!showQuiz">
              <table class=" desktopView table mb-2">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Training Description</th>
                    <th scope="col">Status</th>
                    <th scope="col">Attempted/Total</th>
                    <th scope="col">Correct</th>
                    <th scope="col">Completed At</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody *ngIf="quizList.length > 0">
                  <tr *ngFor="let item of quizList; index as i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.fileName }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.atttemptedQuestionCount }}/{{ item.questionCount }}</td>
                    <td>{{ item.rightQuestions }}</td>
                    <td>{{item.completedAt?(item.completedAt | date:
                      'dd.MM.yyyy'):'-'}}</td>
                    <td>
                      <button
                        *ngIf="item.atttemptedQuestionCount >= item.questionCount && item.obtainedMarks < 80; else attempt"
                        (click)="retakeQuiz(item)" class="btn btn-sm btn-primary">Retake</button>
                      <ng-template #attempt>
                        <button class="btn btn-sm btn-primary" (click)="attemptQuiz(item, false)" title="Attempt Quiz">
                          <span *ngIf="item.status == 'Complete'">View Training</span>
                          <span *ngIf="item.status != 'Complete'">Start Training</span>
                        </button>
                      </ng-template>
                    </td>
                    <td>
                      <app-status-icons [statusValue]="item.status"></app-status-icons>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="quizList.length == 0">
                  <tr>
                    <td colspan="6" class="text-center">No Quizzes</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--Quiz List Mobile View Start  -->
            <div class="table-responsive mobileView">
              <table class=" table mb-2" style="overflow-x:auto !important;">
                <thead style=" overflow: auto;">
                  <tr>
                    <th colspan="3">Training File</th>
                  </tr>
                </thead>
                <tbody *ngIf="quizList.length > 0" style="overflow-y:auto !important;">
                  <tr *ngFor="let item of quizList; index as i">
                    <td style="cursor:pointer;">
                      {{ item.fileName }}
                    </td>
                    <td>
                      <app-status-icons [statusValue]="item.status"></app-status-icons>
                    </td>
                    <td>
                      <button class="btn btn-sm btn-info filesForMobile" (click)="filesForMobile(i)" type="button"
                        data-toggle="modal" data-target="#mobileViewModal" data-backdrop="static"
                        data-keyboard="false">View</button>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="quizList.length == 0">
                  <tr>
                    <td colspan="6" class="text-center">No Quizzes</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End Display Quizes list -->
            <!-- Display Quiz for Desktop  -->
            <div class="desktopView" *ngIf="showQuiz">
              <div class="row">
                <div class="col-1">
                  <button class="btn btn-sm btn-info" (click)="showQuiz=false"
                    style="font-size:12px !important; margin-bottom: 2px;">Go
                    Back</button>
                </div>
                <div class="col-11">
                  <div style="margin:0 auto; width: auto;">
                    <video *ngIf=check controls controlsList="nodownload"
                      style="border: 1px solid gray; border-radius: 8px; width: 100%; height: 50vh;">
                      <source [src]="blobURL" type="video/mp4" />
                    </video>
                    <div *ngIf=!check>
                      <iframe [src]="blobURL" frameborder="0"
                        style="border: 1px solid gray; border-radius: 8px; width: 100%; height: 60vh;"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row mt-2">
                <div class="col-md-2 col-sm-12 mt-1 py-2 pl-md-4 pl-sm-0">
                  <div class="Qdiv">
                    <table class="QTable QTable-lg">
                      <thead>
                        <tr>
                          <th>Q No.</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of questionList; index as i" class="q_row" (click)="switchQuestion(i)">
                          <td id="q_{{i}}">
                            <span
                              [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">Q
                              {{ i+1 }}</span>
                          </td>
                          <td style="font-size:16.5px;text-align:center;">
                            <span *ngIf="item.questions.isAttempted; else notAttempted">
                              <span *ngIf="item.questions.selectedOptionId == item.questions.rightOptionId"
                                [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">&#10003;</span>
                              <span *ngIf="item.questions.selectedOptionId != item.questions.rightOptionId"
                                [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">&#10540;</span>
                            </span>
                            <ng-template #notAttempted>
                              <span
                                [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">&#63;</span>
                            </ng-template>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <table class="QTable QTable-sm">
                    <tbody>
                      <tr>
                        <td><b>Q No.</b></td>
                        <td *ngFor="let item of questionList; index as i" style="text-align: center;">Q {{ i+1 }}</td>
                      </tr>
                      <tr>
                        <td><b>Status</b></td>
                        <td *ngFor="let item of questionList" style="font-size:16.5px;text-align:center;">
                          <span *ngIf="item.questions.isAttempted; else notAttempted">
                            <span *ngIf="item.questions.selectedOptionId == item.questions.rightOptionId"
                              [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">&#10003;</span>
                            <span *ngIf="item.questions.selectedOptionId != item.questions.rightOptionId"
                              [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">&#10540;</span>
                          </span>
                          <ng-template #notAttempted>
                            <span
                              [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">&#63;</span>
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-10 col-sm-12 pl-3 pt-3 pl-md-5">
                  <h6>
                    {{ currentQuestion.questions.statement }}
                    <span class="pt-3 pl-4 text-danger text-italic" style="font-size: 13px !important;">{{errorMessage}}
                    </span>
                  </h6>

                  <form #saveOpt="ngForm" (ngSubmit)="submitAnswer(saveOpt)">
                    <div class="pb-4" *ngIf="currentQuestion.questions.isAttempted">
                      <div class="py-2" *ngFor="let item of currentQuestion.options; index as i">
                        <input *ngIf="item.option && i<4" disabled type="radio"
                          [checked]="item.id==currentQuestion.questions.selectedOptionId">
                        <span *ngIf="item.option && i<4" class="pl-1 pr-2 ml-2 option"
                          [ngStyle]="{'font-weight': currentQuestion.questions.rightOptionId==item.id ? 'bold': 'initial'}">{{
                          item.option }}</span>
                      </div>
                    </div>

                    <div class="pb-4" *ngIf="!currentQuestion.questions.isAttempted">
                      <div class="py-2" *ngFor="let item of currentQuestion.options;  index as i">
                        <input *ngIf="item.option && i<4" type="radio" name="radio" value="{{item.id}}" ngModel>
                        <span *ngIf="item.option && i<4" class="pl-2">{{ item.option }}</span>
                      </div>
                    </div>
                    <div>
                      <button *ngIf="!currentQuestion.questions.isAttempted"
                        [disabled]="disableSubmit ||currentQuestion.questions.isAttempted"
                        class="btn btn-sm btn-secondary" type="submit">Submit</button>
                      <span *ngIf="!exitButton ">
                        <input *ngIf="currentQuestion.questions.isAttempted" [disabled]="questionIndex == lastIndex"
                          class="btn btn-sm btn-primary" (click)="goNext()" value="Next" type="button">
                      </span>
                      <button *ngIf="exitButton " class="btn btn-sm btn-info" value="Exit" (click)="showQuiz=false"
                        style="margin-left: 5px;">Exit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- End - Display Quiz for Desktop -->
          </div>
        </div>
      </div>
      <!-- market value area end -->
    </div>
  </div>
  <!-- main content area end -->
</div>

<!-- MobileViewModal modal(Details about Quiz) start  -->
<div class="modal fade" id="mobileViewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog1  modal-lg modal-dialog-centered">
    <div class="modal-content modal-content1 ">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{ mobileViewQuizList.fileName }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">

          <table *ngIf="!mobileFileView" class="table table-striped table-bordered ">
            <tbody>
              <tr>
                <td>Training Description</td>
                <td> {{ mobileViewQuizList.fileName }}</td>
              </tr>
              <tr>
                <td>Quiz Questions</td>
                <td> {{ mobileViewQuizList.questionCount}}</td>
              </tr>
              <tr>
                <td>Attempted</td>
                <td> {{ mobileViewQuizList.atttemptedQuestionCount }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td> {{ mobileViewQuizList.status }}</td>
              </tr>
              <tr>
                <td>Correct</td>
                <td> {{ mobileViewQuizList.rightQuestions }}</td>
              </tr>
              <tr>
                <td>Training File </td>
                <td>
                  <div class="btn btn-info" target="_blank" (click)="viewQuizFile(mobileViewQuizList, true)">
                    <i class="mx-3" style="font-size: 18px;"> View </i>
                  </div>
                </td>
              </tr>
              <tr>
                <td>View Quiz </td>
                <td>
                  <button
                    *ngIf=" mobileViewQuizList.atttemptedQuestionCount >= mobileViewQuizList.questionCount && mobileViewQuizList.obtainedMarks < 80 ; else attempt "
                    (click)="retakeQuiz(mobileViewQuizList)" class=" btn btn-primary">Retake Quiz</button>
                  <ng-template #attempt>
                    <button class="btn btn-primary" (click)="attemptQuiz(mobileViewQuizList, true)" title="Attempt Quiz"
                      data-toggle="modal" data-target="#trainingFileView">
                      <span
                        *ngIf="mobileViewQuizList.atttemptedQuestionCount==mobileViewQuizList.questionCount ; else startQuiz">View
                        Quiz</span>
                      <ng-template #startQuiz>Start Quiz</ng-template>
                    </button>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="mobileFileView" style="margin:0 auto; width: auto;">
            <button class="btn btn-sm btn-info" (click)="mobileFileView=false"
              style="font-size:12px !important; margin-bottom: 2px;">Go
              Back</button>
            <video *ngIf=check controls controlsList="nodownload"
              style="border: 1px solid gray; border-radius: 8px; width: 100%; height: 70vh;">
              <source [src]="blobURL" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- mobileViewModal modal end  -->
<!--  Mobile View Quiz Start  -->
<div *ngIf="showQuiz" class="modal fade" id="trainingFileView" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog1 modal-lg  " role="document">
    <div class="modal-content modal-content1 ">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{mobileViewQuizList.fileName }}</h5>
        <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mobileView" *ngIf="showQuiz">

          <div *ngIf="!mobileLoader" class=" mt-2">
            <div class=" mb-2">
              <div *ngFor="let item of questionList,index as i" class="qButton">
                <!-- Buttons for question Number -->
                <div *ngIf="item.questions.isAttempted; else notAttempted">
                  <a class="btn btn-success border-secondary rounded " (click)="switchQuestion(i)"
                    *ngIf="item.questions.selectedOptionId == item.questions.rightOptionId"
                    [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">Q {{ i+1
                    }}</a>
                  <a class="btn btn-danger border-secondary rounded " (click)="switchQuestion(i)"
                    *ngIf="item.questions.selectedOptionId != item.questions.rightOptionId"
                    [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">Q {{ i+1
                    }}</a>
                </div>
                <div>
                  <ng-template #notAttempted>
                    <a class="btn btn-secondary border-secondary rounded " (click)="switchQuestion(i)"
                      [ngClass]="{'QActive': currentQuestion.options[0].questionId==item.options[0].questionId}">Q {{
                      i+1
                      }}</a>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h6>
                {{ currentQuestion.questions.statement }}
                <span class="pt-3 pl-4 text-danger text-italic" style="font-size: 13px !important;">{{errorMessage}}
                </span>
              </h6>
              <form #saveOpt="ngForm" (ngSubmit)="submitAnswer(saveOpt)">
                <div class="pb-4" *ngIf="currentQuestion.questions.isAttempted">
                  <div class="py-2" *ngFor="let item of currentQuestion.options; index as i">
                    <input *ngIf="item.option && i<4" disabled type="radio"
                      [checked]="item.id==currentQuestion.questions.selectedOptionId">
                    <span *ngIf="item.option && i<4" class="pl-1 pr-2 ml-2 option"
                      [ngStyle]="{'font-weight': currentQuestion.questions.rightOptionId==item.id ? 'bold': 'initial'}">{{
                      item.option }}</span>
                  </div>
                </div>
                <div class="pb-4" *ngIf="!currentQuestion.questions.isAttempted">
                  <div class="py-2" *ngFor="let item of currentQuestion.options;  index as i">
                    <input *ngIf="item.option && i<4" type="radio" name="radio" value="{{item.id}}" ngModel>
                    <span *ngIf="item.option && i<4" class="pl-2">{{ item.option }}</span>
                  </div>
                </div>

                <div>
                  <button *ngIf="!currentQuestion.questions.isAttempted"
                    [disabled]="disableSubmit ||currentQuestion.questions.isAttempted" class="btn btn-sm btn-secondary"
                    type="submit">Submit</button>
                  <span *ngIf="!exitButton ">
                    <input *ngIf="currentQuestion.questions.isAttempted" [disabled]="questionIndex == lastIndex"
                      class="btn btn-sm btn-primary" (click)="goNext()" value="Next" type="button">
                  </span>
                  <button *ngIf="exitButton" (click)="reset()" class="btn btn-sm btn-info" style="margin-left: 5px;"
                    data-dismiss="modal" aria-label="Close" aria-hidden="true" value="Exit" type="button">Exit</button>
                </div>
              </form>
            </div>
          </div>
          <div *ngIf="mobileLoader">
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
export const countryIds: any = {
        Afghanistan: 2185,
        'Åland Islands': 2407,
        Albania: 2186,
        Algeria: 2187,
        'American Samoa': 2408,
        Andorra: 2188,
        Angola: 2189,
        Anguilla: 2409,
        Antarctica: 2190,
        'Antigua and Barbuda': 2191,
        Argentina: 2192,
        Armenia: 2193,
        Aruba: 2373,
        Australia: 2194,
        Austria: 2195,
        Azerbaijan: 2196,
        Bahamas: 2197,
        Bahrain: 2198,
        Bangladesh: 2199,
        Barbados: 2200,
        Belarus: 2201,
        Belgium: 2202,
        Belize: 2203,
        Benin: 2204,
        Bermuda: 2372,
        Bhutan: 2205,
        'Bolivia; Plurinational State': 2206,
        'Bosnia and Herzegovina': 2207,
        Botswana: 2208,
        'Bouvet Island': 2410,
        Brazil: 2209,
        'British Indian Ocean Territory': 2411,
        'Brunei Darussalam': 2210,
        Bulgaria: 2211,
        'Burkina Faso': 2212,
        Burma: 2385,
        Burundi: 2213,
        Cambodia: 2214,
        Cameroon: 2215,
        Canada: 2216,
        'Cape Verde': 2217,
        'Cayman Islands': 2379,
        'Central African Republic': 2218,
        Chad: 2219,
        'Channel Islands': 2386,
        Chile: 2220,
        China: 2221,
        'Christmas Island': 2412,
        'Cocos (Keeling) Islands': 2413,
        Colombia: 2222,
        Comoros: 2223,
        'Congo; Democratic Republic of': 2368,
        'Congo; Republic of': 2383,
        'Cook Islands': 2414,
        'Costa Rica': 2226,
        "Cote D'Ivoire": 2227,
        Croatia: 2228,
        Cuba: 2229,
        Curacao: 2387,
        Cyprus: 2230,
        'Czech Republic': 2231,
        Denmark: 2232,
        Djibouti: 2233,
        Dominica: 2234,
        'Dominican Republic': 2235,
        Ecuador: 2236,
        Egypt: 2237,
        'El Salvador': 2238,
        'Equatorial Guinea': 2239,
        Eritrea: 2240,
        Estonia: 2241,
        Ethiopia: 2242,
        'Falkland Islands (Malvinas)': 2415,
        'Faroe Islands': 2388,
        Fiji: 2243,
        Finland: 2244,
        France: 2245,
        'French Guiana': 2416,
        'French Polynesia': 2417,
        'French Southern Territories': 2418,
        Gabon: 2246,
        Gambia: 2389,
        Georgia: 2248,
        Germany: 2249,
        Ghana: 2250,
        Gibraltar: 2390,
        Greece: 2251,
        Greenland: 2252,
        Grenada: 2253,
        Guadeloupe: 2419,
        Guam: 2376,
        Guatemala: 2371,
        Guernsey: 2420,
        Guinea: 2255,
        'Guinea-Bissau': 2256,
        Guyana: 2257,
        Haiti: 2258,
        'Heard and McDonald Islands': 2421,
        'Holy See (Vatican City State)': 2362,
        Honduras: 2259,
        'Hong Kong': 2377,
        Hungary: 2260,
        Iceland: 2261,
        India: 2262,
        Indonesia: 2263,
        Iran: 2264,
        Iraq: 2265,
        Ireland: 2266,
        'Isle of Man': 2445,
        Italy: 2268,
        Jamaica: 2269,
        Japan: 2270,
        Jersey: 2422,
        Jordan: 2271,
        Kazakhstan: 2272,
        Kenya: 2273,
        Kiribati: 2391,
        'North Korea': 2274,
        'South Korea': 2275,
        Kosovo: 2392,
        Kuwait: 2276,
        Kyrgyzstan: 2277,
        "Lao People's Democratic Republic": 2278,
        Latvia: 2279,
        Lebanon: 2280,
        Lesotho: 2281,
        Liberia: 2282,
        'Libyan Arab Jamahiriya': 2380,
        Liechtenstein: 2284,
        Lithuania: 2285,
        Luxembourg: 2286,
        Macau: 2287,
        Macedonia: 2288,
        'Macedonia; the Former Yugoslav Republic': 2367,
        Madagascar: 2289,
        Malawi: 2290,
        Malaysia: 2291,
        'Maldives; Repulic of': 2384,
        Mali: 2292,
        Malta: 2293,
        'Marshall Islands': 2394,
        Martinique: 2423,
        Mauritania: 2294,
        Mauritius: 2295,
        Mayotte: 2393,
        Mexico: 2296,
        'Micronesia; Federated States of': 2297,
        Moldova: 2395,
        Monaco: 2299,
        Mongolia: 2300,
        Montenegro: 2396,
        Montserrat: 2424,
        Morocco: 2301,
        Mozambique: 2302,
        Myanmar: 2303,
        Namibia: 2304,
        Nauru: 2397,
        Nepal: 2305,
        Netherlands: 2306,
        'Netherlands Antilles': 2425,
        'New Caledonia': 2426,
        'New Zealand': 2307,
        Nicaragua: 2308,
        Niger: 2309,
        Nigeria: 2310,
        Niue: 2427,
        'Norfolk Island': 2428,
        'Northern Mariana Islands': 2429,
        Norway: 2311,
        Oman: 2312,
        Pakistan: 2313,
        Palau: 2314,
        Palestine: 2430,
        Panama: 2315,
        'Papua New Guinea': 2316,
        Paraguay: 2317,
        Peru: 2318,
        Philippines: 2319,
        Pitcairn: 2431,
        Poland: 2320,
        Portugal: 2321,
        'Puerto Rico': 2374,
        Qatar: 2322,
        Reunion: 2398,
        Romania: 2323,
        'Russian Federation': 2324,
        Rwanda: 2325,
        'Saint Barthélemy': 2432,
        'Saint Helena; Ascension and Tristan Da Cunha': 2433,
        'Saint Kitts and Nevis': 2399,
        'Saint Lucia': 2326,
        'Saint Martin': 2434,
        'Saint Pierre And Miquelon': 2435,
        'Saint Vincent and Grenadines': 2400,
        Samoa: 2401,
        'San Marino': 2327,
        'Sao Tome and Principe': 2403,
        'Saudi Arabia': 2328,
        Senegal: 2329,
        Serbia: 2402,
        Seychelles: 2331,
        'Sierra Leone': 2332,
        Singapore: 2333,
        Slovakia: 2334,
        Slovenia: 2335,
        'Solomon Islands': 2336,
        Somalia: 2337,
        'South Africa': 2338,
        'South Georgia and the South Sandwich Islands': 2436,
        Spain: 2339,
        'Sri Lanka': 2340,
        Sudan: 2341,
        Suriname: 2342,
        'Svalbard And Jan Mayen': 2437,
        Swaziland: 2343,
        Sweden: 2344,
        Switzerland: 2345,
        'Syrian Arab Republic': 2381,
        Taiwan: 2375,
        Tajikistan: 2348,
        Tanzania: 2349,
        Thailand: 2350,
        'Timor-Leste': 2404,
        Togo: 2351,
        Tokelau: 2438,
        Tonga: 2405,
        'Trinidad and Tobago': 2352,
        Tunisia: 2353,
        Turkey: 2354,
        Turkmenistan: 2355,
        'Turks and Caicos Islands': 2439,
        Tuvalu: 2440,
        Uganda: 2356,
        Ukraine: 2357,
        'United Arab Emirates': 2358,
        'United Kingdom': 2359,
        'United States': 1,
        'United States Minor Outlying Islands': 2441,
        Uruguay: 2360,
        Uzbekistan: 2361,
        Vanuatu: 2406,
        'Venezuela; Bolivarian Republic of': 2363,
        Vietnam: 2364,
        'Virgin Islands; British': 2446,
        'Virgin Islands; U.S.': 2442,
        'Wallis and Futuna': 2443,
        'Western Sahara': 2444,
        Yemen: 2382,
        Zambia: 2369,
        Zimbabwe: 2370,
}
<div class="blur-content">
    <div  [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
      <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
      <!-- main content area start -->
      <div class="main-content">
        <app-header></app-header>
        <div class="main-content-inner">
          <!-- market value area start -->
          <div class="row mt-5">
            <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
              <div class="card">
                <div class="card-body">
                  <div *ngIf="loadingDeductions===false">
                    <h4>Submit Deductions</h4>                   
                    <hr>
                    <div class="table-responsive">
                      <table class="table table-sm table-hover table-bordered">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Candidate</th>
                            <th>Deduction</th>
                            <th>Employer</th>
                            <th>Type</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Amount</th>
                            <th>$ Limit</th>
                            <th>Note</th>
                            <th></th>
                            <th></th>
                          </tr>  
                        </thead>
                        <tbody>
                          <ng-container  *ngIf="signedDeduction.length>0">
                            <tr *ngFor="let item of signedDeduction">
                              <td>{{item.uid}}</td>
                              <td>{{item.candidateName}}</td>
                              <td>{{item.name}}</td>
                              <td>{{item.employer}}</td>
                              <td>{{(item.limit_type=="fixed_amount" || item.limit_type=='fixed_pay_period' )?'Fixed':(item.limit_type=="unlimited")?'Recurring':'-'}}</td>
                              <td>{{item.date_from?(item.date_from | date: 'yyyy-MM-dd'):'-'}}</td>
                              <td>{{item.date_to?(item.date_to | date: 'yyyy-MM-dd'):'-'}}</td>
                              <td>{{item.amount?(item.amount |number:'1.2-2'):'-'}}</td>
                              <td>{{item.limit_fixed?(item.limit_fixed |number:'1.2-2'):'-'}}</td>
                              <td>{{item.note?item.note :'-' }}</td>
                              <td>
                                <button class="btn btn-sm btn-success" title="Submit on Astute"  (click)="submitOnAstutue(item)">Submit</button>
                              </td>
                              <td><button type="button" [title]="item.status===2?'Submit on Astute first':'Complete'" class="btn btn-sm btn-danger"
                                [disabled]="item.status===2" (click)="completededuction(item)" data-toggle="modal" data-target="#deductionModal">Complete</button></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="signedDeduction.length<1">
                            <tr>
                                <td colspan="12" class="text-center">No Data</td>
                            </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="loadingDeductions===true">
                    <li class="t_loader">
                      <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load"></div>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- market value area end -->
        </div>
        <!-- main content area end -->
      </div>
    </div>
  </div>

   <!-- Modal -->
   <div class="modal fade" id="deductionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Submit Deductions</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body d-flex justify-content-center">
         <div *ngIf="!message" class="spinner-border text-primary" role="status">
           <span class="sr-only">Loading...</span>
         </div>
         <div *ngIf="message">
          <p class="text-center px-5">{{ message }}</p>
         </div>
       </div>
       <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="complete()">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
       </div>
     </div>
   </div>
 </div>
import { Component, OnInit, Inject , AfterViewInit,OnDestroy} from '@angular/core';
import {
  MsalBroadcastService,
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  RedirectRequest,
  PopupRequest,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IdTokenClaims } from '@azure/msal-common';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GetUserQueryService } from '../services/get-user-query.service';
import { environment } from 'src/environments/environment';
import { QuizService } from '../services/quiz.service';
import { DOCUMENT } from '@angular/common';
import { isAdminFlow } from '../services/url-utils';

type Claims = {
  name?: string;
  given_name?: string;
  family_name?: string;
  emails?: any;
  aud?: string;
  auth_time?: string;
  exp?: number;
  extension_CandidateId?: number;
  iat?: number;
  iss?: string;
  nbf?: number;
  nonce?: string;
  oid?: string;
  sub?: string;
  tfp?: string;
  ver?: string;
};
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy{
  loginDisplay = false;
  profile: any;
  claims!: Claims;
  name: any;
  email: any;
  isAdminFlow: boolean = false;
  isCandidate: boolean = false;
  trainingcompleted: boolean  = false;
  brandLogo:string='assets/img/agrilabour-logo-01.png';
  addItem(newItem: string) {
    this.profile = newItem;
  }
  displayLoader: string = 'false';
  title = 'FrontendService';
  isIframe = false;
  payrollIconCount: any;
  portalName:string='Candidate';

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  constructor(
    private getUserQuery:GetUserQueryService,
    private http: HttpClient,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private route: ActivatedRoute,private quizService: QuizService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(DOCUMENT) private document:Document
  ) { }
  ngOnDestroy(): void {
    if(!this.isAdminFlow){
      this.menuStatus();
      this.tfnStatus();
      this.getQuery();
      this.quizService.getQuizzes().subscribe(({ trainingStatus }) => {
      this.trainingcompleted = trainingStatus ? true : false;
    });
    }
  }
  ngAfterViewInit(): void {
  }
  
  ngOnInit(): void {   
    const brandHostname = this.document.location.hostname;
    if (brandHostname.includes('candidates.healthpluspeople.com.au')){
      this.brandLogo = 'assets/img/health_People.png'
    }
    this.isAdminFlow = isAdminFlow();
    if(this.isAdminFlow){
      this.portalName='Admin';
    }
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });  
   
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        if (this.loginDisplay) {
          if (this.isAdminFlow) {
            this.router.navigate(['/candidate-search']);
          } else {
            this.router.navigate(['/profile']);
          }
        }
        else {
          localStorage.clear();
          sessionStorage.clear();
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_START)
      )
      .subscribe((result: EventMessage) => { });
     
  }  
  getQuery(){
    this.getUserQuery.getUserQuery().subscribe((res:any)=>{
      let queryData=res.data;
      this.payrollIconCount=res.data.length; 
        // Check if all deductions are complete
      const allComplete = queryData?.every((item: { deductionStatus: string; }) => item.deductionStatus === "Complete");
        // Set localStorage value based on the result
      localStorage.setItem('deductionStatus', allComplete ? 'Complete' : 'Incomplete');        
      localStorage.setItem('homeData',this.payrollIconCount);
    })
  }
  // segoeui.WOFF
  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    localStorage.clear();
    sessionStorage.clear();
    this.isAdminFlow = isAdminFlow();
    if (this.isAdminFlow) {
      this.isAdminFlow=true;
      const authority = environment.entraId.authority;
      const clientId = environment.entraId.clientId;
      const redirectUri = window.location.origin;
      const scopes = environment.entraId.scopes;
      this.authService.loginRedirect({
        authority,
        redirectUri,
        scopes,
        ...userFlowRequest,
      } as RedirectRequest);
    } else {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authService
            .loginPopup({
              ...this.msalGuardConfig.authRequest,
              ...userFlowRequest,
            } as PopupRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        } else {
          this.authService
            .loginPopup(userFlowRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
        const authority = this.isAdminFlow? environment.entraId.authority: environment.b2cPolicies.authorities.SignIn.authority;
          this.authService.loginRedirect({
            ...this.msalGuardConfig.authRequest,
            authority,
            ...userFlowRequest,
          } as RedirectRequest);
        } else {
          this.authService.loginRedirect(userFlowRequest);
        }
      }
    }
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  getProfile(url: string) {
    this.http.get(url).subscribe((profile: any) => {
      this.profile = profile.data;
      this.name = this.profile.firstName + this.profile.lastName;
      this.email = this.profile.email;
      localStorage.setItem('name', this.name);
      localStorage.setItem('email', this.email);
      const datepipe: DatePipe = new DatePipe('en-US');
      const formattedDate = datepipe.transform(
        this.profile?.dateOfBirth?.toString(),
        'dd-MM-yyyy'
      );
      this.profile.dateOfBirth = formattedDate;
    });
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  menuStatus() {
    var url = `${environment.apiConfig.uri}/Candidate/AstuteBankStatus`;
    this.http.post(url, {}).subscribe(async (response: any) => { 
      if(response.data){
        localStorage.setItem('bankdetailsStatus',response.data.astuteBankStatus);
        localStorage.setItem('superannuationStatus',response.data.superAnnuationStatus);
      }
    })
  }
  tfnStatus() {
    var url = `${environment.apiConfig.uri}/Candidate/TFNStatus`;
    this.http.post(url, {}).subscribe(async (response: any) => {
      if(response.data){
        localStorage.setItem('taxStatus',response.data.tfnStatus);
      }
    });
  }
}

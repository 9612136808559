<div class="row mt-5">
  <div
    class="col-12"
    style="padding-right: 0 !important; margin-right: 0 !important"
  >
    <div class="card">
      <div class="card-body">
        <!-- Candidate Files -->
        <div class="row">
          <div class="col-7">
            <h4>Candidate files</h4>
            <br />
          </div>
        </div>
        <div *ngIf="loadingCandidateFiles">
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
        </div>
        <div *ngIf="!loadingCandidateFiles" class="table-responsive">
          <table class="table mb-2">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">File Name</th>
                <th scope="col">File Type</th>
                <th scope="col">File Size (Kb)</th>
                <th scope="col">Date Added</th>
                <th scope="col">Download/View</th>
                <!-- <th>Update/Delete</th> -->
              </tr>
            </thead>
            <tbody *ngIf="candidateFilesList.length > 0">
              <tr
                *ngFor="let item of candidateFilesList; index as i"
                id="item-{{ item.id }}"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ item.fileName }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.fileSize }}</td>
                <td>{{ item.dateAdded | date: "dd-MM-yyy" || "N/A" }}</td>
                <td>
                  <button
                    class="btn btn-sm btn-primary"
                    (click)="onClickDownload(item)"
                    title="Download"
                  >
                    <i class="fa fa-download" style="font-size: 18px"></i>
                  </button>

                  <button
                    title="View"
                    [id]="'item_' + item.id"
                    class="btn ml-1 btn-sm btn-info"
                    (click)="onClickView(item)"
                  >
                    <i class="fa fa-file-text-o" style="font-size: 18px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="candidateFilesList.length == 0">
              <tr>
                <td colspan="6" class="text-center">No Files</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Update File -->
<div
  class="modal fade"
  id="updateModalCenter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="updateModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLongTitle">Update File</h5>
        <button
          #closeModelUpdate
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-10 mx-auto">
          <form
            [formGroup]="formGroup"
            (ngSubmit)="updateFileSubmit($event, editFile.id, index)"
          >
          <div   *ngIf="!loading">
            <div class="file-upload">
              <p *ngIf="fileError" class="text-danger text-left">
                Error file size cannot be larger than 10 mb
              </p>
              <div
                class="file-select"
                for="chooseFileUpdate"
                aria-disabled="false"
              >
                <input
                  type="file"
                  name="chooseFileUpdate"
                  id="chooseFileUpdate"
                  (change)="onFileChange($event, 'edit')"
                  accept=".xls,.xlsx,.xlsm,.ppt,.pptx,.txt,.odt,.pdf,.doc,.docx,.jpg,.png,.jpeg"
                />
              </div>
            </div>

            <select
              [disabled]="!enableType"
              (change)="selectType($event)"
              class="form-control my-2"
              style="
                border: 1px solid gray;
                padding-top: 4px;
                padding-bottom: 1.5px;
              "
            >
              <option #TypeUpdate value="">Select Type</option>
              <option *ngFor="let item of fileTypes" [value]="item.value">
                {{ item.label }}
              </option>
            </select>

            <div class="text-center pt-2">
              <button
                [disabled]="!enableUpload"
                class="btn btn-sm btn-info"
                type="submit"
              >
                <span class="px-3">Update</span>
              </button>

              <button
                type="button"
                class="btn btn-sm btn-info px-4 ml-2"
                data-dismiss="modal"
                aria-label="Close"
                (click)="closeModel()"
              >
                Cancel
              </button>
            </div>
          </div>
          <div *ngIf="loading" class="d-flex justify-content-center">
            <div class="spinner-border m-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Delete File -->
<div
  class="modal fade"
  id="deleteModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModelCenterTitle"
  aria-hidden="true"
  (click)="closeModel()"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModelTitle">Delete File</h5>
        <button
          #closeModelDelete
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!loading">
        <div class="px-1 text-center">
          <h5>Are you sure you want to delete this file?</h5>
          <p class="text-danger">(Note: This action cannot be undone)</p>

          <div class="pt-3">
            <button
              class="btn btn-sm btn-danger px-4"
              (click)="deleteFile($event, FileID, index)"
            >
              Delete
            </button>

            <button
              type="button"
              class="btn btn-sm btn-info px-4 ml-2"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModel()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="loading" class="d-flex justify-content-center">
        <div class="spinner-border m-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [id]="id" [bankdetailsStatus]="bankdetailsStatus"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
        <app-header></app-header>
        <div class="main-content-inner mt-3">
            <!-- market value area start -->
            <div *ngIf="!newBankDataForm && !viewBankDataForm && !loadingTaxFiles" class="table-responsive">
                <table class="table  table-hover table-bordered align-middle  bg-white" style="overflow-x:scroll">
                    <thead>
                        <tr>
                            <th colspan="5">Current</th>
                        </tr>
                    </thead>
                    <tbody class="m-2">
                        <ng-container *ngIf="bankLength">
                            <ng-container *ngFor="let item of userQueryData">
                                <tr>
                                    <td class="noWrap" colspan="2">
                                        {{item.companyName}} 
                                    </td>
                                    <td>
                                        <app-status-icons [statusValue]="(item.account_name && item.account_number && item.account_bsb)?'true':'false'"></app-status-icons>
                                    </td>
                                    <td style="align-self: center;">
                                        <button class=" btn btn-sm btn-primary px-3" type="button"
                                            (click)="viewBankAccount(item)" title="View File">
                                            View
                                        </button>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-info px-3" title="Add New File"
                                            (click)="newBankAccount(item)">
                                            New
                                        </button>
                                    </td>
                                </tr>                              
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!bankLength">
                            <tr>
                                <td colspan="4" class="text-center">No Data
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

            </div>
            <ng-container>
                <div *ngIf=" loadingTaxFiles">
                    <li class="t_loader">
                        <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                        <div class="card__desc load"></div>
                    </li>
                </div>
            </ng-container>
            <div *ngIf="newBankDataForm ||viewBankDataForm" class="mt-4 ">
                <button (click)="newBankDataForm=false; viewBankDataForm=false" class="btn badge badge-primary  "
                    type="button">
                    Back
                </button>
                <div class="mt-2">
                    <div >
                        <form #bankForm="ngForm">
                            <p [hidden]="disablefield" class="text-center"><strong>* Only English letters and numbers
                                    can be used.</strong></p>
                            <div class="card">
                                <div class="card-body form-group" style="overflow-x:auto">
                                    <legend>Primary Bank Account </legend>
                                    <table class="table bg-white table-borderless">
                                        <tbody>
                                            <tr>
                                                <td><label class="editor-field-name" for="form_account_name">
                                                        Account Name
                                                    </label></td>
                                                <td>
                                                    <input name="account_name" id="form_account_name" type="text" class="form-control"
                                                        #account_name="ngModel" [disabled]="disablefield" maxlength="32" style="font-size: 14px !important;"                                                  
                                                        [class.is-invalid]="account_name.invalid && (account_name.touched || bankForm.submitted)"
                                                        title="Type a primary account holder name."
                                                        pattern="[a-zA-Z][a-zA-Z\s]*"
                                                        [(ngModel)]="bankDetail.account_name" ngModel required>
                                                    <div
                                                        *ngIf="account_name.touched && (account_name.invalid ||bankForm.submitted)">
                                                        <p class="alert alert-danger form-control"
                                                            *ngIf="account_name.errors &&account_name.errors['pattern']">
                                                            Only English letters can be used.
                                                        </p>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <label for="form_account_bsb">
                                                        BSB Number
                                                    </label></td>
                                                <td>
                                                    <input name="account_bsb" id="form_account_bsb"
                                                        #account_bsb="ngModel" class="mt-1 form-control"
                                                        [class.is-invalid]=" account_bsb.invalid && (account_bsb.touched ||bankForm.submitted)"
                                                        [disabled]="disablefield" type="text"
                                                        pattern="^\d{4,}$"
                                                        [(ngModel)]="bankDetail.account_bsb"
                                                        title="Type a primary account BSB number." ngModel required>
                                                    <div class=""
                                                        *ngIf=" account_bsb.invalid && (account_bsb.touched  ||bankForm.submitted)">
                                                        <p class="alert alert-danger form-control"
                                                            *ngIf="account_bsb.errors && (account_bsb.errors['pattern'] )">
                                                            Please enter only numbers of at least 5 digits. 
                                                        </p>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <label for="form_account_number">
                                                        Account Number
                                                    </label></td>
                                                <td>
                                                    <input name="account_number" id="form_account_number"  type="text"
                                                        #account_number="ngModel" [disabled]="disablefield" class="customText form-control"
                                                        [class.is-invalid]=" account_number.invalid && (account_number.touched ||bankForm.submitted)"
                                                         pattern="[0-9]{1,}" [(ngModel)]="bankDetail.account_number"
                                                        title="Type a primary account account number." ngModel required>
                                                    <div
                                                        *ngIf=" account_number.invalid && (account_number.touched  || bankForm.submitted)">
                                                        <p class="alert alert-danger form-control"
                                                            *ngIf="account_number.errors && account_number.errors['pattern']">
                                                            Please enter only numbers of at least 5 digits. 
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br>
                            <legend class="editor-sect-title">Additional Accounts </legend>
                            <!-- Secondary  -->
                            <div class="card">
                                <div class="card-body">
                                    <div class="form-group">
                                        <legend class="editor-col-title">Secondary Bank Account</legend>
                                        <!-- BEGIN ROW -->
                                        <table class="table bg-white table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td><label for="form_secondary_account_name">
                                                            Account Name
                                                        </label></td>
                                                    <td> <input name="secondary_account_name" style="font-size: 14px !important;"
                                                            #secondary_account_name="ngModel" class="form-control input"
                                                            id="" [disabled]="disablefield" 
                                                            [class.is-invalid]=" secondary_account_name.invalid && (secondary_account_name.touched ) "
                                                            type="text" maxlength="32" patteform_secondary_account_namern="[a-zA-Z][a-zA-Z\s]*"
                                                            [(ngModel)]="bankDetail.secondary_account_name"
                                                            title="Type a secondary account holder name." ngModel>
                                                        <div
                                                            *ngIf="secondary_account_name.invalid && (secondary_account_name.touched || bankForm.submitted)">
                                                            <p class="alert alert-danger form-control"
                                                                *ngIf="secondary_account_name.errors && ( secondary_account_name.errors['pattern'])">
                                                                Only English letters can be used.
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> <label for="form_secondary_account_bsb">
                                                            BSB Number
                                                        </label></td>
                                                    <td>
                                                        <input name="secondary_account_bsb"
                                                            #secondary_account_bsb="ngModel"
                                                            id="form_secondary_account_bsb" [disabled]="disablefield"
                                                            type="text" pattern="^\d{4,}$" class=" form-control"
                                                            [class.is-invalid]=" secondary_account_bsb.invalid && (secondary_account_bsb.touched )"                                                            
                                                            [(ngModel)]="bankDetail.secondary_account_bsb"
                                                            title="Type a secondary account BSB number." ngModel>
                                                        <div class=""
                                                            *ngIf=" secondary_account_bsb.invalid && (secondary_account_bsb.touched  || bankForm.submitted)">
                                                            <p class="alert alert-danger form-control"
                                                                *ngIf="secondary_account_bsb.errors && secondary_account_bsb.errors['pattern']">
                                                                Please enter only numbers of at least 5 digits. 
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> <label class="editor-field-name "
                                                            for="form_secondary_account_number">
                                                            Account Number
                                                        </label></td>
                                                    <td>
                                                        <input name="secondary_account_number"
                                                            #secondary_account_number="ngModel"
                                                            [disabled]="disablefield" id="form_secondary_account_number"
                                                            type="text" pattern="[0-9]{1,}" class="form-control"
                                                            [class.is-invalid]=" secondary_account_number.invalid && (secondary_account_number.touched )"
                                                            [(ngModel)]="bankDetail.secondary_account_number"
                                                            title="Type a secondary account account number." ngModel>
                                                        <div
                                                            *ngIf="secondary_account_number.invalid && (secondary_account_number.touched || bankForm.submitted)">
                                                            <p class="alert alert-danger form-control"
                                                                *ngIf="secondary_account_number.errors && secondary_account_number.errors['pattern']">
                                                                Please enter only numbers of at least 5 digits. 
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> <label class="editor-field-name" for="form_deposit_type">
                                                            Deposit Type
                                                        </label></td>
                                                    <td>                                                        
                                                        <label for="form_deposit_type"
                                                            title="Choose a secondary account deposit type."
                                                            class="mr-3">
                                                            <input name="deposit_type" id="form_deposit_type" type="radio"
                                                            [disabled]="disablefield" class="mr-1"
                                                            #deposit_type="ngModel"
                                                            [(ngModel)]="bankDetail.deposit_type"
                                                            title="Choose a secondary account deposit type." value="0"
                                                            ngModel>Percentage</label>                                                        
                                                        <label for="form_deposit_type2"
                                                            title="Choose a secondary account deposit type.">
                                                            <input name="deposit_type" id="form_deposit_type2" type="radio"
                                                            [attr.disabled]="disablefield ? true : null" class="mr-1"
                                                            [(ngModel)]="bankDetail.deposit_type"
                                                            #deposit_type="ngModel"
                                                            title="Choose a secondary account deposit type." value="1">Dollar
                                                            Amount</label>
                                                        <div *ngIf="deposit_type.invalid || bankForm.submitted">
                                                            <p class="alert alert-danger form-control"
                                                                *ngIf="deposit_type.errors ">
                                                                Select Deposit Type.
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> <label for="form_deposit_amount">
                                                            Deposit Amount
                                                        </label></td>
                                                    <td>
                                                        <input name="deposit_amount" id="form_deposit_amount"
                                                            type="number" #deposit_amount="ngModel" maxlength="9" class=" form-control" 
                                                            [class.is-invalid]="deposit_amount.invalid && (deposit_amount.touched  || bankForm.submitted)"
                                                            pattern="[0-9]+(\.[0-9]+)?"
                                                            [disabled]="disablefield"
                                                            [(ngModel)]="bankDetail.deposit_amount"
                                                            title="Type a secondary account deposit amount." ngModel>
                                                        <div
                                                            *ngIf="deposit_amount.invalid && (deposit_amount.touched  || bankForm.submitted)">
                                                            <p class="alert alert-danger form-control"
                                                                *ngIf="deposit_amount.errors ">
                                                                Only numbers can be used.
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- END ROW -->
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button type="submit" [hidden]="disablefield"
                                    class="btn btn-sm btn-info px-4 mr-4 float-right mt-2" data-target="#submitBank"
                                    data-toggle="modal" name="save-activate" title="Save the user."
                                    (click)="bankAccountSubmit(bankForm.value);bankForm.reset">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- market value area end -->
        </div>
    </div>
    <!-- main content area end -->
</div>
<div class="modal fade " id="submitBank" tabindex="-1" role="dialog" aria-labelledby="submitBankLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body ">
                <div *ngIf="messageforUser">
                    <p style="text-align: center;">{{messageforUser}} </p>
                    <div class="row">
                        <div class="col-5"></div>
                        <button type=" button" class="btn btn-sm btn-info px-3 mr-3"
                            (click)="modalbtn==true?newBankDataForm = false:newBankDataForm = true"
                            data-dismiss="modal">Ok</button>
                    </div>
                </div>
                <div *ngIf="!messageforUser">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [id]="id"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
        <app-header></app-header>
        <div class="main-content-inner">
            <div class="card mt-3">
                <div class="card-body">
                    <!-- market value area start -->
                    <div *ngIf="!displayLoader" style="overflow-x: auto">
                        <div class="table-responsive" >
                            <table class="mt-2 table table-bordered tablecenter table-hover align-middle mb-0 bg-white">
                                <thead>
                                    <tr>
                                        <th>Pay Date</th>
                                        <th>Pay Period</th>
                                        <th>Gross Pay</th>
                                        <th>Deductions</th>
                                        <th>Tax</th>
                                        <th>Net Pay</th>
                                        <th>Status</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="!noPayslipData">
                                    <tr *ngFor="let item of payslipsData ; index as i;  ">
                                        <ng-container *ngIf="item.payslip_status=='Paid'">
                                            <td>{{item.paydate | date: 'dd.MM.yyyy'}} </td>
                                            <td> {{item.pay_period_start |date:'dd.MM.yyyy' }} &nbsp;
                                                to &nbsp;{{item.pay_period_end | date:'dd.MM.yyyy' }}
                                            </td>
                                            <td>{{item.gross_wages | currency}}</td>
                                            <td>{{item.deductions | currency}}</td>
                                            <td>{{item.tax | currency}}</td>
                                            <td>{{item.net_wages | currency}}</td>
                                            <td><button class="btn btn-sm btn-info px-3" disabled>{{item.payslip_status}}</button>
                                            </td>
                                            <td>
                                                <button type=" button" class="btn btn-sm  " (click)="viewPaySlip(i)"
                                                    data-toggle="modal" data-target="#addModalCenter"
                                                    style="color: rgb(36, 36, 185); ">
                                                    <b>View</b></button>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="noPayslipData">
                                    <tr>
                                        <td colspan="8"> No data. </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- Pagination  -->
                        <div>
                            <div class="desktopView">
                                <div *ngIf="payslipsData.length>0" class=" d-flex justify-content-between p-2">
                                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page"
                                        [pageSize]="pageSize" [boundaryLinks]="true" [rotate]="true"
                                        (pageChange)="refreshData()" class="flex-wrap">
                                    </ngb-pagination>
                                    <select class="form-select" style="width: auto" [(ngModel)]="pageSize"
                                        (ngModelChange)="refreshData()">
                                        <option *ngFor="let pageSizeOption of pageSizeOptions"
                                            [ngValue]="pageSizeOption">
                                            {{ pageSizeOption }} items per page
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="mobileView">
                                <pre></pre>
                                <div *ngIf="payslipsData.length>0" class=" justify-content-between "
                                    style="display:flex ;flex-direction: row; ">
                                    <div class="paginationSet" style=" align-items:center ;">
                                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page"
                                            [pageSize]="pageSize" style="font-size: 1vw; margin: auto;" [maxSize]="1"
                                            [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData()"
                                            class="flex-wrap">
                                        </ngb-pagination>
                                    </div>

                                    <select class="paginationSet paginationRight" [(ngModel)]="pageSize"
                                        (ngModelChange)="refreshData()">
                                        <option
                                            style="font-size: 2vw !important;  margin: 0 !important; padding: 0% !important;"
                                            *ngFor="let pageSizeOption of pageSizeOptions" [ngValue]="pageSizeOption">
                                            {{ pageSizeOption }} items per page
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!--  -->
                        </div>
                        <!-- market value area end -->
                    </div>
                    <div *ngIf="displayLoader" style="width: 55% !important;">
                        <li class="t_loader">
                            <div class="card__desc load" id="long"></div>
                        </li>
                        <li class="t_loader">
                            <div class="card__desc load"></div>
                        </li>
                        <li class="t_loader">
                            <div class="card__desc load" id="long"></div>
                        </li>
                        <li class="t_loader">
                            <div class="card__desc load"></div>
                        </li>
                        <li class="t_loader">
                            <div class="card__desc load" id="long"></div>
                        </li>
                        <li class="t_loader">
                            <div class="card__desc load"></div>
                        </li>
                        <li class="t_loader">
                            <div class="card__desc load" id="long"></div>
                        </li>
                    </div>
                </div>
            </div>
        </div>
        <!-- main content area end -->
    </div>
    <div class="modal fade " id="addModalCenter" tabindex="-1" role="dialog" aria-labelledby="addModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-header">
                    <button #closeModelAdd type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <table class="table table-sm  table-bordered ">
                            <thead>
                                <tr>
                                    <td colspan="5" style="text-align: center;"> 
                                       <strong class="mx-3" >To view or download your pay advice, please login to Astute Payroll.</strong> </td>
                                </tr>
                                <tr>
                                    <th colspan="5" class="rowdesign" style="text-align: center;">
                                        Entitlements
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" class="colspan-2 ">Description</th>
                                    <th scope="col" class="tablecenter">Unit</th>
                                    <th scope="col" class="tablecenter">Rate</th>
                                    <th scope="col" class="tablecenter">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of uniqueCustomerName;index as i">
                                    <tr>
                                        <td colspan="5" style="text-align: center;">
                                            <b>{{item}}</b>
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let obj of myobj.payAdviceHistory">
                                        <tr *ngIf="obj.customer_name == item">
                                            <td class="colspan-2">{{obj.paycat_name}} </td>
                                            <td class="tablecenter">{{obj.units }}</td>
                                            <td class="tablecenter">{{obj.rate| currency}}</td>
                                            <td class="tablecenter">{{obj.amount| currency}}</td>

                                        </tr>
                                    </ng-container>
                                </ng-container> 
                                <tr>
                                    <th scope="row" colspan="3" class="pr-4" style="text-align: right;">Gross Payment
                                    </th>
                                    <td class="tablecenter">{{myobj.gross_wages | currency}} </td>
                                </tr>
                                <br>
                                <tr>
                                    <td colspan="5" class="rowdesign" style='text-align: center;  '>
                                        <b>Deduction</b>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="3">Description</th>
                                    <th class="tablecenter">Amount</th>
                                </tr>
                                <tr *ngIf="myobj.othersDeduction!=0">
                                    <td colspan="3"> Others Deductions
                                        <button style="border:none; background-color:white;" placement="top"
                                            ngbTooltip="For an itemised list of other deductions, please refer to your payslip.">
                                            <img tooltip="Tooltip on top" style="width: 17px; height: 17px;"
                                                src="../../../assets/img/info.png" />
                                        </button>
                                    </td>
                                    <td class="tablecenter">{{myobj.othersDeduction| currency}}</td>
                                </tr>
                                <tr>
                                    <td colspan="3"> TAX</td>
                                    <td class="tablecenter">{{myobj.tax | currency}}</td>
                                </tr>
                                <tr>
                                    <th colspan="3" class="pr-4" style="text-align: right;">Total Deductions</th>
                                    <td class="tablecenter">{{myobj.totalDeduction | currency}}</td>
                                </tr>
                                <br>
                                <tr>
                                    <td colspan="5" class="rowdesign" style="text-align: center;">
                                        <b>Net Pay</b>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="3">Description </th>
                                    <th class="tablecenter">Amount</th>
                                </tr>
                                <tr>
                                    <td colspan="3">Gross Pay </td>
                                    <td class="tablecenter">{{myobj.gross_wages | currency}}</td>
                                </tr>
                                <tr>
                                    <td colspan="3"> Deduction & Tax</td>
                                    <td class="tablecenter">{{myobj.totalDeduction | currency}}</td>
                                </tr>
                                <tr>
                                    <th colspan="3" class="pr-4" style="text-align: right;">Net Pay</th>
                                    <td class="tablecenter">{{myobj.netPay | currency}}</td>
                                </tr>
                                <br>
                                <tr>
                                    <td colspan="5" class="rowdesign" style="text-align: center;">
                                        <b>Superannuation</b>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="3">Description</th>
                                    <th class="tablecenter">Amount</th>

                                </tr>
                                <tr>
                                    <td colspan="3">SGC-candidates</td>
                                    <td class="tablecenter">{{myobj.superannuation | currency}}</td>
                                </tr>
                                <tr>
                                    <th colspan="3" class="pr-4" style="text-align: right;">Total Contributions</th>
                                    <td class="tablecenter">{{myobj.superannuation | currency}}</td>
                                </tr>
                                <br>                            
                            </tbody>
                        </table>
                    </div>
                    <div class=" mx-auto">
                        <div>
                            <div class="text-center">
                                <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal"
                                    aria-label="Close">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
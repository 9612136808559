export class BankDetails {
    constructor(
       public id?:number,
       public account_name?: string,
       public account_bsb?: string,
       public account_number?: string,
       public  secondary_account_name?: string,
       public secondary_account_bsb?:string,
       public secondary_account_number?:string,
       public deposit_type?:string,
       public deposit_amount?: string,
       public uid?: string,
       public remoteid?: string){}
}

<div class="row mt-5 mb-5">
  <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important;">
    <div class="card">
      <div class="card-body">
        <div class="market-status-table mt-2 pb-4 profile">
          <form #updateProfile="ngForm" (ngSubmit)="submitForm(updateProfile.value)" method="post">
            <div *ngIf="!displayLoader">
              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Title</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <div *ngFor="let item of BullhornMeta.fields">
                    <select *ngIf="item.name == 'namePrefix'" class="form-control" name="namePrefix"
                      [(ngModel)]="profile.namePrefix" (change)="updateInput($event)">
                      <option *ngIf="!profile.namePrefix" selected value="">
                        Select Title
                      </option>
                      <option *ngFor="let option of item.options" [attr.selected]="profile.namePrefix == option.value"
                        [value]="option.value">
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">First Name</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your First Name" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" (keypress)="updateInput($event)"
                    (keydown.backspace)="updateInput($event)" [(ngModel)]="profile.firstName" type="text"
                    class="form-control" name="firstName" />
                </div>
              </div>
              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Last Name</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your Last Name" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" (keypress)="updateInput($event)"
                    (keydown.backspace)="updateInput($event)" [(ngModel)]="profile.lastName" type="text"
                    class="form-control" name="lastName" />
                </div>
              </div>
              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Preferred Name</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your Preferred Name" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" name="nickName" type="text"
                    class="form-control pointer" (keypress)="updateInput($event)"
                    (keydown.backspace)="updateInput($event)" [(ngModel)]="profile.nickName" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Email</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your Email" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" style="background-color: none !important;"
                    [email]="true" #email="ngModel" type="email" class="form-control pointer"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.email" name="email" />
                  <span *ngIf="email.invalid && email.touched" style="font-size: 13px; color: red; display: block;">
                    Invalid email format
                  </span>
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Mobile Phone</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your Mobile No." (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" #mobile="ngModel"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.mobile" type="text" class="form-control" name="mobile" />
                  <span *ngIf="mobile.invalid && mobile.touched" style="font-size: 13px; color: red; display: block;">
                    Invalid format
                  </span>
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Date Of Birth</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input name="dateOfBirth" type="date" data-date="" data-date-format="DD-MMMM-YYYY"
                    class="form-control pointer" [(ngModel)]="profile.dateOfBirth"
                    (ngModelChange)="updateInput($event)" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Gender</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <div *ngFor="let item of BullhornMeta.fields">
                    <select *ngIf="item.name == 'gender'" class="form-control" name="gender"
                      [(ngModel)]="profile.gender" (change)="updateInput($event)">
                      <option selected *ngIf="!profile.gender" value="">
                        Select Gender
                      </option>
                      <option *ngFor="let option of item.options" [attr.selected]="profile.gender == option.value"
                        [value]="option.value">
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Country of Citizenship</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <div *ngFor="let item of BullhornMeta.fields">
                    <select *ngIf="item.name == 'customText11'" class="form-control" [(ngModel)]="profile.customText11"
                      name="customText11" (change)="updateInput($event)">
                      <option selected *ngIf="!profile.customText11" value="">
                        Select Country of Citizenship
                      </option>
                      <option *ngFor="let option of item.options" [attr.selected]="profile.customText11 == option.value"
                        [value]="option.value">
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Preferred Language</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <div *ngFor="let item of BullhornMeta.fields">
                    <select *ngIf="item.name == 'customText13'" class="form-control" [(ngModel)]="profile.customText13"
                      name="customText13" (change)="updateInput($event)">
                      <option selected *ngIf="!profile.customText13" value="">
                        Select Language
                      </option>
                      <option *ngFor="let option of item.options" [attr.selected]="profile.customText13 == option.value"
                        [value]="option.value">
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Street Address</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="St. Address" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" type="text" class="form-control pointer"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.candidateAddress1" name="address1" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">
                    Street Address
                    <span class="text-secondary">(Line 2)</span>
                  </p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="St. Address Line 2" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" type="text" class="form-control pointer"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.candidateAddress2" name="address2" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">City</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your City" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" type="text" class="form-control pointer"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.candidateAddressCity" name="city" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">State</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your State" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" type="text" class="form-control pointer"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.candidateAddressState" name="state" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Postal Code</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Your Postal Code" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text"
                    class="form-control pointer" (keypress)="updateInput($event)"
                    (keydown.backspace)="updateInput($event)" [(ngModel)]="profile.zip" name="zip" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Address Country</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <select class="form-control" [(ngModel)]="profile.candidateAddressCountryId"
                    name="candidateAddressCountryId" (change)="updateInput($event)">
                    <option selected *ngIf="!profile.candidateAddressCountryId" value="">
                      Select Address Country
                    </option>
                    <option *ngFor="let item of countryIds | keyvalue" [attr.selected]="
                        profile.candidateAddressCountryId == item.value
                      " [value]="item.value">
                      {{ item.key }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-8 col-sm-12 label">
                <h5 class="pt-4">Driver's Licence Details</h5>
              </div>
              <div class="row mb-1 pl-3 pt-3">
                <div class="col-3 label">
                  <p class="label-text mb-0">Licence Type</p>
                </div>
                <div class="col-5 mb-2">
                  <select class="form-control pointer" (change)="updateInput($event)" [(ngModel)]="profile.customText26"
                    name="customText26">
                    <option *ngFor="let option of driversLicenceOptions;let i = index" [value]="option.value">{{ option.label }}</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1 pl-3">
                <div class="col-3 label">
                  <p class="label-text mb-0">Licence Number</p>
                </div>
                <div class="col-5 mb-2">
                  <input placeholder="Driver's Licence Number" (keydown.shift.backspace)="updateInput($event)"
                    (change)="updateInput($event)" (keydown.control.backspace)="updateInput($event)" (keypress)="updateInput($event)"
                    (keydown.backspace)="updateInput($event)" type="text" class="form-control pointer"
                    [(ngModel)]="profile.customText8" name="customText8">
                </div>
              </div>
              <div class="row mb-1 pl-3">
                <div class="col-3 label">
                  <p class="label-text mb-0">Licence Expiry</p>
                </div>
                <div class="col-5 mb-2">
                  <input placeholder="Driver's Licence Expiry Date" data-date-format="DD-MMMM-YYYY" type="date" data-date=""
                    class="form-control pointer" (keydown.shift.backspace)="updateInput($event)" (change)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" (keypress)="updateInput($event)"
                    (keydown.backspace)="updateInput($event)" [(ngModel)]="profile.customDate6" name="customDate6">
                </div>
              </div>
              <div class="row mb-1 pl-3">
                <div class="col-lg-8 col-sm-12 label">
                  <h5 class="pt-4">Emergency Contact</h5>
                </div>
                <div class="col-lg-3 col-sm-12 mb-2"></div>
              </div>

              <div class="row mb-1 pl-3 pt-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Name</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Emergency Contact Name" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" type="text" class="form-control pointer"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.customText36" name="customText36" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Relationship</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Relation with Emergency Contact" (keydown.shift.backspace)="updateInput($event)"
                    (keydown.control.backspace)="updateInput($event)" type="text" class="form-control pointer"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.customText37" name="customText37" />
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Email</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Emergency Contact Email" [email]="true" #email3="ngModel"
                    (keydown.shift.backspace)="updateInput($event)" (keydown.control.backspace)="updateInput($event)"
                    type="email" class="form-control pointer" (keypress)="updateInput($event)"
                    (keydown.backspace)="updateInput($event)" [(ngModel)]="profile.email3" name="email3" />
                  <span *ngIf="email3.invalid && email3.touched" style="font-size: 13px; color: red; display: block;">
                    Invalid email format
                  </span>
                </div>
              </div>

              <div class="row mb-1 pl-3">
                <div class="col-lg-3 col-sm-6 label">
                  <p class="label-text mb-0">Mobile No.</p>
                </div>
                <div class="col-lg-5 col-sm-12 mb-2">
                  <input placeholder="Emergency Contact Mobile No." #phone3="ngModel"
                    (keydown.shift.backspace)="updateInput($event)" (keydown.control.backspace)="updateInput($event)"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    (keypress)="updateInput($event)" (keydown.backspace)="updateInput($event)"
                    [(ngModel)]="profile.phone3" type="text" class="form-control" name="phone3" />
                  <span *ngIf="phone3.invalid && phone3.touched" style="font-size: 13px; color: red; display: block;">
                    Invalid format
                  </span>
                </div>
              </div>

              <div class="row mt-2 pl-3">
                <div class="col-4"></div>
                <div class="col-6 label">
                  <input [disabled]="updateProfile.invalid || !formSession" type="submit" data-toggle="modal"
                    data-target="#switchTab" value="Update" class="btn btn-sm btn-primary px-3">
                </div>
                <div class="col-2"></div>
              </div>
            </div>
          </form>

          <div *ngIf="displayLoader" style="width: 55% !important;">
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal swtich tab without saving form -->
<div class="modal fade mt-5" id="switchTab" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Personal Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div *ngIf="!savedetailsModal">
          <p>You have made changes. <br>Do you want to save or discard them?</p>
          <div class="row" style="text-align: center;">
            <div class="col-6">

            </div>
            <div class="col-2">
              <button #closeModelProfile data-dismiss="modal" aria-label="Close"
                class="btn btn-sm btn-outline-secondary mb-2"><b>Cancel</b></button>
            </div>
            <div class="col-2">
              <button class="btn btn-sm btn-outline-danger">
                <span> <b (click)="navigateUrl()">Discard</b></span>
              </button>
            </div>
            <div class="col-2">
              <button class="btn btn-sm btn-outline-success">
                <span> <b style="cursor: pointer; " (click)=submitForm(updateProfile.value)>Save</b></span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="savedetailsModal">
          <span>Saving The Details</span>
          <button #closeModelProfile type="button" class="close m-3 invisible" data-dismiss="modal"
            style="font-size:2vw" aria-label="Close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isPortalAdmin; else accessDenied" class="mt-5">
    <div *ngIf="!showNewTaxFile && !showNewSuperFile">
        <div class="card">
            <strong class="ml-2 mt-2">Tax Information</strong>
            <div class="card-body table-responsive">
                <table class="table table-borderless table-hover">
                    <thead>
                        <tr>
                            <th>Declaration Name</th>
                            <th>Employee Profile</th>
                            <th>Submission Date</th>
                            <th>Approved Date</th>
                            <th>Approved By</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="showTax1">
                            <td>{{taxProfile1?.declarationName || '-'}}
                            </td>
                            <td>Agri Labour Australia Pty Ltd</td>
                            <td>{{taxProfile1?.submissionTime ? (taxProfile1.submissionTime | date:'dd-MM-yyyy') : '-'}}  
                            </td>
                            <td>{{taxProfile1?.approvalTime ? (taxProfile1.approvalTime | date:'dd-MM-yyyy') : '-'}}
                            </td>
                            <td>{{taxProfile1?.approvedby || '-'}}
                            </td>
                            <td>
                                <span *ngIf="taxProfile1">
                                    <span *ngIf="taxProfile1 && taxProfile1.requestStatus==0; else secondBlock" style="color: red; " class="mx-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path
                                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </span>
                                    <ng-template #secondBlock>
                                        <app-status-icons [statusValue]="taxProfile1 && taxProfile1.requestStatus === 2 ? 'true' : 'false'"></app-status-icons>
                                    </ng-template>                                   
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-info px-3" (click)="addNewTaxFile(taxProfile1?(taxProfile1.id?taxProfile1.id:0):0, p1mid, p1remoteid)" 
                                title="Add New File" >{{taxProfile1?(taxProfile1.submissionTime?'Edit':'New'):'New'}}</button>
                            </td>
                        </tr>
                        <tr *ngIf="showTax2">
                            <td>{{taxProfile2?.declarationName||'-'}}
                            </td>
                            <td>ALA Staff Sourcing Pty Ltd</td>
                            <td>{{taxProfile2?.submissionTime ? (taxProfile2.submissionTime | date:'dd-MM-yyyy') : '-' }} 
                            </td>
                            <td>{{taxProfile2?.approvalTime ? (taxProfile2.approvalTime | date:'dd-MM-yyyy') : '-' }}
                            </td>
                            <td>{{taxProfile2?.approvedby||'-'}}
                            </td>
                            <td><span *ngIf="taxProfile2">
                                    <span *ngIf="taxProfile2 && taxProfile2.requestStatus==0; else secondBlock1" style="color: red; " class="mx-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path
                                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </span>
                                    <ng-template #secondBlock1>
                                        <app-status-icons [statusValue]="taxProfile2 && taxProfile2.requestStatus === 2 ? 'true' : 'false'"></app-status-icons>
                                    </ng-template>                                   
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-info px-3" (click)="addNewTaxFile(taxProfile2?(taxProfile2.id?taxProfile2.id:0):0, p2mid, p2remoteid)" 
                                title="Add New File" >{{taxProfile2?(taxProfile2.submissionTime?'Edit':'New'):'New'}}</button>
                            </td>
                        </tr>
                        <tr *ngIf="showTax4">
                            <td>{{taxProfile4?.declarationName || '-'}}
                            </td>
                            <td>Health Plus People Pty Ltd</td>
                            <td>{{taxProfile4?.submissionTime ? (taxProfile4.submissionTime | date:'dd-MM-yyyy') : '-' }}                        
                            </td>
                            <td>{{taxProfile4?.approvalTime ? (taxProfile4.approvalTime | date:'dd-MM-yyyy') : '-' }}
                            </td>
                            <td>{{taxProfile4?.approvedby || '-'}}
                            </td>
                            <td>
                                <span *ngIf="taxProfile4">
                                    <span *ngIf="taxProfile4 && taxProfile4.requestStatus==0; else secondBlock" style="color: red; " class="mx-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </span>
                                    <ng-template #secondBlock>
                                        <app-status-icons [statusValue]="taxProfile4 && taxProfile4.requestStatus === 2 ? 'true' : 'false'"></app-status-icons>
                                    </ng-template>                                   
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-info px-3" (click)="addNewTaxFile(taxProfile4?(taxProfile4.id?taxProfile4.id:0):0, p4mid, p4remoteid)" 
                                title="Add New File" >{{taxProfile4?(taxProfile4.submissionTime?'Edit':'New'):'New'}}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <!-- superannuation -->
        <div class="card">
            <strong class="ml-2 mt-2">Superannuation Information</strong>
            <div class="card-body table-responsive">
                <table class="table table-borderless table-hover">
                    <thead>
                        <tr>
                            <th>Fund Name</th>
                            <th>Employee Profile</th>
                            <th>Fund ABN</th>
                            <th>Fund Product Name</th>
                            <th>Fund USI</th>
                            <th>Account Name</th>
                            <th>Member Reference</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="viewSuperData.length==0; else dataBlock2">
                            <tr>
                                <td colspan="7" class="text-center">No Data
                                </td>
                            </tr>
                        </ng-container>
                        <ng-template #dataBlock2>
                            <tr *ngFor="let item of viewSuperData; index as i">
                                <td>{{item.super_name?item.super_name:'-'}}</td>
                                <td>
                                    <span *ngIf="item.remoteid.includes('PAYG-1')">Agri Labour Australia Pty Ltd
                                    </span>
                                    <span *ngIf="item.remoteid.includes('PAYG-2')">ALA Staff Sourcing Pty Ltd
                                    </span>
                                    <span *ngIf="item.remoteid.includes('PAYG-4')">Health Plus People Pty Ltd
                                    </span>
                                </td>
                                <td>{{item.supeR_ABN?item.supeR_ABN:'-'}}</td>
                                <td>{{item.super_product_name?item.super_product_name:'-' }}</td>
                                <td>{{item.super_usi?item.super_usi:'-' }}</td>
                                <td>{{item.super_account_name?item.super_account_name:'-' }}</td>
                                <td>{{item.super_member_reference?item.super_member_reference:'-' }}</td>
                                <td>                            
                                    <button class="btn btn-sm btn-info px-3" title="Add New File" (click)="addNewSuperannuation(item.remoteid,item.mid)">New</button>
                                </td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="showNewTaxFile">
        <app-candidate-tax-declaration [mid]="mid" [employee_remote_id]="employee_remote_id" [taxfileId]="taxfileId" [candidateID]="candidateID" (parentValueEmitter)="receiveParentValue1($event)"></app-candidate-tax-declaration>    
    </div>

    <div *ngIf="showNewSuperFile">
        <app-candidate-superannuation [mid]="mid" [remoteid]="employee_remote_id"[candidateID]="candidateID" (superValueEmitter)="receiveValuefromSuper($event)"></app-candidate-superannuation>
    </div>
</div>

<ng-template #accessDenied>
    <div class="access-denied-container">
        <h2 class="access-denied-message">Access Denied</h2>
    </div>
</ng-template>
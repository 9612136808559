<div class="blur-content">
  <div  [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
      <app-header></app-header>
      <div class="main-content-inner">
        <!-- market value area start -->
        <div class="row mt-5">
          <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
            <div class="card">
              <div class="card-body">
                <div *ngIf="loadingDeductions===false">
                  <h4>Create Deductions</h4>
                  <div  class="d-flex ng-select-container">
                    <!-- 1 -->
                    <ng-select [items]="employers" [placeholder]="selectedEmployer ? '' : 'Select an Employer'"
                      dropdownPosition="auto" [hideSelected]="false" [appendTo]="'body'" [ngModel]="selectedEmployer" [searchable]="false"
                      [multiple]="false" [ngClass]="'form-control flex-fill mx-1'" (change)="onSelectEmployer($event)">
                    </ng-select>
                    <!-- 2 -->
                    <ng-select [items]="candidatesdata" [placeholder]="selectedCandidate ? '' : 'Select a candidate'"
                      bindLabel="fullName" bindValue="fullName" dropdownPosition="auto" [hideSelected]="false"
                      [ngModel]="selectedCandidate" [title]="selectedEmployer ? '' : 'Please select an employer first'"
                      [appendTo]="'body'" [searchable]="true" [multiple]="false" [ngClass]="'form-control flex-fill mx-1'"
                      (change)="selectCandidate($event)">
                    </ng-select>
                    <!-- 3 -->
                    <ng-select [items]="canDeductions" [placeholder]="selectedDeduction ? '' : 'Select a Deduction'"
                      bindLabel="name" bindValue="pdid" dropdownPosition="auto" [hideSelected]="false"
                      [appendTo]="'body'" [searchable]="true" [multiple]="false"
                      [title]="selectedDeduction ? '' : 'Please select a candidate first'"
                      [ngClass]="'form-control flex-fill mx-1'" (change)="selectDeduction($event)" #deductionSelect>
                    </ng-select>
                    <button type="button" class=" btn btn-success btn-sm mx-1 px-3 mr-5" (click)="addDeduction()">+ Add</button>
                    <button class="btn btn-sm btn-success ml-lg-4 px-3" (click)="submitSelectedDeductions()" title="Selected Deductions" 
                    data-toggle="modal" data-target="#submitDeductionModal" >Submit</button>

                  </div>
                  <hr>
                  <div class="table-responsive">
                    <table class="table table-sm table-hover table-bordered">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Candidate</th>
                          <th>Deduction</th>
                          <th>Employer</th>
                          <th>Type</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Amount</th>
                          <th>$ Limit</th>
                          <th>Note</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="allDeduction.length > 0">
                          <tr *ngFor="let item of allDeduction">
                            <td><input type="checkbox" [(ngModel)]="item.selected" (change)="updateSelectedCount()"></td>
                            <td>{{item.candidateName}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.employer}}</td>
                            <td>{{(item.limit_type == 'fixed_amount' || item.limit_type == 'fixed_pay_period') ? 'Fixed' : (item.limit_type == 'unlimited') ? 'Recurring' : '-' }}</td>
                            <td><input type="date" name="startDate" [(ngModel)]="item.startDate" [placeholder]="currentDate"></td>
                            <td><input [hidden]="item.limit_type == 'fixed_amount' || item.limit_type == 'fixed_pay_period'" 
                              [disabled]="item.limit_type == 'fixed_amount' || item.limit_type == 'fixed_pay_period'" type="date" 
                              name="endDate" [(ngModel)]="item.endDate" placeholder="No End Time"></td>
                            <td><input type="text" name="amount" maxlength="7" pattern="\d{1,3}(\.\d{1,2})?" [(ngModel)]="item.amount" placeholder="9999.99"
                               required></td>
                            <td><input [hidden]="item.limit_type == 'unlimited'" maxlength="8" pattern="\d{1,4}(\.\d{1,2})?" 
                              [disabled]="item.limit_type == 'unlimited'" type="text" name="limit_fixed" [(ngModel)]="item.limit_fixed" placeholder="99999.99" required></td>
                            <td><input type="text" name="note" [(ngModel)]="item.note" placeholder="This note will be displayed on the ATD"></td>
                            <td class="text-center">
                              <button class="btn btn-sm btn-success px-1" (click)="submitIndividualDeduction(item)" title="Submit" 
                              data-toggle="modal" data-target="#submitDeductionModal">Submit</button></td>
                            <td class="text-center"><button class="btn btn-sm btn-danger" data-toggle="modal" data-target="#cancelDeductionModal" (click)="removededuction(item)">Cancel</button></td>
                          </tr>
                        </ng-container>
                                             
                        <ng-container *ngIf="allDeduction.length<1">
                          <tr>
                              <td colspan="11" class="text-center">No Data</td>
                          </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div *ngIf="loadingDeductions===true">
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- market value area end -->
      </div>
      <!-- main content area end -->
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="submitDeductionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Create Deductions</h5>
        <button type="button" (click)="closeModal()"  class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex justify-content-center">

        <div *ngIf="!message" class="spinner-border text-primary text-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="text-center" *ngIf="message" [innerHTML]="message">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"  (click)="closeModal()" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="cancelDeductionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Create Deductions</h5>
        <button type="button" (click)="closeModal()"  class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex justify-content-center text-center">
        <div *ngIf="!message" class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div *ngIf="message" class="d-flex align-content-center justify-content-center text-center">
          <p class="px-5">{{ message }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmCancel()">Yes</button>
        <button type="button" class="btn btn-danger"  (click)="closeModal()" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
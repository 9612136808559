<div class="blur-content">
    <div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }"
        style="background-color: #f2f8fa !important">
        <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
        <!-- main content area start -->
        <div class="main-content">
            <app-header></app-header>
            <div class="main-content-inner">
                <!-- market value area start -->
                <div class="row mt-5">
                    <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
                        <div class="card">
                            <div class="card-body">
                                <div *ngIf="loading===false"> 
                                    <h4>Assign Candidates</h4> <hr>                                   
                                    <div class="table-responsive">
                                        <table class="table table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('candidateName')">
                                                            Candidate Name<span ><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('accommodation')">
                                                            Accommodation<span ><i class="fa fa-sort"></i></span>
                                                        </div>                                                     
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('vehicle')">
                                                            Vehicles<span ><i class="fa fa-sort"></i></span>
                                                        </div> 
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="search-container">
                                                          <input type="text" [(ngModel)]="searchCandidateName" (ngModelChange)="applyFilter()"
                                                            class="form-control search-input" placeholder="Search...">
                                                          <app-search-icon class="search-icon"></app-search-icon>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="search-container">
                                                          <input type="text" [(ngModel)]="searchAccommodation" (ngModelChange)="applyFilter()"
                                                            class="form-control search-input" placeholder="Search...">
                                                          <app-search-icon class="search-icon"></app-search-icon>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="search-container">
                                                          <input type="text" [(ngModel)]="searchVehicle" (ngModelChange)="applyFilter()"
                                                            class="form-control search-input" placeholder="Search...">
                                                          <app-search-icon class="search-icon"></app-search-icon>
                                                        </div>
                                                      </td>
                                                </tr>
                                            </thead>
                                            <tbody> 
                                                <ng-container *ngIf="filteredData.length">                                                    
                                                    <tr *ngFor="let item of filteredData">
                                                        <td> <a href="javascript:void(0)" (click)="openViewModel(item.candidateId)">
                                                            {{ item.candidateName }}
                                                          </a></td>
                                                        <td>
                                                            <select id="accommodation" name="accommodation" class="custom-select custom-select-sm"
                                                                [(ngModel)]="item.accommodation" (change)="onAccommodationChange(item.candidateId, $event)">
                                                                <option value="" disabled selected>Select Accommodation</option>
                                                                <option *ngFor="let value of allAccommodations" [value]="value.address">{{ value.address }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select id="vehicle" name="vehicle" class="custom-select custom-select-sm"
                                                                [(ngModel)]="item.vehicle" (change)="onVehicleChange(item.candidateId, $event)">
                                                                <option value="" disabled selected>Select Vehicle</option>
                                                                <option *ngFor="let value of allVehicles" [value]='value.rego'>{{ value.rego }}</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <tr *ngIf="!filteredData.length">
                                                   <td class="text-center" colspan="3">No Data</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="loading===true">
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- market value area end -->
            </div>
            <!-- main content area end -->
        </div>
    </div>
</div>
<div *ngIf="isVisible" class="modal fade show d-block" tabindex="-1" role="dialog" aria-labelledby="confirmationModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" [attr.inert]="loading ? '' : null">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="onClose()">
          <span >&times;</span>
        </button>
      </div>
      <div  *ngIf="!successMessage" class="modal-body px-1 text-center">
        <p>{{ message }}</p>
        <p class="text-danger">{{ note }}</p>
        <div *ngIf="!loading">
          <button class="btn btn-sm btn-danger px-4" (click)="onConfirm()">{{ confirmButtonText }}</button>
          <button class="btn btn-sm btn-info px-4 ml-2" (click)="onCancel()" data-dismiss="modal"
          aria-label="Close">{{ cancelButtonText }}</button>
        </div>
        <div *ngIf="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="modal-footer text-center flex-column" *ngIf="successMessage">
        <p class="d-block w-100">{{ successMessage }}</p>
        <button type="button" class="btn btn-sm btn-info px-4 ml-2" (click)="onClose()" data-dismiss="modal"
        aria-label="Close">Close</button>
      </div>
    </div>
  </div>
</div>

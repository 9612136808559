<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" class="parent page-container"
    style="background-color: #f2f8fa !important;">
    <app-sidebar-menu [formSession]="formSession" [selected]="selected" [id]="id" [profilecompleted]="profilecompleted" [homeData]="homeData"
        [name]="name" [email]="email" [lastname]="lastname" (pageName)="selectPageName($event)" [deductionStatus]="deductionStatus"
        (updateProfileForm)="submitForm(updateProfile.value)">
    </app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content, parent">
        <app-header (pageName)="selectPageName($event)"> </app-header>
        <div class="main-content-inner">
            <!-- market value area start -->
            <div class="row mt-5 mb-5">
                <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important;">
                    <div class="card">
                        <div class="card-body">
                            <div class="market-status-table mt-2  pb-4 profile">
                                <form #updateProfile="ngForm" (ngSubmit)="submitForm(updateProfile.value)"
                                    method="post" autocomplete="off">
                                    <div *ngIf="!displayLoader">
                                        <div class="desktopView row mb-1 pl-3 ">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">
                                                    Title
                                                </p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <div *ngFor="let item of BullhornMeta">
                                                    <select *ngIf="item.name == 'namePrefix'" class="form-control"
                                                        name="namePrefix" [(ngModel)]="profile.namePrefix"
                                                        (change)="updateInput($event)">
                                                        <option *ngIf="!profile.namePrefix" selected value="">Select
                                                            Title</option>
                                                        <option *ngFor="let option of item.options"
                                                            [attr.selected]="profile.namePrefix == option.value"
                                                            [value]="option.value">{{option.label}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2 ">                                              
                                                <app-status-icons [statusValue]="profile.namePrefix?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Title</span>
                                                <div class="input_Style">
                                                    <div *ngFor="let item of BullhornMeta">
                                                        <select *ngIf="item.name == 'namePrefix'"
                                                            class="input_Style p-Left" style="border:none;  "
                                                            name="namePrefix" [(ngModel)]="profile.namePrefix"
                                                            (change)="updateInput($event)">
                                                            <option *ngIf="!profile.namePrefix" selected value=""
                                                                class="dropdownWidth">Select
                                                                Title</option>
                                                            <option *ngFor="let option of item.options"
                                                                class="dropdownWidth"
                                                                [attr.selected]="profile.namePrefix == option.value"
                                                                [value]="option.value">{{option.label}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="validation_tick">
                                                    <app-status-icons [statusValue]="profile.namePrefix?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">
                                                    First Name
                                                </p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Your First Name" disabled                                                    
                                                     ngbTooltip="This field cannot be edited."
                                                   [(ngModel)]="profile.firstName" type="text" class="form-control"
                                                    name="firstName">
                                            </div><div class="col-2">                                                
                                                    <app-status-icons [statusValue]="profile.firstName?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine disableField">
                                                <span class="title">
                                                    First Name
                                                </span>
                                                <div class="input_Style">
                                                    <input placeholder="Your First Name" disabled  ngbTooltip="This field cannot be edited."                                                        
                                                        style=" border:none; outline: none;"                                                       
                                                        [(ngModel)]="profile.firstName" type="text"
                                                        class="input_Style p-Left" name="firstName">
                                                </div>
                                                <div class="validation_tick">
                                                    <app-status-icons [statusValue]="profile.firstName?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Last Name</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Your Last Name"
                                                     disabled  ngbTooltip="This field cannot be edited."                                                   
                                                    [(ngModel)]="profile.lastName" type="text" class="form-control"
                                                    name="lastName">
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                    <app-status-icons [statusValue]="profile.lastName?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine disableField">
                                                <span class="title">
                                                    Last Name
                                                </span>
                                                <div class="input_Style">
                                                    <input placeholder="Your Last Name"
                                                        style="border:none; "
                                                        disabled  ngbTooltip="This field cannot be edited."                                                      
                                                        [(ngModel)]="profile.lastName" type="text"
                                                        class="input_Style p-Left" name="lastName">
                                                </div>
                                                <div class="validation_tick">
                                                    <app-status-icons [statusValue]="profile.lastName?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">
                                                    Preferred Name
                                                </p>
                                            </div>
                                            <div class="col-7">
                                                <input placeholder="Your Preferred Name"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" name="nickName"
                                                    type="text" class="form-control pointer"
                                                    (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.nickName">
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">
                                                    Preferred Name
                                                </span>
                                                <div class="input_Style">
                                                    <input placeholder="Your Preferred Name"
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)"
                                                        name="nickName" type="text" class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)" style="border:none; "
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.nickName">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Email</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Your Email"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)"
                                                    style="background-color: none !important;" [email]="true"
                                                    #email="ngModel" type="email" class="form-control pointer"
                                                    (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.email" name="email">
                                                <span *ngIf="email.invalid && email.touched"
                                                    style="font-size: 13px;color: red;display: block;">Invalid email
                                                    format</span>
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.email && email.valid?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Email</span>
                                                <div class="input_Style">
                                                    <input placeholder="Your Email"
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)"
                                                        style="background-color: none !important;border:none; "
                                                        [email]="true" #email="ngModel" type="email"
                                                        class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.email" name="email">
                                                    <span *ngIf="email.invalid && email.touched"
                                                        style="font-size: 13px;color: red;display: block;">Invalid email
                                                        format</span>
                                                </div>
                                                <div class="validation_tick">
                                                     <app-status-icons [statusValue]="profile.email && email.valid?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Mobile Phone</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Your Mobile No"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" #mobile="ngModel"
                                                    (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.mobile" type="text" class="form-control"
                                                    name="mobile">
                                                <span *ngIf="mobile.invalid && mobile.touched"
                                                    style="font-size: 13px;color: red;display: block;">Invalid
                                                    format</span>
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                    <app-status-icons [statusValue]="profile.mobile?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">
                                                    Mobile Phone
                                                </span>
                                                <div class="input_Style">
                                                    <input placeholder="Your Mobile No"
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)"
                                                        #mobile="ngModel" (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)" style="border:none; "
                                                        [(ngModel)]="profile.mobile" type="text"
                                                        class="input_Style p-Left" name="mobile">
                                                    <span *ngIf="mobile.invalid && mobile.touched"
                                                        style="font-size: 13px;color: red;display: block;">Invalid
                                                        format</span>
                                                </div>
                                                <div class="validation_tick">
                                                        <app-status-icons [statusValue]="profile.mobile?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">
                                                    Date Of Birth
                                                </p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input name="dateOfBirth" type="date" data-date=""  ngbTooltip="This field cannot be edited."
                                                    data-date-format="DD-MMMM-YYYY" class="form-control pointer" 
                                                    [(ngModel)]="profile.dateOfBirth" disabled 
                                                   >
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <span *ngIf="profile.dateOfBirth ? profile.dateOfBirth :null"
                                                    style="color:green ;"> <svg xmlns="http://www.w3.org/2000/svg"
                                                        width="16" height="16" fill="currentColor"
                                                        class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                        <path
                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                    </svg></span>
                                                <span *ngIf="profile.dateOfBirth ? !profile.dateOfBirth :true"
                                                    style="color:rgb(236, 236, 27) ;"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-exclamation-triangle-fill"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                    </svg></span>
                                            </div>
                                            <!--  -->
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine disableField">
                                                <span class="label-text title">
                                                    Date Of Birth
                                                </span>
                                                <div class="input_Style">                                                  
                                                    <input name="dateOfBirth" type="date" data-date=""
                                                        style=" border:none ; text-align: left; align-content: flex-start;"
                                                        data-date-format="DD-MMMM-YYYY" disabled  ngbTooltip="This field cannot be edited."
                                                        class="input_Style form-control pointer"
                                                        [(ngModel)]="profile.dateOfBirth">
                                                </div>
                                                <div class="validation_tick">
                                                    <span *ngIf="profile.dateOfBirth ? profile.dateOfBirth :null"
                                                        style="color:green ;"> <svg xmlns="http://www.w3.org/2000/svg"
                                                            width="16" height="16" fill="currentColor"
                                                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                            <path
                                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                        </svg></span>
                                                    <span *ngIf="profile.dateOfBirth ? !profile.dateOfBirth :true"
                                                        style="color:rgb(236, 236, 27) ;"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-exclamation-triangle-fill"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                        </svg></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Gender</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <div *ngFor="let item of BullhornMeta">
                                                    <select *ngIf="item.name == 'gender'" class="form-control "
                                                        name="gender" [(ngModel)]="profile.gender"
                                                        (change)="updateInput($event)">
                                                        <option selected *ngIf="!profile.gender" value="">Select Gender
                                                        </option>
                                                        <option *ngFor="let option of item.options"
                                                            [attr.selected]="profile.gender == option.value"
                                                            [value]="option.value">
                                                            {{option.label}}
                                                           
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                    <app-status-icons [statusValue]="profile.gender?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">
                                                    Gender
                                                </span>
                                                <div class="input_Style">
                                                    <div *ngFor="let item of BullhornMeta">
                                                        <select *ngIf="item.name == 'gender'" class="input_Style p-Left"
                                                            style="border:none; " name="gender"
                                                            [(ngModel)]="profile.gender" (change)="updateInput($event)">
                                                            <option selected *ngIf="!profile.gender" value="">Select
                                                                Gender
                                                            </option>
                                                            <option style="align-items:left ;"
                                                                *ngFor="let option of item.options"
                                                                [attr.selected]="profile.gender == option.value"
                                                                [value]="option.value">
                                                                {{option.label}}
                                                            
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="validation_tick">
                                                        <app-status-icons [statusValue]="profile.gender?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Country of Citizenship</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input name="customText11" type="text" ngbTooltip="This field cannot be edited." class="form-control pointer"
                                                    [(ngModel)]="profile.customText11" disabled>
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                    <app-status-icons [statusValue]="profile.customText11?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">
                                                    Country of Citizenship
                                                </span>
                                                <div class="input_Style">
                                                    <input name="customText11" type="text" style=" border:none"
                                                        data-date-format="DD-MMMM-YYYY" disabled ngbTooltip="This field cannot be edited."
                                                        class="input_Style p-Left" [(ngModel)]="profile.customText11">
                                                </div>
                                                <div class="validation_tick">
                                                    <app-status-icons [statusValue]="profile.customText11?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">
                                                    First Language
                                                </p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <div *ngFor="let item of BullhornMeta">
                                                    <select *ngIf="item.name == 'customText13'" class="form-control"
                                                        [(ngModel)]="profile.customText13" name="customText13"
                                                        (change)="updateInput($event)">
                                                        <option selected *ngIf="!profile.customText13" value="">Select
                                                            Language</option>
                                                        <option *ngFor="let option of item.options"
                                                            [attr.selected]="profile.customText13 == option.value"
                                                            [value]="option.value">
                                                            {{option.label}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                    <app-status-icons [statusValue]="profile.customText13?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">
                                                    First Language
                                                </span>
                                                <div class="input_Style">
                                                    <div *ngFor="let item of BullhornMeta">
                                                        <select *ngIf="item.name == 'customText13'"
                                                            class="input_Style p-Left" style="border:none;  "
                                                            [(ngModel)]="profile.customText13" name="customText13"
                                                            (change)="updateInput($event)">
                                                            <option selected *ngIf="!profile.customText13" value="">
                                                                Select
                                                                Language</option>
                                                            <option *ngFor="let option of item.options"
                                                                style="  font-size: 3vw; width: auto;"
                                                                [attr.selected]="profile.customText13 == option.value"
                                                                [value]="option.value">
                                                                {{option.label}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="validation_tick">
                                                    <app-status-icons [statusValue]="profile.customText13?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Street Address</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="St. Address"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="text"
                                                    class="form-control pointer" (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.candidateAddress1" name="address1">
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.candidateAddress1?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">
                                                    Address 1
                                                </span>
                                                <div class="input_Style">
                                                    <input placeholder="St. Address"
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)" style="border:none; "
                                                        (keydown.control.backspace)="updateInput($event)" type="text"
                                                        class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.candidateAddress1" name="address1">
                                                </div>
                                                <div class="validation_tick">
                                                    <app-status-icons [statusValue]="profile.candidateAddress1?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Street Address <span
                                                        class="text-secondary">(Line 2)</span></p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="St. Address Line 2"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="text"
                                                    class="form-control pointer" (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.candidateAddress2" name="address2">
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title"> Address 2</span>

                                                <div class="input_Style">
                                                    <input placeholder="St. Address Line 2" style="border:none; "
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)" type="text"
                                                        class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.candidateAddress2" name="address2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">City</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Your City"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="text"
                                                    class="form-control pointer" (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.candidateAddressCity" name="city">
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.candidateAddressCity?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">
                                                    City
                                                </span>
                                                <div class="input_Style">
                                                    <input placeholder="Your City" style="border:none; "
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)" type="text"
                                                        class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.candidateAddressCity" name="city">

                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.candidateAddressCity?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">State</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Your State"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="text"
                                                    class="form-control pointer" (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.candidateAddressState" name="state">
                                            </div>
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.candidateAddressState?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">State</span>
                                                <div class="input_Style">
                                                    <input placeholder="Your State" style="border:none; "
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)" type="text"
                                                        class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.candidateAddressState" name="state">

                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.candidateAddressState?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Postal Code</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Your Postal Code"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="text"
                                                    class="form-control pointer " (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)" [(ngModel)]="profile.zip"
                                                    name="zip">
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.zip?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Postal Code</span>
                                                <div class="input_Style">
                                                    <input placeholder="Your Postal Code" style="border:none; "
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)" type="text"
                                                        class="input_Style p-Left pointer "
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.zip" name="zip">
                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.zip?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Address Country </p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <select class="form-control"
                                                    [(ngModel)]="profile.candidateAddressCountryId"
                                                    name="candidateAddressCountryId" (change)="updateInput($event)">
                                                    <option selected *ngIf="!profile.candidateAddressCountryId"
                                                        value="">Select Address Country</option>
                                                    <option *ngFor="let item of countryIds | keyvalue"
                                                        [attr.selected]="profile.candidateAddressCountryId == item.value"
                                                        [value]="item.value">
                                                        {{item.key}}
                                                    </option>
                                                </select>
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.candidateAddressCountryId?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Address Country</span>
                                                <div class="input_Style ">
                                                    <select class="input_Style p-Left" style="border:none; "
                                                        [(ngModel)]="profile.candidateAddressCountryId"
                                                        name="candidateAddressCountryId" (change)="updateInput($event)">
                                                        <option selected *ngIf="!profile.candidateAddressCountryId"
                                                            value="">Select Address Country</option>
                                                        <option *ngFor="let item of countryIds | keyvalue"
                                                            style="width: auto !important;"
                                                            [attr.selected]="profile.candidateAddressCountryId == item.value"
                                                            [value]="item.value">
                                                            {{item.key}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.candidateAddressCountryId?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-sm-12 label">
                                            <h5 class="pt-4">Driver's Licence Details</h5>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3 pt-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Licence Type</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <select class="form-control pointer" (change)="updateInput($event)" [(ngModel)]="profile.customText26" name="customText26">
                                                    <option value="" selected disabled>Select Licence Type</option>
                                                    <option *ngFor="let option of driversLicenceOptions;let i = index" [value]="option.value">{{ option.label }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="mobileView">
                                            <div class="outLine">
                                                <span class="title">Licence Type</span>
                                                <div class="input_Style">
                                                    <select class="input_Style p-Left pointer" (change)="updateInput($event)" [(ngModel)]="profile.customText26" name="customText26">
                                                        <option value="" selected disabled>Select Licence Type</option>
                                                        <option *ngFor="let option of driversLicenceOptions;let i = index" [value]="option.value">{{ option.label }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Licence Number</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Driver's Licence Number" (keydown.shift.backspace)="updateInput($event)"
                                                (change)="updateInput($event)"
                                                (keydown.control.backspace)="updateInput($event)"
                                                (keypress)="updateInput($event)"
                                                (keydown.backspace)="updateInput($event)" type="text" class="form-control pointer"
                                                [(ngModel)]="profile.customText8" name="customText8">
                                            </div>
                                        </div>
                                        <div class="mobileView">
                                            <div class="outLine">
                                                <span class="title">Licence Number</span>
                                                <div class="input_Style">
                                                    <input  placeholder="Driver's Licence Number" (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)"
                                                    (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)" type="text" class="input_Style p-Left pointer" (change)="updateInput($event)"
                                                    [(ngModel)]="profile.customText8" name="customText8">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Licence Expiry</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Driver's Licence Expiry Date" data-date-format="DD-MMMM-YYYY"  type="date" 
                                                data-date="" class="form-control pointer" (keydown.shift.backspace)="updateInput($event)"
                                                (change)="updateInput($event)"
                                                (keydown.control.backspace)="updateInput($event)"
                                                (keypress)="updateInput($event)"
                                                (keydown.backspace)="updateInput($event)"
                                                [(ngModel)]="profile.customDate6" name="customDate6">
                                            </div>
                                        </div>
                                        <div class="mobileView">
                                            <div class="outLine">
                                                <span class="title">Licence Expiry</span>
                                                <div class="input_Style">
                                                    <input placeholder="Driver's Licence Expiry Date"
                                                    (change)="updateInput($event)"
                                                    type="date" class="input_Style p-Left pointer"
                                                    [(ngModel)]="profile.customDate6" name="customDate6">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-sm-12 label">
                                            <h5 class="pt-4">Emergency Contact </h5>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3 pt-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Name</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Emergency Contact Name"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)" (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="text"
                                                    class="form-control pointer" (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.customText36" name="customText36">
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.customText36?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Name</span>
                                                <div class="input_Style">
                                                    <input placeholder="Emergency Contact Name" style="border:none; "
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)" type="text"
                                                        class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.customText36" name="customText36">
                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.customText36?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Relationship</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Relation with Emergency Contact"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="text"
                                                    class="form-control pointer" (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.customText37" name="customText37">
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.customText37?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Relationship</span>

                                                <div class="input_Style">
                                                    <input placeholder="Relation with Emergency Contact"
                                                        style="border:none; "
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)" type="text"
                                                        class="input_Style p-Left pointer"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.customText37" name="customText37">

                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.customText37?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Email</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Emergency Contact Email" [email]="true"
                                                    #email3="ngModel" (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)" type="email"
                                                    class="form-control pointer " (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.email3" name="email3">
                                                <span *ngIf="email3.invalid && email3.touched"
                                                    style="font-size: 13px;color: red;display: block;">Invalid email
                                                    format</span>
                                            </div>
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.email3 && email3.valid?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Email</span>
                                                <div class="input_Style">
                                                    <input placeholder="Emergency Contact Email" [email]="true"
                                                        style="border:none;" #email3="ngModel"
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)"
                                                        (keydown.control.backspace)="updateInput($event)" type="email"
                                                        class="input_Style p-Left pointer "
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.email3" name="email3">
                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.email3 && email3.valid?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="desktopView row mb-1 pl-3">
                                            <div class="col-3 label">
                                                <p class="label-text mb-0">Mobile No.</p>
                                            </div>
                                            <div class="col-7 mb-2">
                                                <input placeholder="Emergency Contact Mobile No." #phone3="ngModel"
                                                    (keydown.shift.backspace)="updateInput($event)"
                                                    (change)="updateInput($event)"
                                                    (keydown.control.backspace)="updateInput($event)"
                                                    (keypress)="updateInput($event)"
                                                    (keydown.backspace)="updateInput($event)"
                                                    [(ngModel)]="profile.phone3" type="text" class="form-control"
                                                    name="phone3">
                                            </div>
                                            <!-- Visual Prompt  -->
                                            <div class="col-2">
                                                <app-status-icons [statusValue]="profile.phone3?'true':'false'"></app-status-icons>
                                            </div>
                                        </div>
                                        <div class=" mobileView ">
                                            <div class="outLine">
                                                <span class="title">Mobile Phone</span>
                                                <div class="input_Style">
                                                    <input placeholder="Emergency Contact Mobile No." #phone3="ngModel"
                                                        (keydown.shift.backspace)="updateInput($event)"
                                                        (change)="updateInput($event)" style="border:none;"
                                                        (keydown.control.backspace)="updateInput($event)"
                                                        (keypress)="updateInput($event)"
                                                        (keydown.backspace)="updateInput($event)"
                                                        [(ngModel)]="profile.phone3" type="text"
                                                        class="input_Style p-Left" name="phone3">
                                                </div>
                                                <div class="validation_tick">
                                                <app-status-icons [statusValue]="profile.phone3?'true':'false'"></app-status-icons>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-2 pl-3">
                                            <div class="col-4"></div>
                                            <div class="col-6 label">
                                                <input [disabled]="updateProfile.invalid || !formSession" type="submit"
                                                    data-toggle="modal" data-target="#switchTab" value="Update"
                                                    class="btn btn-sm btn-primary px-3">
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                    </div>
                                </form>
                                <div *ngIf="displayLoader" style="width: 55% !important;">
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- market value area end -->
            </div>
        </div>
        <!-- main content area end -->
    </div>

</div>

<div *ngIf="saving" class="container pop-up">
    <div style="width: 30%;margin: 16% auto;">
        <div class="box small-6 large-centered " style="border: 2px solid #b2b2b2;">
            <p class="py-3">{{message}} </p>
        </div>
    </div>
</div>

<a class="invisible" #btnSwitchTab data-toggle="modal" data-target="#switchTab">
</a>

<!-- Modal swtich tab without saving form -->
<div class="modal fade" id="switchTab" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Personal Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body text-center">
                <div *ngIf="!savedetailsModal">
                    <p>You have made changes. <br>Do you want to save or discard them?</p>
                    <hr>
                    <div class="button-container" style="display: flex; justify-content: space-evenly;">
                        <button class="btn btn-sm btn-outline-success" style="cursor: pointer; " (click)=submitForm(updateProfile.value)>
                            Save
                        </button>  
                        <button #closeModelProfile data-dismiss="modal" aria-label="Close"
                                  class="btn btn-sm btn-outline-secondary ">Cancel
                        </button>                        
                        <button class="btn btn-sm btn-outline-danger" (click)="navigateUrl()">
                           Discard
                        </button>                          
                      </div>
                </div>
                <div *ngIf="savedetailsModal" class="text-center">
                    <span >Saving The Details</span>
                    <button #closeModelProfile type="button" class="close invisible" data-dismiss="modal"
                        style="font-size:2vw" aria-label="Close">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-submit-deductions',
  templateUrl: './submit-deductions.component.html',
  styleUrls: ['./submit-deductions.component.css']
})
export class SubmitDeductionsComponent implements OnInit {
  loadingDeductions:boolean=true;
  selected:string='submit-deductions';
  signedDeduction:any;
  message:string='';
  mobileView: boolean = false;
  submitDeductionOnAstute:any;

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  constructor(private http:HttpClient,private route: ActivatedRoute) { 
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit(): void {
    this.getSignedDeductions()
  }
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
    }
  }

  getSignedDeductions() {
    this.loadingDeductions = true;
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetAddedDeductions`;
    this.http.get(url).subscribe(
      (response: any) => {
          this.signedDeduction = response.data.filter((data:any)=>data.status===2 || data.status===3) || []; 
          this.signedDeduction.sort((a:any, b:any) => b.id - a.id);
          this.loadingDeductions = false;
      },
      (error) => {
        console.error("Error fetching deductions:", error);
        this.loadingDeductions = false;
      }
    );
  }

  completededuction(data: any) {
    this.submitDeductionOnAstute=data;
    this.message='Are you sure you want to complete this deduction? This will remove it from the list.';    
  }
 
  submitOnAstutue(data: any) {
    this.message='';
    const PDID=data.pdid;
    const UID=data.uid;
    const id=data.id;
    window.open(
      `https://agrilabour.astutepayroll.com/user/edit?UID=${data.uid}`
    );
    const url = `${environment.apiConfig.uri}/admin/Candidates/SubmitToAstuteDeductions?PDID=${PDID}&UID=${UID}&id=${id}`;
    this.http.post(url,'').subscribe(() => {
        this.getSignedDeductions();
      },
      error => {
        console.error('Error completing deduction:', error);
      }
    );
  }

  complete(){
    const data=this.submitDeductionOnAstute;
    const PDID=data.pdid;
    const UID=data.uid;
    const id=data.id;
    const url = `${environment.apiConfig.uri}/admin/Candidates/CompleteDeductions?PDID=${PDID}&UID=${UID}&id=${id}`;
    this.http.post(url,'').subscribe(() => {
        this.message='Deduction Completed successfully.'
        this.getSignedDeductions();
      },
      error => {
        console.error('Error completing deduction:', error);
        this.message='Error completing deduction:'
      }
    );

  }

}

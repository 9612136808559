<div class="blur-content">
    <div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }"
        style="background-color: #f2f8fa !important">
        <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
        <!-- main content area start -->
        <div class="main-content">
            <app-header></app-header>
            <div class="main-content-inner">
                <!-- market value area start -->
                <div class=" mt-5">
                    <div style="padding-right: 0 !important; margin-right: 0 !important">
                        <div class="card">
                            <div class="card-body">
                                <div *ngIf="loadingAccommodations===false">
                                    <div class="d-flex justify-content-between">
                                        <h4>Accommodation</h4> 
                                        <button class="ml-5 btn btn-sm btn-success px-3"
                                            title="Add Workflow" data-toggle="modal"
                                            data-target="#staticBackdrop" (click)="addAccomodation()">+ New</button>
                                    </div>
                                    <hr>
                                    <div class="table-responsive">
                                        <table class="table table-sm table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('address')">
                                                            Address<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('city')">
                                                            City<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('state')">
                                                            State<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('beds')">
                                                            Beds<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('rentalProvider')">
                                                            Rental Provider<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('site')">
                                                            Site<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>

                                                  <th>
                                                    <div class="flex-container" (click)="sort('startDate')">
                                                      Start<span><i class="fa fa-sort"></i></span>
                                                    </div>
                                                  </th>
                                                  <th>
                                                    <div class="flex-container" (click)="sort('endDate')">
                                                      End<span><i class="fa fa-sort"></i></span>
                                                    </div>
                                                  </th>
                                                  <th>
                                                    <div class="flex-container" (click)="sort('notes')">
                                                        Notes<span><i class="fa fa-sort"></i></span>
                                                    </div>
                                                  </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('status')">
                                                            Status<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('costPerWeek')">
                                                            Cost/Week<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>Utilities</th>
                                                    <th>
                                                        <div class="flex-container" (click)="sort('report')">
                                                            Report<span><i class="fa fa-sort"></i></span>
                                                        </div>
                                                    </th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody> 
                                                <ng-container *ngIf="allAccommodations.length">
                                                    <tr *ngFor="let item of allAccommodations; index as i">
                                                      <td>{{i+1}}</td>
                                                      <td>{{item.address}}</td>
                                                      <td>{{item.city}}</td>
                                                      <td>{{item.state}}</td>
                                                      <td>{{item.beds}}</td>
                                                      <td>{{item.rentalProvider}}</td>
                                                      <td>{{item.site}}</td>
                                                      <td>{{item.startDate?(item.startDate| date: 'dd-MM-yyyy'):'N/A'}}</td>
                                                      <td>{{item.endDate?(item.endDate| date: 'dd-MM-yyyy'):'N/A'}}</td>
                                                      <td>{{item.notes}}</td>
                                                      <td>{{item.status}}</td>
                                                      <td>{{item.costPerWeek}}</td>
                                                      <td>{{item.utilities}}</td>
                                                      <td>{{ item.report==true?'Yes':item.report==false?'No':'-' }}</td>
                                                      <td class="text-center">
                                                      <button class="btn btn-sm btn-info px-3" title="Edit Accommodation" data-toggle="modal"
                                                                data-target="#staticBackdrop" (click)="updateAccomodation(i)">Edit</button>
                                                      </td>
                                                      <td class="text-center">
                                                        <button title="Delete" class="btn btn-sm btn-info ml-1" (click)="deleteAccommodation(item)">
                                                            <i class="fa fa-trash-o" style="font-size: 18px"></i>
                                                          </button>
                                                      </td>
                                                    </tr>
                                                  </ng-container>
                                                  <tr *ngIf="!allAccommodations.length">
                                                    <td class="text-center" colspan="10">No Data</td>
                                                  </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="loadingAccommodations===true">
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- market value area end -->
            </div>
            <!-- main content area end -->
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Section for Unsaved Changes Warning -->
            <div *ngIf="unsavedModel">
                <div class="modal-header d-flex justify-content-around text-center">
                    <h5 class="modal-title">Unsaved Changes</h5>
                </div>
                <div class="modal-body text-center">
                    <p>You have made changes. <br>Do you want to save or discard them?</p>
                    <div class="button-container" style="display: flex; justify-content:center;">
                        <button (click)="unsavedModel=false;successMessage=''" class="btn btn-sm btn-outline-secondary">Cancel</button>                        
                        <button class="btn btn-sm btn-outline-danger mx-3" (click)="discardChanges()" data-dismiss="modal">
                            Discard
                        </button>                          
                        <button class="btn btn-sm btn-outline-success" style="cursor: pointer;" type="submit" (click)="saveAccomodation();">
                            Save
                        </button>  
                    </div>
                </div>
            </div>
            <!-- Main Modal Content -->
            <div *ngIf="!unsavedModel">
                <div *ngIf="!successMessage && !loading" class="modal-header d-flex justify-content-around mt-2">
                    <button  class="btn btn-info px-3 fixWidth"  type="submit" title="Save Accommodation" (click)="saveAccomodation()">Save</button>
                    <button  class="btn btn-info px-3 fixWidth" title="Close" data-dismiss="modal" (click)="onCloseClick()">Close</button>
                </div>
                <div class="modal-body">
                    <form *ngIf="!loading" [formGroup]="accomodationForm">
                        <div *ngIf="!successMessage">
                            <div *ngIf="validationMessage" class="alert alert-danger">
                                {{ validationMessage }}
                            </div>
                            <div class="form-group mb-0">
                                <label for="address" class="form-label">Address</label>
                                <input type="text" class="form-control" id="address" (input)="validationMessage = ''" formControlName="address">
                            </div>
                            <div class="form-group mb-0">
                                <label for="city" class="form-label">City</label>
                                <input type="text" class="form-control" id="city" (input)="validationMessage = ''" formControlName="city">
                            </div>
                            <div class="form-group mb-0">
                                <label for="state" class="form-label">State</label>
                                <select class="form-select form-control" id="state" (input)="validationMessage = ''" formControlName="state">
                                    <option value="" disabled>Select State</option>
                                    <option *ngFor="let state of states" [value]="state">{{ state }}</option>
                                </select>
                            </div>
                            <div class="form-group mb-0">
                                <label for="beds" class="form-label">Beds</label>
                                <input type="number" class="form-control" id="beds" (input)="validationMessage = ''" formControlName="beds">
                            </div>
                            <div class="form-group mb-0">
                                <label for="rentalProvider" class="form-label">Rental Provider</label>
                                <input type="text" class="form-control" id="rentalProvider" (input)="validationMessage = ''" formControlName="rentalProvider">
                            </div>
                            <div class="form-group mb-0">
                                <label for="site" class="form-label">Site</label>
                                <ng-select
                                  [items]="sites"
                                  id="site"
                                  bindLabel="site"
                                  formControlName="site"
                                  (change)="validationMessage = ''"
                                  [clearable]="false"
                                  class="form-control dropDown">
                                </ng-select>
                            </div>
                            <div>
                                <label for="startDate" class="form-label">Start Date</label>
                                <input type="date" class="form-control" id="startDate" (input)="validationMessage = ''" formControlName="startDate">
                              </div>
                              <div>
                                <label for="endDate" class="form-label">End Date</label>
                                <input type="date" class="form-control" id="endDate" (input)="validationMessage = ''" formControlName="endDate">
                              </div>
                              <div>
                                <label for="notes" class="form-label">Notes</label>
                                <textarea type="text" id="notes" name="notes" rows="1" class="form-control" formControlName="notes"
                                        (input)="validationMessage = ''">
                                </textarea>
                              </div>
                            <div class="form-group mb-0">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select form-control" id="status" (input)="validationMessage = ''" formControlName="status">
                                    <option value="" disabled>Select Status</option>
                                    <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
                                </select>
                            </div>
                            <div class="form-group mb-0">
                                <label for="costPerWeek" class="form-label">Cost per Week</label>
                                <input type="text" class="form-control" id="costPerWeek" (input)="validationMessage = ''" formControlName="costPerWeek">
                            </div>
                            <div>
                                <label for="utilities" class="form-label">Utilities</label>
                                <input type="text" class="form-control" id="utilities" (input)="validationMessage = ''" formControlName="utilities">
                            </div>  
                            <div>
                                <label for="report" class="form-label">Include in Reports</label>
                                <select
                                  class="form-select form-control"
                                  id="report" 
                                  formControlName="report"
                                >
                                  <option [value]=null disabled>Select</option>
                                  <option [value]="true">Yes</option>
                                  <option [value]="false">No</option>
                                </select>
                              </div>                
                        </div>
                        <div *ngIf="successMessage" class="text-center">
                            <p>{{successMessage}}</p>
                            <button class="btn btn-info px-3" title="Close" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                    <div class="d-flex justify-content-center">
                        <div *ngIf="loading" class="spinner-border text-primary" role="status">
                            <span class="sr-only my-5 py-5">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Delete -->
<app-confirmation-modal 
  [title]="'Delete Accommodation'"
  [message]="'Are you sure you want to delete this Accommodation?'"
  [note]="'(Note: This action cannot be undone)'"
  [confirmButtonText]="'Delete'"
  [cancelButtonText]="'Cancel'"
  [successMessage]="successMessage"
  [loading]="loading"
  [(isVisible)]="isModalVisible"
  (confirm)="confirmDelete()"
  (cancel)="onCancel()"
  (close)="onClose()"
></app-confirmation-modal>
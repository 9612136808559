<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important;">
  <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
  <!-- main content area start -->
  <div class="main-content">
    <app-header></app-header>
    <div class="main-content-inner">
      <!-- market value area start -->
      <div class="row mt-5">
        <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important;">
          <div class="card">
            <div class="card-body">
              <!-- Candidate Files -->
              <div class="row">
                <div class=" desktopView col-7">
                  <h4>Training Assessment</h4>
                  <br />
                </div>
                <div class="mobileView col-8">
                  <h4 style="font-size: 4vw;">Training Assessment</h4>
                  <br />
                </div>
                <div class="col-4 text-right"></div>
              </div>
              <div class="table-responsive">
                <div *ngIf="loadingTrainingFiles">
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                </div>
                <table *ngIf="!loadingTrainingFiles" class="table mb-2 table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Number</th>
                      <th scope="col">File Name</th>
                      <th scope="col">Total Questions</th>
                      <th scope="col"><span class="abc">Quiz</span></th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of trainingFilesList; index as i" id="item-{{ item.id }}">
                      <td scope="col">{{ i + 1 }}</td>
                      <td scope="col">{{ item.fileName }}</td>
                      <td scope="col">{{ item.questionCount }}</td>
                      <td scope="col">
                        <!-- class="col-5 text-right" -->
                        <div>
                          <button class="btn btn-sm btn-info" type="button" data-toggle="modal"
                            data-target="#addModalCenter" (click)="addViewQuiz(item.id, item.fileName, i)">
                            Add / View
                          </button>
                        </div>
                      </td>
                      <td scope="col">
                        <button title="Delete Quiz" class="btn btn-sm btn-info" data-toggle="modal"
                          data-target="#deleteModel" (click)="deleteQuizRequest(item)">
                          <i class="fa fa-trash-o" style="font-size: 18px"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Pagination  -->
                <div class="desktopView">
                  <div *ngIf="!loading" class=" d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                      [maxSize]="4" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData()"
                      class="flex-wrap">
                    </ngb-pagination>
                    <select class="form-select" style="width: auto" [(ngModel)]="pageSize"
                      (ngModelChange)="refreshData()">
                      <option *ngFor="let pageSizeOption of pageSizeOptions" [ngValue]="pageSizeOption">
                        {{ pageSizeOption }} items per page
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mobileView">

                  <div *ngIf="!loading" class=" row d-flex justify-content-between p-2">
                    <div class="col-1"></div>
                    <div class="col-1"></div>
                    <div class="row">
                      <div class="col-2"></div>
                      <div class="col-2"></div>
                    </div>
                  </div>
                </div>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- market value area end -->
    </div>
  </div>
  <!-- main content area end -->
</div>
<!-- Modal Add/Update Quiz  -->
<div class="modal fade bd-example-modal-lg" id="addModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="addModalCenterTitle" aria-hidden="true" aria-labelledby="myLargeModalLabel">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class=" row modal-header justify-content-between d-flex ml-0">
        <div class="col-4">
          <p class="modal-title" id="addModalLongTitle">{{ fileName }}</p>
        </div>
        <button type="button" class="col-6 btn btn-sm btn-info mt-1 fa-solid fa-circle-plus "
          style="margin-left: auto; color: rgb(241, 232, 232);" (click)="addNxtQuestion()">
          <i class="fa fa-solid fa-plus"></i>
          &nbsp; Add New Question
        </button>
        <button #closeModelAdd type="button" class="col-2 close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="onCloseMember()">&times;</span>
        </button>
      </div>
      <div class="modal-body, row ml-0" >
        <div class="col-12 mx-auto">
          <form #completeQuiz="ngForm">
            <div class="row" style="margin: auto;">
              <div class="col-2 form-group">
                <h6 class="desktopView" style="text-align: center;">Q.Number</h6>
                <h6 class="mobileView" style="text-align: center; margin: auto;">Q.No</h6>
                <div style="text-align: center;" id="overflowTest">
                  <button *ngFor="let item of quizQuestions | keyvalue; index as i"
                    class="desktopView child btn btn-sm btn-info mt-1  ml-0"
                    style="cursor: pointer; align-items: center; width: 80%;" data-spy="scroll" [ngStyle]="{
                      'background-color':
                        currentQuestion == item.key ? 'green' : ''
                    }" (click)="displayquestion(item.key)">
                    {{ i + 1 }}
                  </button>
                  <!-- -->
                  <button *ngFor="let item of quizQuestions | keyvalue; index as i"
                    class="mobileView child badge badge-info mt-1  ml-0"
                    style="cursor: pointer; align-items: center; width: 80%;" data-spy="scroll" [ngStyle]="{
                    'background-color':
                      currentQuestion == item.key ? 'green' : ''
                  }" (click)="displayquestion(item.key)">
                    {{ i + 1 }}
                  </button>
                  <!--  -->
                </div>
              </div>
              <!-- Add Question -->
              <div class="col-10" style="border-left: groove;" *ngIf="!showoption">
                <form #userlogin="ngForm">
                  <label for="inputEmail3" class="col-form-label">
                    Statement
                  </label>
                  <div class="form-group">
                    <div class="input-group">
                      <input type="text" name="statement" id="questionId" style="height: 35px;" class="form-control"
                        placeholder="Write New Question's Statement" [(ngModel)]="statement" minlength="4" required
                        [disabled]="questionstatement" pattern="^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$" />
                      <div class="input-group-append">
                      </div>
                    </div>
                  </div>
                </form>
                <div *ngIf="mchoise">
                  <label class="col-form-label">Options</label>
                  <form #addOpt="ngForm" (ngSubmit)="addoptions(addOpt)">
                    <!-- 1 -->
                    <div class="input-group-prepend">
                      <div class="input-group-text custom-control custom-radio">
                        <input type="radio" name="question" id="0" (change)="selectAnswer($event)" required />
                      </div>
                      <input id="option1" type="text" for="question_1" class="form-control question_1" name="option1"
                        aria-label="Text input with checkbox" ngModel required />
                      <div class="input-group-append">
                        <button type="button" style="height: 35px;" class="btn btn-outline-primary"
                          (click)="foropt2(addOpt)" *ngIf="!mchoise2"><i class="fa fa-solid fa-plus"></i>
                          &nbsp; New
                        </button>
                      </div>
                      <br />
                    </div>
                    <!-- 2 -->
                    <div class="input-group-prepend" *ngIf="mchoise2">
                      <div class="input-group-text custom-control custom-radio">
                        <input type="radio" name="question" id="1" (change)="selectAnswer($event)" required />
                      </div>
                      <input id="option2" type="text" for="question_2" class="form-control question_2" name="option2"
                        aria-label="Text input with checkbox" ngModel required />
                      <div class="input-group-append">
                        <button type="button" style="height: 35px;" class="btn btn-outline-primary"
                          (click)="foropt3(addOpt)" *ngIf="!mchoise3"><i class="fa fa-solid fa-plus"></i>
                          &nbsp; New
                        </button>
                      </div>
                      <br />
                    </div>
                    <!-- 3 -->
                    <div class="input-group-prepend" *ngIf="mchoise3">
                      <div class="input-group-text custom-control custom-radio">
                        <input type="radio" name="question" id="2" (change)="selectAnswer($event)" required />
                      </div>
                      <input id="option3" type="text" for="question_3" name="option3"
                        aria-label="Text input with checkbox" class="form-control question_3" ngModel required />
                      <div class="input-group-append">
                        <button type="button" style="height: 35px;" class="btn btn-outline-primary"
                          (click)="foropt4(addOpt)" *ngIf="!mchoise4"><i class="fa fa-solid fa-plus"></i>
                          &nbsp; New
                        </button>
                      </div>
                      <br />
                    </div>
                    <!-- 4 -->
                    <div class="input-group-prepend" *ngIf="mchoise4">
                      <div class="input-group-text custom-control custom-radio">
                        <input type="radio" name="question" id="3" required (change)="selectAnswer($event)" />
                      </div>

                      <input id="option4" type="text" for="question_4" name="option4"
                        aria-label="Text input with checkbox" class="form-control question_4" ngModel required />
                      <br />
                    </div>


                    <button *ngIf="!optionsubmited" type="submit" id="submitoption" class="btn col-4 btn-primary btn-sm mt-2">
                      Submit Question
                    </button>
                    <span class="pl-4 text-danger text-italic" style="font-size: 13px;">
                      {{ errorMessage }}
                    </span>

                  </form>
                </div>
              </div>

              <!-- Update Question  -->
              <div *ngIf="showoption" class="col-9" style="border-left: groove;">
                <form #questionUpdate="ngForm" (ngSubmit)="UpdateQuestion(questionUpdate)">

                </form>

                <br>
                <span class="text-danger text-italic alert alert-dismissible fade show" style="overflow: hidden;">

                </span>
                <form #updateOpt="ngForm" (ngSubmit)="updateOptions(updateOpt)">
                  <div>
                    <label class="col-form-label">Statement</label>
                    <div class="input-group">
                      <input type="text" name="statement" id="questionId" class="form-control input__question"
                        placeholder="Enter Question Statement" class="form-control" required
                        [(ngModel)]="questionData.question" [disabled]="!updateQuestion && !updateOption"
                        pattern="^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$" />

                    </div>
                  </div>
                  <label class="col-form-label">Options</label>
                  <span class="pl-4 text-danger text-italic" style="font-size: 13px;">
                    {{ errorMessage }}
                  </span>
                  <div class="input-group-prepend" *ngFor="let item of McqsOption; index as i">
                    <div class="input-group-text custom-control custom-radio">
                      <input [disabled]="!updateOption" name="radio" type="radio" [value]="McqsOption[i]"
                        (change)="updateOptRadio(i)" [checked]="correctIndex == i" />
                    </div>
                    <input (change)="updateOptTxt($event, i)" type="text" class="form-control" [value]="McqsOption[i]"
                      [disabled]="!updateOption" required />
                    <div class="input-group-append">
                      <button *ngIf="updateOption" type="button" style="height: 35px;" class="btn btn-outline-primary"
                        (click)="removeOpt(i)">
                        <i class="fa fa-trash-o" style="font-size: 18px"></i>
                      </button>
                    </div>
                    <br />
                  </div>

                  <div *ngIf="updateOption && McqsOption.length < 4" class="text-center">
                    <button (click)="appendOption()" type="button" class="btn btn-sm px-5 btn-outline-primary my-1"><i
                        class="fa fa-solid fa-plus"></i>
                      &nbsp; Add New Option
                    </button>
                  </div>
                  <div class="row">

                    <button *ngIf="!updateOption" class="ml-3 col-5  btn btn-outline-primary btn-sm mt-2 ml-3"
                      (click)="updateOption = true" type="button">
                      Update Question
                    </button>
                    <!-- mobile view -->
                    <button *ngIf="!updateOption" class=" mobileView col-5 btn btn-outline-primary btn-sm mt-2 ml-3"
                      (click)="updateOption = true" style="font-size: 3vw;" type="button">
                      Update
                    </button>
                    <!--  -->
                    <button *ngIf="updateOption" class="btn btn-primary col-4 btn-sm mt-2 ml-3" type="submit">
                      Submit Question
                    </button>
                    <!-- mobile view -->
                    <button *ngIf="updateOption" class=" mobileView btn col-4  btn-primary btn-sm mt-3" type="submit">
                      Submit
                    </button>

                    <!--  -->
                    <button class="col-4 ml-3 btn btn-sm btn-danger mt-2 ml-3" (click)="deleteQuestion()" type="button">
                      Delete Question
                    </button>
                    <!-- mobile view -->
                    <button class=" mobileView col-4 ml-3 btn btn-sm btn-danger mt-2" style="font-size: 3vw;"
                      (click)="deleteQuestion()" type="button">
                      Delete
                    </button>
                    <!--  -->
                  </div>

                  <span class="text-danger text-italic pl-2" style="font-size: 13px;;">
                    {{ errorDelete }}
                  </span>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Delete Quiz -->
<div class="modal fade" id="deleteModel" tabindex="-1" role="dialog" aria-labelledby="deleteModelCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModelTitle">Delete Quiz</h5>
        <button #closeModelDelete type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!loading">
        <div>
          <div class="px-1 text-center">
            <h5>Are you sure you want to delete the Quiz associated with this file?</h5>
            <p class="text-danger">(Note: This action cannot be undone)</p>

            <div class="pt-3">
              <button class="btn btn-sm btn-danger px-4" (click)="deleteQuiz()">
                Delete
              </button>

              <button type="button" class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal" aria-label="Close">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loading" class="d-flex justify-content-center">
        <p *ngIf="errorMsg" class="m-5 text-danger h6">{{errorMsg}}</p>
        <div *ngIf="!errorMsg" class="spinner-border m-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ComponentCanDeactivate } from '../../../component-can-deactivate';
import { driversLicenceOptions } from 'src/app/models/driversLicenceOptions';
import { countryIds } from 'src/app/models/countryIds';
import { UpdateCandidate , Address} from 'src/app/models/UpdateCandidate';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.css'],
})
export class CandidateDetailsComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate {
  @Input() candidateID: string = '';
  @ViewChild('btnSwitchTab') btnSwitchTab!: ElementRef;
  @ViewChild('closeNav') closeNav!: ElementRef;
  @ViewChild('closeModelProfile') closeModelProfile!: ElementRef;

  formSession: boolean = false;
  UnSavedPopUp: boolean = false;
  displayLoader: boolean = true;
  update: boolean = false;
  saving: boolean = false;
  navigate: boolean = false;
  mobileView: boolean = false;
  savedetailsModal: boolean = false;
  updatecandidate!: UpdateCandidate;
  profile: any;
  name: any;
  email: any;
  lastname: any;
  preferred_lang: string = 'Spanish';
  dateOfBirth: any;
  message: string = 'Saving the Details..';
  url: any;
  BullhornMeta: any;
  namePrefixes: any;
  customText11: any;
  selected: string = 'profile';
  id: string = '';
  profilecompleted: any;
  pageName: any;
  driversLicenceOptions =driversLicenceOptions;
  countryIds = countryIds;

  canDeactivate(): boolean {
    if (this.navigate) {
      this.UnSavedPopUp = false;
      return true;
    } else if (this.update) {
      this.UnSavedPopUp = true;
      return false;
    }
    return true;
  }

  addItem(newItem: string) {
    this.profile = newItem;
  }
  title = 'FrontendService';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }

  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  nextUrl(pageName: string) {
    this.url = pageName;
  }

  navigateUrl() {
    this.navigate = true;
    var url = this.url;
    this.router.navigate([url]);
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private route: ActivatedRoute,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  ngOnInit(): void {
    let api = '/admin/Candidates/' + this.candidateID;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (window.screen.width <= 768) {
          //('navigation starts...', window.screen.width);
          this.mobileView = true;
        }
      }
    });

    this.getProfile(environment.apiConfig.uri + api);
  }

  getProfile(url: string) {
    this.http.get(url).subscribe((profile: any) => {
      this.profile = profile.data;
      this.name = this.profile.firstName + ' ' + this.profile.lastName;
      this.email = this.profile.email;
      this.dateOfBirth = this.profile?.dateOfBirth;
      this.BullhornMeta = JSON.parse(this.profile.bullhornMetaFields);
      const datepipe: DatePipe = new DatePipe('en-US');
      const formattedDate = datepipe.transform(
        this.profile?.dateOfBirth?.toString(),
        'yyyy-MM-dd'
      );
      this.profile.dateOfBirth = formattedDate;
      const formattedCustomDate6 = datepipe.transform(
        this.profile?.customDate6?.toString(),
        'yyyy-MM-dd'
      );
      this.profile.customDate6 = formattedCustomDate6;

      if (!this.profile.namePrefix) {
        this.profile.namePrefix = '';
      }
      if (!this.profile.gender) {
        this.profile.gender = '';
      }
      if (!this.profile.customText11) {
        this.profile.customText11 = '';
      }
      if (!this.profile.customText13) {
        this.profile.customText13 = '';
      }
      if (!this.profile.candidateAddressCountryId) {
        this.profile.candidateAddressCountryId = '';
      }
      this.displayLoader = false;
    });
  }

  updateInput(event: any) {
    this.formSession = true;
    if (this.update === false) {
      this.update = true;
    }
    var attr = event.target.name;
    if (this.profile.attr === null || this.profile.attr === undefined) {
      this.profile.attr = '';
    }
    if (attr == 'customText11') {
      this.customText11 = event.target.value;
    }
  }

  submitForm(data: any) {
    this.savedetailsModal = true;
    this.UnSavedPopUp = false;
    this.togglePopUp();
    const profileOBJ = <UpdateCandidate>{};
    const addressOBJ = <Address>{};
    for (var key in data) {
      var value = data[key];
      if (key == 'address1') {
        addressOBJ.address1 = value;
      } else if (key == 'customText26') {
        profileOBJ.customText26 = value;
      } else if (key == 'customDate6') {
        let customDate = Math.floor(new Date(value).getTime());
        profileOBJ.customDate6 = customDate; 
      }else if (key == 'customText8') {
        profileOBJ.customText8 = value; 
      }else if (key == 'address2') {
        addressOBJ.address2 = value;
      } else if (key == 'city') {
        addressOBJ.city = value;
      } else if (key == 'state') {
        addressOBJ.state = value;
      } else if (key == 'zip') {
        addressOBJ.zip = value;
      } else if (key == 'candidateAddressCountryId') {
        addressOBJ.countryID = value;
      } else if (key == 'email') {
        profileOBJ.email = value;
      } else if (key == 'dateOfBirth') {
        let newDate = new Date(value).getTime() + 43200000;
        profileOBJ.dateOfBirth = newDate;
      } else if (key == 'gender') {
        profileOBJ.gender = value;
      } else if (key == 'nickName') {
        profileOBJ.nickName = value;
      } else if (key == 'namePrefix') {
        profileOBJ.namePrefix = value;
      } else if (key == 'firstName') {
        profileOBJ.firstName = value;
      } else if (key == 'lastName') {
        profileOBJ.lastName = value;
      } else if (key == 'customText13') {
        profileOBJ.customText13 = value;
      } else if (key == 'customText11') {
        profileOBJ.customText11 = value;
      } else if (key == 'mobile') {
        profileOBJ.mobile = value;
      } else if (key == 'customText36') {
        profileOBJ.customText36 = value;
      } else if (key == 'customText37') {
        profileOBJ.customText37 = value;
      } else if (key == 'email3') {
        profileOBJ.email3 = value;
      } else if (key == 'phone3') {
        profileOBJ.phone3 = value;
      } else if (key == 'id') {
        profileOBJ.id = value;
      }
      if (this.customText11 && this.customText11.length > 0) {
        profileOBJ.customText11 = this.customText11;
      }
    }
    this.name = data.firstName.trim();
    this.lastname = data.lastName.trim();
    this.email = data.email;
    if (Object.keys(addressOBJ) && Object.keys(addressOBJ).length > 0) {
      profileOBJ.address = addressOBJ;
    }
    var url = `${environment.apiConfig.uri}/admin/Candidates/UpdateCandidate/${this.candidateID}`;
    this.http.put<any>(url, profileOBJ).subscribe((response) => {
      var newData = JSON.parse(response.data).data;
      this.togglePopUp();
      this.update = false;
      this.formSession = false;
      if (response.isSuccess == true) {       
        setTimeout(() => {
          this.closeModelProfile.nativeElement.click();
          this.savedetailsModal = false;
        }, 1000);
        this.closeModelProfile.nativeElement.click();
      }
      if (this.pageName) {
        this.navigate = true;
        this.router.navigate([this.pageName]);
      }
    });

  }

  togglePopUp() {
    if (this.saving == true) {
      this.saving = false;
    } else {
      this.saving = true;
    }
  }
  ClosePopUp() {
    this.saving = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

<!-- sidebar menu area start -->
<div class="sidebar-menu" style="background-color: white;">
  <div>
    <div class="sidebar-header" style="
        background-color: white;
        border-bottom: none;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;">
      <div class="logo">
        <a href="/">
          <img [src]="brandLogo" alt="logo" width="80%" />
        </a>
      </div>
      <div *ngIf="!isAdmin" class="logo mt-4">
        <img src="assets/img/profile2.jpg" alt="" class="user-img" />
        <p style="font-size: 14px;">
          <b>{{ name }} {{lastname}} </b>
          <br />
          <span class="text-secondary">{{ email }}</span>
        </p>
      </div>
      <hr />
    </div>

    <div class="main-menu" style="margin-top: 0 !important; padding-top: 0 !important;  ">
      <div class="menu-inner" style="
          background-color: white; overflow: hidden;
          margin-top: 0 !important; padding-top: 0 !important; ">
        <nav style="margin-top: 0 !important; padding-top: 0 !important;">
          <ul *ngIf="!isAdmin" class="metismenu" id="menu"
            style="margin-top: 0 !important; padding-top: 0 !important; ">
            <li>
              <a [routerLink]="['/profile']" [ngClass]="{ 'is-active': selected === 'profile' }">
                <img class="profile-image" src="../../../assets/img/personal_details.png" />
                <span>Personal Details
                  <app-status-icons [statusValue]="profilecompleted"></app-status-icons>
                </span>
              </a>
            </li>

            <li>
              <a (click)="nextUrl('personal-documents')" [routerLink]="['/personal-documents']"
                [ngClass]="{'is-active': selected === 'personal-documents'}">
                <img class="profile-image" src="../../../assets/img/document.png" />
                <span>Documents</span>
              </a>
            </li>

            <li>
              <a (click)="nextUrl('jobs')" [routerLink]="['/jobs']" [ngClass]="{ 'is-active': selected === 'jobs' }">
                <img class="profile-image" src="../../../assets/img/jobs1.png" />
                <span>Job</span>
              </a>
            </li>

            <li>
              <a (click)="nextUrl('candidate-training-assessment')" [routerLink]="['/candidate-training-assessment']"
                [ngClass]="{'is-active': selected === 'candidate-training-assessment'}">
                <img class="profile-image" src="../../../assets/img/training1.png" />
                <span>Training
                  <app-status-icons [statusValue]="trainingcompleted"></app-status-icons>
                </span>
              </a>
            </li>

            <li>
              <a (click)="showSubMenu=!showSubMenu;">
                <img class="profile-image" src="../../../assets/img/payroll_detail.png" />
                <span>Payroll Details</span>
                <img width="10px" class="ml-2" src="../../../assets/img/down.png"/>
                <app-status-icons [statusValue]="((superannuationStatus=='Completed') && (bankdetailsStatus=='Completed')&& (taxStatus=='Completed')&& (deductionStatus=='Complete'))?'true':'false'">
                </app-status-icons>
              </a>
              <ul *ngIf="showSubMenu" class="sub-menu metismenu" id="menu">
                <li><a [routerLink]="['/pay-slips']" [ngClass]="{ 'is-active': selected === 'pay-slips' }"
                    (click)="nextUrl('pay-slips')">
                    <img class="profile-image" src="../../../assets/img/pay_history.png"/>
                      Pay History</a></li>

                <li [hidden]="!homeData"><a [routerLink]="['/bank-account']"
                    (click)="nextUrl('superannuation')" [ngClass]="{ 'is-active': selected === 'bank-account' }"><img
                     class="profile-image mr-1" src="../../../assets/img/bank_account.png"/>Bank
                    Account
                  <app-status-icons [statusValue]="bankdetailsStatus"></app-status-icons>
                  </a></li>

                <li [hidden]="!homeData"><a [routerLink]="['/deductions']" (click)="nextUrl('deductions')"
                    [ngClass]="{ 'is-active': selected === 'deductions' }"><img class="profile-image mr-1"
                      src="../../../assets/img/Create Deduction.png" />Deductions
                  <app-status-icons [statusValue]="deductionStatus=='Complete'?'true':'false'"></app-status-icons>                    
                  </a>
                </li>

                <li [hidden]="!homeData"><a [routerLink]="['/superannuation']"
                    (click)="nextUrl('superannuation')" [ngClass]="{ 'is-active': selected === 'superannuation' }"><img
                      class="profile-image mr-1" src="../../../assets/img/superannuation.png" />Superannuation
                    <app-status-icons [statusValue]="superannuationStatus"></app-status-icons>
                  </a>
                </li>

                <li [hidden]="!homeData"><a (click)="nextUrl('tax-declaration')"
                    [routerLink]="['/tax-declaration']" [ngClass]="{ 'is-active': selected === 'tax-declaration' }">
                    <img style="color: #20529E;" class="profile-image mr-1" src="../../../assets/img/tax.jpg"/>Tax Declaration             
                    <app-status-icons [statusValue]="taxStatus"></app-status-icons>
                  </a>
                </li>

                <li><a [routerLink]="['/payroll-history']" [ngClass]="{ 'is-active': selected === 'payroll-history' }"
                    (click)="nextUrl('payroll-history')"><img class="profile-image mr-1"
                      src="../../../assets/img/pay_history.png"/>Payroll History
                  </a></li>
                <li> <a (click)="checkProfile()">
                    <img class="profile-image mr-1" src="../../../assets/img/astute_payroll.png"/>Astute Payroll</a>
                </li>

              </ul>
            </li>
            <li>
              <a (click)="nextUrl('history')" [routerLink]="['/history']"
                [ngClass]="{ 'is-active': selected === 'history' }">
                <img class="profile-image" src="../../../assets/img/history1.png" />
                <span>History</span>
              </a>
            </li>

            <li class="mobileView" style="color:#8d97ad ;">
              <a class="dropdown-item" (click)="logout()"> <img class="profile-image"
                  src="../../../assets/img/logout.png" /><span class="pl-1">Log Out</span> </a>
            </li>

          </ul>
          <ul *ngIf="isAdmin" class="metismenu" id="menu" style="margin-top: 0 !important; padding-top: 0 !important;">
            <li>
              <a (click)="toggleMenu('search')" class="font-weight-bold">
                <span class="pl-1">{{ isMenuOpen('search') ? '–' : '+' }} Search</span>
              </a>
              <ul *ngIf="isMenuOpen('search')" style="margin-left: 12px;">
                <li><a [routerLink]="['/candidate-search']" [ngClass]="{ 'is-active': selected === 'candidate-search' }" (click)="selectMenu('candidate-search')">Candidate Search</a></li>
                <li><a [routerLink]="['/job-match']" [ngClass]="{ 'is-active': selected === 'job-match' }" (click)="selectMenu('job-match')">Job Match</a></li>
              </ul>
            </li>

            <li>
              <a (click)="toggleMenu('training')" class="font-weight-bold">
                <span class="pl-1">{{ isMenuOpen('training') ? '–' : '+' }} Training</span>
              </a>
              <ul *ngIf="isMenuOpen('training')" style="margin-left: 12px;">
                <li><a [routerLink]="['/training-files']" [ngClass]="{ 'is-active': selected === 'training-files' }" (click)="selectMenu('training')">Training Files</a></li>
                <li><a [routerLink]="['/training-assessment']" [ngClass]="{ 'is-active': selected === 'training-assessment' }" (click)="selectMenu('training')">Quiz Questions</a></li>
              </ul>
            </li>
            <li><a [routerLink]="['/tax-declarations-details']" class="font-weight-bold" (click)="nextUrl('tax-declarations-details')"
              [ngClass]="{ 'is-active': selected === 'tax-declarations-details' }"><span class="pl-1">Tax Declarations</span></a></li>

            <!-- Authority to Deduct -->
            <li>
              <a (click)="toggleMenu('deductions')" class="font-weight-bold">
                <span class="pl-1">{{ isMenuOpen('deductions') ? '–' : '+' }} Authority to Deduct</span>
              </a>
              <ul *ngIf="isMenuOpen('deductions')" style="margin-left: 12px;">
                <li><a [routerLink]="['/add-deductions']" [ngClass]="{ 'is-active': selected === 'add-deductions' }" (click)="selectMenu('deductions')">Create Deductions</a></li>
                <li><a [routerLink]="['/pending-deductions']" [ngClass]="{ 'is-active': selected === 'pending-deductions' }" (click)="selectMenu('deductions')">Pending Deductions</a></li>
                <li><a [routerLink]="['/submit-deductions']" [ngClass]="{ 'is-active': selected === 'submit-deductions' }" (click)="selectMenu('deductions')">Submit Deductions</a></li>
                <li><a [routerLink]="['/change-deductions']" [ngClass]="{ 'is-active': selected === 'change-deductions' }" (click)="selectMenu('deductions')">Change Requests</a></li>
              </ul>
            </li>
           <!-- Workflows -->
            <li>
              <a (click)="toggleMenu('workflows')" class="font-weight-bold">
                <span class="pl-1">{{ isMenuOpen('workflows') ? '–' : '+' }} Workflows</span>
              </a>
              <ul *ngIf="isMenuOpen('workflows')" style="margin-left: 12px;">
                <li><a [routerLink]="['/create-workflow']" [ngClass]="{ 'is-active': selected === 'create-workflow' }" (click)="selectMenu('workflows')">Create Workflow</a></li>
                <li><a [routerLink]="['/workflow-actions']" [ngClass]="{ 'is-active': selected === 'workflow-actions' }" (click)="selectMenu('workflows')">Workflow Actions</a></li>
              </ul>
            </li>
            <!-- Logistics -->
            <li>
              <a (click)="toggleMenu('logistics')" class="font-weight-bold">
                <span class="pl-1">{{ isMenuOpen('logistics') ? '–' : '+' }} Logistics</span>
              </a>
              <ul *ngIf="isMenuOpen('logistics')" style="margin-left: 12px;">
                <li><a [routerLink]="['/vehicles']" [ngClass]="{ 'is-active': selected === 'vehicles' }" (click)="selectMenu('logistics')">Vehicles</a></li>
                <li><a [routerLink]="['/accommodation']" [ngClass]="{ 'is-active': selected === 'accommodation' }" (click)="selectMenu('logistics')">Accommodation</a></li>
                <li><a [routerLink]="['/logistics']" [ngClass]="{ 'is-active': selected === 'logistics' }" (click)="selectMenu('logistics')">Assign Candidates</a></li>
              </ul>
            </li>
            <li *ngIf="isPortalAdmin"><a [routerLink]="['/admin']" class="font-weight-bold"  [ngClass]="{ 'is-active': selected === 'admin' }"><span class="pl-1">Admin</span></a></li>
            <li class="mobileView" style="color: #8d97ad;">
              <a class="dropdown-item" (click)="logout()"> <img  class="profile-image mr-1"
                  src="../../../assets/img/logout.png" /><span class="pl-1">Log Out</span></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="menu-footer">
    <div class="pl-4 ml-2 pb-3">
      <p style="font-size: 14px;">
        <b>
          {{brandPhone}}
          <br />
        </b>
        <span class="text-secondary">
          <a class="text-secondary" style="text-decoration: none !important; font-weight: 500;"
            href="mailto:info@agrilabour.com.au">
            {{brandEmail}}
          </a>
        </span>
      </p>
      <p>
        <span style="
            padding: 8px 12px;
            border-radius: 50% !important;
            background-color: gray;
          ">
          <i class="fa fa-facebook text-light"></i>
        </span>
        <span style="
            padding: 8px 12px;
            border-radius: 50% !important;
            background-color: gray;
          " class="mx-2">
          <i class="fa fa-google text-light"></i>
        </span>
        <span style="
            padding: 8px 12px;
            border-radius: 50% !important;
            background-color: gray;
          ">
          <i class="fa fa-linkedin text-light"></i>
        </span>
      </p>
    </div>
  </div>
</div>
<!-- sidebar menu area end -->
<a class="invisible" #btnWarningModal data-toggle="modal" data-target="#warningModal"></a>
<!-- Modal warning payroll tab -->
<div class="modal fade" id="warningModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Payroll Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span class="m-3">You are about to be redirected to our payroll system.<br> Would you like to continue?</span>
        <hr>
        <div style="display: flex; justify-content: space-evenly;" class="mt-3">
          <button class="btn btn-sm btn-primary mr-1" type="button" data-dismiss="modal" (click)="openPayroll()">
            Yes. Leave this page
          </button>
          <button type="button" class="btn btn-sm btn-secondary px-1" data-dismiss="modal" aria-label="Close">
            No. Stay here.
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
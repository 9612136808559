import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  candidates: any;
  constructor(private http: HttpClient) { }

  getAdmins(
    pageNumber: string = '1',
    query: string = '',
    pageSize: string = '10'
  ): Observable<any> {
    return this.http.get(
      `${environment.apiConfig.uri}/admin/Candidates/GetAdminsPaginatedList?pageNumber=` +
      pageNumber +
      '&query=' +
      query +
      '&pagesize=' +
      pageSize
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface QuizResponse {
  quizList: any[];
  trainingStatus: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  constructor(private http: HttpClient) {}

  getQuizzes(): Observable<QuizResponse> {
    const url = `${environment.apiConfig.uri}/candidate/Quiz`;
    return this.http.get<any>(url).pipe(
      map(response => {
        const quizList = response.data || [];
        let trainingStatus = true;
        
        if (quizList.length === 0) {
          trainingStatus = true;
        } else {
          for (let quiz of quizList) {
            if (quiz.status === 'Incomplete') {
              trainingStatus = false;
              break;
            }
          }
        }

        localStorage.setItem('trainingcompleted', trainingStatus.toString());
        return { quizList, trainingStatus };
      })
    );
  }
}

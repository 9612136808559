import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  InteractionType,
  PopupRequest,
  RedirectRequest,
  AuthenticationResult,
} from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

type CandidateFile = {
  externalID?: string;
  name?: string;
  fileContent?: string;
  type?: string;
};

type NewFile = {
  id?: number;
  fileUrl?: string;
  type?: string;
  fileStream?: string;
  fileSize?: number;
  fileName?: string;
  fileNameOld?: string;
  dateAdded?: string;
  contentType?: string;
  contentSubType?: string;
};

@Component({
  selector: 'app-personal-documents',
  templateUrl: './personal-documents.component.html',
  styleUrls: ['./personal-documents.component.css'],
})
export class PersonalDocumentsComponent implements OnInit {
  fileInputText = "No File Chosen...";
  candidate_file!: CandidateFile;
  file_obj!: NewFile;
  loginDisplay = false;
  loadingJobFiles: boolean = true;
  loadingCandidateFiles: boolean = true;
  path =
    'https://rest60.bullhornstaffing.com/rest-services/2g9m9s/file/Candidate/231585/504294/raw?BhRestToken=74a83c83-759c-4158-8854-6e0c7fdd0769';
  startPage = 1;
  name: any;
  email: any;
  candidateFilesList: any;
  jobFilesList: any;
  updateFileID: any;
  fileName: any;
  fileNameOld: any;
  contentType: any;
  contentSubType: any;
  fileSize!: number;
  enableType: boolean = false;
  enableUpload: boolean = false;
  fileError: boolean = false;
  typeSelected: any;
  base64: any;
  file: any;
  FileID: any;
  mobileView: boolean = false;
  selected: string = 'personal-documents';
  id: string = '';
  loading: boolean = false;
  fileExtension: any;
  currentFile: any
  warning: any

  @ViewChild('fileChosenAdd') fileChosenAdd!: ElementRef;
  @ViewChild('fileChosenUpdate') fileChosenUpdate!: ElementRef;
  @ViewChild('closeModelAdd') closeModelAdd!: ElementRef;
  @ViewChild('closeModelUpdate') closeModelUpdate!: ElementRef;
  @ViewChild('closeModelDelete') closeModelDelete!: ElementRef;
  @ViewChild('TypeAdd') TypeAdd!: ElementRef;
  @ViewChild('TypeUpdate') TypeUpdate!: ElementRef;

  editFile = {
    id: 0,
  };

  index: number = 0;

  fileTypes: any[] = [
    {
      value: 'Approval',
      label: 'Approval',
    },
    {
      value: '408 Visa Letter',
      label: '408 Visa Letter',
    },
    {
      value: 'Client Specific Induction',
      label: 'Client Specific Induction',
    },
    {
      value: 'Confirmation of Enrolment',
      label: 'Confirmation of Enrolment',
    },
    {
      value: 'Correspondence',
      label: 'Correspondence',
    },
    {
      value: 'Cover Letter',
      label: 'Cover Letter',
    },
    {
      value: 'Covid Vax Proof',
      label: 'Covid Vax Proof',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Induction',
      label: 'Induction',
    },
    {
      value: 'Letter of Offer',
      label: 'Letter of Offer',
    },
    {
      value: 'Licence',
      label: 'Licence',
    },
    {
      value: 'MADEC Card',
      label: 'MADEC Card',
    },
    {
      value: 'Position Description',
      label: 'Position Description',
    },
    {
      value: 'Proof of Identity',
      label: 'Proof of Identity',
    },
    {
      value: 'Qualification',
      label: 'Qualification',
    },
    {
      value: 'Resume / CV',
      label: 'Resume / CV',
    },
    {
      value: 'VEVO',
      label: 'VEVO',
    },
    {
      value: 'Visa',
      label: 'Visa',
    },
    {
      value: 'Reference',
      label: 'Reference',
    },
    {
      value: 'Risk Assessment',
      label: 'Risk Assessment',
    },
    {
      value: 'Training Record',
      label: 'Training Record',
    },
    {
      value: 'Warning Letter',
      label: 'Warning Letter',
    },
    {
      value: 'Work Instruction/SOP',
      label: 'Work Instruction/SOP',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  public formGroup = this.fb.group({
    file: [null, Validators.required],
  });

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private route: ActivatedRoute,   private cdr: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit(): void {
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    this.setLoginDisplay();
    this.getCandidateFiles(environment.apiConfig.uri + '/CandidateFiles');
  }

  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

  closeModel() {
    this.enableType = false;
    this.enableUpload = false;
  }

  selectType(event: any) {
    this.typeSelected = event.target.value;
    this.enableUpload = true;
  }

  public onFileChange(event: any, operation: string) {
    this.enableType = true;
    const reader = new FileReader();
    if (event.target.files) {
      this.fileName = event.target.files[0].name;
      this.fileInputText = this.fileName;
      this.fileSize = Math.round(event.target.files[0].size / 1000);
      if (this.fileSize > 10000) {
        this.fileError = true;
        return;
      } else {
        this.fileError = false;
      }
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        var fileString = reader.result?.toString();
        this.base64 = fileString?.split(';base64,')[1];
        this.contentType = fileString
          ?.split(';base64,')[0]
          .split('/')[0]
          .split(':')[1];
        this.contentSubType = fileString?.split(';base64,')[0].split('/')[1];
      };
    }
  }

  public addFileSubmit(values: any, form: any): void {

    form.resetForm()
    this.loading = true;
    this.enableUpload = false;
    const FileObj = <CandidateFile>{};
    FileObj.externalID = 'portoflio';
    FileObj.fileContent = this.base64;
    FileObj.type = this.typeSelected;
    FileObj.name = this.fileName;

    var url = environment.apiConfig.uri + '/CandidateFiles';
    this.http.put<any>(url, FileObj).subscribe((response: any) => {
      this.fileInputText = "No File Chosen...";
      this.closeModelAdd.nativeElement.click();
      this.enableType = false;
      var fileId = response.data.fileId;
      const new_file = <NewFile>{};
      new_file.id = fileId;
      new_file.dateAdded = Date.now().toString();
      new_file.fileName = this.fileName;
      new_file.fileSize = this.fileSize;
      new_file.fileStream = this.base64;
      new_file.contentSubType = this.contentSubType;
      new_file.contentType = this.contentType;
      new_file.type = this.typeSelected;
      this.getCandidateFiles(environment.apiConfig.uri + '/CandidateFiles');
      this.loading = false;
    });

  }

  addFile() {
    this.fileSize = 0;
    this.fileInputText = 'No File Chosen...';
    this.fileName = "Select Title";
  }

  updateFile(item: any, index: number) {
    this.loading = true;
    this.updateFileID = item.id;
    this.fileNameOld = item.fileName;
    this.editFile = item;
    this.index = index;
    this.loading = false;
  }

  public updateFileSubmit(event: any, FileId: any, index: number): void {
    {
      this.loading = true;
      this.enableUpload = false;
      const FileObj = <CandidateFile>{};
      const UpdateObj = <NewFile>{};
      FileObj.fileContent = this.base64;
      FileObj.name = this.fileName;
      FileObj.externalID = 'portoflio';
      FileObj.type = this.typeSelected;
      UpdateObj.fileStream = this.base64;
      UpdateObj.fileName = this.fileName;
      UpdateObj.type = this.typeSelected;
      UpdateObj.contentType = this.contentType;
      UpdateObj.contentSubType = this.contentSubType;
      UpdateObj.id = FileId;
      UpdateObj.fileSize = this.fileSize;
      UpdateObj.dateAdded = Date.now().toString();
      var url = environment.apiConfig.uri + '/CandidateFiles/' + this.updateFileID;
      this.http.post<any>(url, FileObj).subscribe((response: any) => {
        this.closeModelUpdate.nativeElement.click();
        this.enableType = false;
        this.getCandidateFiles(environment.apiConfig.uri + '/CandidateFiles');
        this.loading = false;

      });

    }

  }

  deleteFileRequest(FileID: any, index: number): void {
    this.FileID = FileID;
    this.index = index;
  }

  deleteFile(FileID: any, index: number): void {
    this.loading = true;
    var url = environment.apiConfig.uri + '/CandidateFiles/' + FileID;
    this.http.delete(url).subscribe((response: any) => {
      this.FileID = null;
      this.index = 0;
      this.closeModelDelete.nativeElement.click();
      this.getCandidateFiles(environment.apiConfig.uri + '/CandidateFiles');
    },
      (error) => {
        //('error:', error);
      }
    );

  }

  updateClientDate = async () => {
    this.candidateFilesList = this.candidateFilesList.map((file: any) => {
      const date = new Date(file.dateAdded);
      const offset = date.getTimezoneOffset() * 60 * 1000;
      const newEpoch = date.getTime() - offset;
      const newDate = new Date(newEpoch);
      file.clientDate = newDate;
      return file;
    });
  };

  getCandidateFiles = (url: string) => {
    this.http.get(url).subscribe(async (response: any) => {
      this.candidateFilesList = response.data;
      await this.updateClientDate();
      this.loadingCandidateFiles = false;
    });
  };

  onClickDownload(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item);
    } else {
      const link = document.createElement('a');
      link.download = `${item.fileName}`;
      const source = `data:${item.contentType}/${item.contentSubType=='octet-stream'?'pdf':item.contentSubType};base64,${item.fileStream}`;
      link.href = source;
      link.click();
    }
  }

  download(item: any) {
    const link = document.createElement('a');
    link.download = `${item.fileName}`;
    const source = `data:${item.contentType}/${item.contentSubType=='octet-stream'?'pdf':item.contentSubTypepe};base64,${item.fileStream}`;
    link.href = source;
    link.click();
  }

  onClickView(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item, true);
    } else {
      const link = document.createElement('a');
      link.target = '_blank';
      const Blob = this.dataURItoBlob(item);
      const file = new File([Blob], item.fileName, {
      type: `${item.contentType}/${item.contentSubType=='octet-stream'?'pdf':item.contentSubType}`,

      });
      let url = window.URL.createObjectURL(file);
      link.href = url;
      link.click();
    }
  }

  getFileStream(item: any, view: boolean = false) {
    var url = environment.apiConfig.uri + `/CandidateFiles/GetFileStream/${item.id}`;
    this.http.get(url).subscribe((response: any) => {
      this.file = response.data ? response.data : '$';
      item.fileStream = this.file;
      if (view == true) {
        this.onClickView(item);
      } else {
        this.onClickDownload(item);
      }
    });
  }

  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: `${item.contentType}/${item.contentSubType=='octet-stream'?'pdf':item.contentSubType}`,
    });
    return blob;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
}

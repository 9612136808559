import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

type taxDeclarationForm = {
  id?: number;
  taxfileId?: number;
  employee_remote_id?: any;
  how_to_complete?: string;
  no_tfn?: number;
  tfnProvided?: number;
  exemptionReason?: number;
  previousSurname?: string;
  isPreviousSurnameChanged?: number;
  residencyStatus?: string;
  taxFreeThresholdClaimed?: number;
  hasHelpDebtSupport?: number;
  employmentBasis?: string;
  declarationName?: string;
  declarationSigned?: number;
  passportIssuanceCountry?: string;
  submissionMethod?: string;
  mid?: number;
  dueDate?: string;
  declaration_id?: string;
  candidateId?: number;
  requestPending?: boolean;
  submissionTime: Date;
  requestStatus: number;
  approvalTime: Date;
  approvedby: string;
};
type CandidateFile = {
  externalID?: string;
  name?: string;
  fileContent?: string;
  type?: string;
};

@Component({
  selector: 'app-candidate-tax-declaration',
  templateUrl: './candidate-tax-declaration.component.html',
  styleUrls: ['./candidate-tax-declaration.component.css'],
})
export class CandidateTaxDeclarationComponent implements OnInit {
  @Input() candidateID!: number;
  @Input() employee_remote_id: string = '';
  @Input() mid: number = 0;
  @Input() taxfileId: number = 0;
  @Output() parentValueEmitter = new EventEmitter<boolean>();
  @ViewChild('taxForm') taxForm!: ElementRef;

  newTaxFileTable: boolean = false;
  messaageForUser: string = '';
  requestStatus: number = 0;
  approvedby: string = '';
  error: boolean = true;
  canResetForm: boolean = false;
  editTaxData: any = {};
  exemption: boolean = false;
  residencyStatus: string = '';
  notfn: number = 0;
  passportIssuanceCountry: string = '';
  showNewTaxFile: boolean = true;
  name: any;
  email: any;
  submissionDate: any;
  base64: any;
  fileName: any;
  mobile: any;

  passportIssuanceCountryObj: any = {
    Afghanistan: 'af',
    'Åland Islands': 'ax',
    Albania: 'al',
    Algeria: 'dz',
    'American Samoa': 'as',
    Andorra: 'ad',
    Angola: 'ao',
    Anguilla: 'ai',
    Antarctica: 'aq',
    'Antigua and Barbuda ': 'ag',
    Argentina: 'ar',
    Armenia: 'am',
    Aruba: 'aw',
    // Australia: 'au',
    Austria: 'at',
    Azerbaijan: 'az',
    'Bahamas (the)': 'bs',
    Bahrain: 'bh',
    Bangladesh: 'bd',
    Barbados: 'bb',
    Belarus: 'by',
    Belgium: 'be',
    Belize: 'bz',
    Benin: 'bj',
    Bermuda: 'bm',
    Bhutan: 'bt',
    'Bolivia (Plurinational State of)': 'bo',
    'Bonaire, Sint Eustatius and Saba': 'bq',
    'Bosnia and Herzegovina': 'ba',
    Botswana: 'bw',
    'Bouvet Island': 'bv',
    Brazil: 'br',
    'British Indian Ocean Territory (the) ': 'io',
    'Brunei Darussalam ': 'bn',
    Bulgaria: 'bg',
    'Burkina Faso': 'bf',
    Burundi: 'bi',
    'Cabo Verde': 'cv',
    Cambodia: 'kh',
    Cameroon: 'cm',
    Canada: 'ca',
    'Cayman Islands (the) ': 'ky',
    'Central African Republic (the) ': 'cf',
    Chad: 'td',
    Chile: 'cl',
    China: 'cn',
    'Christmas Island': 'cx',
    'Cocos (Keeling) Islands (the)': 'cc',
    Colombia: 'co',
    'Comoros (the)': 'km',
    'Congo (the Democratic Republic of the)': 'cd',
    'Congo (the) ': 'cg',
    'Cook Islands (the)': 'ck',
    'Costa Rica': 'cr',
    'Côte d Ivoire': 'ci',
    Croatia: 'hr',
    Cuba: 'cu',
    Curaçao: 'cw',
    Cyprus: 'cy',
    Czechia: 'cz',
    Denmark: 'dk',
    Djibouti: 'dj',
    Dominica: 'dm',
    'Dominican Republic (the)': 'do',
    Ecuador: 'ec',
    Egypt: 'eg',
    'El Salvador ': 'sv',
    'Equatorial Guinea ': 'gq',
    Eritrea: 'er',
    Estonia: 'ee',
    Eswatini: 'sz',
    Ethiopia: 'et',
    'Falkland Islands (the) [Malvinas] ': 'fk',
    'Faroe Islands (the)  ': 'fo',
    Fiji: 'fj',
    Finland: 'fi',
    France: 'fr',
    'French Guiana': 'gf',
    'French Polynesia ': 'pf',
    'French Southern Territories (the)': 'tf',
    Gabon: 'ga',
    'Gambia (the)': 'gm',
    Georgia: 'ge',
    Germany: 'de',
    Ghana: 'gh',
    Gibraltar: 'gi',
    Greece: 'gr',
    Greenland: 'gl',
    Grenada: 'gd',
    Guadeloupe: 'gp',
    Guam: 'gu',
    Guatemala: 'gt',
    Guernsey: 'gg',
    Guinea: 'gn',
    'Guinea-Bissau': 'gw',
    Guyana: 'gy',
    Haiti: 'ht',
    'Heard Island and McDonald Islands ': 'hm',
    'Holy See (the) ': 'va',
    Honduras: 'hn',
    'Hong Kong': 'hk',
    Hungary: 'hu',
    Iceland: 'is',
    India: 'in',
    Indonesia: 'id',
    'Iran (Islamic Republic of)': 'ir',
    Iraq: 'iq',
    Ireland: 'ie',
    'Isle of Man': 'im',
    Israel: 'il',
    Italy: 'it',
    Jamaica: 'jm',
    Japan: 'jp',
    Jersey: 'je',
    Jordan: 'jo',
    Kazakhstan: 'kz',
    Kenya: 'ke',
    Kiribati: 'ki',
    'Korea (the Democratic Peoples Republic of)': 'kp',
    'Korea (the Republic of) ': 'kr',
    Kuwait: 'kw',
    Kyrgyzstan: 'kg',
    'Lao Peoples Democratic Republic (the) ': 'la',
    Latvia: 'lv',
    Lebanon: 'lb',
    Lesotho: 'ls',
    Liberia: 'lr',
    Libya: 'ly',
    Liechtenstein: 'li',
    Lithuania: 'lt',
    Luxembourg: 'lu',
    Macao: 'mo',
    Madagascar: 'mg',
    Malawi: 'mw',
    Malaysia: 'my',
    Maldives: 'mv',
    Mali: 'ml',
    Malta: 'mt',
    'Marshall Islands (the)': 'mh',
    Martinique: 'mq',
    Mauritania: 'mr',
    Mauritius: 'mu',
    Mayotte: 'yt',
    Mexico: 'mx',
    'Micronesia (Federated States of) ': 'fm',
    'Moldova (the Republic of) ': 'md',
    Monaco: 'mc',
    Mongolia: 'mn',
    Montenegro: 'me',
    Montserrat: 'ms',
    Morocco: 'ma',
    Mozambique: 'mz',
    Myanmar: 'mm',
    Namibia: 'na',
    Nauru: 'nr',
    Nepal: 'np',
    'Netherlands (the)': 'nl',
    'New Caledonia': 'nc',
    'New Zealand': 'nz',
    Nicaragua: 'ni',
    'Niger (the)': 'ne',
    Nigeria: 'ng',
    Niue: 'nu',
    'Norfolk Island': 'nf',
    'North Macedonia ': 'mk',
    'Northern Mariana Islands (the)': 'mp',
    Norway: 'no',
    Oman: 'om',
    Pakistan: 'pk',
    Palau: 'pw',
    'Palestine, State of ': 'ps',
    Panama: 'pa',
    'Papua New Guinea ': 'pg',
    Paraguay: 'py',
    Peru: 'pe',
    'Philippines (the)': 'ph',
    Pitcairn: 'pn',
    Poland: 'pl',
    Portugal: 'pt',
    'Puerto Rico': 'pr',
    Qatar: 'qa',
    Réunion: 're',
    Romania: 'ro',
    'Russian Federation (the)': 'ru',
    Rwanda: 'rw',
    'Saint Barthélemy': 'bl',
    'Saint Helena, Ascension and Tristan da Cunha ': 'sh',
    'Saint Kitts and Nevis': 'kn',
    'Saint Lucia': 'lc',
    'Saint Martin (French part)': 'mf',
    'Saint Pierre and Miquelon': 'pm',
    'Saint Vincent and the Grenadines': 'vc',
    Samoa: 'ws',
    'San Marino': 'sm',
    'Sao Tome and Principe': 'st',
    'Saudi Arabia': 'sa',
    Senegal: 'sn',
    Serbia: 'rs',
    Seychelles: 'sc',
    'Sierra Leone': 'sl',
    Singapore: 'sg',
    'Sint Maarten (Dutch part) ': 'sx',
    Slovakia: 'sk',
    Slovenia: 'si',
    'Solomon Islands': 'sb',
    Somalia: 'so',
    'South Africa': 'za',
    'South Georgia and the South Sandwich Islands ': 'gs',
    'South Sudan': 'ss',
    Spain: 'es',
    'Sri Lanka ': 'lk',
    'Sudan (the)': 'sd',
    Suriname: 'sr',
    'Svalbard and Jan Mayen': 'sj',
    Sweden: 'se',
    Switzerland: 'ch',
    'Syrian Arab Republic (the)': 'sy',
    'Taiwan (Province of China)': 'tw',
    Tajikistan: 'tj',
    'Tanzania, the United Republic of ': 'tz',
    Thailand: 'th',
    'Timor-Leste': 'tl',
    Togo: 'tg',
    Tokelau: 'tk',
    Tonga: 'to',
    'Trinidad and Tobago ': 'tt',
    Tunisia: 'tn',
    Turkey: 'tr',
    Turkmenistan: 'tm',
    'Turks and Caicos Islands (the)': 'tc',
    Tuvalu: 'tv',
    Uganda: 'ug',
    Ukraine: 'ua',
    'United Arab Emirates (the)': 'ae',
    'United Kingdom of Great Britain and Northern Ireland (the)': 'gb',
    'United States Minor Outlying Islands (the) ': 'um',
    'United States of America (the)': 'us',
    Uruguay: 'uy',
    Uzbekistan: 'uz',
    Vanuatu: 'vu',
    'Venezuela (Bolivarian Republic of) ': 've',
    'Viet Nam ': 'vn',
    'Virgin Islands (British) ': 'vg',
    'Virgin Islands (U.S.)  ': 'vi',
    'Wallis and Futuna ': 'wf',
    'Western Sahara*': 'eh',
    Yemen: 'ye',
    Zambia: 'zm',
    Zimbabwe: 'zw',
  };

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    this.submissionDate = new Date().toLocaleString();
    
    this.mobile = localStorage.getItem('mobile');
  }

  showExpectionReason(event: any) {
    this.notfn = event.target.value;
    this.exemption = event.target.checked ? true : false;
  }

  checkForeignOrWork(a: any) {
    this.residencyStatus = a.target.value;
  }

  passportCoutrySelect(value: string): void {
    this.passportIssuanceCountry = value;
    const taxDecObj = <taxDeclarationForm>{};
    var countrycode = value;
    taxDecObj.passportIssuanceCountry = value;
  }
  closeModal() {
    this.showNewTaxFile = false;
    this.parentValueEmitter.emit(this.showNewTaxFile);
  }

  submitElectronicDeclaration(data: any) {
    this.messaageForUser = '';
    const taxDecObj = <taxDeclarationForm>{};
    taxDecObj.how_to_complete = '';
    taxDecObj.no_tfn = data.no_tfn;
    taxDecObj.declarationSigned = data.declarationSigned;
    taxDecObj.passportIssuanceCountry = data.passportIssuanceCountry;
    taxDecObj.candidateId = this.candidateID;
    taxDecObj.tfnProvided = data.tfnProvided;
    taxDecObj.exemptionReason = data.exemptionReason;
    taxDecObj.previousSurname = data.previousSurname;
    taxDecObj.isPreviousSurnameChanged = data.isPreviousSurnameChanged;
    taxDecObj.residencyStatus = data.residencyStatus;
    taxDecObj.taxFreeThresholdClaimed = data.taxFreeThresholdClaimed;
    taxDecObj.hasHelpDebtSupport = data.hasHelpDebtSupport;
    taxDecObj.employmentBasis = data.employmentBasis;
    taxDecObj.declarationName = data.declarationName;
    taxDecObj.submissionMethod = 'electronic';
    taxDecObj.mid = this.mid;
    taxDecObj.dueDate = '28-10-2024';
    taxDecObj.declaration_id = '1234';
    taxDecObj.submissionTime = new Date();
    taxDecObj.requestStatus = this.requestStatus;
    taxDecObj.employee_remote_id = this.employee_remote_id;
    taxDecObj.taxfileId = this.taxfileId;
    taxDecObj.id = this.taxfileId;
    if (!taxDecObj.no_tfn && !taxDecObj.tfnProvided) {
      this.messaageForUser = 'Tax File Number is required.';
      this.error = false;
      return;
    } else if (!data.exemptionReason && taxDecObj.no_tfn) {
      this.messaageForUser = 'Exemption reason is required.';
      this.error = false;
      return;
    } else if (
      data.isPreviousSurnameChanged != 0 &&
      data.isPreviousSurnameChanged != 1
    ) {
      this.messaageForUser = 'Have you Change your surename is required.';
      this.error = false;
      return;
    } else if (data.isPreviousSurnameChanged == 1 && !data.previousSurname) {
      this.messaageForUser = 'Previous surename is required.';
      this.error = false;
      return;
    } else if (!data.residencyStatus) {
      this.messaageForUser = 'Residency is required.';
      this.error = false;
      return;
    } else if (
      data.residencyStatus == 'A' &&
      data.taxFreeThresholdClaimed != 0 &&
      data.taxFreeThresholdClaimed != 1
    ) {
      this.messaageForUser = 'Tax Free Threshold is required.';
      this.error = false;
      return;
    } else if (
      (data.residencyStatus == 'A' || data.residencyStatus == 'F') &&
      data.hasHelpDebtSupport != 0 &&
      data.hasHelpDebtSupport != 1
    ) {
      this.messaageForUser = 'Select Higher Education Loan Program';
      this.error = false;
      return;
    } else if (!data.employmentBasis) {
      this.messaageForUser = 'On what basis are you paid is required.';
      this.error = false;
      return;
    } else if (!data.declarationName) {
      this.messaageForUser =
        'Full Name of person making the declaration is required.';
      this.error = false;
      return;
    } else if (data.residencyStatus == 'H' && !data.passportIssuanceCountry) {
      this.messaageForUser =
        'Working holiday makers must provide a country of passport.';
      this.error = false;
      return;
    } else if (!data.declarationSigned) {
      this.messaageForUser =
        'The box to sign with the  identification  details is required.';
      this.error = false;
      return;
    } else if (data.declarationSigned) {
      const url = this.taxfileId
        ? `${environment.apiConfig.uri}/admin/taxDecleration/EditTax/${this.candidateID}`
        : `${environment.apiConfig.uri}/admin/taxDecleration/AddTax/${this.candidateID}`;
      const pdfCreationPromise = new Promise<void>((resolve, reject) => {
        this.createAndSubmitPDF();
        resolve();
      });

      this.http.post(url, taxDecObj).subscribe(
        async (response) => {
          await pdfCreationPromise;
          this.messaageForUser = 'Tax Form added Successfully';
          this.canResetForm = true;
        },
        (error) => {
          this.messaageForUser = 'Oops! Something went wrong.';
          this.canResetForm = false;
        }
      );
    }
    return;
  }

  async createAndSubmitPDF() {
    const formElement = document.getElementById('taxForm');
    const extraContent = document.getElementById('extraData');

    if (formElement) {
      if (extraContent) {
        extraContent.classList.remove('hidden-content');
      }
      // Create configuration for html2pdf
      const pdfOptions = {
        margin: 5,
        filename: `TFN Declaration - ${this.name} - ${this.submissionDate}.pdf`,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 1, logging: true, useCORS: true },
        jsPDF: { unit: 'mm', format: 'legal', orientation: 'portrait' },
      };

      try {
        // Generate PDF using html2pdf
        const pdf = await html2pdf()
          .from(formElement)
          .set(pdfOptions)
          .outputPdf();
        const pdfBase64 = this.encodeToBase64(pdf);
        const fileObj: CandidateFile = {
          externalID: 'portfolio',
          fileContent: pdfBase64,
          type: 'Others',
          name: pdfOptions.filename,
        };
        const url = `${environment.apiConfig.uri}/admin/Candidates/AddPdf/${this.candidateID}`;
        // Submit PDF to the server
        const response = await this.http.put<any>(url, fileObj).toPromise();
        // Handle the response as needed
      } catch (error) {
        console.error('Error generating or submitting PDF:');
      } finally {
        // extraContent.classList.add('hidden-content');
      }
    } else {
      console.error('Content is empty or element not found.');
    }
  }

  private encodeToBase64(data: string): string {
    return btoa(data);
  }
}

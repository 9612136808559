<div class="modal-header">
  <h4 class="modal-title">{{candidateName}}</h4>
  <span type="button" class="btn-close btn-lg" aria-label="Close" aria-hidden="true"
    (click)="activeModal.close('Close click')">&times;</span>
</div>

<div class="modal-body px-0">
  <div class="overflow-hidden" style="overflow-y: hidden; overflow-x: auto; height: calc(100vh - 15rem)">
    <div class="px-2" style="overflow-y: auto; height: 100%;">

      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" style="font-size: 14px;">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Status</a>
          <ng-template ngbNavContent>
            <app-candidate-status [candidateID]="candidateID"></app-candidate-status>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Details</a>
          <ng-template ngbNavContent>
            <app-candidate-details [candidateID]="candidateID"></app-candidate-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink>Jobs</a>
          <ng-template ngbNavContent>
            <app-candidate-jobs [candidateID]="candidateID"></app-candidate-jobs>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink>History</a>
          <ng-template ngbNavContent>
            <app-candidate-history [candidateID]="candidateID"></app-candidate-history>
          </ng-template>
        </li>
        <li [ngbNavItem]="5">
          <a ngbNavLink>Documents</a>
          <ng-template ngbNavContent>
            <app-candidate-documents [candidateID]="candidateID"></app-candidate-documents>
          </ng-template>
        </li>
        <li [ngbNavItem]="6">
          <a ngbNavLink>Training Assessment</a>
          <ng-template ngbNavContent>
            <app-candidate-training [candidateID]="candidateID"></app-candidate-training>
          </ng-template>
        </li>
        <li [ngbNavItem]="7">
          <a ngbNavLink [ngClass]="{'admin-highlight': !isPortalAdmin, 'active-admin': !isPortalAdmin && nav.activeId == '7'}">Tax & Super</a>
          <ng-template ngbNavContent>
            <app-tax-super [candidateID]="candidateID" [isPortalAdmin]="isPortalAdmin" (parentValueEmitter)="receiveParentValue($event)">
            </app-tax-super>
          </ng-template>
        </li>
        <li [ngbNavItem]="8">
          <a ngbNavLink [ngClass]="{'admin-highlight': !isPortalAdmin, 'active-admin': !isPortalAdmin && nav.activeId == '8'}">Deductions</a>
          <ng-template ngbNavContent>
            <app-candidate-deductions [candidateID]="candidateID" [isPortalAdmin]="isPortalAdmin" [name]="candidateName" [email]="candidateEmail"></app-candidate-deductions>
          </ng-template>
        </li>
        <li [ngbNavItem]="9">
          <a ngbNavLink [ngClass]="{'admin-highlight': !isPortalAdmin, 'active-admin': !isPortalAdmin && nav.activeId == '9'}">Bank Details</a>
          <ng-template ngbNavContent>
            <app-bank-details [candidateID]="candidateID" [isPortalAdmin]="isPortalAdmin"></app-bank-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="10">
          <a ngbNavLink [ngClass]="{'admin-highlight': !isPortalAdmin, 'active-admin': !isPortalAdmin && nav.activeId == '10'}">Payroll History</a>
          <ng-template ngbNavContent>
            <app-candidate-payroll-history [candidateID]="candidateID" [isPortalAdmin]="isPortalAdmin"></app-candidate-payroll-history>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click');showNewTaxFile=false">
    Close
  </button>
</div>
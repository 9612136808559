import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CandidateService } from 'src/app/services/candidate.service';
import { Candidate } from 'src/app/models/Candidate';

@Component({
  selector: 'app-candidate-status',
  templateUrl: './candidate-status.component.html',
  styleUrls: ['./candidate-status.component.css'],
})
export class CandidateStatusComponent implements OnInit {
  @Input() candidateID: string = '';
  statusData: any[] = [];
  serverData: any;
  displayLoader: boolean = true;
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;
  loading: boolean = true;
  candidates: Candidate[] = [];
  query: any ;

  constructor(private http: HttpClient ,  private candidateService: CandidateService ) {}

  ngOnInit(): void {
    this.query = localStorage.getItem("query") ;
    this.CandidateStatusUpdate();
  }

  CandidateStatusUpdate() {
  const postURL = `${environment.apiConfig.uri}/admin/Candidates/CandidateStatusUpdate/${this.candidateID}`;
  this.http.post(postURL, {}).subscribe(
      async (response: any) => {
        this.serverData = response.data;
        this.statusData = [
          { name: 'Personal Details', status: this.serverData.candidateProfile },
          { name: 'Training', status: this.serverData.trainingStatus },
          { name: 'Bank Account', status: this.serverData.astuteBankStatus },
          { name: 'Superannuation', status: this.serverData.superAnnuationStatus },
          { name: 'Tax Declaration', status: this.serverData.tfnStatus },
        ];
        this.displayLoader = false;
      },
      (error) => {
        console.error('Error updating candidate status:', error);
        this.displayLoader = false;
      }
    );
}


}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild,Renderer2, ElementRef, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GetUserQueryService } from 'src/app/services/get-user-query.service';
import { environment } from 'src/environments/environment';
import html2pdf from 'html2pdf.js';
import { ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-deductions',
  templateUrl: './deductions.component.html',
  styleUrls: ['./deductions.component.css'],
})
export class DeductionsComponent implements OnInit {
  selected: string = 'deductions';
  id: string = '';
  mid!: number;
  unlimitedData: any;
  fixedData: any;
  queryData: any;
  dataATD: any;
  currentDate: any;
  name: any;
  email: any;
  mobile: any;
  submissionDate: any;
  file: any;
  successMessage: string = '';
  mobileView: boolean = false;
  unlimitTable: boolean = false;
  fixTable: boolean = false;
  loadingFiles: boolean = true;
  openDeduction: boolean = false;
  loading: boolean = false;
  docuSignUrl: string = '';
  modalTitle:string='';
  requestData:any;
  comments: string = "";
  payOutAmount?: number;
  deductionStatus:any;
  recurringDeduction:boolean=false;
  fixedDeduction:boolean=false;
  messageATD:string=`Your request has been sent to our payroll team for consideration. We will contact you to let you know the outcome.`
  reason:string='';
  address:string='';
  numberOfPeople: any=0;
  accommodation: string='';
  transport: string='';
  end_date:any;
  candidateName:string=''
  employeer:string='';
  validationMessage:string=''
  showSuccessMsg:boolean=false;
  viewData:any;
  reasonsOptions: string[]=['I am making my own arrangements.','I am leaving the country.','Other reason.']
  staticWarning:string="";
  minDate!: string;
  @ViewChild('staticBackdrop', { static: false }) modal!: ElementRef ;
  @ViewChild('AuthorityModal', { static: false }) modal2!: ElementRef ;
  @ViewChild('closeStaticModal', { static: true }) closeStaticModal!: ElementRef;
  payOutId:string='';
  comment:string='';
  payoutAmount1:number=0;
  payoutATD:boolean=false;
  accommodationOptions : string[]= ['Private Lease','Living With Friends'];
  brandLogo:string = 'assets/img/agrilabour-logo-01.png';
  transportOptions : string[]=['Employer Provided Vehicle','Private Transport']
  brandPhone:string='1300 247 823';
  brandEmail:string='info@agrilabour.com.au';
  brandName:string='Agri Labour Australia';
  brandUrl:string='www.agrilabour.com.au';
  redirectUrl:string='';

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private getUserQuery: GetUserQueryService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2, private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document   
  ) {
    const today = new Date();
    const minDate = new Date(today.getTime() + 28 * 24 * 60 * 60 * 1000);
    this.minDate = minDate.toISOString().split('T')[0]; // format: YYYY-MM-DD
    this.getQuery();
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit(): void {  
    this.redirectUrl = window.location.href;
    const brandHostname = this.document.location.hostname;
    if (brandHostname.includes('candidates.healthpluspeople.com.au')){
      this.brandLogo = 'assets/img/health_People.png';
      this.brandEmail='info@healthpluspeople.com.au';
      this.brandPhone='1300 367 719';
      this.brandName='Health Plus People';
      this.brandUrl='healthpluspeople.com.au';
    }   
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    this.mobile = localStorage.getItem('mobile');
    }

  getQuery() {
    this.getUserQuery.getUserQuery().subscribe((res: any) => {
        this.queryData = res.data;
        const allComplete = this.queryData?.every((item: { deductionStatus: string }) => item.deductionStatus === 'Complete');
        localStorage.setItem('deductionStatus', allComplete ? 'Complete' : 'Incomplete');
        this.deductionStatus=localStorage.getItem('deductionStatus');
        this.loadingFiles = false;
      },
      (error) => {
        console.error('Error fetching query data:');
        this.loadingFiles = false;
      }
    );
  }

  onKeyDown(event: KeyboardEvent) {
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].indexOf(event.key) !== -1) {
      event.preventDefault();
    }
  }

  viewDeduction(item: any): void {
    this.viewData=item;
    this.successMessage = '';
    this.loadingFiles = true;
    this.mid = item.mid;
    this.employeer=item.companyName;
    this.candidateName= item.name_first+" "+item.name_last;
    // First API call
    const apiUrl = `${environment.apiConfig.uri}/AstutePayAdvice/GetDeduction/${this.mid}`;
    this.http.get(apiUrl).subscribe(
      (response: any) => {
        this.unlimitedData = response.data.unlimited;
        this.fixedData = response.data.fixed_amount;
        const secondUrl = `${environment.apiConfig.uri}/Candidate/GetDeductionMetaData/${this.mid}`;
        this.http.get(secondUrl).subscribe((metaDataResponse: any) => {
          const mdata = metaDataResponse.data;
          const metaData = mdata.map((item: any) => {
            return {
              ...item, // Keep all existing properties
              amount: parseFloat(item.amount).toFixed(2),
              date_from: item.date_from.split('T')[0],
            };
          });

          // Iterate through unlimitedData and metaData to update fileId
          this.unlimitedData.forEach((unlimitedItem: any) => {
            metaData.forEach((metaItem: any) => {
              if (
                unlimitedItem.pdid == metaItem.pdid && unlimitedItem.deductionId == metaItem.deductionId &&
                parseFloat(unlimitedItem.amount).toFixed(2) ==
                  parseFloat(metaItem.amount).toFixed(2) &&
                unlimitedItem.date_from == metaItem.date_from
              ) {
                unlimitedItem.isSigned = metaItem.isSigned;
                unlimitedItem.fileId = metaItem.fileId;
                unlimitedItem.status = metaItem.status;
                unlimitedItem.envelopeId = metaItem.envelopeId;
              }
            });
          });

          this.fixedData.forEach((fixedItem: any) => {
            metaData.forEach((metaItem: any) => {
              if (
                fixedItem.pdid == metaItem.pdid && fixedItem.deductionId == metaItem.deductionId &&
                parseFloat(fixedItem.amount).toFixed(2) ==
                  parseFloat(metaItem.amount).toFixed(2) &&
                fixedItem.date_from == metaItem.date_from
              ) {
                fixedItem.isSigned = metaItem.isSigned;
                fixedItem.fileId = metaItem.fileId;
                fixedItem.status = metaItem.status;
                fixedItem.envelopeId = metaItem.envelopeId;
              }
            });
          });
          this.loadingFiles = false;
          this.openDeduction = true;
        });
      },
      (error) => {
        console.error('Error fetching file stream:', error);
        this.loadingFiles = false;
      }
    );
  }

  resetForm() {
    this.reason = '';
    this.validationMessage = '';
    this.showSuccessMsg = false;
    this.end_date = '';
    this.comments = '';
    this.payOutAmount = undefined;
}

stopDeduction(data: any) {
    this.resetForm();
    this.recurringDeduction = true;
    this.fixedDeduction = false;
    this.requestData = data;
    this.staticWarning=`You must provide 28 days notice to stop a deduction.`
    this.modalTitle = 'Request to Stop Deduction';
}

payOutDeduction(data: any) {
    this.resetForm();
    this.fixedDeduction = true;
    this.staticWarning=''
    this.recurringDeduction = false;
    this.requestData = data;
    this.modalTitle = 'Request to Pay Out Deduction';
}
  
submitRequest(comments: string, payOutAmount: any, end_date: any, reason: string) {
  this.validationMessage = '';
  this.payoutAmount1 = payOutAmount;
  const pdid = this.requestData.pdid;
  const uid = this.requestData.uid;
  if (this.recurringDeduction) {
      if (!end_date) {
          this.validationMessage = 'End Date is required for recurring deductions.';
          return;
      } else if (!reason) {
          this.validationMessage = 'Reason is required for recurring deductions.';
          return;
      } else if (!comments) {
          this.validationMessage = 'Comments are required for recurring deductions.';
          return;
      }
  } else if (this.fixedDeduction) {
      if (!payOutAmount || payOutAmount <= 0 || isNaN(payOutAmount)) {
          this.validationMessage = 'Pay Out Amount is required and must be greater than 0.';
          return;
      }
  }
  // Prepare the request data object
  const reqData: any = {
      uid: uid,
      pdid: pdid,
      name: this.requestData.name,
      candidateName: this.candidateName,
      employeer: this.employeer,
      note: comments,
      limit_type: this.requestData.limit_type,
      isAstute: this.requestData.isAstute,
      isPayOut: true
  };
  // Add recurring deduction-specific fields if applicable
  if (this.recurringDeduction) {
      reqData.date_to = end_date;
      reqData.reason = reason;
  }
  // Add fixed deduction-specific fields if applicable
  if (this.fixedDeduction) {
      reqData.amount_taken = payOutAmount;
  }
  // Optional fields for number of people, accommodation, and transport
  if (this.numberOfPeople) {
      reqData.numberOfPeople = this.numberOfPeople;
  }
  if (this.accommodation) {
      reqData.accommodation = this.accommodation;
  }
  if (this.transport) {
      reqData.transport = this.transport;
  }
  if (this.address) {
    reqData.address = this.address;
}
  const reqUrl = `${environment.apiConfig.uri}/AstutePayAdvice/DeductionStop`;
  this.loading = true;
  this.http.post(reqUrl, reqData).subscribe((res: any) => {
      try {
          this.showSuccessMsg = true;
          this.loading = false;
          this.staticWarning = '';
          this.successMessage = '';
          this.payOutId = res.data;
          this.currentDate = new Date();
          if (this.fixedDeduction) {
              this.payoutATD = true;
              this.comment = comments;
              this.payoutAmount1 = payOutAmount;
              this.successMessage = '';
              this.currentDate = new Date();
              this.dataATD = [this.requestData];
              this.closeModal('staticBackdrop');
              this.openModal('AuthorityModal');
          }
          this.viewDeduction(this.viewData);
      } catch (error: any) {
          this.loading = false;
          this.validationMessage = error.message;
          this.showSuccessMsg = true;
      }
      // Clear form fields after successful submission
      this.reason = "";
      this.end_date = "";
      this.comments = "";
      this.payOutAmount = 0;
      this.numberOfPeople = null;
      this.address='';
      this.accommodation = "";
      this.transport = "";
  });
}

  private openModal(modalId: string) {
    const modal = this.elementRef.nativeElement.querySelector(`#${modalId}`);
    if (modal) {
      // Add Bootstrap modal classes and attributes
      this.renderer.addClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'block');
      this.renderer.setAttribute(modal, 'aria-hidden', 'false');
      this.renderer.setAttribute(modal, 'aria-modal', 'true');
      this.renderer.setAttribute(modal, 'role', 'dialog');
  
      // Ensure modal backdrop is added
      const backdrop = this.renderer.createElement('div');
      this.renderer.addClass(backdrop, 'modal-backdrop');
      this.renderer.addClass(backdrop, 'fade');
      this.renderer.addClass(backdrop, 'show');
      this.renderer.appendChild(document.body, backdrop);
  
      // Add modal-open class and adjust body styles
      this.renderer.addClass(document.body, 'modal-open');
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
      this.renderer.setStyle(document.body, 'padding-right', '0');
    }
  }

  private closeModal(modalId: string) {
    const modal = this.elementRef.nativeElement.querySelector(`#${modalId}`);
    if (modal) {
      // Remove Bootstrap modal classes and attributes
      this.renderer.removeClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'none');
      this.renderer.setAttribute(modal, 'aria-hidden', 'true');
      this.renderer.removeAttribute(modal, 'aria-modal');
      this.renderer.removeAttribute(modal, 'role');

        // Remove the modal backdrop if it exists
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.parentNode?.removeChild(backdrop);
        }
  
        // Remove modal-open class and reset body styles
        this.renderer.removeClass(document.body, 'modal-open');
        this.renderer.setStyle(document.body, 'overflow', '');
        // this.renderer.setStyle(document.body, 'padding-right', '');
    }
  }
  
  closeAuthorityModal(data:any) {
    this.closeModal(data);
    this.loading = false;
    if (this.successMessage) {
      this.openDeduction = false;
    }
  }

  formatmetaDateString(dateString: string): string {
    if (!dateString) return '';
    const date = dateString;
    return date.toString().split('T')[0];
  }
  formatDateString(dateString: any): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  private formatData(dataItems: any[]): any[] {
    return dataItems.map((dataItem: any) => ({
      ...dataItem,
      amount: parseFloat(dataItem.amount).toFixed(2),
      amount_taken: parseFloat(dataItem.amount_taken).toFixed(2),
      balance:
        dataItem.balance === 'No'
          ? '-'
          : parseFloat(dataItem.balance).toFixed(2),
      date_from: this.formatDate(dataItem.date_from),
    }));
  }

  private formatDate(dateString: string | null): Date | string {
    if (!dateString || dateString === '-' || dateString === 'PAID') {
      return dateString === 'PAID' ? 'PAID' : '-';
    }
    return new Date(dateString.replace(/\s+/g, ' '));
  }

  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
      this.cdr.detectChanges();
    }
  }

  openDocuSign() {
    window.location.href = this.docuSignUrl;
  }

  createDeductionSlip(data: any) {
    if(data.batchId){
       // Filter fixedData and unlimitedData to find entries with the same batchId
    const fixedDataMatches = this.fixedData.filter((item: { batchId: any; }) => item.batchId === data.batchId);
    const unlimitedDataMatches = this.unlimitedData.filter((item: { batchId: any; }) => item.batchId === data.batchId);
    // Combine the matching entries
    this.dataATD = [...fixedDataMatches, ...unlimitedDataMatches];
    }
    else{
      this.dataATD = [data];
    }
    this.successMessage = '';
    this.currentDate = new Date();
  }

  async submitATD() {
    this.docuSignUrl = '';
    this.submissionDate = new Date().toLocaleString();
    const pdfdata = document.getElementById('ATD_pdf');

    if (pdfdata) {
      const pdfOptions = {
        margin: 10,
        filename: `Authority to Deduct  – ${this.dataATD[0].name?this.dataATD[0].name:"Multi"} - ${this.name}.pdf`,
        image: { type: 'png', quality: 0.9 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      try {
        this.loading = true;
        // Generate PDF using html2pdf
        const pdf = await html2pdf().from(pdfdata).set(pdfOptions).outputPdf();
        const pdfBase64 = this.encodeToBase64(pdf);
        const deductions = this.dataATD.map((item: {
          liability_account_code: any;
          date_to: any; limit_fixed: any;
          amount_taken: any; old_PSID: any;
          pcid: any; limit_type: any;isPayOut:any;
          amount_owed: any; balance: any;
          note: any; uid: any; pdid: any; amount: any; date_from: any;
          name: any; deductionId: any; batchId:any;isAstute:any }) => {
          return {            
            epdiid: 0,
            uid: item.uid,
            pdid: item.pdid,
            amount: item.amount,
            active: "yes",
            date_from: item.date_from,
            date_to: item.date_to,
            limit_fixed: item.limit_fixed,
            amount_taken: item.amount_taken,
            old_PSID: item.old_PSID,
            name: item.name,
            pcid: item.pcid,
            limit_type: item.limit_type,
            amount_owed: item.amount_owed,
            total_paid: item.amount_owed,
            balance: item.balance,
            liability_account_code: item.liability_account_code,           
            isAstute: item.isAstute,
            note: item.note,
            isPayOut:item.isPayOut,
            deductionId: item.deductionId,
            batchId: item.batchId,
            // isSigned: false,
            
          };
        });
        const FileObj = {
          deduction: deductions,
          file: pdfBase64,
          candidatename: this.candidateName,
          employer: this.employeer
        };       
        const url = `${environment.apiConfig.uri}/Candidate/SaveATDHistory?fileName=${encodeURIComponent(
          `Authority to Deduct  – ${this.dataATD[0].name?this.dataATD[0].name:"Multi"} - ${this.name}.pdf`)}&receiverEmail=${encodeURIComponent(
          this.email)}&receiverName=${encodeURIComponent(this.name)}&url=${this.redirectUrl}&payOutId=${encodeURIComponent(this.payOutId)}`;
        const response = await this.http.post<any>(url, FileObj).toPromise();
        this.loading = false; // Hide loading indicator
        this.successMessage ='Please Open below to add your signature to the document.';
        this.docuSignUrl = response.data;
      } catch (error) {
        this.loading = false;
        console.error('Error generating or submitting PDF:', error);
      }
    } else {
      this.loading = false;
      console.error('Content is empty or element not found.');
    }
  }

  private encodeToBase64(data: string): string {
    return btoa(data);
  }

  onClickDownload(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const link = document.createElement('a');
      link.download = `${item.name}`;
      const source = `data:application/pdf;base64,${item.fileStream}`;
      link.href = source;
      link.click();
    }
  }

  getFileStream(item: any, view: boolean = false) {
    var url = environment.apiConfig.uri +`/CandidateFiles/GetFileStream/${item.fileId}`;
    this.http.get(url).subscribe(
      (response: any) => {
        this.file = response.data || '';
        item.fileStream = this.file;
        if (view) {
          this.onClickView(item);
        } else {
          this.onClickDownload(item);
        }
      },
      (error) => {
        console.error('Error fetching file stream:', error);
      }
    );
  }

  onClickView(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item, true);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const link = document.createElement('a');
      link.target = '_blank';
      const blob = this.dataURItoBlob(item);
      const file = new File([blob], item.name, {
        type: `application/pdf`,
      });
      let url = window.URL.createObjectURL(file);
      link.href = url;
      link.click();
    }
  }

  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: `application/pdf`,
    });
    return blob;
  }

}

import {  Component,  ElementRef,  OnInit,  ViewChild,  ViewEncapsulation,  Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
interface deductionObj {
  id: string;
  candidateName: string;
  employer: string;
  pdid: number;
  uid: number;
  date_from: Date;
  date_to: Date;
  name: string;
  amount: string;
  limit_type: string;
  limit_fixed: string;
  note: string;
  status:number;
  amount_taken: string;
  liability_account_code: string;
  candidateId:number;
}

@Component({
  selector: 'app-add-deductions',
  templateUrl: './add-deductions.component.html',
  styleUrls: ['./add-deductions.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddDeductionsComponent implements OnInit {
  selected: string = 'add-deductions';
  employers: string[] = [
    'Agri Labour Australia Pty Ltd',
    'ALA Staff Sourcing Pty Ltd',
    'Health Plus People Pty Ltd',
  ];
  canDeductions: any[] = [];
  selectedCandidate: any;
  selectedEmployer!: string;
  selectedDeduction: any;
  candidateName: any;
  allDeduction: any;
  cDate: Date = new Date();
  selectedPersonId: any;
  candidatesdata: any;
  currentDate: any = this.cDate.toDateString();
  userQueryData: any;
  adminId!:number;
  addDeductionPayload!:deductionObj;
  submitDeductionPayload!:deductionObj;
  fullName:string='';
  message:string='';
  loadingDeductions:boolean=true;
  eValue!:string;
  uid!:number;
  mobileView: boolean = false;
  deductionToRemove:any;
  remoteid:string='';
  candidateId!:number;
  anySelected = false;
  @ViewChild('submitDeductionModal', { static: false }) modal!: ElementRef ;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  ngOnInit(): void {
    this.getAddedDeductions();
  }
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
    }
  }

  getAddedDeductions() {
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetAddedDeductions`;
    this.http.get(url).subscribe(
      (response: any) => {
        this.allDeduction = response.data.filter((data:any)=>data.status===0) || []; // Assign response data or an empty array if response.data is falsy
        this.allDeduction.sort((a:any, b:any) => b.id - a.id); // Sort in descending order based on id
        this.loadingDeductions = false;
      },
      (error) => {
        console.error("Error fetching deductions:", error);
        this.loadingDeductions = false;
      }
    );
  }

  onSelectEmployer(employer: string) {
    this.selectedCandidate=''
    this.selectedEmployer = employer;
    if (this.selectedEmployer.length>0) {
      if(this.selectedEmployer=="Agri Labour Australia Pty Ltd"){
        this.eValue='1';
      } else if(this.selectedEmployer=="ALA Staff Sourcing Pty Ltd"){
        this.eValue='2';
      } else if(this.selectedEmployer=="Health Plus People Pty Ltd"){
        this.eValue='4';
      }
      this.loadingDeductions=true;
      const url = `${environment.apiConfig.uri}/admin/Candidates/GetcandidateWithCompany?companyName=${this.eValue}`;
      this.http.get(url).subscribe((response: any) => {
          this.loadingDeductions=true;
          this.candidatesdata = response.data.map((candidate: any) => ({
            ...candidate,
            fullName: `${candidate.employee_name_first} ${candidate.employee_name_last}`, // Combine first and last names
          }));
          // Sort candidatesdata array alphabetically by full name
          this.candidatesdata.sort((a: any, b: any) => {
            const fullNameA = a.fullName.toUpperCase();
            const fullNameB = b.fullName.toUpperCase();
            if (fullNameA < fullNameB) {
              return -1;
            }
            if (fullNameA > fullNameB) {
              return 1;
            }
            return 0;
          });

          this.loadingDeductions=false
      } ,(error) => {
          console.error("Error fetching Candidates:", error);
          this.loadingDeductions = false;
        }
      );
    } else{
      console.log("Select Employer")
    }
  }

  selectCandidate(candidate: any) {
    this.selectedCandidate = candidate.fullName;
    this.selectedDeduction=''
    this.uid=candidate.employee_MID
    this.remoteid=candidate.remoteid;
    let parts = this.remoteid.split('-');
    this.candidateId = parseInt(parts[2], 10);
    this.fullName = candidate.fullName;
    if(candidate.fullName.length>0){
      this.loadingDeductions = true;
      const url = `${environment.apiConfig.uri}/admin/Candidates/GetCandidateDeductions?UID=${this.uid}`;
      this.http.get(url).subscribe((response: any) => {
          this.canDeductions = response.data;
          this.loadingDeductions = false;
        },
        (error) => {
          console.error('Error fetching candidate deductions:', error);
          this.loadingDeductions = false;
        }
      );
    }
  }

  selectDeduction(data: any) {
    this.selectedDeduction = data;
  }

  addDeduction() {
    this.message = ''; 
    if (!this.selectedEmployer) {
      this.message = 'Please select an employer to add deductions.';
      this.openModal();
      return;
    }
  
    if (!this.selectedCandidate) {
      this.message = 'Please select a candidate for which you are adding deductions.';
      this.openModal();
      return;
    }
  
    if (!this.selectedDeduction) {
      this.message = 'Please select a deduction first.';
      this.openModal();
      return;
    }
  
    const existingDeduction = this.allDeduction.find((deduction: { name: any; candidateName: any; }) =>
      deduction.name === this.selectedDeduction.name && deduction.candidateName === this.selectedCandidate);
  
    if (existingDeduction) {
      this.message = `Deduction "${this.selectedDeduction.name}" already exists for this candidate.`;
      this.openModal();
      return;  // Exit function if deduction name already exists
    }
  
    let data = this.selectedDeduction;
    this.addDeductionPayload = {
      id: data.id,
      candidateName: this.fullName,
      employer: this.selectedEmployer,
      pdid: data.pdid,
      uid: this.uid,
      date_from: data.date_from,
      date_to: data.date_to,
      name: data.name,
      amount: data.amount,
      limit_type: data.limit_type == 'fixed_pay_period' ? 'fixed_amount' : data.limit_type,
      limit_fixed: data.limit_fixed,
      note: '',
      status: 0,
      amount_taken: '0',
      liability_account_code: data.liability_account_code,
      candidateId: this.candidateId
    };
  
    const url = `${environment.apiConfig.uri}/admin/Candidates/AddDeductions`;
    this.http.post(url, this.addDeductionPayload).subscribe((response: any) => {
      this.getAddedDeductions();
    });
  }
  
  private openModal() {
    const modal = this.elementRef.nativeElement.querySelector('#submitDeductionModal');
    if (modal) {
      this.renderer.addClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'block');
    } else {
      console.error("Modal element not found.");
    }
  }
  
  closeModal() {
    const modal = this.elementRef.nativeElement.querySelector('#submitDeductionModal');
    if (modal) {
      this.renderer.removeClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'none');
    } else {
      console.error("Modal element not found.");
    }
  }

  removededuction(data: any) {
    this.deductionToRemove=data;
    this.message='Are you sure you want to cancel this deduction? This will remove it from list.'; 
  }
  confirmCancel(){
    let data=this.deductionToRemove;
    const PDID=data.pdid;
    const UID=data.uid;
    const id =data.id;
    const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteDeductions?id=${id}&PDID=${PDID}&UID=${UID}`;
    this.http.delete(url).subscribe(() => {
        this.message='Deduction deleted successfully.'
        this.getAddedDeductions()
      },
      error => {
        this.message='Error deleting deduction:'
      }
    );

  }

  // Method to update the anySelected property
updateSelectedCount() {
  this.anySelected = this.allDeduction.some((item: { selected: any; }) => item.selected);
}

  submitSelectedDeductions() {
    this.message = '';
    const selectedDeductions = this.allDeduction.filter((item: { selected: boolean; }) => item.selected);
    const validationErrors: string[] = [];

    if (selectedDeductions.length === 0) {
      this.message = 'Please select at least one deduction.';
      this.openModal();
      return;
    }
  
     // Check if all selected deductions have the same candidate name and employer
    const candidateNames = new Set(selectedDeductions.map((item: { candidateName: string; }) => item.candidateName));
    const employers = new Set(selectedDeductions.map((item: { employer: string; }) => item.employer));
  
    if (candidateNames.size > 1 || employers.size > 1) {
    this.message = `You have selected multiple candidates or employers. You must select deductions from one candidate 
    and one employer before selecting Submit.`;
    this.openModal();
    return;
    }
  
    selectedDeductions.forEach((item: { name: any; }) => {
      const errors = this.validateInput(item);
      if (errors.length > 0) {
        const formattedErrors = errors.map(error => `<li>${error}</li>`).join('');
        validationErrors.push(`<p><strong>Please Complete details for ${item.name}:</strong></p><ul>${formattedErrors}</ul>`);
      }
    });
  
    if (validationErrors.length > 0) {
      this.message = validationErrors.join('<br>'); // Combine all error messages with a line break
      // this.openModal();
      return;
    }
  
    const payloads = selectedDeductions.map((item: any) => this.buildPayload(item));
  
    const url = `${environment.apiConfig.uri}/admin/Candidates/SubmitDeductions`;
    this.http.post(url, payloads).subscribe((response: any) => {
      if (response && response.isSuccess) {
        const submittedIds = selectedDeductions.map((item: { id: any; }) => item.id);
        this.allDeduction = this.allDeduction.filter((row: { id: any; }) => !submittedIds.includes(row.id));
        this.message = "Deductions submitted successfully.";
    this.updateSelectedCount();
      } else {
        console.error('Error Submitting Deductions:', response);
        this.message = 'Error Submitting Deductions.';
      }
    }, error => {
      console.error('Error submitting deduction data:', error);
      this.message = 'Error submitting deduction data.';
    });
  } 
  
  validateInput(data: any) {
    const validatedAmount = this.validateAmount(data.amount);
    const validatedLimitFixed = this.validateLimitFixed(data.limit_fixed);
    const errors = [];
    if (!data.startDate) {
      errors.push('Please provide a Start Date.');
    }
    if (data.amount === null || data.amount.trim() === '' || isNaN(data.amount)) {
      errors.push('Enter valid Amount.');
    } else if (!validatedAmount) {
      errors.push('Please Enter valid amount with a maximum of 6 digits.');
    }
    if ((data.limit_type === "fixed_amount" || data.limit_type === 'fixed_pay_period') && (data.limit_fixed === null ||data.limit_fixed ===0 ||data.limit_fixed.trim() === '' || isNaN(data.limit_fixed))) {
      if (!validatedLimitFixed) {
        errors.push('Please enter valid limit with a maximum of 7 characters.');
      } else {
        errors.push('Enter valid Limit.');
      }
    } else if (validatedAmount !== null && validatedLimitFixed !== null && validatedAmount > validatedLimitFixed) {
      errors.push('Amount cannot be greater than the deduction limit.');
    }
    return errors;
  }
  
  // Separate function to build the payload
  buildPayload(data: any) {
    return {
      id: data.id,
      candidateName: data.candidateName,
      employer: data.employer,
      pdid: data.pdid,
      uid: data.uid,
      date_from: data.startDate,
      date_to: data.endDate || null,
      name: data.name,
      amount: data.amount,
      limit_type: (data.limit_type === 'fixed_pay_period' ? 'fixed_amount' : data.limit_type),
      limit_fixed: data.limit_fixed || "0",
      note: data.note || '',
      status: 1,
      amount_taken: "0",
      liability_account_code: data.liability_account_code || 0,
      candidateId:data.candidateId
    };
  }

  submitIndividualDeduction(item: any) {
    this.message = '';  
    // Validate the input for the individual item
    const errors = this.validateInput(item);
    if (errors.length > 0) {
      const formattedErrors = errors.map(error => `<li>${error}</li>`).join('');
      this.message = `<p><strong>Please Complete details for ${item.name}:</strong></p><ul>${formattedErrors}</ul>`;
      // Optionally, open a modal to display the error message
      this.openModal();
      return;
    }
  
    // Build the payload for the individual item
    const payload = [this.buildPayload(item)];
  
    const url = `${environment.apiConfig.uri}/admin/Candidates/SubmitDeductions`;
    this.http.post(url, payload).subscribe((response: any) => {
      if (response && response.isSuccess) {
        this.allDeduction = this.allDeduction.filter((row: { id: any; }) => row.id !== item.id);
        this.message = "Deduction submitted successfully.";
        this.updateSelectedCount();
      } else {
        console.error('Error Submitting Deduction:', response);
        this.message = 'Error Submitting Deduction.';
      }
    }, error => {
      console.error('Error submitting deduction data:', error);
      this.message = 'Error submitting deduction data.';
    });
  }
  


  

  // Validate amount (number with max 5 characters, including up to 2 decimals)
  validateAmount(amount: string): number | null {
    if (amount === null || amount.trim() === '') {
      return null;
    }
    const regex = /^\d+(?:\.\d+)?$/;  // Regular expression for number with optional decimals
    if (!amount.match(regex) || amount.length > 7) {
      return null;
    }
    return parseFloat(amount); // Convert to a number
  }
  
  // Validate limitFixed (number with max 7 characters, including up to 2 decimals)
  validateLimitFixed(limit_fixed: string): number | null {
    if (limit_fixed === null || limit_fixed.trim() === '') {
      return null;
    }
    const regex = /^\d+(?:\.\d+)?$/;
    if (!limit_fixed.match(regex) || limit_fixed.length > 8) {
      return null;
    }
    return parseFloat(limit_fixed);
  }

}

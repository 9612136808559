<div class="row mt-5">
    <div
      class="col-12"
      style="padding-right: 0 !important; margin-right: 0 !important"
    >
      <div class="card">
        <div class="card-body">
          <!-- Candidate Files -->
          <div class="row">
            <div class="col-12">
              <h6>Training Assessment</h6>
              <br />
              <div>
                <table class="table mb-2">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Training Description</th>
                      <th scope="col">Status</th>
                      <th scope="col">Attempted/Total</th>
                      <th scope="col">Correct</th>
                      <th scope="col">Obtained Marks %</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody *ngIf="totalQuiz!=0">
                    <tr *ngFor="let item of quizList; index as i">
                      <td>{{i +1}}</td>
                      <td>{{item.fileName}}</td>
                      <td>{{item.status}}</td>
                      <td>{{ item.atttemptedQuestionCount }}/{{ item.questionCount }}</td>
                      <td>{{ item.rightQuestions }}</td>
                      <td>{{item.obtainedMarks}}</td>                      
                      <td>
                        <app-status-icons [statusValue]="item.status"></app-status-icons>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="totalQuiz==0">
                    <tr>
                      <td class="text-center" scope="col" colspan="7">No data</td>
                    </tr>
                  </tbody>
                 
                </table>
              </div>
            </div>
          </div>  

          <div *ngIf="loadingCandidateFiles">
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load"></div>
            </li>
            <li class="t_loader">
              <div class="card__desc load" id="long"></div>
            </li>
          </div>
          <div *ngIf="!loadingCandidateFiles" class="table-responsive">
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal Update File -->
  <div
    class="modal fade"
    id="updateModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="updateModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateModalLongTitle">Update File</h5>
          <button
            #closeModelUpdate
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-10 mx-auto">
            
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { BlobServiceClient, AnonymousCredential, newPipeline } from '@azure/storage-blob';
const {BlobServiceClient} =require('@azure/storage-blob')
import { AzureBlobsService } from '../../services/azure-blobs.service';
import { ComponentCanDeactivate } from '../../component-can-deactivate';
import * as moment from "moment";
import { from, Subject } from 'rxjs';

import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
declare var require: any;
type TrainingFile = {
  fileName?: string;
  fileSize?: string;
  contentType?: string;
  contentSubType?: string;
  fileContent?: string;
  extension?: string;
};

type NewFile = {
  id?: number;
  fileUrl?: string;
  type?: string;
  fileStream?: string;
  fileSize?: number;
  fileName?: string;
  fileNameOld?: string;
  dateAdded?: string;
  contentType?: string;
  contentSubType?: string;
};

@Component({
  selector: 'app-training-files',
  templateUrl: './training-files.component.html',
  styleUrls: ['./training-files.component.css'],
})
export class TrainingFilesComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  sorting: boolean = false
  selected: string = 'training-files';
  loadingTrainingFiles: boolean = true;
  
  warning: string = "";
  path =
    'https://rest60.bullhornstaffing.com/rest-services/2g9m9s/file/Candidate/231585/504294/raw?BhRestToken=74a83c83-759c-4158-8854-6e0c7fdd0769';
  trainingFilesList: any;
  updateFileID: any;
  fileName: any;
  fileNameOld: any;
  contentType: any;
  contentSubType: any;
  fileSize!: number;
  fileError: boolean = false;
  typeSelected: any;
  base64: any;
  file: any;
  FileID: any;
  mobileView: boolean = false;
  id: string = '';
  loading: boolean = false;
  tempID: string = '';
  downloadId: string = '';
  traininfFileMetaFields: any;
  fileInputText = "No File Chosen...";
  fileExtension: any;
  copied: boolean = false;
  src!: SafeResourceUrl;
  blobURL: any;
  blobStream = new Blob();
  progressValue: number = 0;
  uploading: boolean = false;
  uploaded: boolean = false;
  uploadFailed: boolean = false;
  updating: boolean = false;
  index: number = 0;
  progressText: string = 'uploading...';
  errorMsg: any;
  currentFile: File = new File([], '');
  SASTokan: any;
  moment: any = moment;
  pageSizeOptions = [10, 20, 50, 100];
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;
  query: string = '';

  @Input() fileView: boolean = false;
  @Input() updateDelete: boolean = false;
  @Input() data: any = [];
  @Input() tableRows: string[] = ['fileName',
    'extension',
    'fileSize'];
  @Input() tableHeader: any = [{ name: 'File Name', button: "fa fa-sort" },
  { name: 'File Type' },
  { name: 'File Size (kb)' }];
  // public refreshData!: Function;
  private readonly _destroying$ = new Subject<void>();

  @ViewChild('fileChosenAdd') fileChosenAdd!: ElementRef;
  @ViewChild('fileChosenUpdate') fileChosenUpdate!: ElementRef;
  @ViewChild('closeModelAdd') closeModelAdd!: ElementRef;
  @ViewChild('closeModelSwitch') closeModelSwitch!: ElementRef;
  @ViewChild('closeModelUpdate') closeModelUpdate!: ElementRef;
  @ViewChild('closeModelDelete') closeModelDelete!: ElementRef;
  @ViewChild('TypeAdd') TypeAdd!: ElementRef;
  @ViewChild('TypeUpdate') TypeUpdate!: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('switchTabBtn') switchTabBtn!: ElementRef;

  editFile = {
    id: 0,
    fileName: '',
  };

  fileTypes: any[] = [
    {
      value: 'Approval',
      label: 'Approval',
    },
    {
      value: '408 Visa Letter',
      label: '408 Visa Letter',
    },
    {
      value: 'Client Specific Induction',
      label: 'Client Specific Induction',
    },
    {
      value: 'Confirmation of Enrolment',
      label: 'Confirmation of Enrolment',
    },
    {
      value: 'Correspondence',
      label: 'Correspondence',
    },
    {
      value: 'Cover Letter',
      label: 'Cover Letter',
    },
    {
      value: 'Covid Vax Proof',
      label: 'Covid Vax Proof',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Induction',
      label: 'Induction',
    },
    {
      value: 'Letter of Offer',
      label: 'Letter of Offer',
    },
    {
      value: 'Licence',
      label: 'Licence',
    },
    {
      value: 'MADEC Card',
      label: 'MADEC Card',
    },
    {
      value: 'Position Description',
      label: 'Position Description',
    },
    {
      value: 'Proof of Identity',
      label: 'Proof of Identity',
    },
    {
      value: 'Qualification',
      label: 'Qualification',
    },
    {
      value: 'Resume / CV',
      label: 'Resume / CV',
    },
    {
      value: 'VEVO',
      label: 'VEVO',
    },
    {
      value: 'Visa',
      label: 'Visa',
    },
    {
      value: 'Reference',
      label: 'Reference',
    },
    {
      value: 'Risk Assessment',
      label: 'Risk Assessment',
    },
    {
      value: 'Training Record',
      label: 'Training Record',
    },
    {
      value: 'Warning Letter',
      label: 'Warning Letter',
    },
    {
      value: 'Work Instruction/SOP',
      label: 'Work Instruction/SOP',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  public formGroup = this.fb.group({
    file: [null, Validators.required],
  });
  pageName: any;

  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }

  constructor(
    private azureBlobsService: AzureBlobsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private msalService: MsalService,
    private sanitizer: DomSanitizer,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
  }
  viewFile(item: any) {
    window.open(`${environment.storageUrl}/training-files/${item.id}.${item.extension}${environment.SASToken}`, "_blank")
  }
  selectPageName(pageName: any) {
    this.pageName = pageName;
  }

  async ngOnInit(): Promise<void> {
    this.SASTokan = environment.SASToken;
    this.getTrainingFiles();
    this.formGroup.reset();

  }

  sort() {
    this.sorting = !this.sorting
    if (this.sorting) {
      this.trainingFilesList.sort((a: any, b: any) => {
        return a.fileName.localeCompare(b.fileName);
      })
    } else {
      this.trainingFilesList.reverse((a: any, b: any) => {
        return a.fileName.localeCompare(b.fileName);
      })
    }
  }

  canDeactivate(): boolean {
    if (!this.uploading) {
      return true;
    } else if (this.uploading) {
      this.switchTabBtn.nativeElement.click();
      return false;
    }
    return true;
  }

  switchTab() {
    if (!this.uploaded) {
      // delete blob from db
      var url = environment.apiConfig.uri + '/admin/TrainingFile/DeleteTrainingFileDbOnly/' + this.FileID;
      this.http.delete(url).subscribe(
        (response) => {
          //(response);
        }
      );
    }
    this.uploading = false;
    this.closeModelSwitch.nativeElement.click();
    this.router.navigate([this.pageName]);
  }

  getTrainingFiles = () => {
    var url = `${environment.apiConfig.uri}/admin/TrainingFile/GetTrainingFiles?pageNumber=${this.page}&pagesize=${this.pageSize}`;
    this.http.get(url).subscribe(async (response: any) => {
      this.trainingFilesList = response.data;
      this.traininfFileMetaFields = JSON.parse(response.extraParam.metaFields);
      this.traininfFileMetaFields = this.traininfFileMetaFields.fields[0].options;
      this.collectionSize = response.extraParam.FileCount;
      await this.updateClientDate();
      this.loadingTrainingFiles = false;
    });
  };
  refreshData() {
    this.loadingTrainingFiles = true;
    this.loading = true;
    this.http.get(
      `${environment.apiConfig.uri}/admin/TrainingFile/GetTrainingFiles?pageNumber=${this.page}&pagesize=${this.pageSize}`).subscribe((response: any) => {
        this.trainingFilesList = response.data;
        this.collectionSize = response.extraParam.FileCount;
        this.loading = false;
        this.loadingTrainingFiles = false;
      });
    // this.getTrainingFiles();
  }
  async upload(filename: string) {
    const blobServiceClient = new BlobServiceClient(environment.storageUrl + environment.SASToken);
    const containerClient = blobServiceClient.getContainerClient('training-files');
    const client = containerClient.getBlockBlobClient(filename);
    client.deleteIfExists();
    await client.uploadBrowserData(this.currentFile, {
      blockSize: this.fileSize, // 4MB block size
      concurrency: 20, // 20 concurrency
      // onProgress: (ev) => //(ev),
      blobHTTPHeaders: { blobContentType: this.currentFile.type }
    })
      .then(
        () => {
          this.uploaded = true;
          this.hideProgressBar();
        }
      )
      .catch(
        () => {
          this.hideProgressBar();
        }
      );
  }
  addFile() {
    this.errorMsg = "";
    this.fileSize = 0;
    this.fileInputText = 'No File Chosen...';
    this.errorMsg = "";
    // this.base64 = null;
    this.fileName = "";
    this.warning = "";
  }
  cross(ref: any) {
    ref[1].value = ""
  }
  handelCancle(data: any, values: any, ref: any) {

    ref.value = ""
    this.fileInputText = "";
    data.resetForm();
    this.errorMsg = "";
    this.warning = "";
  }
  removeValues(data: any, values: any) {
    values.chooseFileNew = "";
    this.fileInputText = "";
    this.errorMsg = "";
    this.warning = "";
  }
  public addFileSubmit(values: any, form: any): void {
    this.errorMsg = ""
    form.resetForm()
    var fileNames = '';
    for (let index in this.trainingFilesList) {
      fileNames += ',' + this.trainingFilesList[index].fileName;
    }
    var existedFileNames = fileNames.split(',');
    if (existedFileNames.indexOf(this.fileName) !== -1) {
      this.warning = "This File is already existed"
      return
    }

    // (this.fileExtension !== "pdf" && this.fileExtension !== "mp4" && this.fileName)
    if (this.fileExtension !== "pdf" && this.fileExtension !== "mp4") {
      this.warning = "Only Pdf and Mp4 files are allowed"
      return
    }

    this.warning = "";
    if (!this.fileSize && !this.fileName) {
      this.errorMsg = "Please select a title and file!";
      setTimeout(() => {
        this.errorMsg = null;
      }, 5000);
    } else if (!this.fileName) {
      this.errorMsg = "Please select a title!";
      setTimeout(() => {
        this.errorMsg = null;
      }, 5000);
    } else if (!this.fileSize) {
      this.errorMsg = "Please choose a file!";
      setTimeout(() => {
        this.errorMsg = null;
      }, 5000);
    }
    else {
      this.closeModelAdd.nativeElement.click();
      this.displayProgressBar();
      const FileObj = <TrainingFile>{};
      FileObj.fileName = this.fileName;
      FileObj.fileSize = this.fileSize.toString();
      FileObj.contentType = this.contentType;
      FileObj.contentSubType = this.contentSubType;
      // FileObj.fileContent = this.base64;
      if (this.fileExtension == "mp4") {

        FileObj.contentType = "video";
        FileObj.contentSubType = "mp4";
      }

      if (FileObj.contentType == "video") {
        FileObj.contentSubType = "mp4";
        this.fileExtension = "mp4";
      }
      // 
      if (this.fileExtension == "MOV") {
        FileObj.contentType = "video";
        FileObj.contentSubType = "mp4";
      }

      FileObj.extension = this.fileExtension;
      var url = environment.apiConfig.uri + '/admin/TrainingFile/AddTrainingFileDBOnly';
      this.http.post<any>(url, FileObj).subscribe((response: any) => {
        //(response);
        this.FileID = response.data.id;
        var filename = `${this.FileID}.${this.fileExtension}`;
        this.upload(filename);
      },
        (error: any) => {
          this.uploadFailed = true;
          this.hideProgressBar();
        });
    }

  }

  public updateFileSubmit(FileId: number, data: any, value: any): void {

    if (this.fileSize == 0 && !this.editFile.fileName) {  // this.base64 --> replace with this.currentfile
      this.errorMsg = "Please select a title and file!";
      setTimeout(() => {
        this.errorMsg = null;
      }, 5000);
    } else if (!this.editFile.fileName) {
      this.errorMsg = "Please select a title!";
      setTimeout(() => {
        this.errorMsg = null;
      }, 5000);
    } else if (this.fileSize == 0) {
      this.errorMsg = "Please choose a file!";
      setTimeout(() => {
        this.errorMsg = null;
      }, 5000);
    } else if (this.fileExtension !== "pdf" && this.fileExtension !== "mp4") {
      this.errorMsg = "Only Pdf and Mp4 files are allowed"
      return
    }

    else {
      this.updating = true;
      this.closeModelUpdate.nativeElement.click();
      this.displayProgressBar();
      const FileObj = <TrainingFile>{};
      FileObj.fileName = this.editFile.fileName;
      FileObj.contentType = this.contentType;
      FileObj.fileSize = this.fileSize.toString();
      FileObj.contentSubType = this.contentSubType;
      FileObj.extension = this.fileExtension;
      this.FileID = FileId;
      var url = environment.apiConfig.uri + '/admin/TrainingFile/UpdateTrainingFileDBOnly/' + FileId;
      this.http.post<any>(url, FileObj).subscribe((response: any) => {
        var filename = `${FileId}.${this.fileExtension}`;
        this.upload(filename);
      },
        (error: any) => {
          //(error);
          this.uploadFailed = true;
          this.hideProgressBar();
        });
      this.editFile.fileName = "";
    }
  }

  updateFile(item: any, index: number) {
    this.fileInputText = 'No File Chosen...';
    this.errorMsg = null;
    this.base64 = null;
    this.fileSize = 0;
    this.updateFileID = item.id;
    this.fileNameOld = item.fileName;
    this.fileName = item.filename;
    this.editFile = item;
    this.index = index;
  }
  resetForm(form: any) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
    form.get(key).setErrors(null);
    });
  }

  async onClickDownload(item: any) {
    var filename = `${item.id}.${item.extension}`;
    var url = `${environment.storageUrl}/training-files/${item.id}.${item.extension}${environment.SASToken}`;
  }

  onClickView(item: any) {
    var url = `https://agrilabourdev.blob.core.windows.net/training-files/${item.id}.${item.extension}`;
    if (item.extension == 'pdf') {
      url = 'https://docs.google.com/viewer?embedded=true&url=' + url;
      window.open(url, '_blank');
    } else if (item.contentType == 'video') {
      this.blobURL = url;
      var redirectUrl = location.origin + `/file-viewer?docType=video&fileUrl=${url}`;
      window.open(redirectUrl, '_blank');
    }
  }

  deleteFileRequest(file: any): void {
    this.loading = false;
    this.FileID = file.id;
  }

  deleteFile(): void {
    this.errorMsg = null;
    var url = environment.apiConfig.uri + '/admin/TrainingFile/' + this.FileID;
    this.loading = true;

    this.http.delete(url).subscribe(
      (response: any) => {
        //(response);
        this.errorMsg = "Deleted the file!";
        setTimeout(() => {
          this.closeModelDelete.nativeElement.click();
          this.getTrainingFiles();
          this.FileID = null;
          this.fileName = null;
          setTimeout(() => {
            this.loading = false;
            this.errorMsg = null;
          }, 100);
        }, 100);
      },
      (error) => {
        this.errorMsg = 'Unable to delete the file. Please delete the associated quiz first.';
        setTimeout(() => {
          this.closeModelDelete.nativeElement.click();
          setTimeout(() => {
            this.loading = false;
            this.errorMsg = null;
          }, 500);
        }, 2000);

      }
    );
  }

  updateClientDate = async () => {
    this.trainingFilesList = this.trainingFilesList.map((file: any) => {
      const date = new Date(file.dateAdded);
      const offset = date.getTimezoneOffset() * 60 * 1000;
      const newEpoch = date.getTime() - offset;
      const newDate = new Date(newEpoch);
      file.clientDate = newDate;
      return file;
    });
  };

  selectFileName(event: any) {
    this.fileName = event.target.value;
  }

  selectFileNameUpdate(event: any) {
    this.editFile.fileName = event.target.value;
  }

  selectType(event: any) {
    this.typeSelected = event.target.value;
  }
  clear() {
    this.fileInputText = ""
  }
  onFileChange(event: any, operation: string) {
    this.fileInputText = "";
    const [file] = ""
    this.fileInputText = ""
    this.warning = ""
    const reader = new FileReader();
    if (event.target.files) {
      this.fileInputText = event.target.files[0].name;
      var fileNameList = this.fileInputText.split(".");
      this.fileExtension = fileNameList[fileNameList.length - 1];
      this.fileSize = Math.round(event.target.files[0].size / 1000);
      const [file] = event.target.files;
      this.currentFile = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        var fileString = reader.result?.toString();
        this.contentType = fileString?.split(';base64,')[0].split('/')[0].split(':')[1];
        this.contentSubType = fileString?.split(';base64,')[0].split('/')[1];
      };
    }
  }

  displayProgressBar() {
    this.uploading = true;
    this.progressValue = 0;
    var time = (65 / 19186) * this.fileSize;
    var increment = 100 / (time * 4);
    const intervalID = setInterval(() => {
      this.progressValue += increment;
      if (this.progressValue > 98) {
        clearTimeout(intervalID);
      }
    }, 250);
  }

  hideProgressBar() {
    this.progressValue = 100;
    setTimeout(() => {
      this.uploading = false;
      this.uploaded = false;
      this.uploadFailed = false;
    }, 2500);
    this.fileInputText = "No File Chosen...";
    this.fileSize = 0;
    this.FileID = null;
    this.fileName = null;
    this.updating = false;
    this.getTrainingFiles();
  }

  copyTrainingFiles() {
    if (!this.copied) {
      var fileNames = '';
      for (let index in this.trainingFilesList) {
        fileNames += this.trainingFilesList[index].fileName + ',';
      }
      navigator.clipboard.writeText(fileNames);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    }
  }

  getFileStream(item: any, view: boolean = false) {
    var url = environment.apiConfig.uri + `/admin/File/GetFileStream/training-files/${item.id}`;
    this.http.get(url).subscribe((response: any) => {
      this.file = response.data ? response.data : '$';
      item.fileStream = this.file;
      if (view == true) {
        this.onClickView(item);
      } else {
        this.onClickDownload(item);
      }
    });
  }

  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: `${item.contentType}/${item.contentSubType}`,
    });
    return blob;
  }

  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}

<div class="blur-content">
    <div class="page-container" style="background-color: #f2f8fa !important;">
        <app-sidebar-menu></app-sidebar-menu>
        <!-- main content area start -->
        <div class="main-content">
            <app-header></app-header>
      
            <div class="main-content-inner">
                <!-- market value area start -->
                <div class="row mt-5 mb-5">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="market-status-table mt-2 profile">
          
                                    <div class="row mb-2">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">
                                                Title
                                            </p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="some title">
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-1">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">
                                                First Name
                                            </p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="firstName">
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-1">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">Last Name</p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="lastName">
                                        </div>
                                    </div>
                                    
                                    <div  class="row mb-1">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">
                                                Preferred Name
                                            </p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="nickName">
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-2">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">Postal Code</p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="profile.zip">
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-2">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">City</p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="profile.city">
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-2">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">State</p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="profile.state">
                                        </div>
                                    </div>
                                    
                                    <div  class="row mb-2">
                                        <div class="col-lg-2 col-sm-6 label">
                                            <p class="label-text">Country</p>
                                        </div>
                                        <div class="col-lg-5 col-sm-12">
                                            <input disabled type="text" class="form-control" value="profile.customText11">
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- market value area end -->
            </div>
        </div>
        <!-- main content area end -->
      </div>
    </div>
</div> 

<div id="unblurred">
    <div id="box" class="row">
        <div class="snippet" data-title=".dot-spin">
            <div class="stage">
            <div class="dot-spin"></div>
            <h3>Loading...</h3>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
interface Payload extends AuthenticationResult {
  idTokenClaims: {
    name?: string;
    given_name?: string;
    family_name?: string;
    emails?: Array<string>;
    aud?: string;
    auth_time?: string;
    exp?: Date;
    extension_CandidateId?: number;
    iat?: number;
    iss?: string;
    nbf?: number;
    nonce?: string;
    oid?: string;
    sub?: string;
    tfp?: string;
    ver?: string;
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {
  profile: any;
  name: any;
  email: any;
  mySubscription: any;
  addItem(newItem: string) {
    this.profile = newItem;
  }
  title = 'FrontendService';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private route: ActivatedRoute,
    private router: Router, private activatedRoute: ActivatedRoute
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }
  ngOnInit(): void { }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

}

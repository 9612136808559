import { Injectable } from '@angular/core';
import { from, OperatorFunction, Subject } from 'rxjs';
import { map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpResponseBase } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AzureBlobsService {
  

  constructor(
    private httpClient:HttpClient
  ) { }

  public downloadFile(url:string){
    return this.httpClient.get(url, {observe: 'response', responseType: 'blob'})
  }
}

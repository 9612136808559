<div class="row mt-5">
  <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
    <div class="card">
      <div class="card-body">
        <div *ngIf="displayLoader">
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
        </div>
        <div class="table-responsive">
          <table *ngIf="jobsList; else noJobs" class="table mb-2">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Job Title</th>
                <th scope="col">Host Employer</th>
                <th scope="col">Street Address</th>
                <th scope="col">Town / Suburb</th>
                <th scope="col">State</th>
                <th scope="col">Start Date</th>
                <th scope="col">Status</th>
                <th scope="col">Files</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let job of jobsList; index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ job.title }}</td>
                <td>{{ job.clientName || "N/A" }}</td>
                <td>{{ job.address_line1 || "N/A" }}</td>
                <td>{{ job.address_city || "N/A" }}</td>
                <td>{{ job.address_state || "N/A" }}</td>
                <td>{{ job.startDate | date: "dd-MM-yyy" || "N/A" }}</td>
                <td>{{ job.status }}</td>
                <td>
                  <button (click)="loadFiles(job.id)" style="font-size: 12px !important" type="button"
                    data-toggle="modal" data-target="#exampleModalCenter" class="btn btn-sm btn-info mb-2 px-3">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ng-template #noJobs>
            <div *ngIf="!displayLoader">
              <h5>No Jobs</h5>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Job Files</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="loadingJobFiles">
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load"></div>
          </li>
          <li class="t_loader">
            <div class="card__desc load" id="long"></div>
          </li>
        </div>
        <div *ngIf="!loadingJobFiles" class="table-responsive">
          <table class="table mb-2">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">File Name</th>
                <th scope="col">File Type</th>
                <th scope="col">File Size (Kb)</th>
                <th scope="col">Date Added</th>
                <th scope="col">View</th>
              </tr>
            </thead>
            <tbody *ngIf="jobFilesList.length > 0">
              <tr *ngFor="let item of jobFilesList; index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.fileName }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.fileSize }}</td>
                <td>{{ item.dateAdded | date: "dd-MM-yyy" || "N/A" }}</td>
                <td>
                  <button *ngIf="
                      item.contentSubType == 'pdf' ||
                        item.contentSubType == 'jpg' ||
                        item.contentSubType == 'png' ||
                        item.contentSubType == 'jpeg';
                      else showJobDownload
                    " class="btn btn-sm btn-info px-4" (click)="onClickView($event, item)">
                    View
                  </button>

                  <ng-template #showJobDownload>
                    <button class="btn btn-sm btn-info" (click)="onClickDownload($event, item)">
                      Download
                    </button>
                  </ng-template>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="jobFilesList.length == 0">
              <tr>
                <td colspan="6" class="text-center">No Files</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
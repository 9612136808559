<div class="blur-content">
  <div class="page-container" style="background-color: #f2f8fa !important">
      <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
      <!-- main content area start -->
      <div class="main-content">
          <app-header></app-header>
          <div class="main-content-inner">
              <!-- market value area start -->
              <div class="row mt-5">
                  <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
                      <div class="card">
                          <div class="card-body">
                              <div class="d-flex justify-content-between align-items-center">
                                  <h3>Job Match</h3>
                                  <button type="submit" class="btn btn-primary" (click)="onSearch()">Search</button>
                              </div>

                              <!-- Loading Spinner -->
                              <div *ngIf="loading" class="d-flex justify-content-center">
                                <div class="spinner-border m-5" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <!-- Error Message -->
                              <div *ngIf="!loading && errorMessage" class="alert alert-danger mt-3 d-flex align-items-center justify-content-between">
                                <p class="flex-grow-1">{{ errorMessage }}</p>
                                <button class="btn btn-primary" (click)="onSearch()">Try Again</button>
                              </div>
                              
                              <!-- Job Match Form and Data Display -->
                              <div *ngIf="!loading && !errorMessage" class="job-match-form mt-3 table-responsive mb-4">
                                <form [formGroup]="jobMatchForm">
                                  <table class="table table-bordered table-hover table-striped align-middle mb-0 bg-white">
                                    <thead>
                                      <tr>
                                        <th>Job ID</th>
                                        <th>Submission</th>
                                        <th>Age (days)</th>
                                        <th>Candidate Status</th>
                                        <th>Has Own Car</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input id="jobId" type="text" formControlName="jobId" class="form-control"/>
                                          <div *ngIf="jobMatchForm.get('jobId')?.touched && jobMatchForm.get('jobId')?.hasError('required')" class="text-danger">
                                            Job ID is required.
                                          </div>
                                        </td>
                                        <td>
                                          <select id="submission" formControlName="submission" class="form-control">
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                          <div *ngIf="jobMatchForm.get('submission')?.touched && jobMatchForm.get('submission')?.hasError('required')" class="text-danger">
                                            Submission is required.
                                          </div>
                                        </td>
                                        <td>
                                          <input id="age" type="number" formControlName="age" class="form-control"/>
                                          <div *ngIf="jobMatchForm.get('age')?.touched && jobMatchForm.get('age')?.hasError('required')" class="text-danger">
                                            Age is required.
                                          </div>
                                          <div *ngIf="jobMatchForm.get('age')?.touched && jobMatchForm.get('age')?.hasError('max')" class="text-danger">
                                            Age cannot be greater than 100.
                                          </div>
                                        </td>
                                        <td>
                                          <select id="candidateStatus" formControlName="candidateStatus" multiple class="form-control">
                                            <!-- <option value="">Select</option> -->
                                            <option *ngFor="let status of statusValues" [value]="status">{{ status }}</option>
                                          </select>
                                          <div *ngIf="jobMatchForm.get('candidateStatus')?.touched && jobMatchForm.get('candidateStatus')?.hasError('required')" class="text-danger">
                                            Candidate Status is required.
                                          </div>
                                        </td>
                                        <td>
                                          <select id="hasOwnCar" formControlName="hasOwnCar" multiple class="form-control">
                                            <!-- <option value="">Select</option> -->
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                          <div *ngIf="jobMatchForm.get('hasOwnCar')?.touched && jobMatchForm.get('hasOwnCar')?.hasError('required')" class="text-danger">
                                            Has Own Car is required.
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </form>
                                
                                <!-- <button type="submit" class="btn btn-primary" (click)="onSearch()">Search</button> -->
                                

                                  <!-- Job Details Table -->
                                  <table class="table table-bordered table-hover table-striped align-middle mt-2 bg-white">
                                      <thead>
                                          <tr>
                                            <th>Job Title</th>
                                            <th>Client Name</th>
                                            <th>Program</th>
                                            <th>Key Words</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngIf="jobDetails">
                                            <td>{{jobDetails.title}}</td>
                                            <td>{{jobDetails.companyName}}</td>
                                            <td>{{jobDetails.correlatedCustomText8}}</td>
                                            <td>{{jobKeywords}}</td>
                                        </tr>
                                        <tr *ngIf="!jobDetails">
                                          <td colspan="4" class="text-center">No data</td>
                                        </tr>
                                      </tbody>
                                  </table>
                              </div>

                              <!-- Matched Candidates Table and Pagination -->
                              <div *ngIf="!loading && !errorMessage" class="job-match-table">
                                <h4>Matched Candidates</h4>
                                
                                <div class="table-responsive mt-2">
                                  <table class="table table-bordered table-hover table-striped align-middle mb-0 bg-white">
                                    <thead>
                                      <tr>
                                        <th>Candidate Id</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Score</th>
                                        <th>Key Words</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let candidate of matchingCandidates">
                                        <td>{{ candidate.candidateId }}</td>
                                        <td> <a target="_blank" href="https://cls60.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id={{candidate.candidateId}}">{{ candidate.name }}</a></td>
                                        <td>{{ candidate.status }}</td>
                                        <td>{{ candidate.score }}</td>
                                        <td>
                                          <ng-container *ngFor="let keyword of candidate.candidateKeywords">
                                            <span
                                              [ngClass]="{'highlight': isKeywordMatch(keyword)}"
                                              style="margin-right: 5px;"
                                            >
                                              {{ keyword }}
                                            </span>
                                            <!-- Add a comma separator except after the last item -->
                                            <span *ngIf="candidate.candidateKeywords[candidate.candidateKeywords.length - 1] !== keyword">, </span>
                                          </ng-container>>
                                        </td>
                                      </tr>
                                      <tr *ngIf="matchingCandidates.length === 0">
                                        <td colspan="5" class="text-center">No data</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              
                                <div *ngIf="matchingCandidates.length !== 0" class="pagination-container mt-3 d-flex justify-content-between align-items-center">
                                  <div class="select-wrapper">
                                    <select
                                      id="pageSizeSelect"
                                      class="form-select"
                                      [(ngModel)]="pageSize"
                                      (ngModelChange)="onPageSizeChange($event)"
                                    >
                                      <option *ngFor="let pageSizeOption of pageSizeOptions" [ngValue]="pageSizeOption">
                                        {{ pageSizeOption }} items per page
                                      </option>
                                    </select>
                                  </div>
                              
                                  <ngb-pagination 
                                    [collectionSize]="collectionSize"
                                    [(page)]="page"
                                    [pageSize]="pageSize"
                                    [maxSize]="4"
                                    [boundaryLinks]="true"
                                    [rotate]="true"
                                    (pageChange)="onPageChange($event)"
                                    class="paginationSet">
                                  </ngb-pagination>
                                </div>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
              <!-- market value area end -->
          </div>
          <!-- main content area end -->
      </div>
  </div>
</div>

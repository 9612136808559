import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from 'src/app/models/Vehicles';
import { Accommodation } from 'src/app/models/Accommodations';
import { Logistics } from 'src/app/models/Logistics';
import { forkJoin } from 'rxjs';
import { CandidateService } from 'src/app/services/candidate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CandidateModalComponent } from '../candidate/candidate-modal/candidate-modal.component';
import { MsalService } from '@azure/msal-angular';
import { PortalAdminService } from 'src/app/services/portal-admin.service';
@Component({
  selector: 'app-logistics',
  templateUrl: './logistics.component.html',
  styleUrls: ['./logistics.component.css']
})
export class LogisticsComponent implements OnInit {

  selected: string = 'logistics';
  loading: boolean = false;
  mobileView: boolean = false;
  currentSortColumn: string = '';
  sortDirection: boolean = true; // true for ascending, false for descending
  searchCandidateName: string = '';
  searchAccommodation: string = '';
  searchVehicle: string = '';
  errorMessage: string = '';
  allAccommodations: Accommodation[] = [];
  allVehicles: Vehicle[] = [];
  allLogistics: Logistics[] = [];
  filteredData: Logistics[] = [];
  candidateId!: string;
  page: any = 1;
  pageSize: any = 10;
  isPortalAdmin:boolean=false;

  constructor(private http: HttpClient, private candidateService: CandidateService,private authService: MsalService,
    private modalService: NgbModal, private portalAdminService:PortalAdminService) {}
  ngOnInit(): void {
      this.portalAdminService.isPortalAdmin().subscribe((res:any)=>{
      this.isPortalAdmin = res;
    },
    (error) => {
      console.error('Failed to determine admin status', error);
      this.isPortalAdmin = false;
    })
    this.getLogisticsData();
  }

  getLogisticsData() {
    this.loading = true;
    const logisticsUrl = `${environment.apiConfig.uri}/admin/Candidates/logistics`;
    const accommodationsUrl = `${environment.apiConfig.uri}/admin/Candidates/GetAccommodation`;
    const vehiclesUrl = `${environment.apiConfig.uri}/admin/Candidates/GetVechiles`;

    forkJoin({
      logistics: this.http.get<{ data: Logistics[] }>(logisticsUrl),
      accommodations: this.http.get<{ data: Accommodation[] }>(
        accommodationsUrl
      ),
      vehicles: this.http.get<{ data: Vehicle[] }>(vehiclesUrl),
    }).subscribe(
      ({ logistics, accommodations, vehicles }) => {
        this.allLogistics = logistics.data.map((logistic) => ({
          ...logistic,
          candidateName: `${logistic.firstName} ${logistic.lastName} [${logistic.candidateId}] ${logistic.clientCorporationName}`,
        }));
        this.filteredData = this.allLogistics;
        this.allAccommodations = accommodations.data
        .filter(accommodation => accommodation.status === 'Active')
        .filter(accommodation => accommodation.address.toLowerCase() !== 'unknown' && accommodation.address.toLowerCase() !== 'private accommodation')
        .sort((a, b) => a.address.localeCompare(b.address));
      
      // Find "unknown" and "Private Accommodation" in the original data
      const unknownAccommodation = accommodations.data.find(accommodation => accommodation.address.toLowerCase() == 'unknown');
      const privateAccommodation = accommodations.data.find(accommodation => accommodation.address.toLowerCase() == 'private accommodation');
      
      this.allAccommodations = [
        ...(unknownAccommodation ? [unknownAccommodation] : []),
        ...(privateAccommodation ? [privateAccommodation] : []),
        ...this.allAccommodations
      ];
        this.allVehicles = vehicles.data
        .filter(vehicle => vehicle.status === 'Active')
        .filter(vehicle => vehicle.rego.toLowerCase() !== "unknown" && vehicle.rego.toLowerCase() !== "private accommodation")
        .sort((a, b) => a.rego.localeCompare(b.rego));
        const unknownVehicle=vehicles.data.find(vehicle=>vehicle.rego.toLowerCase() == 'unknown');
        const privateVehicle=vehicles.data.find(vehicle=>vehicle.rego.toLowerCase() == 'private transport')

        this.allVehicles=[
          ...(unknownVehicle?[unknownVehicle]:[]),
          ...(privateVehicle?[privateVehicle]:[]),
          ...this.allVehicles
        ]

        this.loading = false;
      },
      (error) => {
        this.errorMessage = 'Failed to load data. Please try again later.';
        this.loading = false;
      }
    );
  }

  sort(column: string) {
    if (this.currentSortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.currentSortColumn = column;
      this.sortDirection = true;
    }
    this.filteredData.sort((a: Logistics, b: Logistics) => {
      const aValue = a[column as keyof Logistics];
      const bValue = b[column as keyof Logistics];
      const aStrValue = (aValue !== null && aValue !== undefined) ? (typeof aValue === 'string' ? aValue.toLowerCase().trim() : aValue) : '';
      const bStrValue = (bValue !== null && bValue !== undefined) ? (typeof bValue === 'string' ? bValue.toLowerCase().trim() : bValue) : '';
      let comparison = 0;
      if (aStrValue > bStrValue) {
        comparison = 1;
      } else if (aStrValue < bStrValue) {
        comparison = -1;
      }
  
      return this.sortDirection ? comparison : -comparison;
    });
  }
  
  openViewModel = (id: any) => {
    this.candidateId = id;
    const modalRef = this.modalService.open(CandidateModalComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.candidateID = id;
    modalRef.componentInstance.isPortalAdmin = this.isPortalAdmin;
  };

  onVehicleChange(candidateId: any, event: Event) {
    const selectedVehicle = (event.target as HTMLSelectElement).value;
    const vehicleData = this.allVehicles.find((vehicle) => vehicle.rego === selectedVehicle);
      const url = `${environment.apiConfig.uri}/admin/Candidates/AssignVehicle?candidateId=${candidateId}&vehicle=${vehicleData?vehicleData.id:''}`;
      this.http.post(url, {}).subscribe(
        (response) => {
        },
        (error) => {
          console.error('Something went wrong', error);
        }
      );
  }

  onAccommodationChange(candidateId: any, event: Event) {
    const selectedAccommodation=(event.target as HTMLSelectElement).value;
    const accommodationData = this.allAccommodations.find((acc) => acc.address == selectedAccommodation);
      const url = `${environment.apiConfig.uri}/admin/Candidates/AssignAccommodation?candidateId=${candidateId}&accommodation=${accommodationData?accommodationData.id:''}`;
      this.http.post(url, {}).subscribe(
        (response) => {
        },
        (error) => {
          console.error('Something went wrong', error);
        }
      );
  }

  applyFilter() {
    this.filteredData = this.allLogistics.filter((item: Logistics) => {
      const matchesCandidateName = this.searchCandidateName
        ? item.candidateName?.toLowerCase().includes(this.searchCandidateName.toLowerCase())
        : true;

      const matchesAccommodation = this.searchAccommodation
        ? item.accommodation?.toLowerCase().includes(this.searchAccommodation.toLowerCase())
        : true;

      const matchesVehicle = this.searchVehicle
        ? item.vehicle?.toLowerCase().includes(this.searchVehicle.toLowerCase())
        : true;

      return matchesCandidateName && matchesAccommodation && matchesVehicle;
    });
  }

}

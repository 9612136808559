import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from './candidate/profile/profile.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './candidate/history/history.component';
import { JobsComponent } from './candidate/jobs/jobs.component';
import { PersonalDocumentsComponent } from './candidate/personal-documents/personal-documents.component';
import { CandidateSearchComponent } from './admin/candidate-search/candidate-search.component';
import { TrainingFilesComponent } from './admin/training-files/training-files.component';
import { TrainingAssessmentComponent } from './admin/training-assessment/training-assessment.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { FormSessionGuard } from './form-session.guard';
import { CandidateTrainingAssessmentComponent } from './candidate/candidate-training-assessment/candidate-training-assessment.component';
import { FileViewerComponent } from './admin/file-viewer/file-viewer.component';
import { TaxDeclarationComponent } from './candidate/tax-declaration/tax-declaration.component';
import { PaySlipsComponent } from './candidate/pay-slips/pay-slips.component';
import { TaxDeclarationsDetailsComponent } from './admin/tax-declarations-details/tax-declarations-details.component';
import { SuperannuationComponent } from './candidate/superannuation/superannuation.component';
import { BankAccountComponent } from './candidate/bank-account/bank-account.component';
import { DeductionsComponent } from './candidate/deductions/deductions.component';
import { PayrollHistoryComponent } from './candidate/payroll-history/payroll-history.component';
import { AddDeductionsComponent } from './admin/add-deductions/add-deductions.component';
import { PendingDeductionsComponent } from './admin/pending-deductions/pending-deductions.component';
import { SubmitDeductionsComponent } from './admin/submit-deductions/submit-deductions.component';
import { ChangeDeductionsComponent } from './admin/change-deductions/change-deductions.component';
import { CreateWorkflowComponent } from './admin/create-workflow/create-workflow.component';
import { WorkflowActionsComponent } from './admin/workflow-actions/workflow-actions.component';
import { LogisticsComponent } from './admin/logistics/logistics.component';
import { AccommodationComponent } from './admin/accommodation/accommodation.component';
import { VehiclesComponent } from './admin/vehicles/vehicles.component';
import { AdminComponent } from './admin/admin/admin.component';
import { JobMatchComponent } from './admin/job-match/job-match.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // component: ProfileComponent, canActivate: [AuthGuardService]
    // redirectTo: '/profile', pathMatch: 'full'
  },
  {
    path: 'login-redirect',
    component: LoginRedirectComponent,
  },
  {
    path: 'file-viewer',
    component: FileViewerComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
    canDeactivate: [FormSessionGuard]
  },
  {
    path: 'jobs',
    component: JobsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'tax-declaration',
    component: TaxDeclarationComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'bank-account',
    component: BankAccountComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'deductions',
    component: DeductionsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },  
  {
    path: 'superannuation',
    component: SuperannuationComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'pay-slips',
    component: PaySlipsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'personal-documents',
    component: PersonalDocumentsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'candidate-training-assessment',
    component: CandidateTrainingAssessmentComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'payroll-history',
    component:PayrollHistoryComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'user',
    },
  },
  {
    path: 'candidate-search',
    component: CandidateSearchComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path: 'job-match',
    component: JobMatchComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path: 'training-files',
    component: TrainingFilesComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
    canDeactivate: [FormSessionGuard]
  },
  {
    path: 'training-assessment',
    component: TrainingAssessmentComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path: 'tax-declarations-details',
    component: TaxDeclarationsDetailsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path: 'add-deductions',
    component: AddDeductionsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path: 'pending-deductions',
    component: PendingDeductionsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path: 'submit-deductions',
    component: SubmitDeductionsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path: 'change-deductions',
    component: ChangeDeductionsComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: 'admin',
    },
  },
  {
    path:'create-workflow',
    component:CreateWorkflowComponent,
    canActivate:[MsalGuard],
    data:{
      expectedRole:'admin',
    }
  },
  {
    path:'workflow-actions',
    component:WorkflowActionsComponent,
    canActivate:[MsalGuard],
    data:{
      expectedRole:'admin',
    }
  },
  {
    path:'vehicles',
    component:VehiclesComponent,
    canActivate:[MsalGuard],
    data:{
      expectedRole:'admin',
    }
  },
  {
    path:'accommodation',
    component:AccommodationComponent,
    canActivate:[MsalGuard],
    data:{
      expectedRole:'admin',
    }
  },
  {
    path:'logistics',
    component:LogisticsComponent,
    canActivate:[MsalGuard],
    data:{
      expectedRole:'admin',
    }
  },
  {
    path:'admin',
    component:AdminComponent,
    canActivate:[MsalGuard],
    data:{
      expectedRole:'admin',
    }
  },
  {
    // Needed for hash routing
    path: 'error',
    component: HomeComponent,
  },
  {
    // Needed for hash routing
    path: 'state',
    component: HomeComponent,
  },
  {
    // Needed for hash routing
    path: 'code',
    component: HomeComponent,
  },
  {
    path: 'login-failed',
    redirectTo: '',
    // component: FailedComponent
  },
  // { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabled'
          : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

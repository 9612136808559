import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tax-super',
  templateUrl: './tax-super.component.html',
  styleUrls: ['./tax-super.component.css'],
})
export class TaxSuperComponent implements OnInit {
  @Input() candidateID!: number;
  @Input() isPortalAdmin:boolean=false;
  @Output() parentValueEmitter = new EventEmitter<boolean>();

  viewTaxData: any[] = [];
  viewSuperData: any[] = [];
  taxProfile1: any;
  taxProfile2: any;
  taxProfile4: any;
  showTax1: boolean = false;
  showTax2: boolean = false;
  showTax4: boolean = false;
  queryData: any;
  profile1Status: number = 0;
  profile2Status: number = 0;
  profile4Status: number = 0;
  newTaxFileTable: boolean = false;
  showNewTaxFile: boolean = false;
  showNewSuperFile: boolean = false;
  taxfileId: any;
  employee_remote_id: string = '';
  mid!: number;
  p1mid!: number;
  p2mid!: number;
  p4mid!: number;
  p1remoteid!: number;
  p2remoteid!: number;
  p4remoteid!: number;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    if(this.isPortalAdmin){
      this.getQuery();
      this.viewTaxDeclaration();
      this.Super();
    }
  }

  getQuery() {
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetUserQuery/${this.candidateID}`;
    this.http.get(url).subscribe((res: any) => {
      this.queryData = res.data;
      for (let item of this.queryData) {
        if (item.remoteid.includes('PAYG-1')) {
          this.showTax1 = true;
          this.profile1Status = item.tfn;
          this.p1mid = item.mid;
          this.p1remoteid = item.remoteid;
        } else if (item.remoteid.includes('PAYG-2')) {
          this.showTax2 = true;
          this.profile2Status = item.tfn;
          this.p2mid = item.mid;
          this.p2remoteid = item.remoteid;
        }else if (item.remoteid.includes('PAYG-4')) {
          this.showTax4 = true;
          this.profile4Status = item.tfn;
          this.p4mid = item.mid;
          this.p4remoteid = item.remoteid;
        }
      }
    });
  }

  receiveParentValue1(value: any) {
    this.showNewTaxFile = value;
    this.viewTaxDeclaration();
    this.parentValueEmitter.emit(this.showNewTaxFile);
  }

  receiveValuefromSuper(value: any) {
    this.Super();
    this.showNewSuperFile = value;
    this.parentValueEmitter.emit(this.showNewSuperFile);
  }

  Super() {
    var url = environment.apiConfig.uri + `/admin/Candidates/GetCandidateSuperAnnuation/${this.candidateID}`;
    this.http.get(url).subscribe(async (response: any) => {
      try {
        this.viewSuperData = response.data;
      } catch (err) {}
    });
  }

  addNewTaxFile(id: any, mid: any, rid: any) {
    this.taxfileId = id;
    this.showNewTaxFile = true;
    this.employee_remote_id = rid;
    this.mid = mid;
  }
  addNewSuperannuation(rid: any, mid: any) {
    this.showNewSuperFile = true;
    this.employee_remote_id = rid;
    this.mid = mid;
  }

  viewTaxDeclaration() {
    var url =environment.apiConfig.uri+`/admin/taxDecleration/GetAll/${this.candidateID}`;
    this.http.get(url).subscribe(async (response: any) => {
      try {
        this.viewTaxData = response.data;
        for (let item of this.viewTaxData) {
          if (item.employee_remote_id.includes('PAYG-1')) {
            this.taxProfile1 = item;
            this.showTax1 = true;
          } else if (item.employee_remote_id.includes('PAYG-2')) {
            this.taxProfile2 = item;
            this.showTax2 = true;
          }
          else if (item.employee_remote_id.includes('PAYG-4')) {
            this.taxProfile4 = item;
            this.showTax4 = true;
          }
        }
        // console.log(this.viewTaxData)
      } catch (err) {}
    });
  }
}

<div class="blur-content">
  <div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
      <app-header></app-header>
      <div class="main-content-inner">
        <!-- market value area start -->
        <div class="row mt-5">
          <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
                <div *ngIf="loadingDeductions===false"  class="table-responsive">
                    <div class="card">
                        <div class="card-body" style="overflow-x: auto">
                            <h5>Change Requests - Deductions Each Pay</h5><hr>
                            <table
                                class="table table-sm table-bordered tablecenter table-hover align-middle mb-0 bg-white">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Candidate</th>
                                        <th>Deduction</th>
                                        <th>Employeer</th>
                                        <th>Created Date</th>
                                        <th>End Date</th>
                                        <th>Reason</th>
                                        <th>Note</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of unlimited">
                                    <td>{{item.uid}}</td>
                                    <td>{{item.candidateName}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.employeer}}</td>
                                    <td>{{item.createdAt?(item.createdAt| date: 'dd-MM-yyyy'):'N/A'}}</td>
                                    <td>{{item.date_to| date: 'dd-MM-yyyy'}}</td>
                                    <td>{{item.reason}}</td>
                                    <td>{{item.note}}</td>
                                    <td class=" text-center">
                                      <button class="btn btn-sm btn-danger modalButton" data-toggle="modal" (click)="completeDeduction(item)" data-target="#deductionModal">Complete</button>
                                    </td>
                                  </tr>
                                  <ng-container *ngIf="unlimited.length<1">
                                    <tr>
                                        <td colspan="8" class="text-center">No Data</td>
                                    </tr>
                                </ng-container> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div class="card">
                        <div class="card-body" style="overflow-x: auto">
                            <h5>Change Requests - Fixed Deductions</h5><hr>
                            <table class="table table-sm rounded table-bordered tablecenter table-hover align-middle mb-0 bg-white">
                                <thead>
                                    <tr>
                                      <th>ID</th>
                                      <th>Candidate</th>
                                      <th>Deduction</th>
                                      <th>Employeer</th>
                                      <th>Created Date</th>
                                      <th>Amount</th>
                                      <th>Note</th>
                                      <th></th>
                                    </tr>
                                </thead>
                                <tbody >
                                  <tr *ngFor="let item of fixed_amount">
                                    <td>{{item.uid}}</td>
                                    <td>{{item.candidateName}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.employeer}}</td>
                                    <td>{{item.createdAt?(item.createdAt| date: 'dd-MM-yyyy'):'N/A'}}</td>
                                    <td>{{item.amount_taken}}</td>
                                    <td>{{item.note}}</td>
                                    <td class=" text-center">                                      
                                      <button class="btn btn-sm btn-danger modalButton" data-toggle="modal" data-target="#deductionModal" (click)="completeDeduction(item)" >Complete</button>
                                  </tr>   
                                  <ng-container *ngIf="fixed_amount.length<1">
                                    <tr>
                                        <td colspan="7" class="text-center">No Data</td>
                                    </tr>
                                </ng-container>                               
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="loadingDeductions===true">
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                </div>
          </div>        
          <!-- market value area end -->
        </div>
        <!-- main content area end -->
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="deductionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex position-relative">
        <h6 class="modal-title w-100 text-center mr-1" id="exampleModalLabel">Change Request</h6>
        <button type="button" class="close position-absolute px-1" style="right: 20px;" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex justify-content-center align-items-center">
        <div *ngIf="!loading && !message" class="text-center px-5 letter-spacing">
          <p>Are you sure you want to complete this change request? </p>
          <div class="d-flex justify-content-center align-items-center">
            <label for="note">Notes</label>
            <textarea type="text" id="note" class="form-control mx-2" [(ngModel)]="note" name="note"  rows="1" required></textarea>
          </div>
        </div>
        <div *ngIf="loading" class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>        
        <div *ngIf="!loading && message" class="text-center">
          {{message}}
        </div>
      </div>
      <div class="modal-footer d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-success modalButton" [title]="!note?'Please Add Note':'Submit'" [disabled]="!note" (click)="confirmSubmit(note)" *ngIf="!message">Yes</button>
        <button type="button" class="btn btn-danger modalButton" data-dismiss="modal">{{message?'Ok':'Cancel'}}</button>
      </div>
    </div>
  </div>
</div>
  
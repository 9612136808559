import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
type superannuation = {
  supeR_ABN?: string;
  super_findfund?: string;
  super_fund_type?: string;
  super_account_name?: string;
  super_member_number?: string;
  super_address1?: string;
  super_address2?: string;
  super_address3?: string;
  super_esa?: string;
  super_member_reference?: string;
  super_name?: string;
  super_payment_account?: string;
  super_payment_bsb?: string;
  super_payment_reference?: string;
  super_phone?: string;
  super_postcode?: string;
  super_product?: string;
  super_product_name?: string;
  super_provider?: string;
  super_smsf?: string;
  super_spin?: string;
  super_state?: string;
  super_usi?: string;
  super_upload_nonsmsf?: string;
  super_fund?: string;
  uid?: string;
  remoteid?: string;
};
type CandidateFile = {
  externalID?: string;
  name?: string;
  fileContent?: string;
  type?: string;
};
interface ViewDataPdf {
  super_name?: string;
  supeR_ABN?: string;
  super_product_name?: string;
  super_usi?: string;
  super_account_name?: string;
  super_member_reference?: string;
}
@Component({
  selector: 'app-candidate-superannuation',
  templateUrl: './candidate-superannuation.component.html',
  styleUrls: ['./candidate-superannuation.component.css'],
})
export class CandidateSuperannuationComponent implements OnInit {
  @Input() candidateID!: number ; 
  @Input() remoteid: string = '';
  @Input() mid: number=0;
  @Output() superValueEmitter = new EventEmitter<boolean>();

  id: string = '';
  newSuperannuationForm: boolean = false;
  questionValue: string = '';
  selectedValue: string = '';
  fundtype: string = '';
  super_findfund: string = '';
  supeR_ABN: string = '';
  super_fund_type: string = '';
  super_fund: string = '';
  super_member_number: string = '';
  super_name: string = '';
  super_address1: string = '';
  super_state: string = '';
  super_postcode: string = '';
  super_phone: string = '';
  super_usi: string = '';
  super_esa: string = '';
  super_payment_bsb: string = '';
  super_account_name: string = '';
  super_member_reference: string = '';
  osupeR_ABN: string = '';
  osuper_name: string = '';
  osuper_product_name: string = '';
  osuper_usi: string = '';
  osuper_account_name: string = '';
  osuper_member_reference: string = '';
  taxSuperData: any = {};
  messaageForUser: string = '';
  modalLoader: boolean = true;
  loadingTaxFiles: boolean = true;
  superData: any;
  canResetForm: boolean = false;
  selected: string = 'superannuation';
  super_product_name: string = '';
  uid: any;
  super_payment_account: string = '';
  super_product: string = '';
  actualsupeR_ABN: string = '';
  super_payment_reference: string = '';
  noDataMessage: boolean = false;
  showAlert: boolean = false;
  dataFound: boolean = false;
  fundDetailsData: any;
  viewSuperData = {};
  viewFormData: any;
  viewSuperannuationForm: boolean = false;
  superannuationStatus: any;
  candidateName: any;
  showNewSuperFile: boolean = true;
  name: any;
  email: any;
  submissionDate: any;
  viewDataPdf:ViewDataPdf={}
  mobile:any;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getUserQuery();
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    this.submissionDate = new Date().toLocaleString();
    this.mobile = localStorage.getItem('mobile');
  }

  getUserQuery() {
    var url = `${environment.apiConfig.uri}/admin/Candidates/GetCandidateSuperAnnuation/${this.candidateID}`;
    this.http.get(url).subscribe(async (response: any) => {
    this.superData = response.data;
    const filteredArray = this.superData.filter((item: { remoteid: string; }) => item.remoteid === this.remoteid);
    // Pass the filtered array to the newSuperannuation function
    this.newSuperannuation(filteredArray);
    });
  }

  newSuperannuation(data: any) {
    if (data && data.length > 0) {
      this.actualsupeR_ABN = data[0].supeR_ABN;
      this.selectedValue = '';
      this.uid = data[0].mid;
      this.remoteid = data[0].remoteid;
      this.supeR_ABN = data[0].supeR_ABN;
      this.super_name = data[0].super_name;
      this.super_product_name = data[0].super_product_name;
      this.super_usi = data[0].super_usi;
      this.super_account_name = data[0].super_account_name;
      this.super_member_reference = data[0].super_member_reference;
      this.osupeR_ABN = data[0].supeR_ABN;
      this.osuper_name = data[0].super_name;
      this.osuper_product_name = data[0].super_product_name;
      this.osuper_usi = data[0].super_usi;
      this.osuper_account_name = data[0].super_account_name;
      this.osuper_member_reference = data[0].super_member_reference;
      this.super_payment_account = data[0].super_payment_account;
      this.super_payment_bsb = data[0].super_payment_bsb;
      this.super_payment_reference = data[0].super_payment_reference;
      this.super_phone = data[0].super_phone;
    } else {
      // Handle the case when data is empty
      console.error('Data is empty or not in the expected format.');
    }
  }

  closeModal(){
    this.showNewSuperFile = false;
    this.superValueEmitter.emit(this.showNewSuperFile);
  }

  questionStatus(e: any) {
    this.questionValue = e.target.value;
  }

  resetSuperForm() {
    this.questionValue = '';
    this.selectedValue = '';
    this.super_findfund = '';
    this.super_fund = '';
    this.super_account_name = '';
    this.super_member_number = '';
    this.super_fund_type = '';
    this.fundtype = '';
    this.supeR_ABN = '';
    this.super_member_reference = '';
    this.super_name = '';
    this.super_payment_account = '';
    this.super_payment_bsb = '';
    this.super_payment_reference = '';
    this.super_phone = '';
    this.super_product_name = '';
    this.super_usi = '';
    this.super_state = '';
    this.showNewSuperFile = false;
    this.superValueEmitter.emit(this.showNewSuperFile);
    this.getUserQuery();
  }

  async fundDetails() {
    this.dataFound = false;
    if (this.selectedValue === 'default') {
      this.super_usi = '';
      this.super_account_name = this.candidateName;
    }
    var url = `${environment.apiConfig.uri}/candidate/Superannuation/GetFundDetail?ABN=${this.super_usi}`;
    this.http.get(url).subscribe(async (response: any) => {
      if (response.data.length < 1) {
        this.selectedValue = 'nodata';
      } else {
        this.fundDetailsData = response.data;
        this.supeR_ABN = this.fundDetailsData[0].abn;
        this.super_name = this.fundDetailsData[0].fundName;
        this.super_product_name = this.fundDetailsData[0].productName;
        this.super_usi = this.fundDetailsData[0].usi;
        this.dataFound = true;
      }
    });
  }

  openNextQuestion() {
    this.showAlert = false;
    this.selectedValue = this.questionValue;

    if (this.questionValue == 'supeR_ABN') {
      this.super_member_number = '';
      this.super_account_name = '';
      this.super_member_reference = '';
    } else if (
      this.questionValue == 'new_apra_rsa' ||
      this.questionValue == 'no_new_apra_rsa' ||
      this.questionValue == 'new_smsf' ||
      this.questionValue == 'no_new_smsf'
    ) {
      this.supeR_ABN = '';
      this.super_name = '';
      this.super_address1 = '';
      this.super_postcode = '';
      this.super_phone = '';
      this.super_usi = '';
      this.super_account_name = '';
      this.super_state = '';
      this.super_esa = '';
      this.super_payment_bsb == '';
      this.super_payment_account = '';
    } else if (
      this.questionValue == 'search' ||
      this.questionValue == 'default'
    ) {
      this.super_member_reference = '';
      this.super_account_name = '';
      this.dataFound = false;
      this.fundDetails();
    } else if (this.questionValue == 'use_existing') {
      this.supeR_ABN = this.osupeR_ABN;
      this.super_name = this.osuper_name;
      this.super_product_name = this.osuper_product_name;
      this.super_usi = this.osuper_usi;
      this.super_account_name = this.osuper_account_name;
      this.super_member_reference = this.osuper_member_reference;
    }
  }

  openPreviousQuestion() {
    this.showAlert = false;
    this.supeR_ABN = this.actualsupeR_ABN;
    if (this.selectedValue == 'search' || this.selectedValue == 'on') {
      this.selectedValue = '';
      this.super_fund = '';
      this.questionValue = 'search';
    } else if (this.selectedValue == 'supeR_ABN') {
      this.dataFound = true;
      this.fundDetailsData = this.fundDetailsData;
      this.super_account_name = this.osuper_account_name;
      this.super_member_reference = this.osuper_member_reference;
      this.selectedValue = 'search';
      this.questionValue = 'supeR_ABN';
    } else if (this.selectedValue == 'new') {
      this.selectedValue = 'search';
      this.questionValue = 'new';
      this.fundtype = '';
    } else if (this.selectedValue == 'new_apra_rsa') {
      this.selectedValue = 'new';
      this.questionValue = 'new_apra_rsa';
    } else if (this.selectedValue == 'new_smsf') {
      this.selectedValue = 'new';
      this.questionValue = 'new_smsf';
    } else if (this.selectedValue == 'nodata') {
      this.super_fund_type = '';
      this.selectedValue = '';
      this.questionValue = 'search';
    } else if (this.selectedValue == 'no_new_apra_rsa') {
      this.selectedValue = 'nodata';
      this.questionValue = 'no_new_apra_rsa';
    } else if (this.selectedValue == 'no_new_smsf') {
      this.selectedValue = 'nodata';
      this.questionValue = 'no_new_smsf';
    } else if (this.selectedValue == 'default') {
      this.selectedValue = '';
      this.dataFound = true;
      this.supeR_ABN = this.osupeR_ABN;
      this.super_name = this.osuper_name;
      this.super_product_name = this.osuper_product_name;
      this.super_usi = this.osuper_usi;
      this.super_account_name = this.osuper_account_name;
      this.super_member_reference = this.osuper_member_reference;
      this.questionValue = 'default';
    } else if (this.selectedValue == 'use_existing') {
      this.selectedValue = '';
      this.super_account_name = this.osuper_account_name;
      this.super_member_reference = this.osuper_member_reference;
      this.questionValue = 'use_existing';
    }

  }

  apraValues(data: any) {
    this.questionValue = 'supeR_ABN';
    this.supeR_ABN = data.abn;
    this.super_name = data.fundName;
    this.super_product_name = data.productName;
    this.super_usi = data.usi;
  }

  submitSuperannuationForm(data: any) {
    this.messaageForUser = '';
    const superObj = <superannuation>{};
    superObj.super_account_name = data.super_account_name?data.super_account_name.replace(/&/g, 'and'): (this.super_account_name?this.super_account_name.replace(/&/g, 'and'):'');
    superObj.super_usi = data.super_usi ? data.super_usi.replace(/&/g, 'and') : (this.super_usi?this.super_usi.replace(/&/g, 'and'):'');
    superObj.super_esa = data.super_esa ? data.super_esa.replace(/&/g, 'and') : (this.super_esa?this.super_esa.replace(/&/g, 'and'):'');
    superObj.super_product_name = data.super_product_name ? data.super_product_name.replace(/&/g, 'and') : (this.super_product_name?this.super_product_name.replace(/&/g, 'and'):'');
    superObj.super_member_reference = data.super_member_reference? data.super_member_reference.replace(/&/g, 'and'): (this.super_member_reference?this.super_member_reference.replace(/&/g, 'and'):'');
    superObj.super_name = data.super_name ? data.super_name.replace(/&/g, 'and') : (this.super_name?this.super_name.replace(/&/g, 'and'):'');
    superObj.super_fund = data.super_fund ? data.super_fund.replace(/&/g, 'and') : (this.super_fund?this.super_fund.replace(/&/g, 'and'):'');
    if (superObj.super_esa) {superObj.super_usi = ''};    
    superObj.super_payment_bsb = data.super_payment_bsb? data.super_payment_bsb: this.super_payment_bsb;
    superObj.super_payment_account = data.super_payment_account? data.super_payment_bsb: this.super_payment_bsb;
    superObj.supeR_ABN = data.supeR_ABN? data.supeR_ABN.toString(): this.supeR_ABN.toString();
    superObj.super_product = this.super_product;
    superObj.remoteid = this.remoteid;
    superObj.uid = this.uid.toString();
    superObj.super_payment_reference = data.super_payment_reference? data.super_payment_reference: this.super_payment_reference;
    superObj.super_address1 = data.super_address1? data.super_address1: this.super_address1;
    superObj.super_address2 = data.address2;
    superObj.super_address3 = data.address3;
    superObj.super_state = data.super_state? data.super_state: this.super_state;
    superObj.super_postcode = data.super_postcode? data.super_postcode: this.super_postcode;
    superObj.super_phone = data.super_phone? data.super_phone: this.super_phone;
    superObj.super_findfund = data.super_findfund? data.super_findfund: this.super_findfund;
    superObj.super_fund_type = data.super_fund_type? data.super_fund_type: this.super_fund_type;
    superObj.super_member_number = data.super_member_number? data.super_member_number: this.super_member_number;
    superObj.super_upload_nonsmsf = data.upload_nonsmsf;
    superObj.super_provider = '';
    superObj.super_smsf = '';
    superObj.super_spin = '';
    
    this.viewDataPdf = superObj;
    var url = `${environment.apiConfig.uri}/admin/Candidates/AddSuperAnnuation/${this.candidateID}`;
    this.http.post(url, superObj).subscribe(
      (response: any) => {
        if (response.isSuccess) {          
      this.createAndSubmitPDF();
          this.canResetForm = true;
          this.messaageForUser = 'Supperannuation Form Added successfully.';
        } else {
          this.canResetForm = false;
          this.messaageForUser =
            'Supperannuation Form cannot be added successfully.';
        }
      },
      (err) => {
        this.messaageForUser =err.error.message;
          // 'Supperannuation Form cannot be added successfully,  Enter Valid fields.';
        this.canResetForm = false;
      }
    );
  }
  async createAndSubmitPDF() {
    const extraContent = document.getElementById('extraInfo');
    if (extraContent) {
      extraContent.classList.remove('hidden-content');  
      const pdfOptions = {
        margin: 10,
        filename: `Superannuation Nomination – ${this.name} - ${this.submissionDate}.pdf`,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
  
      try {
        // Generate PDF using html2pdf
        const pdf = await html2pdf().from(extraContent).set(pdfOptions).outputPdf();
        // extraContent.classList.add('hidden-content');
        const pdfBase64 = this.encodeToBase64(pdf);  
        const FileObj: CandidateFile = {
          externalID: 'portfolio',
          fileContent: pdfBase64,
          type: 'Others',
          name: `Superannuation Nomination – ${this.name} - ${this.submissionDate}.pdf`
        };
        const url = `${environment.apiConfig.uri}/admin/Candidates/AddPdf/${this.candidateID}`;
        // Submit PDF to the server
        const response = await this.http.put<any>(url, FileObj).toPromise();
        // Handle the response as needed
      } catch (error) {
        console.error('Error generating or submitting PDF:');
      } finally {
        // extraContent.classList.add('hidden-content');
      }
    } else {
      console.error('Content is empty or element not found.');
    }
  }

  private encodeToBase64(data: string): string {
    return btoa(data);
  }
}

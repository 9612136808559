import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.css']
})

export class ReportProblemComponent implements OnInit {
  name: string = "";
  email: string = "";
  check: boolean = false;
  userabservable$: any;
  @ViewChild('closeModelAdd') closeModelAdd!: ElementRef;

  constructor(private http: HttpClient) { };

  ngOnInit(): void {
    setTimeout(() => {
      this.name = localStorage.getItem('name') || '';
      this.email = localStorage.getItem("email") || '';
    }, 2000);
  }

  reportProblem(data: any, form: any) {
    let Payload = { ...data };
    this.http.post(`${environment.apiConfig.uri}/ReportIssue`, Payload).subscribe((result) => {
      this.check = true;
      alert("submit");
      form.resetForm();
      this.closeModelAdd.nativeElement.click();
    },
      (error) => {
        // alert(error)`
      })
  }
  
}

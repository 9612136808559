<div class="row mt-3">
    <div class="col-12 m-3">
        <div class="card">
            <div class="card-body">
                <form #taxDecelaration="ngForm" (ngSubmit)="submitElectronicDeclaration(taxDecelaration.value); ">
                    <div id="taxForm">
                        <p class="text-center"><strong>* Only English letters and numbers can be
                                used.</strong></p>
                        <!-- Q : 1  -->
                        <div class="row">
                            <strong class="col">Tax File Number Declaration</strong>
                            <a target="_blank" name="how_to_complete" id="how_to_complete" class="col text-right"
                                href="https://astutepayroll.zendesk.com/hc/en-au/articles/4412088187033-Complete-a-Tax-File-Number-Declaration">How
                                To Complete This Form</a>
                        </div>
                        <!--Q : 2-->
                        <div class="form-group">
                            <div class="  my-1 mr-sm-2">
                                <input type="checkbox" name="no_tfn" id="no_tfn-2" (change)="showExpectionReason($event)"
                                    [value]=2 [(ngModel)]="editTaxData.no_tfn" ngModel>
                                <label for="no_tfn-2" class="ml-2">I don't Have my
                                    TFN</label>
                            </div><br>
                            <div class="input-group " *ngIf="!editTaxData.no_tfn">
                                <div class="row">
                                    <label class="col">Tax File Number:
                                        <input class=" form-control" [disabled]="editTaxData.no_tfn || exemption"
                                            #tfnProvided="ngModel" type="text" placeholder="Tax File Number"
                                            [(ngModel)]="editTaxData.tfnProvided"
                                            [class.is-invalid]="tfnProvided.invalid && tfnProvided.touched"
                                            pattern="[0-9]{1,9}" name="tfnProvided" maxlength="9" aria-label="Default"
                                            required> </label>
                                </div>
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col" *ngIf="tfnProvided.invalid &&tfnProvided.touched">
                                        <div class="alert alert-danger form-control "
                                            *ngIf="tfnProvided.errors && tfnProvided.errors['pattern']">
                                            Only numbers can be used.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Q : 3 -->
                        <div *ngIf="exemption==true || editTaxData.no_tfn">
                            <div class="dropdown-divider"></div>
                            <strong for="exemptionReason">Exemption Reason
                            </strong>
                            <!-- Options  -->
                            <div id="taxExemptionReasonDiv">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exemptionReason"
                                        [(ngModel)]="editTaxData.exemptionReason" id="exemptionReason-4" value=4 ngModel
                                        [checked]="editTaxData.exemptionReason">
                                    <label class="form-check-label" for="exemptionReason-4">
                                        I am claiming an exemption because I am in receipt of a pension,
                                        benefit, or
                                        allowance
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exemptionReason"
                                        [(ngModel)]="editTaxData.exemptionReason" id="exemptionReason-3"
                                        [checked]="editTaxData.exemptionReason" value=3 ngModel>
                                    <label class="form-check-label" for="exemptionReason-3">
                                        I am claiming an exemption because I am under 18 years old and
                                        do not earn enough to pay tax
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="exemptionReason-1"
                                        [(ngModel)]="editTaxData.exemptionReason" name="exemptionReason"
                                        [checked]="editTaxData.exemptionReason" value=1 ngModel>
                                    <label class="form-check-label" for="exemptionReason-1">
                                        I have made a separate application to the ATO for a new or
                                        existing tax file number </label>
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <!-- Q : 4 -->
                        <div>
                            <strong>Have you changed your surname since you last dealt with the
                                Australian Tax Office?</strong>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="isPreviousSurnameChanged"
                                    [(ngModel)]="editTaxData.isPreviousSurnameChanged" id="isPreviousSurnameChanged-1"
                                    [value]=1>
                                <label class="form-check-label" for="isPreviousSurnameChanged-1">Yes</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="isPreviousSurnameChanged"
                                    [(ngModel)]="editTaxData.isPreviousSurnameChanged" id="isPreviousSurnameChanged-0"
                                    [value]=0>
                                <label class="form-check-label" for="isPreviousSurnameChanged-0">No</label>
                            </div>
                            <div *ngIf="editTaxData.isPreviousSurnameChanged == '1'" class="form-check form-group"
                                id="previousSurnameDiv">
                                <strong for="previousSurname" class="optional">Previous
                                    surname:</strong>
                                <input type="text" placeholder="Previous surname" name="previousSurname"
                                    #previousSurname="ngModel" id="previousSurname" maxlength="32"
                                    [class.is-invalid]="previousSurname.invalid && previousSurname.touched"
                                    [(ngModel)]="editTaxData.previousSurname" class="form-control"
                                    pattern="[a-zA-Z][a-zA-Z\s]*" aria-label="Default" required>
                                <!--  -->
                                <div *ngIf="previousSurname.touched && previousSurname.invalid">
                                    <div class="alert alert-danger form-control"
                                        *ngIf="previousSurname.errors &&(previousSurname.errors['pattern'])">
                                        Only English letters can be used.
                                    </div>
                                </div>
                                <!--  -->
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <!-- Q : 5 -->
                        <div id="tax_residency_status">
                            <strong>Are you an Australian resident for tax purposes or a working
                                holidays maker?
                            </strong>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="residencyStatus"
                                    [(ngModel)]="editTaxData.residencyStatus" id="residencyStatus-A" value="A" ngModel
                                    (change)="checkForeignOrWork($event)">
    
                                <label class="form-check-label" for="residencyStatus-A">
                                    Australian resident for tax purpose
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="residencyStatus"
                                    [(ngModel)]="editTaxData.residencyStatus" id="residencyStatus-F" value="F"
                                    (change)="checkForeignOrWork($event)" ngModel>
                                <label class="form-check-label" for="residencyStatus-F">
                                    Foreign resident
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="residencyStatus"
                                    [(ngModel)]="editTaxData.residencyStatus" value="H"
                                    (change)="checkForeignOrWork($event)" id="residencyStatus-H" ngModel>
                                <label class="form-check-label" for="residencyStatus-H">
                                    Working Holidays maker
                                </label>
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <!-- || editTaxData.residencyStatus=='A' -->
                        <div *ngIf="residencyStatus=='A'  " id="tax_free_threshold">
                            <strong>Do You want to claim the tax-free threshold from this
                                player?</strong>
                            <div class="form-check">
                                <input class="form-check-input" [value]='1' name="taxFreeThresholdClaimed"
                                    [(ngModel)]="editTaxData.taxFreeThresholdClaimed" id="taxFreeThresholdClaimed-1"
                                    type="radio">
                                <label class="form-check-label" for="taxFreeThresholdClaimed-1">Yes</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" [value]='0' name="taxFreeThresholdClaimed"
                                    [(ngModel)]="editTaxData.taxFreeThresholdClaimed" id="taxFreeThresholdClaimed-0"
                                    type="radio">
                                <label class="form-check-label" for="taxFreeThresholdClaimed-0">No</label>
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <!-- Q : 7 -->
                        <div *ngIf=" residencyStatus=='A' || residencyStatus=='F'   ">
                            <strong>Do you have a Higher Education Loan Program (HELP), VET Student Loan
                                (VSL),
                                Financial Supplement (FS), Student Start-up Loan (SSL) or Trade Support
                                Loan
                                (TSL)
                                debt?
                            </strong>
                            <div class="form-check" id="tax_has_debt_support">
                                <input class="form-check-input" type="radio" name="hasHelpDebtSupport"
                                    [(ngModel)]="editTaxData.hasHelpDebtSupport" id="hasHelpDebtSupport-1" [value]=1
                                    ngModel>
                                <label class="form-check-label" for="hasHelpDebtSupport-1">
                                    Yes
                                </label> <br>
                                <input class="form-check-input" type="radio" name="hasHelpDebtSupport"
                                    [(ngModel)]="editTaxData.hasHelpDebtSupport" id="hasHelpDebtSupport-0" [value]=0
                                    ngModel>
                                <label class="form-check-label" for="hasHelpDebtSupport-0">
                                    No
                                </label>
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <!-- Q : 8 -->
                        <div id="tax_employment_basis">
                            <strong>On what basis are you paid?
                            </strong>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="employmentBasis"
                                    [(ngModel)]="editTaxData.employmentBasis" id="employmentBasis-full-time"
                                    value="full-time" ngModel>
                                <label class="form-check-label" for="employmentBasis-full-time">
                                    Full-time
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="employmentBasis"
                                    [(ngModel)]="editTaxData.employmentBasis" id="employmentBasis-part-time"
                                    value="part-time" ngModel>
                                <label class="form-check-label" for="employmentBasis-part-time">
                                    Part-time
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="employmentBasis"
                                    [(ngModel)]="editTaxData.employmentBasis" id="employmentBasis-casual" value="casual"
                                    ngModel>
                                <label class="form-check-label" for="employmentBasis-casual">
                                    Casual
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="employmentBasis-labour-hire"
                                    name="employmentBasis" [(ngModel)]="editTaxData.employmentBasis" value="labour-hire"
                                    ngModel>
                                <label class="form-check-label" for="employmentBasis-labour-hire">
                                    Labour Hire
                                </label>
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <!-- q : 9 -->
                        <div id="tax_declaration_name">
                            <strong>I declare that the information transmitted in this TFN Declaration
                                is true
                                and
                                correct and that I am authorised to make this declaration
                            </strong><br>
                            <label for="declarationName" class="required">Full name of the person making
                                the
                                declaration</label>
                            <br>
                            <div>
                                <input type="text" class="form-control" name="declarationName" #declarationName="ngModel"
                                    [(ngModel)]="editTaxData.declarationName" id="declarationName"
                                    placeholder="Full name of the person making the declaration"
                                    pattern="[a-zA-Z][a-zA-Z\s]*" maxlength="50" aria-label="Username"
                                    aria-describedby="addon-wrapping" ngModel required>
                            </div>
                            <!--  -->
                            <div *ngIf="declarationName.touched && declarationName.invalid">
                                <div class="alert alert-danger form-control"
                                    *ngIf="declarationName.errors &&(declarationName.errors['pattern'])">
                                    Only English letters can be used.
                                </div>
                            </div>
                            <!--  -->
                            <div class="dropdown-divider"></div>
                        </div>
                        <!-- Q : 11 -->
                        <div *ngIf="residencyStatus=='H' || editTaxData.residencyStatus=='H'"
                            id="tax_passport_issuance_country">
                            <div id="passportIssuanceCountry-label">
                                <strong>Working holiday makers must provide a country of passport
                                </strong><br>
                                <strong for="passportIssuanceCountry" class="optional">What is the place
                                    of
                                    issue
                                    in your passport?</strong>
                            </div>
                            <br>
                            <select #pCountry (change)="passportCoutrySelect(pCountry.value)" name="passportIssuanceCountry"
                                id="passportIssuanceCountry" [(ngModel)]="editTaxData.passportIssuanceCountry"
                                class=" form-control">
                                <option value="Select" selected="selected">Select</option>
                                <option *ngFor="let item of passportIssuanceCountryObj | keyvalue" [value]="item.value">
                                    {{item.key}}</option>
                            </select>
                            <div class="dropdown-divider"></div>
                        </div>
                        <div>
                            <div class="form-group form-check">
                                <input required type="checkbox" name="declarationSigned" id="declarationSigned"
                                    class="form-check-input" value=1 [(ngModel)]="editTaxData.declarationSigned" ngModel>
                                <strong class="form-check-label required" for="declarationSigned">Tick
                                    this box to sign with the identification details you used to log on
                                    and your name supplied above</strong>
                            </div>
                            <span></span>
                            <div class="dropdown-divider"></div>
                        </div>
                           <!-- Adding name, email and contact number of candidate for pdf submit   -->
                        <div id="extraData" class="hidden-content mt-2">
                            <div><b>Submitted By:&nbsp;&nbsp;</b>{{name}}</div>
                            <div><b>Login Credentials:&nbsp;&nbsp;</b> {{mobile?mobile:email}}</div>
                            <div><b>Submission Date:&nbsp;&nbsp;</b>{{submissionDate}}</div>
                        </div>
                    </div>
                    <!-- Q : 12 -->
                    <div class="float-right">
                        <button (click)=" closeModal()" class=" btn btn-sm btn-danger m-1 px-3 " type="button">
                            Cancel
                        </button>
                        <button class="btn ml-1 btn-sm btn-info m-1 px-3 onboarding_button " value="submit"
                            title="Submit File" type="submit" data-target="#submitTFN" data-toggle="modal">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade mt-5" id="submitTFN" tabindex="-1" role="dialog" aria-labelledby="submitTFNLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm " role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div *ngIf="messaageForUser">
                    <p style="text-align: center;">{{messaageForUser}}</p>
                    <div class="row">
                        <div class="col-5"></div>
                        <button type="button" class="btn btn-sm btn-info px-3 mr-3"
                        (click)="canResetForm ? closeModal() : null"
                        data-dismiss="modal">Ok</button>
                        <div class=" col-2">
                        </div>
                        <div class="col-5"></div>
                    </div>
                </div>
                <div *ngIf="!messaageForUser">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="blur-content">
  <div class="page-container" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
      <app-header></app-header>
      <div class="main-content-inner">
        <!-- market value area start -->
        <div class="row mt-5">
          <div
            class="col-12"
            style="padding-right: 0 !important; margin-right: 0 !important"
          >
            <div class="card">
              <div class="card-body">
                <!-- Candidate Files -->
                <div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center justify-content-between">
                  <p class="flex-grow-1">{{ errorMessage }}</p>
                  <button class="btn btn-primary" (click)="refreshCandidates()">Retry</button>
                </div>
                <app-table *ngIf="!errorMessage"
                  [tableHeader]="[
                    'Id',
                    'First Name',
                    'Last Name',
                    'Nick Name',
                    'Mobile Phone',
                    'Email',
                    'Consultant',
                    'Status'
                  ]"
                  [tableRows]="[
                    'id',
                    'firstName',
                    'lastName',
                    'nickName',
                    'mobile',
                    'email',
                    'consultant',
                    'status'
                  ]"
                  [data]="candidates"
                  [page]="page"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizeOptions"
                  [collectionSize]="collectionSize"
                  [model]="model"
                  [refreshData]="refreshCandidates"
                  [openViewModel]="openViewModel"
                  [typeAhead]="typeAhead"
                  [loading]="loading"
                  [isView]="true"
                  [isSearch]="true"
                  [title]="'Candidate Search'"
                ></app-table>
              </div>
            </div>
          </div>
        </div>
        <!-- market value area end -->
      </div>
      <!-- main content area end -->
    </div>
  </div>
</div>

<div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [id]="id" [taxStatus]="taxStatus"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
        <app-header></app-header>
        <div class="main-content-inner">
            <div class=" mt-3 m-2">
                <!-- market value area start -->
                <div *ngIf="!newTaxFileTable && !viewTaxFileTable">
                    <div class="table-responsive">
                        <div style="overflow-x:auto">
                            <table *ngIf="!loadingTaxFiles" class="table table-hover align-middle  bg-white">
                                <thead>
                                    <tr>
                                        <th colspan="2">Current</th>
                                        <th colspan="3"> <span *ngIf="profile1Date || profile2Date|| profile4Date">Date
                                                Submitted</span></th>
                                    </tr>
                                </thead>
                                <tbody class="m-3">
                                    <ng-container *ngIf="taxProfiles">
                                        <ng-container *ngFor="let item of taxProfiles">
                                            <tr *ngIf="item.remoteid.includes('PAYG-1')">
                                                <td class="noWrap">
                                                    <span>  {{item.companyName}} </span>
                                                </td>
                                                <td>
                                                    <ng-container
                                                        *ngIf="(pending1Status==1 && profile1Status!=2); else Block1">
                                                        <span>Pending</span>
                                                    </ng-container>
                                                    <ng-template #Block1>
                                                        <app-status-icons [statusValue]="profile1Status==2?'true':'false'"></app-status-icons>                                                       
                                                    </ng-template>
                                                </td>
                                                <td class="noWrap">
                                                    <span *ngIf="profile1Date">
                                                        {{profile1Date | date: "dd-MM-yyy"}}
                                                    </span>
                                                </td>
                                                <td style="align-self: center;">
                                                    <ng-container *ngFor="let a of dataWithStatus2">
                                                        <ng-container *ngIf="a.mid==firstmid ">
                                                            <button (click)="acceptedTaxHis(a)"
                                                                class=" btn btn-sm btn-primary px-3 " type="button"
                                                                title="View File"> View
                                                            </button>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <button *ngIf="!profile1new" class="btn btn-sm btn-info px-3"
                                                        title="Add New File"
                                                        (click)="newTaxFile(item.remoteid, item.mid)">New</button>
                                                    <button *ngIf="profile1new" class="btn btn-sm btn-info px-3 "
                                                        tooltip="Tooltip on top" placement="bottom" ngbTooltip="“There is a current declaration pending. Please edit that form or cancel it
                                                        and start again.">New</button>
                                                </td>
                                            </tr>
                                            <tr *ngIf="item.remoteid.includes('PAYG-2')">
                                                <td class="noWrap">
                                                    <span> {{item.companyName}}</span>
                                                </td>
                                                <td>
                                                    <!-- Visual Prompt  -->
                                                    <ng-container
                                                    *ngIf=" (pending2Status==1 && profile2Status!=2) ; else Block2">
                                                    <span>Pending</span>
                                                    </ng-container>
                                                    <ng-template #Block2>
                                                    <app-status-icons [statusValue]="profile2Status==2?'true':'false'"></app-status-icons>
                                                    </ng-template>
                                        
                                                </td>
                                                <td class="noWrap">
                                                    <span *ngIf="profile2Date ">
                                                        {{profile2Date | date: "dd-MM-yyy"}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <ng-container *ngFor="let b of dataWithStatus2">
                                                        <ng-container *ngIf="b.mid==secondmid ">
                                                            <button class=" btn btn-sm btn-primary px-3 " type="button"
                                                                title="View File" (click)="acceptedTaxHis(b)">
                                                                View
                                                            </button>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <button *ngIf="!profile2new" title="View"
                                                        class="btn btn-sm btn-info px-3 mr-3" title="Add New File"
                                                        (click)="newTaxFile(item.remoteid, item.mid)">
                                                        New
                                                    </button>
                                                    <button *ngIf="profile2new" class="btn btn-sm btn-info px-3 "
                                                        tooltip="Tooltip on top" placement="bottom" ngbTooltip="“There is a current declaration pending. Please edit that form or cancel it
                                                               and start again."> New
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr *ngIf="item.remoteid.includes('PAYG-4')">
                                                <td class="noWrap">
                                                    <span>  {{item.companyName}} </span>
                                                </td>
                                                <td>
                                                    <!-- Visual Prompt  -->
                                                    <ng-container
                                                    *ngIf=" (pending4Status==1 && profile4Status!=2) ; else Block2">
                                                    <span>Pending</span>
                                                    </ng-container>
                                                    <ng-template #Block2>
                                                    <app-status-icons [statusValue]="profile4Status==2?'true':'false'"></app-status-icons>
                                                    </ng-template>
                                                </td>
                                                <td class="noWrap">
                                                    <span *ngIf="profile4Date ">
                                                        {{profile4Date | date: "dd-MM-yyy"}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <ng-container *ngFor="let b of dataWithStatus2">
                                                        <ng-container *ngIf="b.mid==fourthmid ">
                                                            <button class=" btn btn-sm btn-primary px-3 " type="button"
                                                                title="View File" (click)="acceptedTaxHis(b)">
                                                                View
                                                            </button>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <button *ngIf="!profile4new" title="View"
                                                        class="btn btn-sm btn-info px-3 mr-3" title="Add New File"
                                                        (click)="newTaxFile(item.remoteid, item.mid)">
                                                        New
                                                    </button>
                                                    <button *ngIf="profile4new" class="btn btn-sm btn-info px-3 "
                                                        tooltip="Tooltip on top" placement="bottom" ngbTooltip="“There is a current declaration pending. Please edit that form or cancel it
                                                               and start again."> New
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!taxProfiles">
                                        <tr>
                                            <td colspan="4" class="text-center noWrap">No Data</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <ng-container *ngIf="loadingTaxFiles">
                            <div *ngIf="noData==false">
                                <li class="t_loader">
                                    <div class="card__desc load" id="long"></div>
                                </li>
                                <li class="t_loader">
                                    <div class="card__desc load"></div>
                                </li>
                                <li class="t_loader">
                                    <div class="card__desc load" id="long"></div>
                                </li>
                                <li class="t_loader">
                                    <div class="card__desc load"></div>
                                </li>
                                <li class="t_loader">
                                    <div class="card__desc load" id="long"></div>
                                </li>
                                <li class="t_loader">
                                    <div class="card__desc load"></div>
                                </li>
                            </div>
                            <ng-container *ngIf="noData==true">
                                <tr>
                                    <td colspan="5" style="text-align: center">No Data</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <!-- Pending Table Start -->
                        <div style="overflow-x:auto">
                            <table *ngIf="!loadingTaxFiles" class="table  table-hover  align-middle  bg-white">
                                <thead>
                                    <tr>
                                        <th colspan="2">Pending</th>
                                        <th colspan="3">Date Submitted</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="dataWithoutStatus2">
                                        <ng-container *ngFor="let item of dataWithoutStatus2; index as i;">
                                            <ng-container>
                                                <tr *ngIf="item.employee_remote_id.includes('PAYG-1') ">
                                                    <td class="noWrap"> <span> Agri Labour Australia Pty Ltd</span></td>
                                                    <td class="noWrap">
                                                        <span *ngIf="item.requestStatus==0" style="color: red; "
                                                            class="mx-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-x-lg"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                            </svg>
                                                        </span>
                                                    </td>
                                                    <td class="noWrap">{{item.submissionTime | date: "dd-MM-yyy" }}</td>
                                                    <td class="noWrap">
                                                        <button class="btn btn-sm btn-primary px-3 "
                                                            (click)="taxdeclarationHistory(i)" type="button"
                                                            title="View File">
                                                            View
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm btn-info px-3 "
                                                            (click)="editTaxDeclaration(i)" type="button"
                                                            title="Edit File">Edit</button>
                                                    </td>
                                                </tr>
                                                <tr *ngIf=" item.employee_remote_id.includes('PAYG-2') ">
                                                    <td class="noWrap"><span>ALA Staff Sourcing Pty Ltd</span></td>
                                                    <td class="noWrap">
                                                        <span *ngIf="item.requestStatus==0" style="color: red; " class="mx-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                            </svg>
                                                        </span>
                                                    </td>
                                                    <td class="noWrap">{{item.submissionTime | date: "dd-MM-yyy"}}</td>
                                                    <td class="noWrap">
                                                        <button class="btn btn-sm btn-primary px-3 " type="button"
                                                            title="View File" (click)="taxdeclarationHistory(i)">
                                                            View
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm btn-info px-3 " type="button"
                                                            (click)="editTaxDeclaration(i)" title="Edit File">Edit</button>
                                                    </td>
                                                </tr>
                                                <tr *ngIf=" item.employee_remote_id.includes('PAYG-4') ">
                                                    <td class="noWrap"> <span>Health Plus People Pty Ltd</span></td>
                                                    <td class="noWrap">
                                                        <span *ngIf="item.requestStatus==0" style="color: red; " class="mx-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                            </svg>
                                                        </span>
                                                    </td>
                                                    <td class="noWrap">{{item.submissionTime | date: "dd-MM-yyy"}}</td>
                                                    <td class="noWrap">
                                                        <button class="btn btn-sm btn-primary px-3" type="button"
                                                            title="View File" (click)="taxdeclarationHistory(i)">
                                                            View
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm btn-info px-3" type="button"
                                                            (click)="editTaxDeclaration(i)" title="Edit File">Edit</button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!dataWithoutStatus2.length">
                                        <tr>
                                            <td colspan="4" class="text-center">No Data
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="newTaxFileTable" class="row mt-3">
                    <div class="col-12 m-3">
                        <div class="card">
                            <div class="card-body">
                                <form #taxDecelaration="ngForm"
                                    (ngSubmit)="submitElectronicDeclaration(taxDecelaration.value); ">
                                    <div id="taxForm">
                                        <p class="text-center"><strong>* Only English letters and numbers can be
                                                used.</strong></p>
                                        <!-- Q : 1  -->
                                        <div class="row">
                                            <strong class="col">Tax File Number Declaration</strong>
                                            <a target="_blank" name="how_to_complete" id="how_to_complete"
                                                class="col text-right"
                                                href="https://astutepayroll.zendesk.com/hc/en-au/articles/4412088187033-Complete-a-Tax-File-Number-Declaration">How
                                                To Complete This Form</a>
                                        </div>
                                        <!--Q : 2-->
                                        <div class="form-group">
                                            <div class="  my-1 mr-sm-2">
                                                <input type="checkbox" name="no_tfn" id="no_tfn-2"
                                                    (change)="showExpectionReason($event)" [value]=2
                                                    [(ngModel)]="editTaxData.no_tfn" ngModel>
                                                <label for="no_tfn-2" class="ml-2">I don't Have my
                                                    TFN</label>
                                            </div><br>
                                            <div class="input-group " *ngIf="!editTaxData.no_tfn">
                                                <div class="row">
                                                    <label class="col">Tax File Number:
                                                        <input class=" form-control"
                                                            [disabled]="editTaxData.no_tfn || exemption"
                                                            #tfnProvided="ngModel" type="text"
                                                            placeholder="Tax File Number"
                                                            [(ngModel)]="editTaxData.tfnProvided"
                                                            [class.is-invalid]="tfnProvided.invalid && tfnProvided.touched"
                                                            pattern="[0-9]{1,9}" name="tfnProvided" maxlength="9"
                                                            aria-label="Default" required> </label>
                                                </div>
                                                <div class="row">
                                                    <div class="col"></div>
                                                    <div class="col" *ngIf="tfnProvided.invalid &&tfnProvided.touched">
                                                        <div class="alert alert-danger form-control "
                                                            *ngIf="tfnProvided.errors && tfnProvided.errors['pattern']">
                                                            Only numbers can be used.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Q : 3 -->
                                        <div *ngIf="exemption==true || editTaxData.no_tfn">
                                            <div class="dropdown-divider"></div>
                                            <strong for="exemptionReason">Exemption Reason
                                            </strong>
                                            <div id="taxExemptionReasonDiv">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="exemptionReason"
                                                        [(ngModel)]="editTaxData.exemptionReason" id="exemptionReason-4"
                                                        [value]=4 ngModel [checked]="editTaxData.exemptionReason">
                                                    <label class="form-check-label" for="exemptionReason-4">
                                                        I am claiming an exemption because I am in receipt of a pension,
                                                        benefit, or allowance
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="exemptionReason"
                                                        [(ngModel)]="editTaxData.exemptionReason" id="exemptionReason-3"
                                                        [checked]="editTaxData.exemptionReason" [value]=3 ngModel>
                                                    <label class="form-check-label" for="exemptionReason-3">
                                                        I am claiming an exemption because I am under 18 years old and
                                                        do not earn enough to pay tax
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" id="exemptionReason-1"
                                                        [(ngModel)]="editTaxData.exemptionReason" name="exemptionReason"
                                                        [checked]="editTaxData.exemptionReason" [value]=1 ngModel>
                                                    <label class="form-check-label" for="exemptionReason-1">
                                                        I have made a separate application to the ATO for a new or
                                                        existing tax file number </label>
                                                </div>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <!-- Q : 4 -->
                                        <div>
                                            <strong>Have you changed your surname since you last dealt with the
                                                Australian Tax Office?</strong>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="isPreviousSurnameChanged"
                                                    [(ngModel)]="editTaxData.isPreviousSurnameChanged"
                                                    id="isPreviousSurnameChanged-1" [value]=1>
                                                <label class="form-check-label"
                                                    for="isPreviousSurnameChanged-1">Yes</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="isPreviousSurnameChanged"
                                                    [(ngModel)]="editTaxData.isPreviousSurnameChanged"
                                                    id="isPreviousSurnameChanged-0" [value]=0>
                                                <label class="form-check-label"
                                                    for="isPreviousSurnameChanged-0">No</label>
                                            </div>
                                            <div *ngIf="editTaxData.isPreviousSurnameChanged == '1'"
                                                class="form-check form-group" id="previousSurnameDiv">
                                                <strong for="previousSurname" class="optional">Previous
                                                    surname:</strong>
                                                <input type="text" placeholder="Previous surname" name="previousSurname"
                                                    #previousSurname="ngModel" id="previousSurname" maxlength="32"
                                                    [class.is-invalid]="previousSurname.invalid && previousSurname.touched"
                                                    [(ngModel)]="editTaxData.previousSurname" class="form-control"
                                                    pattern="[a-zA-Z][a-zA-Z\s]*" aria-label="Default" required>
                                                <div *ngIf="previousSurname.touched && previousSurname.invalid">
                                                    <div class="alert alert-danger form-control"
                                                        *ngIf="previousSurname.errors &&(previousSurname.errors['pattern'])">
                                                        Only English letters can be used.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <!-- Q : 5 -->
                                        <div id="tax_residency_status">
                                            <strong>Are you an Australian resident for tax purposes or a working
                                                holidays maker?
                                            </strong>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="residencyStatus"
                                                    [(ngModel)]="editTaxData.residencyStatus" id="residencyStatus-A"
                                                    value="A" ngModel (change)="checkForeignOrWork($event)">

                                                <label class="form-check-label" for="residencyStatus-A">
                                                    Australian resident for tax purpose
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="residencyStatus"
                                                    [(ngModel)]="editTaxData.residencyStatus" id="residencyStatus-F"
                                                    value="F" (change)="checkForeignOrWork($event)" ngModel>
                                                <label class="form-check-label" for="residencyStatus-F">
                                                    Foreign resident
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="residencyStatus"
                                                    [(ngModel)]="editTaxData.residencyStatus" value="H"
                                                    (change)="checkForeignOrWork($event)" id="residencyStatus-H"
                                                    ngModel>
                                                <label class="form-check-label" for="residencyStatus-H">
                                                    Working Holidays maker
                                                </label>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <div *ngIf="residencyStatus=='A'  " id="tax_free_threshold">
                                            <strong>Do You want to claim the tax-free threshold from this
                                                player?</strong>
                                            <div class="form-check">
                                                <input class="form-check-input" [value]='1'
                                                    name="taxFreeThresholdClaimed"
                                                    [(ngModel)]="editTaxData.taxFreeThresholdClaimed"
                                                    id="taxFreeThresholdClaimed-1" type="radio">
                                                <label class="form-check-label"
                                                    for="taxFreeThresholdClaimed-1">Yes</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" [value]='0'
                                                    name="taxFreeThresholdClaimed"
                                                    [(ngModel)]="editTaxData.taxFreeThresholdClaimed"
                                                    id="taxFreeThresholdClaimed-0" type="radio">
                                                <label class="form-check-label"
                                                    for="taxFreeThresholdClaimed-0">No</label>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <!-- Q : 7 -->
                                        <div *ngIf=" residencyStatus=='A' || residencyStatus=='F'   ">
                                            <strong>Do you have a Higher Education Loan Program (HELP), VET Student Loan
                                                (VSL), Financial Supplement (FS), Student Start-up Loan (SSL) or Trade Support
                                                Loan (TSL) debt?
                                            </strong>
                                            <div class="form-check" id="tax_has_debt_support">
                                                <input class="form-check-input" type="radio" name="hasHelpDebtSupport"
                                                    [(ngModel)]="editTaxData.hasHelpDebtSupport"
                                                    id="hasHelpDebtSupport-1" [value]=1 ngModel>
                                                <label class="form-check-label" for="hasHelpDebtSupport-1">
                                                    Yes
                                                </label> <br>
                                                <input class="form-check-input" type="radio" name="hasHelpDebtSupport"
                                                    [(ngModel)]="editTaxData.hasHelpDebtSupport"
                                                    id="hasHelpDebtSupport-0" [value]=0 ngModel>
                                                <label class="form-check-label" for="hasHelpDebtSupport-0">
                                                    No
                                                </label>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <!-- Q : 8 -->
                                        <div id="tax_employment_basis">
                                            <strong>On what basis are you paid?
                                            </strong>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="employmentBasis"
                                                    [(ngModel)]="editTaxData.employmentBasis"
                                                    id="employmentBasis-full-time" value="full-time" ngModel>
                                                <label class="form-check-label" for="employmentBasis-full-time">
                                                    Full-time
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="employmentBasis"
                                                    [(ngModel)]="editTaxData.employmentBasis"
                                                    id="employmentBasis-part-time" value="part-time" ngModel>
                                                <label class="form-check-label" for="employmentBasis-part-time">
                                                    Part-time
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="employmentBasis"
                                                    [(ngModel)]="editTaxData.employmentBasis"
                                                    id="employmentBasis-casual" value="casual" ngModel>
                                                <label class="form-check-label" for="employmentBasis-casual">
                                                    Casual
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    id="employmentBasis-labour-hire" name="employmentBasis"
                                                    [(ngModel)]="editTaxData.employmentBasis" value="labour-hire"
                                                    ngModel>
                                                <label class="form-check-label" for="employmentBasis-labour-hire">
                                                    Labour Hire
                                                </label>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <!-- q : 9 -->
                                        <div id="tax_declaration_name">
                                            <strong>I declare that the information transmitted in this TFN Declaration
                                                is true and correct and that I am authorised to make this declaration
                                            </strong><br>
                                            <label for="declarationName" class="required">Full name of the person making
                                                the
                                                declaration</label>
                                            <br>
                                            <div>
                                                <input type="text" class="form-control" name="declarationName"
                                                    #declarationName="ngModel" [(ngModel)]="editTaxData.declarationName"
                                                    id="declarationName"
                                                    placeholder="Full name of the person making the declaration"
                                                    pattern="[a-zA-Z][a-zA-Z\s]*" maxlength="50" aria-label="Username"
                                                    aria-describedby="addon-wrapping" ngModel required>
                                            </div>
                                            <!--  -->
                                            <div *ngIf="declarationName.touched && declarationName.invalid">
                                                <div class="alert alert-danger form-control"
                                                    *ngIf="declarationName.errors &&(declarationName.errors['pattern'])">
                                                    Only English letters can be used.
                                                </div>
                                            </div>
                                            <!--  -->
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <!-- Q : 11 -->
                                        <div *ngIf="residencyStatus=='H' || editTaxData.residencyStatus=='H'"
                                            id="tax_passport_issuance_country">
                                            <div id="passportIssuanceCountry-label">
                                                <strong>Working holiday makers must provide a country of passport
                                                </strong><br>
                                                <strong for="passportIssuanceCountry" class="optional">What is the place
                                                    of issue in your passport?</strong>
                                            </div>
                                            <br>
                                            <select #pCountry (change)="passportCoutrySelect(pCountry.value)"
                                                name="passportIssuanceCountry" id="passportIssuanceCountry"
                                                [(ngModel)]="editTaxData.passportIssuanceCountry" class=" form-control">
                                                <option value="Select" selected="selected">Select</option>
                                                <option *ngFor="let item of passportIssuanceCountryObj | keyvalue"
                                                    [value]="item.value">{{item.key}}</option>
                                            </select>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <div>
                                            <div class="form-group form-check">
                                                <input required type="checkbox" name="declarationSigned"
                                                    id="declarationSigned" class="form-check-input" value=1
                                                    [(ngModel)]="editTaxData.declarationSigned" ngModel>
                                                <strong class="form-check-label required" for="declarationSigned">Tick
                                                    this box to sign with the identification details you used to log on
                                                    and your name supplied above</strong>
                                            </div>
                                            <span></span>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                        <!-- Adding name, email and contact number of candidate for pdf submit   -->
                                        <div id="extraData" class="hidden-content mt-2">
                                            <div><b>Submitted By:&nbsp;&nbsp;</b>{{name}}</div>
                                            <div><b>Login Credentials:&nbsp;&nbsp;</b> {{mobile?mobile:email}}</div>
                                            <div><b>Submission Date:&nbsp;&nbsp;</b>{{submissionDate}}</div>
                                        </div>

                                    </div>
                                    <!-- Q : 12 -->
                                    <div class="float-right">
                                        <button (click)=" newTaxFileTable=false"
                                            class=" btn btn-sm btn-danger m-1 px-3 " type="button">
                                            Cancel
                                        </button>
                                        <button class="btn ml-1 btn-sm btn-info m-1 px-3 onboarding_button "
                                            value="submit" title="Submit File" type="submit" data-target="#submitTFN"
                                            data-toggle="modal">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->
                <!-- Modal on Submit form -->
                <div class="modal fade " id="submitTFN" tabindex="-1" role="dialog" aria-labelledby="submitTFNLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-body ">
                                <div *ngIf="messaageForUser">
                                    <p style="text-align: center;">{{messaageForUser}} </p>
                                    <div class="row">
                                        <div class="col-5"></div>
                                        <button *ngIf="!canResetForm" type="button"
                                            class="btn btn-sm btn-info px-3 mr-3" data-dismiss="modal">Ok</button>
                                        <button *ngIf="canResetForm" type=" button"
                                            class="btn btn-sm btn-info px-3 mr-3" (click)="newTaxFileTable=false"
                                            data-dismiss="modal">Ok</button>
                                        <div class=" col-2">
                                        </div>
                                        <div class="col-5"></div>
                                    </div>
                                </div>
                                <div *ngIf="!messaageForUser">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="viewTaxFileTable">
                    <button (click)="viewTaxFileTable=false" class=" btn badge badge-primary  mt-3 " type="button">
                        Back
                    </button>
                    <div class="  bg-white m-2">
                        <div class="mx-4">
                            <table class="table table-borderless table-responsive" style="overflow-x:auto">
                                <tbody>
                                    <tr>
                                        <td scope="col" colspan="4"><strong>Tax File Number Declaration
                                            </strong></td>
                                    </tr>
                                    <!-- 0 -->
                                    <tr *ngIf="viewFormData.submissionTime">
                                        <td scope="col" colspan="3">
                                            <strong>Submission Date
                                            </strong>
                                        </td>
                                        <td scope="col">
                                            <span>{{viewFormData.submissionTime | date: 'dd/MM/yyyy'}}</span>
                                        </td>
                                    </tr>
                                    <!-- 1 -->
                                    <tr>
                                        <td scope="col" colspan="4" style="background-color: #e9ecef;   ">
                                            <strong> Tax File number Declaration</strong>
                                        </td>
                                    </tr>
                                    <!-- 2 -->
                                    <tr>
                                        <td scope="col" colspan="3">
                                            <strong *ngIf="viewFormData.no_tfn" for="no_tfn-2">I
                                                don't Have my TFN</strong> <br>
                                            <strong *ngIf="viewFormData.tfnProvided" for="tfnProvided">Tax File
                                                Number: </strong>
                                        </td>
                                        <td scope="col">
                                            <input *ngIf="viewFormData.no_tfn" type="checkbox" name="no_tfn"
                                                id="no_tfn-2" [(ngModel)]="viewFormData.no_tfn" disabled ngModel><br>
                                            <input *ngIf="viewFormData.tfnProvided" disabled type="text"
                                                placeholder="Tax File Number" [(ngModel)]="viewFormData.tfnProvided"
                                                name="tfnProvided" id="tfnProvided" aria-label="Default" ngModel>
                                        </td>
                                    </tr>
                                    <!-- 3 -->
                                    <tr *ngIf="viewFormData.exemptionReason">
                                        <td scope="col" colspan="2">
                                            <strong for="exemptionReason">Exemption Reason
                                            </strong>
                                        </td>
                                        <!-- Options  -->
                                        <td colspan="2" scope="col">
                                            <div>
                                                <input type="radio" name="exemptionReason" id="exemptionReason-4"
                                                    [(ngModel)]="viewFormData.exemptionReason" [value]=4 disabled>
                                                <label class="form-check-label mx-2" for="exemptionReason-4">
                                                    I am claiming an exemption because I am in receipt of a
                                                    pension, benefit, or allowance
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" name="exemptionReason" id="exemptionReason-3"
                                                    [value]=3 [(ngModel)]="viewFormData.exemptionReason" disabled>
                                                <label class="form-check-label mx-2" for="exemptionReason-3">
                                                    I am claiming an exemption because I am under 18 years
                                                    old and do not earn enough to pay tax
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" id="exemptionReason-1" name="exemptionReason"
                                                    [value]=1 [(ngModel)]="viewFormData.exemptionReason" disabled>
                                                <label class="form-check-label mx-2" for="exemptionReason-1">
                                                    I have made a separate application to the ATO for a new
                                                    or existing tax file number
                                                </label>
                                            </div>
                                        </td>

                                    </tr>
                                    <!-- 4 -->
                                    <tr>
                                        <td scope="col" colspan="3"> <strong>Have you change your surename since
                                                you last dealt with the Australian Tax Office
                                            </strong>
                                        </td>
                                        <td scope="col">
                                            <input disabled type="radio" name="isPreviousSurnameChanged"
                                                [checked]="viewFormData.isPreviousSurnameChanged == 1"
                                                id="isPreviousSurnameChanged-1" value="true">
                                            <label for="isPreviousSurnameChanged-1" class="mx-2">
                                                Yes
                                            </label><br>
                                            <input disabled type="radio" name="isPreviousSurnameChanged"
                                                [checked]="viewFormData.isPreviousSurnameChanged == 0"
                                                id="isPreviousSurnameChanged-0" value="false">
                                            <label for="isPreviousSurnameChanged-0" class="mx-2">
                                                No
                                            </label>
                                        </td>
                                    </tr>
                                    <tr *ngIf="viewFormData.previousSurname">
                                        <td scope="col" colspan="3">
                                            <strong for="previousSurname">Previous surname: </strong>
                                        </td>
                                        <td scope="col">
                                            <input type="text" placeholder="Previous surename" name="previousSurname"
                                                [(ngModel)]="viewFormData.previousSurname " id="previousSurname"
                                                disabled aria-label="Default" ngModel>
                                        </td>
                                    </tr>
                                    <!-- 5 -->
                                    <tr *ngIf="viewFormData.residencyStatus">
                                        <td scope="col" colspan="3"> <strong>Are you an Australian resident for
                                                tax purposes or a working holidays maker?
                                            </strong></td>
                                        <td scope="col">
                                            <div>
                                                <input type="radio" disabled name="residencyStatus"
                                                    [(ngModel)]="viewFormData.residencyStatus " id="residencyStatus-A"
                                                    value="A" ngModel>
                                                <label for="residencyStatus-A" class="mx-2">
                                                    Australian resident for tax purpose
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" [(ngModel)]="viewFormData.residencyStatus " disabled
                                                    name="residencyStatus" id="residencyStatus-F" value="F" ngModel>
                                                <label for="residencyStatus-F" class="mx-2">
                                                    Foreign resident
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" disabled name="residencyStatus"
                                                    [(ngModel)]="viewFormData.residencyStatus " value="H"
                                                    id="residencyStatus-H" ngModel>
                                                <label for="residencyStatus-H" class="mx-2">
                                                    Working Holidays maker
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- 6 -->
                                    <tr *ngIf="viewFormData.residencyStatus=='A'  ">
                                        <td scope="col" colspan="3"> <strong>Do You want to claim the tax free
                                                threshold from this player?
                                            </strong></td>
                                        <td scope="col">
                                            <input disabled name="taxFreeThresholdClaimed"
                                                [checked]="viewFormData.taxFreeThresholdClaimed == 1"
                                                id="taxFreeThresholdClaimed-1" type="radio" value="1">
                                            <label for="taxFreeThresholdClaimed-1" class="mx-2">
                                                Yes
                                            </label>
                                            <br>
                                            <input disabled type="radio" name="taxFreeThresholdClaimed"
                                                [checked]="viewFormData.taxFreeThresholdClaimed == 0"
                                                id="taxFreeThresholdClaimed-0" value="0">
                                            <label for="taxFreeThresholdClaimed-0" class="mx-2">
                                                No
                                            </label>

                                        </td>
                                    </tr>
                                    <!-- 7 -->
                                    <tr *ngIf="viewFormData.residencyStatus=='A' ||viewFormData.residencyStatus=='F'  ">
                                        <td scope="col" colspan="3"> <strong>Do you have a Higher Education Loan
                                                Program (HELP), VET Student Loan (VSL),
                                                Financial Supplement (FS), Student Start-up Loan (SSL) or Trade
                                                Support Loan (TSL) debt?
                                            </strong></td>
                                        <td scope="col">
                                            <input type="radio" disabled name="hasHelpDebtSupport"
                                                [checked]="viewFormData.hasHelpDebtSupport == 1"
                                                id="hasHelpDebtSupport-1" value="1">
                                            <label for="hasHelpDebtSupport-1" class="mx-2">
                                                Yes
                                            </label> <br>
                                            <input type="radio" [checked]="viewFormData.hasHelpDebtSupport == 0"
                                                disabled name="hasHelpDebtSupport" id="hasHelpDebtSupport-0" value="0">
                                            <label for="hasHelpDebtSupport-0" class="mx-2">
                                                No
                                            </label>
                                        </td>
                                    </tr>
                                    <!-- 8 -->
                                    <tr *ngIf="viewFormData.employmentBasis">
                                        <td scope="col" colspan="3"> <strong>On what basis are you paid?</strong></td>
                                        <td scope="col">
                                            <div>
                                                <input type="radio" disabled name="employmentBasis"
                                                    [ngModel]="viewFormData.employmentBasis "
                                                    id="employmentBasis-full-time" value="full-time" ngModel>
                                                <label for="employmentBasis-full-time" class="mx-2"> Full-time
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" disabled name="employmentBasis"
                                                    id="employmentBasis-part-time" value="part-time"
                                                    [(ngModel)]="viewFormData.employmentBasis " ngModel>
                                                <label for="employmentBasis-part-time" class="mx-2"> Part-time
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" disabled name="employmentBasis"
                                                    id="employmentBasis-casual"
                                                    [(ngModel)]="viewFormData.employmentBasis " value="casual" ngModel>
                                                <label for="employmentBasis-casual" class="mx-2"> Casual
                                                </label>
                                            </div>
                                            <div>
                                                <input disabled type="radio" [(ngModel)]="viewFormData.employmentBasis "
                                                    id="employmentBasis-labour-hire" name="employmentBasis"
                                                    value="labour-hire" ngModel>
                                                <label for="employmentBasis-labour-hire" class="mx-2"> Labour Hire
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- 9 -->
                                    <tr>
                                        <td scope="col" colspan="4" style="background-color: #e9ecef; ">
                                            <strong>I declare that the information transmitted in this TFN Declaration
                                                is true
                                                and correct and that I am authorised to make this declaration
                                            </strong>
                                        </td>
                                    </tr>
                                    <!-- 10 -->
                                    <tr>
                                        <td scope="col" colspan="3">
                                            <strong *ngIf="viewFormData.declarationName " for="declarationName">Full
                                                name of the person making the declaration</strong>
                                            <br>
                                            <strong *ngIf="viewFormData.declarationSigned " for=" Check1"
                                                for="declarationSigned">Tick this box to sign with the
                                                identification details you used to log on and your name supplied
                                                above</strong>
                                        </td>
                                        <td scope="col">
                                            <input *ngIf="viewFormData.declarationName " type="text" disabled
                                                name="declarationName" id="declarationName" value
                                                placeholder="Full name " aria-label="Username"
                                                [(ngModel)]="viewFormData.declarationName"
                                                aria-describedby="addon-wrapping" ngModel />
                                            <br>
                                            <input *ngIf="viewFormData.declarationSigned " disabled type="checkbox"
                                                [(ngModel)]="viewFormData.declarationSigned " name="declarationSigned"
                                                id="declarationSigned" ngModel />
                                        </td>
                                    </tr>
                                    <!-- 11 -->
                                    <tr
                                        *ngIf="viewFormData.residencyStatus=='H' && viewFormData.passportIssuanceCountry">
                                        <td scope="col" colspan="3"><strong>Working holiday makers must provide a
                                                country of passport </strong><br>
                                            <strong for="passportIssuanceCountry">What is the place of issue in your
                                                passport?</strong>
                                        </td>
                                        <td scope="col">
                                            <div *ngFor="let country of passportIssuanceCountryObj | keyvalue">
                                                <div *ngIf="country.value == viewFormData.passportIssuanceCountry">
                                                    <span> {{country.key}} </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- market value area end -->
            </div>
        </div>
        <!-- main content area end -->
    </div>
</div>
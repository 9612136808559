<div *ngIf="displayLoader == 'false'" id="login-banner">
  <div class="container ">
    <!-- phonefactor_container -->
    <div class="">
      <div class="row" style="
          position: absolute !important;
          top: 28%;
          left: 0;
          right: 0;
          margin: 0;
        ">
        <div class="col-1"></div>
        <div class=" col-10 panel panel-default" style="background-color:white ;">
          <div class=" panel-body" style=" padding-top: 3% !important; padding-bottom: 8% !important ; ">
            <img alt="Company Logo" class="companyLogo" [src]='brandLogo' width="40%"/>
            <div id="api" class="">
              <div class="">
                <button *ngIf="!loginDisplay" (click)="login()" class="btn px-4 btn-primary"
                  style="margin-top: 7%; align-items: center; align-self: center; ">
                  Login To {{portalName}} Portal
                </button>
                <div class="pt-3" *ngIf="loginDisplay">
                  <button class="btn btn-sm btn-primary mr-3 px-3" (click)="logout()">
                    Log Out
                  </button>
                  <span *ngIf="isAdminFlow">
                    <button class="btn btn-sm btn-outline-primary" [routerLink]="['/candidate-search']">
                      Go To Dashboard
                    </button>
                  </span>
                  <span *ngIf="!isAdminFlow">
                    <button class="btn btn-sm btn-outline-primary" [routerLink]="['/profile']">
                      Go To Profile
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="displayLoader == 'true'" class="blur-content">
  <div class="page-container" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [homeData]="payrollIconCount" ></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
      <app-header></app-header>
      <div class="main-content-inner">
        <!-- market value area start -->
        <div class="row mt-5 mb-5">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="market-status-table mt-2 profile">
                  <div class="row mb-2">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">Title</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="some title" />
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">First Name</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="firstName" />
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">Last Name</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="lastName" />
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">Preferred Name</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="nickName" />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">Postal Code</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="profile.zip" />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">City</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="profile.city" />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">State</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="profile.state" />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-lg-2 col-sm-6 label">
                      <p class="label-text">Country</p>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <input disabled type="text" class="form-control" value="profile.customText11" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- market value area end -->
        </div>
      </div>
      <!-- main content area end -->
    </div>
  </div>
</div>

<div *ngIf="displayLoader == 'true'" id="unblurred">
  <div id="box" class="row">
    <div class="snippet" data-title=".dot-spin">
      <div class="stage">
        <div class="dot-spin"></div>
        <h3>Loading...</h3>
      </div>
    </div>
  </div>
</div>